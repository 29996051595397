import React, { FC, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import mime from 'mime'

import { toast } from 'App'

import { IIpfsFiles } from 'models'

import { useStore } from 'store'

import { ReactComponent as CloseIcon } from 'sources/images/close2.svg'
import ExcelIcon from 'sources/images/fileTypes/excel.png'
import PDFIcon from 'sources/images/fileTypes/pdf.png'
import PlainIcon from 'sources/images/fileTypes/plain.png'
import WordIcon from 'sources/images/fileTypes/word.png'
import { ReactComponent as File } from 'sources/images/superpostTypes/file.svg'

import { CustomViewZoom } from '../CustomViewZoom'
import InnerLoading from '../InnerLoading'
import { DownloadButton } from '../ViewImageGifVideo/DownloadButton'

import styles from './styles.module.scss'

// const mime = require('mime')

interface IProps {
  file: IIpfsFiles
  onDeleteFile?: (postId: string, key: string) => void
  postId?: string
  createMode?: boolean
  onCheckFile?: (id: string, fileId: string) => void
  isFileLoadingByViewerError?: boolean
}

export const FileView: FC<IProps> = ({
  postId,
  file,
  onDeleteFile,
  createMode,
  onCheckFile,
  isFileLoadingByViewerError,
}) => {
  const [isZoomed, setIsZoomed] = useState(false)
  const [filePreview, setFilePreview] = useState(
    createMode
      ? ''
      : 'https://authencity.infura-ipfs.io/ipfs/QmZpGT47ZMRjGK3QtVhg7fwi4oGPSkVgGFV9wdD5TjMdoe'
  )
  const [loading, setLoading] = useState(false)

  const previewLink = useMemo(() => {
    return filePreview
    // return createMode ? filePreview : file.FilePreview
  }, [filePreview, file, createMode])

  const getIcon = useMemo(() => {
    const mimeType = createMode
      ? mime.getExtension((file.FileLink as any).type)
      : file.FileType //need new field
    if (mimeType === 'doc' || mimeType === 'docx') return WordIcon
    if (mimeType === 'xls' || mimeType === 'xlsx') return ExcelIcon
    if (mimeType === 'pdf') return PDFIcon
    if (mimeType === 'txt') return PlainIcon
    return WordIcon
  }, [file])

  useEffect(() => {
    checkFile()
    window.addEventListener('scroll', handleScroll)
    document.addEventListener('keydown', handleClickEscape)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      document.removeEventListener('keydown', handleClickEscape)
    }
  }, [])

  const checkFile = async () => {
    if (createMode && postId && onCheckFile) {
      setLoading(true)
      try {
        // api call
        //   setFilePreview
        //
        setTimeout(() => {
          setLoading(false)
          onCheckFile(postId, file.Id)
          setFilePreview(
            'https://authencity.infura-ipfs.io/ipfs/QmZpGT47ZMRjGK3QtVhg7fwi4oGPSkVgGFV9wdD5TjMdoe'
          )
        }, 100)
      } catch (e) {
        setLoading(false)
        deleteFile()
        toast({
          type: 'warning',
          message: 'The file you tried to add is not trusted',
        })
      }
    }
  }

  const handleScroll = () => {
    setIsZoomed(false)
  }

  const handleClickEscape = (e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      setIsZoomed(false)
    }
  }

  const handleClickOnZoom = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if ((e.target as HTMLDivElement).tagName === 'BUTTON') {
      setIsZoomed(prev => !prev)
    }
  }

  const deleteFile = () => {
    if (postId && onDeleteFile) {
      onDeleteFile(postId, file.FileLink)
    }
  }

  return (
    <div
      className={cn({
        [styles.fileViewContainer]: true,
        [styles.fileViewContainerCreateMode]: createMode,
      })}
    >
      {createMode ? (
        <button className={styles.deleteButton} onClick={deleteFile}>
          <CloseIcon />
        </button>
      ) : null}

      <div className={styles.fileNameBlock}>
        <img src={getIcon} />
        {(file.FileLink as any).name}
      </div>

      <div className={styles.imageBlock} onClick={handleClickOnZoom}>
        {!createMode ? (
          <DownloadButton
            imageUrl={file.FileLink}
            showWarning={isFileLoadingByViewerError}
            fileType={file.FileType}
          />
        ) : null}

        {filePreview || !createMode ? (
          <div className={styles.iconsBlock}>
            <File style={{ zIndex: 1 }} />
          </div>
        ) : null}
      </div>
      <InnerLoading loading={loading} text={'Checking'} dots />
    </div>
  )
}
