import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import Checkbox from '@mui/material/Checkbox'
import ButtonIcon from '../ButtonIcon'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SxProps } from '@mui/system'

import styles from './styles.module.scss'


interface IProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  formSX?: SxProps
}

export const AdultCheckbox: FC<IProps> = ({ checked, onChange, formSX }) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          sx={{
            color: 'var(--textColor)'
          }}
        />
      }
      label={
        <>
          <span>This is adult / sensitive content</span>
          <ButtonIcon
            style={{
              minWidth: '13px',
              width: '13px',
              height: '13px',
              margin: '0 0 1px 3px'
            }}
            styleIcon={{
              width: '7px',
              height: '7px'
            }}
            icon={'info'}
            tooltip="By selecting the checkbox, you acknowledge that the content you are uploading is intended for mature audiences and may contain sensitive or adult material. Therefore, it will be subject to special visual treatment in the live feed, and only users who are 18 years old or older will be able to view it."
            tooltipPosition="top"
            tooltipDisablePortal={false}
            bordered
          />
        </>}
      className={styles.adultCheckbox}
      sx={formSX}
    />
  )
}
