import React, { FC, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import styles from './styles.module.scss'
import { UserInfoBoxForTag } from './UserInfoBoxForTag'
import editorStyles from '../CustomEditor/editorStyles.module.scss'
import Zoom from '@material-ui/core/Zoom'
import Tooltip from '@material-ui/core/Tooltip'
import { IUserInfoByTag } from 'models'

interface IProps {
  children?: React.ReactElement
  userData?: IUserInfoByTag
  nickName: string
}

export const UserInfoTooltip: FC<IProps> = ({ children, userData, nickName }) => {
  const [userInfo] = useState(() => {
    if (userData) {
      return userData
    } else {
      return {
        FirstName: '',
        ImageLink: '',
        LastName: '',
        NickName: nickName,
        OpenSeaLink: null
      }
    }
  })

  return (
    <Tooltip title={<UserInfoBoxForTag userData={userInfo} style={{ color: 'white', padding: '5px 0 7px' }}/>}
             placement={'top'}
             arrow
             interactive
             classes={{ popper: editorStyles.popper, popperArrow: editorStyles.popperArrow }}
             enterTouchDelay={0}
             TransitionComponent={Zoom}
             PopperProps={{
               disablePortal: true,
               popperOptions: {
                 positionFixed: true,
                 modifiers: {
                   preventOverflow: {
                     enabled: true,
                     boundariesElement: 'window'
                   }
                 }
               }
             }}
    >
      {children
        ? children
        : <span className={styles.taggedNickname}>@{userInfo.NickName}</span>}
    </Tooltip>
  )
}
