import React, { CSSProperties } from 'react'

import { Dots } from 'components/Dots'

import { ReactComponent as LoaderInner } from 'sources/images/loader/loaderInner.svg'
import { ReactComponent as LoaderOuter } from 'sources/images/loader/loaderOuter.svg'

import styles from './styles.module.scss'

interface IProps {
  loading: boolean
  text?: string
  dots?: boolean
  style?: CSSProperties
  styleLoading?: CSSProperties
}

const InnerLoading: React.FC<IProps> = ({
  loading,
  text,
  dots,
  style,
  styleLoading,
}) => {
  return (
    <>
      {loading ? (
        <div className={styles.loading} style={style}>
          <div className={styles.loaderBox} style={styleLoading}>
            <LoaderOuter className={styles.loaderOuter} />
            <LoaderInner className={styles.loaderInner} />
          </div>
          {text ? (
            <div className={styles.textBox}>
              <div className={styles.text}>{text}</div>
              {dots ? <Dots /> : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

InnerLoading.displayName = 'InnerLoading'

export default InnerLoading
