import PropTypes from 'prop-types';
import React from 'react';

import { StyleButton } from '../StyleButton';
import { HYPERLINK } from '../../../util/constants'
import cn from 'classnames'
import styles from '../styles.module.scss'


export const INLINE_BUTTONS = [
  {
    label: 'B',
    style: 'BOLD',
    description: 'Bold'
  },
  {
    label: 'I',
    style: 'ITALIC',
    description: 'Italic'
  },
  {
    label: 'U',
    style: 'UNDERLINE',
    description: 'Underline'
  },
  // {
  //   label: 'Hi',
  //   style: 'HIGHLIGHT',
  //   description: 'Highlight selection'
  // },
]

interface IInlineToolbarProps {
  editorState: any;
  onToggle: (style: string) => void;
}

const InlineToolbar: React.FC<IInlineToolbarProps> = ({editorState, onToggle}) => {

  const currentStyle = editorState.getCurrentInlineStyle();
  return (
    // <div className="md-RichEditor-controls md-RichEditor-controls-inline">
    <div className={cn(styles.controls)}>
      {INLINE_BUTTONS.map(type => {
        return (
          <StyleButton
            key={type.style}
            label={type.label}
            active={currentStyle.has(type.style)}
            onToggle={onToggle}
            style={type.style}
            description={type.description}
          />
        );
      })}
    </div>
  );
};


export default InlineToolbar;
