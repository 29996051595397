import React, { CSSProperties, FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'

import { getRoundingValue } from 'utils'

import { IUserInfoByTag } from 'models'

import { useStore } from 'store'

import { ReactComponent as CompanyIcon } from 'sources/images/companyIconWhite.svg'
import defaultUserPhoto from 'sources/images/defaultUser.svg'

import styles from './styles.module.scss'

interface IProps {
  userData: IUserInfoByTag
  style?: CSSProperties
}

export const UserInfoBoxForTag: FC<IProps> = observer(({ userData, style }) => {
  const navigate = useNavigate()
  const { profile } = useStore()

  const [data, setData] = useState<IUserInfoByTag>(userData)
  // const [loading, setLoading] = useState(true)

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    try {
      const userCachedData = profile.usersInfoByTag.find(
        item => item.NickName === userData.NickName
      )
      if (userCachedData) {
        setData(userCachedData)
      } else {
        const response = await profile.getInfoForTagInPost(userData.NickName)
        setData(response)
        profile.setUsersInfoByTag([...profile.usersInfoByTag, response])
      }
    } catch (e) {
      console.log('getInfoForTagInPost', e)
    } finally {
      // setLoading(false)
    }
  }

  const redirectToProfile = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    nickName: string
  ) => {
    e.stopPropagation()
    nickName && navigate(`/user/${nickName}`)
  }

  return (
    <div className={styles.userInfoContainer} style={style}>
      <div
        className={styles.userInfo}
        onClick={e => redirectToProfile(e, userData.NickName)}
      >
        <LazyLoadImage
          className={styles.avatar}
          alt="avatar"
          effect="blur"
          src={data.ImageLink ? data.ImageLink : defaultUserPhoto}
        />
        <div className={styles.userNameBox}>
          <div
            className={styles.userName}
          >{`${data.FirstName} ${data.LastName}`}</div>
          <div className={styles.nickname}>
            {data.NickName ? `@${data.NickName}` : ''}
          </div>
        </div>
      </div>
      <div className={styles.userInfoActivity}>
        <div className={styles.backedBy}>
          Backed by:{' '}
          {getRoundingValue(data.BackProfileCount ? data.BackProfileCount : 0)}
          <CompanyIcon className={styles.backedByIcon} />
        </div>
        <div className={styles.followers}>
          Followers: {data.FollowersCount ? data.FollowersCount : 0}
        </div>
      </div>
    </div>
  )
})
