import React, { FC, useEffect, useMemo, useState } from 'react'

import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'

import axios from 'axios'
// import DocViewer, { DocViewerRenderers } from 'react-doc-viewer'
import cn from 'classnames'
import mime from 'mime'

import { toast } from '../../App'

import { IIpfsFiles } from 'models'

import { ReactComponent as CloseIcon } from 'sources/images/close2.svg'
import ExcelIcon from 'sources/images/fileTypes/excel.png'
import PDFIcon from 'sources/images/fileTypes/pdf.png'
import PlainIcon from 'sources/images/fileTypes/plain.png'
import WordIcon from 'sources/images/fileTypes/word.png'

import { FileView } from '../FileView'
import { FileWarningOverlay } from '../FileWarningOverlay'
import { DownloadButton } from '../ViewImageGifVideo/DownloadButton'

import styles from './styles.module.scss'

interface IProps {
  file: IIpfsFiles
  className?: string
  onDeleteFile?: (postId: string, key: string) => void
  postId?: string
  createMode?: boolean
  onCheckFile?: (id: string, fileId: string) => void
  postOnX?: boolean
}

export const FileViewer: FC<IProps> = React.memo(props => {
  const {
    file,
    className,
    postId,
    onDeleteFile,
    createMode,
    onCheckFile,
    postOnX,
  } = props

  const mimeType: string = useMemo(() => {
    return (
      createMode
        ? mime.getExtension((file.FileLink as any).type)
        : file.FileExtension
    ) as string
  }, [file])

  const getIcon = useMemo(() => {
    if (mimeType === 'doc' || mimeType === 'docx') return WordIcon
    if (mimeType === 'xls' || mimeType === 'xlsx') return ExcelIcon
    if (mimeType === 'pdf') return PDFIcon
    if (mimeType === 'txt') return PlainIcon
    if (mimeType === 'html') return PlainIcon
    return WordIcon
  }, [mimeType])

  const [isError, setIsError] = useState(false)
  const [isZoomed, setIsZoomed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFile, setShowFile] = useState(false)

  const [doc, setDoc] = useState('')
  const fileExtension = !createMode
    ? file.FileLink?.split('.')?.at(-1) || ''
    : ''
  const checkPdfTxtExtension =
    ['pdf', 'txt', 'html'].includes(fileExtension) && fileExtension

  useEffect(() => {
    // workaround to view files. The viewer doesn't show files because CDN doesn't return since files are private
    const fetchData = async () => {
      const response = await axios.get(file.FileLink, {
        responseType: 'blob',
      })

      let type = ''

      switch (fileExtension) {
        case 'pdf':
          type = 'application/pdf'
          break
        case 'txt':
          type = 'text/plain'
          break
        case 'html':
          type = 'text/html'
          break
        default:
      }

      const blob = new Blob([response.data], {
        type: type,
      })
      const blobUrl = URL.createObjectURL(blob)

      setDoc(blobUrl)
    }

    if (!createMode && checkPdfTxtExtension) {
      fetchData()
    }
  }, [createMode, file])

  useEffect(() => {
    checkFile()
    window.addEventListener('scroll', handleScroll)
    document.addEventListener('keydown', handleClickEscape)

    return () => {
      window.removeEventListener('scroll', handleScroll)
      document.removeEventListener('keydown', handleClickEscape)
    }
  }, [])

  const checkFile = async () => {
    if (createMode && postId && onCheckFile) {
      setLoading(true)
      try {
        // api call
        //   setFilePreview
        //
        setTimeout(() => {
          setLoading(false)
          onCheckFile(postId, file.Id)
          setShowFile(true)
        }, 1000)
      } catch (e) {
        setLoading(false)
        deleteFile()
        toast({
          type: 'warning',
          message: 'The file you tried to add is not trusted',
        })
      }
    }
  }

  const handleScroll = () => {
    setIsZoomed(false)
  }

  const handleClickEscape = (e: KeyboardEvent) => {
    if (e.key == 'Escape') {
      setIsZoomed(false)
    }
  }

  const handleClickOnZoom = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if ((e.target as HTMLDivElement).tagName === 'BUTTON') {
      setIsZoomed(prev => !prev)
    }
  }

  const deleteFile = () => {
    if (postId && onDeleteFile) {
      onDeleteFile(postId, file.FileLink)
    }
  }

  const microsoftOfficeFormats =
    mimeType === 'doc' ||
    mimeType === 'docx' ||
    mimeType === 'xls' ||
    mimeType === 'xlsx'

  if (isError || (createMode && microsoftOfficeFormats)) {
    return (
      <FileView
        file={file}
        isFileLoadingByViewerError={true}
        onCheckFile={onCheckFile}
        onDeleteFile={onDeleteFile}
        createMode={createMode}
        postId={postId}
      />
    )
  }

  return (
    <div
      className={cn({
        [styles.fileViewer]: true,
        [styles.fileViewerContainerCreateMode]: createMode,
        [className || '']: className,
      })}
    >
      {createMode && (
        <FileWarningOverlay
          file={file}
          postOnX={!!postOnX}
          text={
            "This attachment exceeds X's (Twitter's) size or format limits. It will be posted only on Authencity"
          }
        />
      )}

      {createMode ? (
        <button className={styles.deleteButton} onClick={deleteFile}>
          <CloseIcon />
        </button>
      ) : null}

      <div className={styles.fileNameBlock}>
        <img src={getIcon} />
        {(file.FileLink as any).name}
      </div>

      {(!createMode || (createMode && showFile)) && (
        <DocViewer
          documents={[
            {
              uri: !createMode
                ? checkPdfTxtExtension
                  ? doc
                  : file.FileLink
                : (window.URL.createObjectURL(file.FileLink as any) as any),
            },
          ]}
          pluginRenderers={DocViewerRenderers}
          config={{
            header: {
              disableHeader: true,
            },
            noRenderer: {
              overrideComponent: () => {
                setIsError(true)

                return null
              },
            },
          }}
          className={cn({
            [styles.docViewer_docx_xlsx]: ['docx', 'xls', 'xlsx'].includes(
              mimeType
            ),
          })}
        />
      )}

      {!createMode && (
        <div className={styles.buttons}>
          <DownloadButton
            imageUrl={file.FileLink}
            fileType={file.FileType}
            showWarning={true}
          />
        </div>
      )}
    </div>
  )
})
