import React, { FC, useEffect, useId, useRef, useState } from 'react'
//@ts-ignore
// import ReactPannellum, { getConfig } from 'react-pannellum'
import { ModalView } from '../ModalView'
import { ReactComponent as View360Icon } from 'sources/images/view_360.svg'
// import { v4 as uuidv4 } from 'uuid'
// import cn from 'classnames'
// import { IconButton } from '@mui/material'
// import {
//   Fullscreen,
//   FullscreenExit,
//   ExploreOutlined,
// } from '@mui/icons-material'
// import AdjustIcon from '@mui/icons-material/Adjust'
// import AddIcon from '@mui/icons-material/Add'
// import RemoveIcon from '@mui/icons-material/Remove'
// import ClearIcon from '@mui/icons-material/Clear'

import View360New from './NewViewer'
import { IIpfsFiles } from '../../models/user'

import styles from './styles.module.scss'
import { useDebouncedCallback } from 'use-debounce'

interface IProps {
  url: string
  openImmediately?: boolean
  openMode?: 'modal' | 'block'
  imagePosition?: IIpfsFiles['Image360Position'] | null
  onRotateOnCreate?: (position: IIpfsFiles['Image360Position']) => void
}

//Resize
export const View360: FC<IProps> = ({
  url,
  openImmediately = false,
  openMode = 'modal',
  imagePosition,
  onRotateOnCreate,
}) => {
  const [open, setOpen] = useState(false)
  // const [fullscreen, setFullscreen] = useState(false)
  // const [isCompassActive, setIsCompassActive] = useState(false)

  // useEffect(() => {
  //   document.addEventListener('fullscreenchange', handleToggleFullscreen)
  //
  //   return () => {
  //     document.removeEventListener('fullscreenchange', handleToggleFullscreen)
  //   }
  // }, [])

  const [position, setPosition] = useState({
    yaw: 0,
    pitch: 0,
  })

  const onRotate = useDebouncedCallback((position: any) => {
    setPosition(position)
  }, 500)

  const closeModal = () => {
    // ReactPannellum.destroy()
    setOpen(false)
    onRotateOnCreate?.(position)
  }

  const openModal = () => {
    setOpen(true)
  }

  // const handleToggleFullscreen = () => {
  //   setFullscreen(prev => !prev)
  // }

  // const toggleFullscreen = () => {
  //   ReactPannellum.toggleFullscreen()
  // }
  //
  // const toggleCompass = () => {
  //   if (!ReactPannellum.isOrientationSupported()) return
  //   if (ReactPannellum.isOrientationActive()) {
  //     setIsCompassActive(false)
  //     ReactPannellum.stopOrientation()
  //   } else {
  //     setIsCompassActive(true)
  //     ReactPannellum.startOrientation()
  //   }
  // }
  //
  // const zoomIn = () => {
  //   ReactPannellum.setHfov(ReactPannellum.getHfov() - 10)
  // }
  //
  // const zoomOut = () => {
  //   ReactPannellum.setHfov(ReactPannellum.getHfov() + 10)
  // }

  if (!open && !openImmediately) {
    return (
      <button onClick={openModal} className={styles.button}>
        <View360Icon className={styles.btn360} />
      </button>
    )
  }

  const iconButtonStyle = {
    marginTop: '5px',
    width: '30px',
    height: '30px',
    backgroundColor: 'white',
    padding: '0',
    '&.MuiButtonBase-root:hover': {
      bgcolor: 'rgba(255, 255, 255, 0.5)',
    },
  }

  // const image360_old = (
  //   <>
  //     <ReactPannellum
  //       className={styles.pannellumContainer}
  //       style={
  //         openMode === 'modal'
  //           ? {
  //               width: '95vw',
  //               height: '90vh',
  //               background: 'transparent',
  //             }
  //           : {}
  //       }
  //       id={uuidv4()}
  //       sceneId={uuidv4()}
  //       imageSource={url}
  //       config={{
  //         // type: 'equirectangular',
  //         autoRotate: -2,
  //         autoLoad: true,
  //         showControls: false,
  //         // compass: true,
  //       }}
  //     >
  //       {openMode === 'modal' && (
  //         <div className={styles.closeIconBox}>
  //           <IconButton
  //             disableRipple
  //             onClick={closeModal}
  //             sx={{ ...iconButtonStyle, marginTop: 0 }}
  //           >
  //             <ClearIcon style={{ color: '#2e2b63', fontSize: '20px' }} />
  //           </IconButton>
  //         </div>
  //       )}
  //       <div className={styles.controls}>
  //         <IconButton
  //           disableRipple
  //           onClick={toggleCompass}
  //           sx={iconButtonStyle}
  //         >
  //           {isCompassActive ? (
  //             <AdjustIcon style={{ color: '#2e2b63', fontSize: '22px' }} />
  //           ) : (
  //             <ExploreOutlined style={{ color: '#2e2b63', fontSize: '22px' }} />
  //           )}
  //         </IconButton>
  //         <IconButton
  //           disableRipple
  //           onClick={toggleFullscreen}
  //           sx={iconButtonStyle}
  //         >
  //           {fullscreen ? (
  //             <FullscreenExit style={{ color: '#2e2b63', fontSize: '24px' }} />
  //           ) : (
  //             <Fullscreen style={{ color: '#2e2b63', fontSize: '24px' }} />
  //           )}
  //         </IconButton>
  //         <IconButton disableRipple onClick={zoomOut} sx={iconButtonStyle}>
  //           <RemoveIcon style={{ color: '#2e2b63', fontSize: '20px' }} />
  //         </IconButton>
  //         <IconButton onClick={zoomIn} sx={iconButtonStyle}>
  //           <AddIcon style={{ color: '#2e2b63', fontSize: '20px' }} />
  //         </IconButton>
  //       </div>
  //     </ReactPannellum>
  //   </>
  // )

  const image360 = (
    <View360New
      url={url}
      iconButtonStyle={iconButtonStyle}
      closeModal={closeModal}
      openMode={openMode as any}
      imagePosition={imagePosition}
      onRotateOnCreate={onRotate}
    />
  )

  if (openMode === 'block') {
    return (
      <div
        style={{
          width: '95vw',
          height: '70vh',
          maxWidth: '95vw',
          maxHeight: '90vh',
        }}
      >
        {image360}
      </div>
    )
  }

  return (
    <ModalView
      blockOutsideClose
      onClose={closeModal}
      open={open}
      unmountModal={false}
      closeButton360
      style={{
        width: '95vw',
        height: '90vh',
        maxWidth: '95vw',
        maxHeight: '90vh',
        // backgroundColor: 'transparent'
        backgroundColor: 'rgba(22, 20, 50, 0.1)',
      }}
      children={image360}
    />
  )
}
