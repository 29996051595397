import React, { FC, ForwardedRef, MutableRefObject, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import WaveformData, { JsonWaveformData } from 'waveform-data'
import Peaks, { PeaksInstance, PeaksOptions, WaveformZoomView } from 'peaks.js'


import styles from './styles.module.scss'
import { PauseSharp, PlayArrowSharp } from '@mui/icons-material'
import { ReactComponent as PlayIcon } from 'sources/images/playAudio.svg'
import { Volume } from './Volume'


interface IProps {
  ref: React.Ref<HTMLAudioElement>
  audioUrl: string,
  audioContentType: string,
  waveformDataUri?: string,
  waveformJson?: JsonWaveformData,
  waveformArrayBuffer?: ArrayBuffer,
  audioContext?: AudioContext,
  onPlay: () => void
  whiteStyle?: boolean
}

const zeroPad = (number: number, precision: number = 2) => {
  let numberS = number.toString()

  while (numberS.length < precision) {
    numberS = '0' + number
  }

  return numberS
}
const formatTime = (time: number) => {
  const result = []
  const seconds = Math.floor(time)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  if (hours > 0) {
    result.push(hours) // Hours
  }
  result.push(minutes % 60) // Minutes
  result.push(seconds % 60) // Seconds

  return result.map(item => zeroPad(item)).join(':')
}

const WaveFormView: FC<IProps> = React.forwardRef<HTMLAudioElement, IProps>((
  {
    audioUrl,
    audioContentType,
    audioContext,
    waveformDataUri,
    waveformArrayBuffer,
    waveformJson,
    onPlay,
    whiteStyle
  }, ref) => {
  const { theme } = useStore()

  const [peaks, setPeaks] = useState<PeaksInstance | null>()
  const zoomViewWaveformRef = useRef<HTMLDivElement | null>(null)
  const overviewWaveformRef = useRef<HTMLDivElement | null>(null)
  // const audioElementRef = useRef<HTMLAudioElement | null>(null)
  const [audioElementRef] = useState(ref as MutableRefObject<HTMLAudioElement>)

  const [playing, setPlaying] = useState(false)
  const [timeToEnd, setTimeToEnd] = useState('00:00')
  const [volume, setVolume] = useState(100)
  const audioDurationRef = useRef<number>(0)

  const handlePlayAndPause = () => {
    setPlaying(prev => {
      if (peaks) {
        prev ? peaks.player.pause() : peaks.player.play()
      }
      return !prev
    })
  }

  const onPlayStop = () => {
    setPlaying(false)
  }

  const handleChangeVolume = (e: Event, value: number | number[]) => {
    const volumeNumber = Array.isArray(value) ? value[0] : value
    setVolume(volumeNumber)
    if (audioElementRef.current) {
      audioElementRef.current.volume = volumeNumber / 100
    }
  }

  const handleClickVolume = () => {
    const setVolumeData = (value: number) => {
      setVolume(value * 100)
      if (audioElementRef.current) {
        audioElementRef.current.volume = value
      }
    }
    if (volume === 0) {
      setVolumeData(1)
    } else {
      setVolumeData(0)
    }
  }

  useEffect(() => {
    if (!(waveformJson || waveformArrayBuffer)) return
    setPlaying(false)
    initPeaks()

    return () => {
      if (peaks) {
        peaks.destroy()
      }
    }
  }, [waveformJson, waveformArrayBuffer, theme.theme])

  const initPeaks = () => {
    const options: any = {
      containers: {
        overview: overviewWaveformRef.current
        // zoomview: zoomViewWaveformRef.current
      },
      mediaElement: audioElementRef.current as Element,
      keyboard: false,
      logger: console.error.bind(console),
      waveformColor: theme.theme === 'dark' || whiteStyle ? 'rgba(255, 255, 255, 0.15)' : 'rgba(46, 43, 99, 0.15)',
      playheadColor: 'transparent',
      axisGridlineColor: 'transparent',
      overview: {
        showAxisLabels: false,
        playedWaveformColor: theme.theme === 'dark' || whiteStyle ? 'white' : '#2E2B63',
        // playheadTextColor: theme.theme === 'dark' || whiteStyle ? 'white' : '#2E2B63',
        playheadTextColor: 'transparent',
        fontSize: 12,
        formatPlayheadTime: (item: number) => {
          // Maybe use timeUpdate listener
          setTimeToEnd(formatTime(audioDurationRef.current - item))
          // formatTime(item)
          return ''
        }
      }
    }

    if (waveformJson) {
      options.waveformData = {
        json: waveformJson
      }
    } else if (waveformArrayBuffer) {
      options.waveformData = {
        arraybuffer: waveformArrayBuffer
      }
    } else if (waveformDataUri) {
      options.dataUri = {
        arraybuffer: waveformDataUri
      }
    } else if (audioContext) {
      // @ts-ignore
      options.webAudio = {
        audioContext: audioContext
      }
    }

    if (audioElementRef.current) {
      audioElementRef.current.src = audioUrl
    }

    if (peaks) {
      peaks.destroy()
      setPeaks(null)
    }

    Peaks.init(options, (err, peaks) => {
      if (peaks) {
        const view = peaks.views.getView('overview') as WaveformZoomView
        view.showPlayheadTime(true) //Show time
        // view.showAxisLabels(false);
        // view.setPlayedWaveformColor('#2E2B63');
        setPeaks(peaks)
        const duration = peaks.player.getDuration()
        audioDurationRef.current = duration
        setTimeToEnd(formatTime(duration))
        onPeaksReady()
      }
    })
  }

  const onPeaksReady = () => {
    // Do something when the Peaks instance is ready for use
    console.log('Peaks.js is ready')
  }

  return (
    <div className={cn({
      [styles.audioPlayerContainer]: true,
      [styles.audioPlayerContainerWhiteStyle]: whiteStyle,
    })}>
      <button
        className={styles.playButton}
        onClick={handlePlayAndPause}
      >
        {playing
          ? <PauseSharp style={{ color: whiteStyle ? '#2E2B63' : 'var(--mainColorRevert)', height: '18px', width: '18px' }}/>
          : <PlayIcon className={styles.playIcon}/>
        }
      </button>
      <div className={styles.waveFormContainer}>
        {/*<div className={styles.zoomViewContainer} ref={zoomViewWaveformRef}></div>*/}
        <div className={styles.overviewContainer} ref={overviewWaveformRef}></div>
      </div>

      <div className={styles.timeToEnd}>
        {timeToEnd}
      </div>
      <Volume volume={volume}
              onChangeVolume={handleChangeVolume}
              onClickVolumeButton={handleClickVolume}
              changeTypeWidth={576}
              whiteStyle={whiteStyle}
      />

      <audio ref={audioElementRef}
             onEnded={onPlayStop}
             onPause={onPlayStop}
             onPlay={onPlay}>
        <source src={audioUrl} type={audioContentType}/>
        Your browser does not support the audio element.
      </audio>
    </div>
  )
})

export default observer(WaveFormView)