import React, { useEffect, useState } from 'react'

import { iframely } from '@iframely/embed.js'

import styles from './atomicEmbed.module.scss'


interface AtomicEmbedComponentProps {
  data: {
    url: string;
  };
}

export const AtomicEmbedComponent: React.FC<AtomicEmbedComponentProps> = ({ data }) => {
  useEffect(() => {
    if (iframely) {
      iframely.load()
    }
  })

  const { url } = data

  const innerHTML = `<div class="iframely-embed"><div class="iframely-responsive"><a data-iframely-url href="${url}"></a></div></div>`

  return (
    <div className={styles.atomicEmbed} dangerouslySetInnerHTML={{ __html: innerHTML }}/>
  )
}


export const AtomicSeparatorComponent = (props: any) => (
  <hr/>
)

// const getLinkFrame = (url: string) => {
//   // &card=small (small)
//   // iframely or oembed
//   // &media=0
//   // &iframe=card
//   fetch(`https://iframe.ly/api/oembed?url=${url}&api_key=${process.env.REACT_APP_IFRAMELY_API_KEY}&omit_script&iframe=card`)
//     .then((res) => res.json())
//     .then(
//       (res) => {
//         if (res.html) {
//           console.log('res.html', res.html)
//         } else if (res.error) {
//           console.log('res.error', res.error)
//           console.log('res.message', res.message)
//         }
//       },
//       (error) => {
//         console.log('error', error)
//       }
//     )
// }

