import React, { CSSProperties, FC } from 'react'

import { observer } from 'mobx-react'

import Button from 'components/Button'
import Modal from 'components/Modal'

import { useStore } from '../../store'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onClickYes: () => void | Promise<void>
  onClickNo?: () => void
  text: React.ReactNode | JSX.Element
  closeOnYes?: boolean
  blockOutsideClose?: boolean
  textButton1?: string
  textButton2?: string
  disabledButton1?: boolean
  style?: CSSProperties
}

//Resize
export const ConfirmModal: FC<IProps> = observer(
  ({
    isOpen,
    setIsOpen,
    onClickYes,
    onClickNo,
    text,
    closeOnYes = true,
    blockOutsideClose,
    textButton1,
    textButton2,
    disabledButton1,
    style,
  }) => {
    const { mediaQuery } = useStore()

    const handlerOnClickYes = () => {
      closeOnYes && setIsOpen(false)
      onClickYes()
    }

    const handlerOnClickNo = () => {
      setIsOpen(false)
      onClickNo && onClickNo()
    }

    return (
      <Modal
        contentStyle={{ maxHeight: '90vh', ...style }}
        size={'small'}
        blockOutsideClose={blockOutsideClose}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        children={
          <div className={styles.confirmModal}>
            <div className={styles.modalTitle}>{text}</div>
            <div className={styles.buttons}>
              <Button
                disabled={disabledButton1}
                style={{
                  width: '48%',
                  height: mediaQuery.minWidth_2200 ? '50px' : '40px',
                  background:
                    'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
                }}
                styleText={{ color: '#ffffff' }}
                btnStyle={disabledButton1 ? 'disabled' : 'classic'}
                text={textButton1 ? textButton1 : 'Yes'}
                onClick={handlerOnClickYes}
                blueHover
              />
              <Button
                style={{
                  width: '48%',
                  height: mediaQuery.minWidth_2200 ? '50px' : '40px',
                  background: 'var(--boxColor)',
                  borderColor: '#1F45C3',
                }}
                styleText={{ color: 'var(--modalButtonBorderTextColor)' }}
                btnStyle="bordered"
                text={textButton2 ? textButton2 : 'No'}
                onClick={handlerOnClickNo}
                blueHover
              />
            </div>
          </div>
        }
      />
    )
  }
)

ConfirmModal.displayName = 'ConfirmModal'
