import React, { CSSProperties } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'

import {
  createPostFileType,
  getPostLengthFromEditorState,
  ViewTypeEditor,
} from 'utils'
import { useMediaQuery } from 'utils/hooks'

import { AddFileButtons } from 'components/AddFileButtons'
import { AdultCheckbox } from 'components/AdultCheckbox'
import Button from 'components/Button'
import { CustomEditor } from 'components/CustomEditor'
import { PriceUpdate } from 'components/PriceUpdate'
import { SwitchCurrency } from 'components/SwitchCurrency'
import { UserImageForEditor } from 'components/UserImageForEditor'
import { ViewMediaOnCreateBlock } from 'components/ViewMediaOnCreateBlock'

import {
  DraftStatus,
  IIpfsFiles,
  ISendPublication,
  ISocialPostInfo,
} from 'models'

import { useStore } from 'store'
import { PostType } from 'store/createPost'

import { ReactComponent as Close } from 'sources/images/close.svg'
import { ReactComponent as FullIcon } from 'sources/images/fullScreen.svg'
import { ReactComponent as FullExitIcon } from 'sources/images/fullScreenExit.svg'

import { PostOnTwitterCheckbox } from '../../../PostOnTwitterCheckbox'
import SocialPostInfo from '../SocialPostInfo'
import { SuperpostPublicText } from '../SuperpostPublicText'

import styles from './styles.module.scss'

interface IProps {
  isAdultContent: boolean
  index: number
  post: ISendPublication
  postType: PostType
  prepareDraftStatus: DraftStatus
  activeBlock: string
  listLength: number
  onDeleteBlock: Function
  onChangeText: (
    id: string,
    editorData: EditorState,
    transactionBlockKey: string
  ) => void
  onCreateNewPostBlock: Function
  onAddFile: (id: string, fileList: IIpfsFiles[]) => void
  onAddVideoPoster: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  onAddVideoDuration?: (id: string, duration: number) => void
  onAddAudiWaveForm: (id: string, waveForm: string) => void
  onCheckFile: (id: string, fileId: string) => void
  onChangeTitleDescription: (
    id: string,
    text: EditorState,
    transactionBlockKey: string,
    type: 'title' | 'description',
    fileID: string
  ) => void
  onDeleteFile: (postId: string, key: string) => void
  onEditFile: (postId: string, key: string, newFile: IIpfsFiles) => void
  onChangeAdultContent: (isAdult: boolean, id: string) => void
  onCreatePost: (postType?: PostType) => void
  onNextBlogpost: () => void
  onSaveStreamInDraft: () => void
  onOpenEmojiPicker: (top: number, left: number, isEnoughSpace: boolean) => void
  onGetPrice: () => Promise<{ price: string } | undefined>
  quantitySymbols: number
  transactionBlockKey: string
  isFirstTransactionBlock: boolean
  blockStyle?: CSSProperties
  superPostPublicText: EditorState
  onChangeSuperpostPublicText: (value: EditorState) => void
  setActiveBlock: Function
  setActiveEditorSuperpostPublicText: (value: boolean) => void
  setTransactionActiveBlock: Function
  fullSizeStyle: boolean
  changeFullSize: () => void
  getOverflowNodeDOMRect: () => DOMRect | null
  onRotate360Image?: (
    postId: string,
    key: string,
    position: {
      yaw: number
      pitch: number
    }
  ) => void
  postOnX: boolean
  onChangePostOnTwitter: (toPost: boolean, id: string) => void
  socialPostLink: EditorState
  onChangeSocialPostLink: (value: EditorState) => void
  socialPostInfo: ISocialPostInfo | null
  onReceiveSocialPostInfo: (info: ISocialPostInfo) => void
}

//Resize
const Block: React.FC<IProps> = observer(
  ({
    isAdultContent,
    index,
    post,
    postType,
    prepareDraftStatus,
    activeBlock,
    listLength,
    onDeleteBlock,
    setActiveBlock,
    onChangeText,
    onCreateNewPostBlock,
    onAddFile,
    onAddVideoPoster,
    onAddVideoDuration,
    onAddAudiWaveForm,
    onCheckFile,
    onChangeTitleDescription,
    onDeleteFile,
    onEditFile,
    onChangeAdultContent,
    onCreatePost,
    onNextBlogpost,
    onSaveStreamInDraft,
    onOpenEmojiPicker,
    onGetPrice,
    quantitySymbols,
    setTransactionActiveBlock,
    transactionBlockKey,
    isFirstTransactionBlock,
    blockStyle,
    superPostPublicText,
    onChangeSuperpostPublicText,
    setActiveEditorSuperpostPublicText,
    fullSizeStyle,
    changeFullSize,
    getOverflowNodeDOMRect,
    onRotate360Image,
    postOnX,
    onChangePostOnTwitter,
    socialPostLink,
    onChangeSocialPostLink,
    socialPostInfo,
    onReceiveSocialPostInfo,
  }) => {
    const { theme, profile } = useStore()
    const lightTheme = theme.theme === 'light'
    const width_360 = useMediaQuery('(max-width: 360px)')

    const isEncrypted = postType === PostType.Encrypted
    const isSuperpost = postType === PostType.Superpost
    const isBlogpost = postType === PostType.Blogpost
    const isStreampost = postType === PostType.Streampost
    const isFreepost = postType === PostType.Freepost
    const isBlockchainPost = postType === PostType.Blockchain
    const isSocialPost = postType === PostType.SocialPost

    const isTwitterConnected = profile?.selectedUser?.IsTwitterConnected
    // profile?.selectedUser?.TwitterId && profile?.selectedUser?.TwitterName

    const handlerOnClickEditorBlock = () => {
      setTransactionActiveBlock(transactionBlockKey)
      setActiveBlock(post.Id)
      setActiveEditorSuperpostPublicText(false)
    }

    const handleChangeAdultContent = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onChangeAdultContent(event.target.checked, post.Id)
    }

    const handleChangePostOnTwitter = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onChangePostOnTwitter(event.target.checked, post.Id)
    }

    const postLength = getPostLengthFromEditorState(post.EditorData)

    const getMintButtonData = () => {
      let text
      let onClick

      if (isBlogpost) {
        text = 'Next'
        onClick = onNextBlogpost
      } else if (isFreepost) {
        text = 'Create'
        onClick = () => {
          onCreatePost(PostType.Freepost)
        }
      } else {
        text = 'Mint'
        onClick = () => {
          if (isEncrypted) {
            onCreatePost(PostType.Encrypted)
          } else if (isSuperpost) {
            onCreatePost(PostType.Superpost)
          } else if (isStreampost) {
            onCreatePost(PostType.Streampost)
          } else if (isSocialPost) {
            onCreatePost(PostType.SocialPost)
          } else {
            onCreatePost()
          }
        }
      }

      return { text, onClick }
    }

    const mintButton = getMintButtonData()

    if (postType === PostType.SocialPost && !isTwitterConnected) {
      return (
        <div
          className={cn({
            [styles.connectTwitterBlock]: true,
          })}
          key={post.Id}
          style={blockStyle}
        >
          <p className={styles['connectTwitterBlock-text']}>
            Connect X (Twitter) account to create X post
          </p>
        </div>
      )
    }

    return (
      <div
        className={cn({
          [styles.createModalBlock]: true,
          [styles.createModalBlockFullSize]: fullSizeStyle,
        })}
        key={post.Id}
        style={blockStyle}
      >
        {/*---▼▼▼--- SUPERPOST PUBLIC TEXT ---▼▼▼---*/}
        {isSuperpost ? (
          <SuperpostPublicText
            text={superPostPublicText}
            onChange={onChangeSuperpostPublicText}
            onClick={setActiveEditorSuperpostPublicText}
          />
        ) : null}

        {/*---▼▼▼--- SOCIAL POST PUBLIC TEXT ---▼▼▼---*/}
        {isSocialPost ? (
          <SocialPostInfo
            socialPostLink={socialPostLink}
            onChange={onChangeSocialPostLink}
            socialPostInfo={socialPostInfo}
            onReceiveSocialPostInfo={onReceiveSocialPostInfo}
          />
        ) : null}

        {/*---▼▼▼--- SYMBOLS QUANTITY ---▼▼▼---*/}
        {activeBlock === post.Id ? (
          <div
            className={cn({
              [styles.symbolsQuantity]: true,
              [styles.symbolsQuantityMaxWidth]: width_360,
              [styles.symbolsQuantityError]:
                quantitySymbols > 5000 && !isBlogpost,
            })}
          >
            <div
              className={cn({
                [styles.blockQuantityPlus]: true,
                [styles.blockQuantityPlusMaxWidth]: width_360,
                // [styles.blockQuantityPlusNotEmpty]:
                //   postLength > 0 || post.IpfsFiles.length > 0,
                [styles.symbolsQuantityError]:
                  quantitySymbols > 5000 && !isBlogpost,
              })}
            >
              {/* {isEncrypted ||
              isSuperpost ||
              isBlogpost ||
              isStreampost ||
              isFreepost ? (
                <span />
              ) : (
                <img
                  src={lightTheme ? PlusSvg : PlusSvgDark}
                  onClick={() => {
                    onCreateNewPostBlock(index)
                  }}
                  className={cn({
                    [styles.addBlockBtn]: true,
                    [styles.addBlockBtnVisible]:
                      postLength > 0 || post.IpfsFiles.length > 0,
                  })}
                  alt="plus"
                />
              )}*/}
              {isBlogpost ? quantitySymbols : `${quantitySymbols}/5000`}
            </div>
            {/*---▼▼▼--- DELETE BLOCK ICON ---▼▼▼---*/}
            {index !== 0 && (
              <Close
                className={styles.onDeleteIcon}
                onClick={() => {
                  onDeleteBlock(post.Id)
                }}
              />
            )}
          </div>
        ) : null}

        {/*---▼▼▼--- TEXT INPUT BLOCK ---▼▼▼---*/}
        <div className={styles.editorAreaWrapper}>
          <CustomEditor
            viewType={ViewTypeEditor.editor}
            postId={post.Id}
            transactionBlockKey={transactionBlockKey}
            editorState={post.EditorData}
            setPostEditorState={onChangeText}
            onClickEditorBlock={handlerOnClickEditorBlock}
            isEncrypted={isEncrypted}
            isBlogpostEditor={isBlogpost}
            fullSizeStyle={fullSizeStyle}
            getOverflowNodeDOMRect={getOverflowNodeDOMRect}
            onAddFile={onAddFile}
            withoutToolbar={!isBlogpost}
          />
          {!isBlogpost ? <UserImageForEditor /> : null}
        </div>

        {/*---▼▼▼--- MEDIA BLOCK ---▼▼▼---*/}
        {post.IpfsFiles.length > 0 && (
          <ViewMediaOnCreateBlock
            IpfsFiles={post.IpfsFiles}
            postId={post.Id}
            transactionBlockKey={transactionBlockKey}
            onDeleteFile={onDeleteFile}
            onEditFile={onEditFile}
            onAddVideoPoster={onAddVideoPoster}
            onAddVideoDuration={onAddVideoDuration}
            onAddAudiWaveForm={onAddAudiWaveForm}
            onChangeTitleDescription={onChangeTitleDescription}
            onCheckFile={onCheckFile}
            onRotate360Image={onRotate360Image}
            postOnX={postOnX}
          />
        )}
        {/*---▼▼▼--- CONTROL BLOCK ---▼▼▼---*/}
        <div
          className={cn({
            [styles.controlBlock]: true,
            [styles.activeControlBlock]: activeBlock === post.Id,
            [styles.activeControlBlockWithCheckbox]:
              activeBlock === post.Id &&
              (post.IpfsFiles.length > 0 || isAdultContent),
          })}
        >
          {/*---▼▼▼--- ADDITIONAL BUTTONS BLOCK ---▼▼▼---*/}
          <div className={styles.additionalButtonsBlock}>
            {/*---▼▼▼--- ADD NESTING BUTTONS ---▼▼▼---*/}
            <AddFileButtons
              ipfsFiles={post.IpfsFiles}
              onAddFile={onAddFile}
              postId={post.Id}
              onOpenEmojiPicker={onOpenEmojiPicker}
              onlyEmoji={
                isEncrypted || isBlogpost || isStreampost || isSocialPost
              }
              withSpatialVideo={true}
            />

            {isFreepost ? null : (
              <div className={styles.getPriceBlock}>
                <SwitchCurrency />
                <PriceUpdate
                  onGetPrice={onGetPrice}
                  activeBlock={activeBlock}
                  blockId={post.Id}
                />
              </div>
            )}
          </div>
          {/*---▼▼▼--- CREATE BUTTON BLOCK ---▼▼▼---*/}
          {isFirstTransactionBlock && (
            <div className={styles.createButtonBlock}>
              <div className={styles.buttonsCheckboxes}>
                {(post.IpfsFiles.length > 0 &&
                  !post.IpfsFiles.find(
                    item => item.FileType === createPostFileType.file
                  )) ||
                isStreampost ? (
                  <AdultCheckbox
                    checked={isAdultContent}
                    onChange={handleChangeAdultContent}
                    formSX={{ margin: '0 0 5px -7px' }}
                  />
                ) : null}

                {(isFreepost || isBlockchainPost) && (
                  <PostOnTwitterCheckbox
                    checked={postOnX}
                    onChange={handleChangePostOnTwitter}
                    formSX={{ margin: '0 0 5px -7px' }}
                    disabled={!isTwitterConnected}
                    hasFiles={post?.IpfsFiles.length > 0}
                  />
                )}
              </div>

              <div className={styles.buttonsBottom}>
                <Button
                  type="button"
                  style={{
                    width: '100%',
                    height: '50px',
                    background:
                      'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
                    boxShadow: 'none',
                  }}
                  styleText={{ color: '#ffffff' }}
                  btnStyle={
                    isStreampost &&
                    prepareDraftStatus !== DraftStatus.readyToMint
                      ? 'disabled'
                      : 'classic'
                  }
                  text={mintButton.text}
                  onClick={mintButton.onClick}
                  blueHover
                  disabled={
                    isStreampost &&
                    prepareDraftStatus !== DraftStatus.readyToMint
                  }
                />
                <button className={styles.fullSize} onClick={changeFullSize}>
                  {fullSizeStyle ? <FullExitIcon /> : <FullIcon />}
                </button>
              </div>
              {isStreampost ? (
                <button
                  onClick={onSaveStreamInDraft}
                  className={styles.saveInDraft}
                >
                  Save in draft
                </button>
              ) : null}
            </div>
          )}
        </div>
        {/*---▼▼▼--- CONNECTED LINES ---▼▼▼---*/}
        <div
          className={cn({
            [styles.connectedLines]: index !== listLength - 1,
          })}
        />
      </div>
    )
  }
)

const memoizedComponent = React.memo(Block)
memoizedComponent.displayName = 'Block'

export default memoizedComponent
