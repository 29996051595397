import React, { ChangeEvent, FC, useState } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'

import { getPostLengthFromEditorState, ViewTypeEditor } from 'utils'

import { CustomEditor } from 'components/CustomEditor'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {
  text: EditorState
  onChange: (value: EditorState) => void
  onClick: (value: boolean) => void
}
export const SuperpostPublicText: FC<IProps> = ({
  text,
  onChange,
  onClick,
}) => {
  const publicTextQuantity = getPostLengthFromEditorState(text)

  const handleClick = () => {
    onClick(true)
  }

  return (
    <div className={styles.superpostPublicTextContainer}>
      <div className={styles.title}>
        <div>Public text</div>
        <span>*</span>
        <div
          className={cn({
            [styles.charactersQuantity]: true,
            [styles.charactersQuantityError]: publicTextQuantity > 200,
          })}
        >
          {publicTextQuantity}/200
        </div>
      </div>
      <div className={styles.editorWrapper}>
        <CustomEditor
          viewType={ViewTypeEditor.editorWithoutAvatar}
          editorState={text}
          setEditorState={onChange}
          editorAutoHeight
          onClickEditorBlock={handleClick}
          styleContainer={{ margin: 0 }}
          withoutToolbar={true}
        />
      </div>
    </div>
  )
}
