import QuoteCaptionBlock from './blockquotecaption'
import CaptionBlock from './caption'
import AtomicBlock from './atomic'
import TodoBlock from './todo'
import ImageBlock from './image'
import BreakBlock from './break'
// import HeaderBlock from './header'

import { Block } from '../util/constants'
import { ContentBlock, EditorState } from 'draft-js'

export default (
    setEditorState: (editorState: EditorState) => void,
    getEditorState: () => void,
    readOnly?: boolean
  ) =>
  (contentBlock: ContentBlock) => {
    const type = contentBlock.getType()

    switch (type) {
      // case Block.H1:
      // case Block.H2:
      // case Block.H3:
      // case Block.H4:
      // case Block.H5:
      // case Block.H6:
      //   return {
      //     component: HeaderBlock,
      //     props: {
      //       setEditorState,
      //       getEditorState,
      //       readOnly: readOnly,
      //       id: contentBlock?.getKey(),
      //     },
      //   }
      case Block.ATOMIC:
        return {
          component: AtomicBlock,
          editable: false,
          props: {
            setEditorState,
            getEditorState,
          },
        }
      case Block.BLOCKQUOTE_CAPTION:
        return {
          component: QuoteCaptionBlock,
        }
      case Block.CAPTION:
        return {
          component: CaptionBlock,
        }
      case Block.TODO:
        return {
          component: TodoBlock,
          props: {
            setEditorState,
            getEditorState,
          },
        }
      case Block.IMAGE:
        return {
          component: ImageBlock,
          props: {
            setEditorState,
            getEditorState,
            readOnly: readOnly,
          },
        }
      case Block.BREAK:
        return {
          component: BreakBlock,
          editable: false,
        }
      default:
        return null
    }
  }
