import React, {
  CSSProperties,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import cn from 'classnames'
import Draft, {
  convertFromRaw,
  convertToRaw,
  EditorState,
  Entity,
  Modifier,
  RichUtils,
  SelectionState,
} from 'draft-js'
import createListPlugin from 'draft-js-list-plugin'
// @ts-ignore
import isSoftNewlineEvent from 'draft-js/lib/isSoftNewlineEvent'
import LinkifyIt from 'linkify-it'
import mime from 'mime'
import { userData } from 'three/examples/jsm/nodes/shadernode/ShaderNodeBaseElements'
import { useDebouncedCallback } from 'use-debounce'
import { v4 } from 'uuid'

import Editor from '@draft-js-plugins/editor'
import '@draft-js-plugins/emoji/lib/plugin.css'
import createHashtagPlugin from '@draft-js-plugins/hashtag'
import '@draft-js-plugins/hashtag/lib/plugin.css'
import createImagePlugin from '@draft-js-plugins/image'
import '@draft-js-plugins/image/lib/plugin.css'
// import createEmojiPlugin from '@draft-js-plugins/emoji'
import createLinkifyPlugin from '@draft-js-plugins/linkify'
import createMentionPlugin, { MentionData } from '@draft-js-plugins/mention'
import '@draft-js-plugins/mention/lib/plugin.css'
import EditorUtils from '@draft-js-plugins/utils'
import createVideoPlugin from '@draft-js-plugins/video'

import { isMobile } from 'utils'
import { createPostFileType } from 'utils/const'
import { getFileExtensionFromName } from 'utils/functions/common'
import { useMediaQuery } from 'utils/hooks'
import URLUtils from 'utils/URLUtils'

import { toast } from 'App'

import { ICurrencyInfoByTag, IIpfsFiles, ISearchTagUser } from 'models'

import { useStore } from 'store'

import { CurrencyTooltip } from '../CurrencyTooltip'
import { UserImageForEditor } from '../UserImageForEditor'
import { UserInfoTooltip } from '../UserInfoTooltip'
import rendererFn from './blocks/blockRendererFn'
import blockStyleFn from './blocks/blockStyleFn'
import { badWordsHighlightDecoratorPlugin } from './decorators/BadWordsHighlight'
import { linkDecoratorPlugin } from './decorators/Link'
import {
  addNewBlock,
  addNewBlockAt,
  getCurrentBlock,
  isCursorBetweenLink,
  resetBlockWithType,
} from './util'
import {
  Block,
  Entity as E,
  HANDLED,
  KEY_COMMANDS,
  NOT_HANDLED,
} from './util/constants'
import customStyleMap from './util/customStyleMap'
// import { handleDraftEditorPastedText} from "draftjs-conductor";
import positionSuggestions from './util/positionSuggestionsEmoji'
import { CurrencyEntryComponent } from './сomponent/CurrencyEntryComponent'
import { EncryptedToast } from './сomponent/EncryptedToast'
import { MentionEntryComponent } from './сomponent/MentionEntryComponent'
import { SideAddButton } from './сomponent/SideAddButton'
import Toolbar from './сomponent/Toolbar'
import { LinkEditComponent } from './сomponent/Toolbar/LinkEditComponent'

// import 'draft-js/dist/Draft.css'; //fix placeholder position //maybe change some another style
import editorStyles from './editorStyles.module.scss'
import emojiStyles from './pluginsStyle/emojiStyles.module.scss'
import hashtagStyles from './pluginsStyle/hashtagStyles.module.scss'
import mentionsStyles from './pluginsStyle/mentionsStyles.module.scss'

const linkify = new LinkifyIt()

export enum ViewTypeEditor {
  editor = 'editor',
  editorWithoutAvatar = 'editorWithoutAvatar',
  editorNonStyle = 'editorNoneStyle',
  post = 'post',
  text = 'text',
}

interface IProps {
  postId?: string
  transactionBlockKey?: string
  editorState: EditorState
  setEditorState?: (editorData: EditorState) => void
  setPostEditorState?: (
    id: string,
    editorData: EditorState,
    transactionBlockKey: string
  ) => void
  onClickEditorBlock?: () => void
  onClickEnter?: () => any
  readOnly?: boolean
  withoutToolbar?: boolean
  viewType: ViewTypeEditor
  styleContainer?: CSSProperties
  editorAutoHeight?: boolean
  editorAutoHeightWithMaxHeight?: boolean
  editorKey?: string
  isEncrypted?: boolean
  canDecrypt?: string
  decryptedText?: string
  isSuperPost?: boolean
  isSuperPostPublicText?: boolean
  isSuperPostOpened?: boolean
  isBlogpostEditor?: boolean
  isBlogpostView?: boolean
  isBlogpostSingleView?: boolean
  whiteStyle?: boolean
  fullSizeStyle?: boolean
  getOverflowNodeDOMRect?: () => DOMRect | null
  placeholder?: string
  modePWA?: boolean
  withoutPlaceholder?: boolean
  onAddFile?: (id: string, fileList: IIpfsFiles[]) => void
}

type CatchEditorErrorProps = {
  children: React.ReactNode
}

class CatchEditorError extends React.Component<CatchEditorErrorProps> {
  componentDidCatch() {
    this.forceUpdate()
  }

  render() {
    return <>{this.props.children}</>
  }
}

type TToolbarHandles = {
  handleLinkInput(
    e: React.MouseEvent<HTMLElement> | null,
    direct: boolean
  ): void
} | null

//Resize
export const CustomEditor: React.FC<IProps> = ({
  postId,
  editorState,
  setEditorState,
  setPostEditorState,
  onClickEditorBlock,
  onClickEnter,
  transactionBlockKey,
  readOnly,
  viewType,
  styleContainer,
  editorAutoHeight,
  editorAutoHeightWithMaxHeight,
  editorKey,
  isEncrypted,
  canDecrypt,
  decryptedText,
  isSuperPost,
  isSuperPostPublicText,
  isSuperPostOpened,
  isBlogpostEditor,
  isBlogpostView,
  isBlogpostSingleView,
  whiteStyle,
  fullSizeStyle,
  getOverflowNodeDOMRect,
  placeholder,
  withoutToolbar,
  modePWA,
  withoutPlaceholder,
  onAddFile,
}): ReactElement => {
  const minWidth_2200 = useMediaQuery('(min-width: 2201px)')
  const EditorNode = useRef<Editor>(null)
  const EditorContainerNode = useRef<HTMLDivElement | null>(null)
  const ToolbarHandlesRef = useRef<TToolbarHandles | null>(null)

  const { search, theme, widget, coins } = useStore()

  const [editorStateReadOnly, setEditorStateReadOnly] =
    useState<EditorState>(editorState)
  const [open, setOpen] = useState(false)
  const [suggestions, setSuggestions] = useState([])
  const [mentionsEncrypted, setMentionsEncrypted] = useState<string[]>([])
  const [isMobileDevice] = useState<boolean>(() => isMobile())
  const [addedMention, setAddedMention] = useState<{ mention: MentionData }>()

  const [openCurrency, setOpenCurrency] = useState(false)
  const [suggestionsCurrency, setSuggestionsCurrency] = useState<
    ICurrencyInfoByTag[]
  >([])
  const [addedCurrency, setAddedCurrency] = useState<{ mention: MentionData }>()

  const isEncryptedRef = useRef<boolean>(false)
  const editorStateRef = useRef<EditorState>(editorState)
  editorStateRef.current = editorState

  //Only for decrypted text
  const isFirstRenderRef = useRef<boolean>(false)
  useEffect(() => {
    if (readOnly && isFirstRenderRef.current) {
      setEditorStateReadOnly(editorState)
    }
    isFirstRenderRef.current = true
  }, [editorState])

  useEffect(() => {
    if (viewType === ViewTypeEditor.editor) {
      isEncryptedRef.current = Boolean(isEncrypted)
      setMentionsEncrypted([])
    }
  }, [isEncrypted])

  useEffect(() => {
    if (
      isEncrypted &&
      viewType === ViewTypeEditor.editor &&
      Object.keys(convertToRaw(editorState.getCurrentContent()).entityMap)
        .length === 0
    ) {
      setMentionsEncrypted([])
    }
  }, [editorState])

  useEffect(() => {
    if (editorKey && readOnly) setEditorStateReadOnly(editorState)
  }, [editorKey])

  useEffect(() => {
    if (addedMention && addedMention.mention && addedMention.mention.name) {
      const selection = editorState.getSelection()
      const anchorKey = selection.getAnchorKey()

      const contentState = editorState.getCurrentContent()
      const rawState = convertToRaw(contentState)

      const mentionName = '@' + addedMention.mention.name

      rawState.blocks = rawState.blocks.map(item => {
        if (item.key === anchorKey) {
          const newText = item.text
            .split(' ')
            .map(item => (item.includes(mentionName) ? mentionName : item))
            .join(' ')
          return {
            ...item,
            text: newText,
          }
        } else {
          return item
        }
      })

      const replacedEditorState = EditorState.createWithContent(
        convertFromRaw(rawState)
      )
      const newContentState = Modifier.insertText(
        replacedEditorState.getCurrentContent(),
        selection,
        ''
      )
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters'
      )
      onChange(newEditorState)
    }
  }, [addedMention])

  useEffect(() => {
    if (addedCurrency && addedCurrency.mention && addedCurrency.mention.name) {
      const selection = editorState.getSelection()
      const anchorKey = selection.getAnchorKey()

      const contentState = editorState.getCurrentContent()
      const rawState = convertToRaw(contentState)

      const mentionName = '$' + addedCurrency.mention.name

      rawState.blocks = rawState.blocks.map(item => {
        if (item.key === anchorKey) {
          const newText = item.text
            .split(' ')
            .map(item => (item.includes(mentionName) ? mentionName : item))
            .join(' ')
          return {
            ...item,
            text: newText,
          }
        } else {
          return item
        }
      })

      const replacedEditorState = EditorState.createWithContent(
        convertFromRaw(rawState)
      )
      const newContentState = Modifier.insertText(
        replacedEditorState.getCurrentContent(),
        selection,
        ''
      )
      const newEditorState = EditorState.push(
        editorState,
        newContentState,
        'insert-characters'
      )
      onChange(newEditorState)
    }
  }, [addedCurrency])

  // EDITOR PLUGINS------------------------------------------------------------------------------------------------↓↓↓
  const {
    plugins,
    MentionSuggestions,
    CurrencySuggestions,
    // EmojiSuggestions,
    // EmojiSelect,
    imagePlugin,
    videoPlugin,
    listPlugin,
  } = useMemo(() => {
    // MENTION
    const mentionPlugin = createMentionPlugin({
      theme: mentionsStyles,
      mentionPrefix: '@',
      mentionComponent(mentionProps) {
        const userData = {
          NickName: mentionProps.mention.name,
          FirstName: mentionProps.mention.FirstName,
          LastName: mentionProps.mention.LastName,
          ImageLink: mentionProps.mention.ImageLink,
          OpenSeaLink: mentionProps.mention.OpenSeaLink,
        }

        handlerAddMention(mentionProps.mention)

        return (
          <UserInfoTooltip userData={userData} nickName={userData.NickName}>
            <span
              onClick={e => e.stopPropagation()}
              className={mentionProps.className}
              style={{ cursor: 'pointer' }}
            >
              {mentionProps.children}
            </span>
          </UserInfoTooltip>
        )
      },
    })
    const { MentionSuggestions } = mentionPlugin

    // CURRENCY
    const currencyPlugin = createMentionPlugin({
      theme: mentionsStyles,
      mentionPrefix: '$',
      mentionTrigger: '$',
      mentionComponent(mentionProps) {
        const currencyData: ICurrencyInfoByTag =
          mentionProps?.mention as ICurrencyInfoByTag

        return (
          <CurrencyTooltip currency={currencyData}>
            <span
              onClick={e => e.stopPropagation()}
              className={mentionProps.className}
              style={{ cursor: 'pointer' }}
            >
              {mentionProps.children}
            </span>
          </CurrencyTooltip>
        )
      },
    })
    const { MentionSuggestions: CurrencySuggestions } = currencyPlugin

    // HASHTAG
    const hashtagPlugin = createHashtagPlugin({
      theme: hashtagStyles,
      hashtagComponent: props => {
        return (
          <a
            href={'/hashtags/' + props?.decoratedText?.replace(/#/gi, '')}
            target={'_blank'}
            onClick={e => e.stopPropagation()}
            style={{ color: '#5ecaf4', textDecoration: 'none' }}
          >
            {props?.children}
          </a>
        )
      },
    })

    // EMOJI
    // const emojiPlugin = createEmojiPlugin({
    //   theme: emojiStyles,
    //   positionSuggestions: positionSuggestions,
    //   useNativeArt: true,
    // })
    // const { EmojiSuggestions, EmojiSelect } = emojiPlugin

    // LINKIFY
    const customLinkRegex = /\[([^\]]+)\]\.\((https?:\/\/[^\s)]+)\)/g

    const customExtractLinks = (text: string) => {
      let matches = []

      let match
      while ((match = customLinkRegex.exec(text)) !== null) {
        matches.push({
          url: match[2], // Extracted URL
          index: match.index,
          lastIndex: match.index + match[0].length,
        })
      }

      linkify.match(text)?.forEach((match: any) => {
        matches.push({
          url: match.url,
          index: match.index,
          lastIndex: match.lastIndex,
        })
      })

      return matches
    }

    const linkifyPlugin = createLinkifyPlugin({
      target: '_blank',
      component(props) {
        const text = (props?.children as any)?.[0]?.props?.text || ''
        const regex = /\[(.*?)\]\.\(https?:\/\/[^\s)]+\)/
        const match = text.match(regex)

        const customText = match?.[1]

        return (
          <a
            onClick={e => e.stopPropagation()}
            style={{ color: 'rgb(118 45 186)' }}
            {...props}
          >
            {customText || props?.children}
          </a>
        )
      },
      customExtractLinks,
    })

    // IMAGE
    const imagePlugin = createImagePlugin()

    // VIDEO
    const videoPlugin = createVideoPlugin()

    const listPlugin = createListPlugin()

    let plugins = [
      linkDecoratorPlugin,
      mentionPlugin,
      hashtagPlugin,
      // emojiPlugin,
      linkifyPlugin,
      imagePlugin,
      videoPlugin,
      listPlugin,
      currencyPlugin,
    ]

    plugins = readOnly
      ? plugins
      : [...plugins, badWordsHighlightDecoratorPlugin]

    return {
      plugins,
      MentionSuggestions,
      CurrencySuggestions,
      // EmojiSuggestions,
      // EmojiSelect,
      videoPlugin,
      imagePlugin,
      listPlugin,
    }
  }, [])
  // EDITOR PLUGINS-----------------------------------------------------------------------------------------------↑↑↑

  const onOpenChange = useCallback((open: boolean) => {
    setOpen(open)
  }, [])

  const onOpenCurrencyChange = useCallback((open: boolean) => {
    setOpenCurrency(open)
  }, [])

  const onChange = useCallback((_editorState: EditorState) => {
    if (setPostEditorState && postId && transactionBlockKey) {
      setPostEditorState(postId, _editorState, transactionBlockKey)
    } else if (setEditorState) {
      setEditorState(_editorState)
    } else if (readOnly) {
      setEditorStateReadOnly(_editorState)
    }
  }, [])

  const onSearchChange = useCallback(({ value }: { value: string }) => {
    if (!value) return
    debouncedOnSearch(value)
    // setSuggestions(defaultSuggestionsFilter(value, mentions))
  }, [])

  const debouncedOnSearch = useDebouncedCallback((value: string) => {
    searchUsersByNameAndNickName(value)
  }, 500)

  const searchUsersByNameAndNickName = async (value: string) => {
    try {
      const response = await search.searchUsersByTag(value)
      const users = response.map((user: ISearchTagUser) => ({
        name: user.NickName,
        ...user,
      }))
      setSuggestions(users)
    } catch (e) {
      console.log(e)
    }
  }

  const onAddMention = (mention: MentionData) => {
    setAddedMention({ mention })
  }

  const handlerAddMention = (mention?: MentionData) => {
    if (viewType === ViewTypeEditor.editor && isEncryptedRef.current) {
      const entityMap = convertToRaw(
        editorStateRef.current.getCurrentContent()
      ).entityMap
      const mentionArr = Object.keys(entityMap)
        .filter(idx => entityMap[idx].type === 'mention')
        .map(key => entityMap[key].data.mention.name)
      const mentionsNotEmpty = mentionArr?.filter((m: string) => !!m)
      setMentionsEncrypted(mentionsNotEmpty)
    }
  }

  const onSearchCurrencyChange = useCallback(({ value }: { value: string }) => {
    if (!value) return
    debouncedOnSearchCurrency(value)
  }, [])

  const debouncedOnSearchCurrency = useDebouncedCallback((value: string) => {
    searchCurrencyExchangeRate(value)
  }, 500)

  const searchCurrencyExchangeRate = async (value: string) => {
    try {
      const response = await coins.searchCurrencies(value)

      setSuggestionsCurrency(response)
    } catch (e) {
      console.log(e)
    }
  }

  const onAddCurrency = (mention: MentionData) => {
    setAddedCurrency({ mention })
  }

  const onPaste = (
    text: string,
    html: string | undefined,
    editorState: EditorState
  ): Draft.DraftHandleValue => {
    const selection = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const newContentState = Modifier.replaceText(contentState, selection, text)
    const newEditorState = EditorState.push(
      editorState,
      newContentState,
      'insert-fragment'
    )

    onChange(newEditorState)
    return HANDLED
  }

  const getEditorState = () => {
    // return editorState
    return editorStateRef.current
  }

  // Never use after set Editor state This will broken plugin
  const focusEditor = () => {
    EditorNode.current?.focus()
  }

  const blockRendererFn = useMemo(() => {
    return rendererFn(onChange, getEditorState, readOnly)
  }, [readOnly])

  const handleDroppedFiles = (
    selection: SelectionState,
    files: Array<Blob>
  ) => {
    const file = files[0]

    const filteredFileList = files.filter(
      file =>
        (file.type === 'image/png' || file.type === 'image/jpeg') &&
        file.size <= 52428800
    )

    let fileList: IIpfsFiles[] = []

    if (filteredFileList.length > 0) {
      if (isBlogpostEditor) {
        onChange(
          addNewBlock(
            getEditorState(),
            Block.IMAGE,
            {
              // srcArray: await getImagesSrcWithDimensions(filteredFileList)
              srcArray: filteredFileList.map(file => URL.createObjectURL(file)),
            },
            true
          )
        )
        return HANDLED
      } else {
        fileList = filteredFileList?.map(f => ({
          Id: v4(),
          Size: f.size,
          FileLink: f as any,
          FileType: createPostFileType.image,
          FileExtension:
            mime.getExtension?.(f.type) ||
            getFileExtensionFromName(f as File) ||
            '',
          Is360Image: false,
          Is3DImage: false,
          IsSpatialVideo: false,
          Image360Position: null,
        }))
      }
    }

    // if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
    //   if (file.size > 52428800) {
    //     toast({
    //       type: 'warning',
    //       message: 'Maximum image size limit is 50MB'
    //     })
    //     return NOT_HANDLED
    //   }
    //   const objectURL = URL.createObjectURL(file)
    //   onChange(imagePlugin.addImage(getEditorState(), objectURL, {}))
    //   return HANDLED
    // }

    if (file && (file.type === 'video/mp4' || file.type === 'video/webm')) {
      if (file.size > 1610612736) {
        toast({
          type: 'warning',
          message: `Maximum video size limit is 400 MB`,
        })
        return NOT_HANDLED
      }

      if (isBlogpostEditor) {
        const objectURL = URL.createObjectURL(file)
        onChange(videoPlugin.addVideo(editorState, { src: objectURL }))

        return HANDLED
      } else {
        fileList.push({
          Id: v4(),
          Size: file.size,
          FileLink: file as any,
          FileType: createPostFileType.video,
          FileExtension:
            mime.getExtension?.(file.type) ||
            getFileExtensionFromName(file as File) ||
            '',
          Is360Image: false,
          Is3DImage: false,
          IsSpatialVideo: false,
          Image360Position: null,
          VideoAudioTitle: EditorState.createEmpty(),
          VideoAudioDescription: EditorState.createEmpty(),
        })
      }
    }

    if (fileList?.length > 0) {
      new Promise(resolve => {
        resolve(onAddFile?.(postId as string, fileList))
      }).then(() => {
        return NOT_HANDLED
      })
    }

    return NOT_HANDLED
  }

  const toggleBlockType = (blockType: string) => {
    const type = RichUtils.getCurrentBlockType(editorState)
    if (type.indexOf(`${Block.ATOMIC}:`) === 0) {
      return
    }
    onChange(RichUtils.toggleBlockType(editorState, blockType))
  }

  const toggleInlineStyle = (inlineStyle: string) => {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  // Adds a hyperlink on the selected text with some basic checks.
  const createLinkAtSelection = (url: string) => {
    let newUrl = url
    if (!URLUtils.isMail(URLUtils.normaliseMail(newUrl))) {
      newUrl = URLUtils.normalizeUrl(newUrl)
      if (!URLUtils.isUrl(newUrl)) {
        toast({
          type: 'warning',
          message: 'Invalid URL format',
        })
        return focusEditor()
      }
    } else {
      newUrl = URLUtils.normaliseMail(newUrl)
    }
    if (newUrl !== '') {
      // const contentState = editorState.getCurrentContent().createEntity('LINK', 'MUTABLE', { url });
      // const entityKey = contentState.getLastCreatedEntityKey();
      // const newEditorState = RichUtils.toggleLink(
      //   editorState,
      //   editorState.getSelection(),
      //   entityKey
      // );
      // onChange(EditorState.forceSelection(newEditorState, newEditorState.getSelection()));

      onChange(EditorUtils.createLinkAtSelection(getEditorState(), newUrl))

      // To check mention
      if (viewType === ViewTypeEditor.editor && isEncryptedRef.current) {
        setTimeout(() => {
          handlerAddMention()
        }, 0)
      }
    }
  }

  const removeLink = (blockKey: string, entityKey: string) => {
    // const selection = editorState.getSelection();
    // const newEditorState = RichUtils.toggleLink(editorState, selection, null);
    // onChange(EditorState.forceSelection(newEditorState, editorState.getSelection()))

    const content = editorState.getCurrentContent()
    const block = content.getBlockForKey(blockKey)
    const oldSelection = editorState.getSelection()
    block.findEntityRanges(
      character => {
        const eKey = character.getEntity()
        return eKey === entityKey
      },
      (start, end) => {
        const selection = new SelectionState({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: start,
          focusOffset: end,
        })
        const newEditorState = EditorState.forceSelection(
          RichUtils.toggleLink(editorState, selection, null),
          oldSelection
        )
        onChange(newEditorState)
      }
    )
  }

  const editLinkAfterSelection = (
    blockKey: string,
    entityKey: string | null = null
  ) => {
    if (entityKey === null) {
      return
    }

    const content = editorState.getCurrentContent()
    const block = content.getBlockForKey(blockKey)
    block.findEntityRanges(
      character => {
        const eKey = character.getEntity()
        return eKey === entityKey
      },
      (start, end) => {
        const selection = new SelectionState({
          anchorKey: blockKey,
          focusKey: blockKey,
          anchorOffset: start,
          focusOffset: end,
        })
        const newEditorState = EditorState.forceSelection(
          editorState,
          selection
        )
        onChange(newEditorState)
        setTimeout(() => {
          if (ToolbarHandlesRef.current) {
            ToolbarHandlesRef.current.handleLinkInput(null, true)
          }
        }, 100)
      }
    )
  }

  let isCursorLink = null
  if (!readOnly) {
    isCursorLink = isCursorBetweenLink(editorState)
  }

  const getEditorNodeDOMRect = useCallback(() => {
    if (EditorNode.current) {
      return EditorNode.current.getEditorRef().editor.getBoundingClientRect()
    }
    return null
  }, [EditorNode.current])

  const handleReturn = (e: any) => {
    if (isSoftNewlineEvent(e)) {
      onChange(RichUtils.insertSoftNewline(editorState))
      return HANDLED
    }

    if (!e.altKey && !e.metaKey && !e.ctrlKey) {
      const currentBlock = getCurrentBlock(editorState)
      const blockType = currentBlock.getType()
      if (blockType.indexOf(Block.ATOMIC) === 0) {
        onChange(addNewBlockAt(editorState, currentBlock.getKey()))
        return HANDLED
      }

      // Reset style after create new block
      if (currentBlock.getLength() === 0) {
        switch (blockType) {
          case Block.BLOCKQUOTE:
          case Block.H1:
            // case Block.UL:
            // case Block.OL:
            // case Block.BLOCKQUOTE_CAPTION:
            // case Block.CAPTION:
            // case Block.TODO:
            // case Block.H2:
            // case Block.H3:
            onChange(resetBlockWithType(editorState, Block.UNSTYLED))
            return HANDLED
          default:
            return NOT_HANDLED
        }
      }

      const selection = editorState.getSelection()

      if (
        selection.isCollapsed() &&
        currentBlock.getLength() === selection.getStartOffset()
      ) {
        const continuousBlocks = [
          Block.UNSTYLED,
          Block.BLOCKQUOTE,
          // Block.OL,
          // Block.UL,
          // Block.CODE,
          // Block.TODO,
        ]

        if (continuousBlocks.indexOf(blockType) < 0) {
          onChange(addNewBlockAt(editorState, currentBlock.getKey()))
          return HANDLED
        }
        return NOT_HANDLED
      }
      return NOT_HANDLED
    }
    return NOT_HANDLED
  }

  // Need make
  // const handleKeyCommand = (command: any) => {
  //   console.log(command);
  //
  //   const block = getCurrentBlock(editorState);
  //   const currentBlockType = block.getType();
  //   // if (command === KEY_COMMANDS.deleteBlock()) {
  //   //   if (currentBlockType.indexOf(Block.ATOMIC) === 0 && block.getText().length === 0) {
  //   //     this.onChange(resetBlockWithType(editorState, Block.UNSTYLED, { text: '' }));
  //   //     return HANDLED;
  //   //   }
  //   //   return NOT_HANDLED;
  //   // }
  //   if (command.indexOf(`${KEY_COMMANDS.changeType()}`) === 0) {
  //     let newBlockType = command.split(':')[1];
  //     // const currentBlockType = block.getType();
  //     if (currentBlockType === Block.ATOMIC) {
  //       return HANDLED;
  //     }
  //     if (currentBlockType === Block.BLOCKQUOTE && newBlockType === Block.CAPTION) {
  //       newBlockType = Block.BLOCKQUOTE_CAPTION;
  //     } else if (currentBlockType === Block.BLOCKQUOTE_CAPTION && newBlockType === Block.CAPTION) {
  //       newBlockType = Block.BLOCKQUOTE;
  //     }
  //     onChange(RichUtils.toggleBlockType(editorState, newBlockType));
  //     return HANDLED;
  //   } else if (command.indexOf(`${KEY_COMMANDS.toggleInline()}`) === 0) {
  //     const inline = command.split(':')[1];
  //     toggleInlineStyle(inline);
  //     return HANDLED;
  //   }
  //   const newState = RichUtils.handleKeyCommand(editorState, command);
  //   if (newState) {
  //     onChange(newState);
  //     return HANDLED;
  //   }
  //   return NOT_HANDLED;
  // }

  const placeholderH1 = () => {
    if (!isBlogpostEditor) return false
    const blockMap = editorState.getCurrentContent().getBlockMap()
    return blockMap.first().getType() === Block.H1 && blockMap.size === 1
  }

  const placeholderText = () => {
    if (withoutPlaceholder) {
      return undefined
    }

    if (placeholder) {
      return placeholder
    }

    if (isBlogpostEditor && placeholderH1()) {
      return 'Title'
    }

    return 'Add text'
  }

  // const anchorKey = getEditorState().getSelection().getAnchorKey()
  // console.log('Editor anchorKey', anchorKey)

  const getCurrentContent = () => {
    console.log('editorState', convertToRaw(editorState.getCurrentContent()))
    // console.log('size', editorState.getCurrentContent().getBlockMap().size)
    // console.log('type', editorState.getCurrentContent().getBlockMap().first().getType())
  }

  const handleKeyCommand = (command: any) => {
    if (command === KEY_COMMANDS.ignoreDefault()) {
      return HANDLED
    }

    return NOT_HANDLED
  }

  const keyBindingFn = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      return onClickEnter ? onClickEnter() : Draft.getDefaultKeyBinding(e)
    }

    return Draft.getDefaultKeyBinding(e)
  }

  // Need test
  if (minWidth_2200 === undefined) return null as any

  return (
    <CatchEditorError>
      {/*<button onClick={getCurrentContent}>getCurrentContent</button>*/}
      {isEncrypted && viewType === ViewTypeEditor.editor && modePWA ? (
        <EncryptedToast mentions={mentionsEncrypted} modePWA />
      ) : null}
      <div
        ref={EditorContainerNode}
        onClick={e => {
          if (!readOnly) {
            e.stopPropagation()
            onClickEditorBlock && onClickEditorBlock()

            if (!widget.isWidget) {
              EditorNode.current?.focus()
            }
          }
        }}
        style={styleContainer}
        className={cn({
          [editorStyles.editorContainer]: true,
          [editorStyles.editorContainerFullSize]: fullSizeStyle,
          [editorStyles.typeEditor]:
            viewType === ViewTypeEditor.editor ||
            viewType === ViewTypeEditor.editorWithoutAvatar,
          [editorStyles.typeEditorBlogPost]:
            viewType === ViewTypeEditor.editor && isBlogpostEditor,
          [editorStyles.typeEditorEncrypted]:
            isEncrypted && viewType === ViewTypeEditor.editor,
          [editorStyles.typeEditorWithoutAvatar]:
            viewType === ViewTypeEditor.editorWithoutAvatar,
          [editorStyles.typePost]: viewType === ViewTypeEditor.post,
          [editorStyles.typeBlogpostSingle]: isBlogpostSingleView,
          [editorStyles.typeText]: viewType === ViewTypeEditor.text, //Free style
          [editorStyles.typeTextEncrypted]:
            isEncrypted &&
            !decryptedText &&
            (viewType === ViewTypeEditor.text ||
              viewType === ViewTypeEditor.post),
          [editorStyles.typeTextSuperpost]: isSuperPost,
          [editorStyles.typeTextSuperpostPublicText]: isSuperPostPublicText,
          [editorStyles.typeTextSuperpostAvailable]:
            isSuperPostOpened && (isSuperPost || isSuperPostPublicText),
          [editorStyles.typeTextWhite]: whiteStyle,
          [editorStyles.typeEditorAutoHeight]: editorAutoHeight,
          [editorStyles.typeEditorAutoHeightWithMaxHeight]:
            editorAutoHeightWithMaxHeight,
          [editorStyles.disableCopyText]:
            isEncrypted && viewType !== ViewTypeEditor.editor && !decryptedText,
          [editorStyles.placeholderH1Style]:
            ViewTypeEditor.editor && isBlogpostEditor && placeholderH1(),
          [editorStyles.modePWA]: modePWA,
          [editorStyles.modePWABlogpost]: modePWA && isBlogpostEditor,
        })}
      >
        {isEncrypted && viewType === ViewTypeEditor.editor && !modePWA ? (
          <EncryptedToast mentions={mentionsEncrypted} />
        ) : null}
        {modePWA && !isBlogpostEditor && viewType === ViewTypeEditor.editor ? (
          <UserImageForEditor pwaMode />
        ) : null}
        <div
          id={`editorBlock_${postId}`}
          className={cn({
            [editorStyles.editorContainerInner]: true,
          })}
          // onClick={(e) => {
          //   if (!readOnly) {
          //     e.stopPropagation()
          //     onClickEditorBlock && onClickEditorBlock()
          //     EditorNode.current?.focus()
          //   }
          // }}
        >
          {isEncrypted &&
          !decryptedText &&
          canDecrypt &&
          viewType !== ViewTypeEditor.editor ? (
            <UserInfoTooltip nickName={canDecrypt} />
          ) : null}
          <Editor
            blockRendererFn={
              isBlogpostView || isBlogpostEditor ? blockRendererFn : undefined
            }
            blockStyleFn={
              isBlogpostView || isBlogpostEditor ? blockStyleFn : undefined
            }
            customStyleMap={customStyleMap}
            editorState={readOnly ? editorStateReadOnly : editorState}
            editorKey={editorKey ? editorKey : 'editor'}
            handleDroppedFiles={!readOnly ? handleDroppedFiles : undefined}
            handleKeyCommand={handleKeyCommand}
            keyBindingFn={keyBindingFn}
            handleReturn={handleReturn}
            readOnly={readOnly}
            placeholder={readOnly ? undefined : placeholderText()}
            handlePastedText={onPaste}
            onChange={onChange}
            plugins={plugins as any}
            ref={EditorNode}
            spellCheck={!readOnly}
          />
          <MentionSuggestions
            open={open}
            onOpenChange={onOpenChange}
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            entryComponent={MentionEntryComponent as any}
            // popoverContainer={({ children }) => <div>{children}</div>}
            onAddMention={onAddMention}
          />
          <CurrencySuggestions
            open={openCurrency}
            onOpenChange={onOpenCurrencyChange}
            suggestions={suggestionsCurrency}
            onSearchChange={onSearchCurrencyChange}
            entryComponent={CurrencyEntryComponent as any}
            onAddMention={onAddCurrency}
          />
          {/*<EmojiSuggestions/>*/}
          {!readOnly && !withoutToolbar ? (
            <>
              <Toolbar
                ref={ToolbarHandlesRef}
                getEditorNodeDOMRect={getEditorNodeDOMRect}
                getOverflowNodeDOMRect={getOverflowNodeDOMRect}
                isBlogpostEditor={isBlogpostEditor}
                editorState={editorState}
                toggleBlockType={toggleBlockType}
                toggleInlineStyle={toggleInlineStyle}
                createLinkAtSelection={createLinkAtSelection}
                focus={focusEditor}
              />
            </>
          ) : null}
          {!readOnly && isBlogpostEditor ? (
            <>
              {/*<AlignmentTool/>*/}
              <SideAddButton
                editorState={editorState}
                getEditorState={getEditorState}
                setEditorState={onChange}
                focus={focusEditor}
                imagePlugin={imagePlugin}
                videoPlugin={videoPlugin}
                fullSizeStyle={fullSizeStyle}
                placeholderH1={placeholderH1()}
              />
            </>
          ) : null}
          {isCursorLink && !readOnly ? (
            <LinkEditComponent
              {...isCursorLink}
              editorState={editorState}
              removeLink={removeLink}
              editLink={editLinkAfterSelection}
              getEditorNodeDOMRect={getEditorNodeDOMRect}
              getOverflowNodeDOMRect={getOverflowNodeDOMRect}
              isBlogpostEditor={isBlogpostEditor}
            />
          ) : null}
          {/*<EmojiSelect closeOnEmojiSelect />*/}
        </div>
      </div>
    </CatchEditorError>
  )
}

CustomEditor.displayName = 'CustomEditor'

//This broken plugins
// useEffect(() => {
//   if (!readOnly) {
//     focusEditor()
//   }
// }, [])
//
// const focusEditor = () => {
//   EditorNode.current?.focus()
// }

// const handleDroppedFiles = (selection: SelectionState, files: Array<Blob>) => {
//   const file = files[0];
//   if (file.type.indexOf('image/') === 0) {
//     const src = URL.createObjectURL(file);
//     onChange(addNewBlockAt(
//       editorState,
//       selection.getAnchorKey(),
//       Block.IMAGE, {
//         src,
//       }
//     ));
//     return HANDLED;
//   }
//   return NOT_HANDLED
// }
