import React, { CSSProperties, FC, useEffect, useState } from 'react'

import cn from 'classnames'

import { TRenderType } from '../../utils'

import styles from './styles.module.scss'

interface IProps {
  buttons: Array<{ key: number; title: string }>
  switchStyle?: CSSProperties
  buttonStyle?: CSSProperties
  onSwitchClick: (value: number) => void
  initialPosition: number
  preferredSwitchPositionFromModal?: number
  renderType?: TRenderType
}

const CustomMultipleSwitch: FC<IProps> = ({
  buttons,
  switchStyle,
  buttonStyle,
  initialPosition,
  onSwitchClick,
  preferredSwitchPositionFromModal,
  renderType = 'default',
}) => {
  const [switchPosition, setSwitchPosition] = useState<number>(initialPosition)

  const handlerSwitchClick = (value: number) => {
    if (switchPosition === value) return
    // setSwitchPosition(value) // now update switch position after confirm in modal
    onSwitchClick(value)
  }

  // now update switch position after confirm in modal
  useEffect(() => {
    if (typeof preferredSwitchPositionFromModal === 'number') {
      setSwitchPosition(preferredSwitchPositionFromModal)
    }
  }, [preferredSwitchPositionFromModal])

  const buttonWidth = `${100 / buttons.length}%`
  return (
    <div
      style={switchStyle}
      className={cn(styles.switch, {
        [styles.switch_widget]: renderType === 'widget',
      })}
    >
      <div className={styles.switchInner}>
        <div
          style={{
            width: buttonWidth,
            transform: `translateX(${switchPosition * 100}%)`,
          }}
          className={styles.switchBtn}
        />
        {buttons.map(item => (
          <div
            style={{ width: buttonWidth, ...buttonStyle }}
            key={item.key}
            className={cn({
              [styles.button]: true,
              [styles.inactive]: switchPosition !== item.key,
            })}
            onClick={() => handlerSwitchClick(item.key)}
          >
            {item.title}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CustomMultipleSwitch
