import React, { FC } from 'react'

import cn from 'classnames'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SxProps } from '@mui/system'

import { useStore } from 'store'

import ButtonIcon from '../ButtonIcon'

import styles from './styles.module.scss'

interface IProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  text: string
  buttonTooltip?: string
  color?: string
  formSX?: SxProps
  className?: string
}

export const CustomCheckbox: FC<IProps> = ({
  checked,
  onChange,
  text,
  buttonTooltip,
  color,
  formSX,
  className,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={onChange}
          sx={{
            color: color || 'var(--textColor)',
          }}
        />
      }
      label={
        <>
          <span
            style={{
              color: color,
            }}
          >
            {text}
          </span>
          {buttonTooltip && (
            <ButtonIcon
              style={{
                minWidth: '13px',
                width: '13px',
                height: '13px',
                margin: '0 0 1px 3px',
              }}
              styleIcon={{
                width: '7px',
                height: '7px',
              }}
              icon={'info'}
              tooltip={buttonTooltip}
              tooltipPosition="top"
              tooltipDisablePortal={false}
              bordered
            />
          )}
        </>
      }
      className={cn({
        [styles.customCheckbox]: true,
        [className || '']: !!className,
      })}
      sx={formSX}
    />
  )
}
