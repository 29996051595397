import { convertToRaw } from 'draft-js'
import { action, makeAutoObservable } from 'mobx'

import { api } from 'utils/config'

import type { IComment, ICreateComment } from 'models/comments'

import CreatePostStore from 'store/createPost'

class CommentsStore {
  constructor() {
    makeAutoObservable(this)
  }

  async createComments(data: ICreateComment, isGetBlockchainId?: boolean) {
    const response = await api.post(`api/Comment/Create`, {
      ...data,
      Text: isGetBlockchainId
        ? ''
        : JSON.stringify(convertToRaw(data.Text.getCurrentContent())),
      IpfsFiles: isGetBlockchainId
        ? []
        : CreatePostStore.convertIpfsFiles(data.IpfsFiles),
      NetworkType: 2,
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getComments(
    publicationId: number,
    page: number,
    pageSize: number,
    startTotalItems: number,
    parentCommentId?: number
  ) {
    const response = !parentCommentId
      ? await api.get(`api/Comment`, {
          params: {
            publicationId,
            page,
            pageSize,
            startTotalItems,
            parentCommentId,
          },
        })
      : await api.get(`api/Comment/getNestedComments`, {
          params: {
            commentId: parentCommentId,
            page,
            pageSize,
            startTotalItems,
          },
        })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getCommentById(commentId: number) {
    const response = await api.get(`api/Comment/getCommentById`, {
      params: {
        commentId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async deleteComment(commentId: number) {
    const response = await api.delete(`api/Comment`, {
      params: {
        commentId,
      },
    })

    if (response.status !== 204) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }
}

export default new CommentsStore()
