import React, { FC, useEffect } from 'react'

import cn from 'classnames'

import { Image3dFileExtensions } from '../../../utils/const'
import { getFileExtensionFromName } from '../../../utils/functions/common'
import { useGetElementSize } from '../../../utils/hooks'

import { CustomViewZoom } from 'components/CustomViewZoom'
import { View3D } from 'components/View3D'
import { View360 } from 'components/View360'
import ViewerModel3D from 'components/ViewerModel3D'

import { IIpfsFiles } from 'models'

import { ReactComponent as CloseIcon } from 'sources/images/close2.svg'
import { ReactComponent as Edit } from 'sources/images/edit_copy.svg'

import { FileWarningOverlay } from '../../FileWarningOverlay'
import { MediaBlur } from '../../MediaBlur'

import styles from './styles.module.scss'

interface IProps {
  images: IIpfsFiles[]
  handleClickOnZoom: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isZoomed: boolean
  onDeleteFile: (postId: string, key: string) => void
  postId: string
  handleEditImage: (fileLink: string) => void
  imageIndex: number
  imageBlockMarginBottom?: boolean
  onAdd3DPreview?: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  onRotate360Image?: (
    key: string,
    {
      yaw,
      pitch,
    }: {
      yaw: number
      pitch: number
    }
  ) => void
  postOnX?: boolean
}

//Resize
export const ImageBlock: FC<IProps> = ({
  images,
  handleClickOnZoom,
  isZoomed,
  onDeleteFile,
  postId,
  handleEditImage,
  imageIndex,
  imageBlockMarginBottom,
  onAdd3DPreview,
  onRotate360Image,
  postOnX,
}) => {
  const getButtonElement = (value: IIpfsFiles) => {
    if (value.Is360Image) {
      return (
        <View360
          url={URL.createObjectURL(value?.FileLink as unknown as File)}
          imagePosition={
            value?.Image360Position as IIpfsFiles['Image360Position']
          }
          onRotateOnCreate={position =>
            onRotate360Image?.(value?.FileLink, position as any)
          }
        />
      )
    } else if (value.Is3DImage) {
      const fileExtension =
        value.FileExtension ||
        getFileExtensionFromName(value?.FileLink as unknown as File)

      if (fileExtension === Image3dFileExtensions.usdz) {
        return null
      }

      return (
        <View3D
          url={URL.createObjectURL(value?.FileLink as unknown as File)}
          fileExtension={fileExtension}
        />
      )
    } else if (value?.IsSpatialVideo) {
      return null
    } else {
      return (
        <button
          className={cn({
            [styles.button]: true,
            [styles.buttonEdit]: true,
          })}
          onClick={e => handleEditImage(value?.FileLink)}
        >
          <Edit />
        </button>
      )
    }
  }

  return (
    <div
      onClick={handleClickOnZoom}
      className={cn({
        [styles.imageBlock]: true,
        [styles.imageBlockMarginBottom]: imageBlockMarginBottom,
      })}
    >
      <FileWarningOverlay
        files={images}
        fileIndex={imageIndex}
        postOnX={!!postOnX}
        text={
          "This attachment exceeds X (Twitter's) size or format limits. It will be posted only on Authencity"
        }
      />

      {images[imageIndex]?.Is3DImage ? (
        <ViewerModel3D
          imageBlob={images[imageIndex]?.FileLink}
          withLoading
          // disableZoom
          postId={postId}
          fileId={images[imageIndex]?.Id}
          onAdd3DPreview={onAdd3DPreview}
        />
      ) : images[imageIndex]?.IsSpatialVideo ? (
        <MediaBlur type={'spatialImage'} />
      ) : (
        <>
          <CustomViewZoom wrapStyleOpacity>
            <img
              src={URL.createObjectURL(
                images[imageIndex]?.FileLink as unknown as File
              )}
              alt=""
              className={cn({
                [styles.imageZoom]: true,
                [styles.imageZoomOpacity]: isZoomed,
              })}
            />
          </CustomViewZoom>
          <img
            className={styles.imageCover}
            src={URL.createObjectURL(
              images[imageIndex]?.FileLink as unknown as File
            )}
            alt=""
          />
        </>
      )}
      <button
        className={cn({
          [styles.button]: true,
          [styles.buttonDelete]: true,
          [styles.buttonDelete3D]:
            images[imageIndex]?.Is360Image || images[imageIndex]?.Is3DImage,
        })}
        onClick={() =>
          onDeleteFile(postId, images[imageIndex]?.FileLink as string)
        }
      >
        <CloseIcon />
      </button>

      {getButtonElement(images[imageIndex])}
    </div>
  )
}
