import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import moment from 'moment/moment'
import { toast } from 'App'

import styles from './styles.module.scss'

interface IProps {
  isAdultContent: boolean
  onlyView?: boolean
}

export const Blur: FC<IProps> = observer(({ isAdultContent, onlyView }) => {
  const [visible, setVisible] = useState(isAdultContent)
  const { profile } = useStore()

  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (onlyView) return
    e.stopPropagation()
    e.preventDefault()
    if (!profile.isAuthorizedProfileToast()) return
    if (!(moment.duration(moment().diff(profile.selectedUser.DateOfBirth)).asYears() >= 18)) {
      return toast({
        type: 'warning',
        message: `You don't have access to this functionality. You must be at least 18 years old`
      })
    }
    setVisible(false)
  }

  return (
    <>
      {visible
        ? <div className={styles.adultBlur} onClick={onClick}>
          <div className={styles.messageBlock}>
            <div>Attention</div>
            <div>This content has been marked as adult / sensitive</div>
            <div>Please click to view</div>
          </div>
        </div>
        : null}
    </>
  )
})