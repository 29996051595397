import { v4 as uuid } from 'uuid'

// Step 1: Import the S3Client object and all necessary SDK commands.
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

// Step 2: The s3Client function validates your request and directs it to your Space's specified endpoint using the AWS SDK.
const s3Client = new S3Client({
  endpoint: process.env.REACT_APP_AWS_ENDPOINT, // Find your endpoint in the control panel, under Settings. Prepend "https://".
  forcePathStyle: false, // Configures to use subdomain/virtual calling format.
  region: 'us-east-1', // Must be "us-east-1" when creating new Spaces. Otherwise, use the region in your endpoint (for example, nyc3).
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Access key pair. You can create access key pairs using the control panel or API.
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_SECRET_KEY, // Secret access key defined through an environment variable.
  },
} as any)

// Step 4: Define a function that uploads your object using SDK's PutObjectCommand object and catches any errors.
const addFileToDigitalOceanSpaces = async (
  file: any,
  folder: TFolderTypes = 'other'
) => {
  let customExtension = ''
  switch (folder) {
    case 'videos/preview':
      customExtension = '.jpg'
      break
    default:
  }

  const fileNameParts = file.name.split('.')
  const fileExtension = fileNameParts.pop()
  const newName =
    fileNameParts
      .join('.')
      .replace(/[^a-zA-Z0-9]/g, '_')
      ?.slice(0, 100) +
    '_' +
    uuid() +
    '.' +
    fileExtension

  // Step 3: Define the parameters for the object you want to upload.
  const params: any = {
    Bucket: process.env.REACT_APP_AWS_BUCKET_NAME, // The path to the directory you want to upload the object to, starting with your Space name.
    Key: `${process.env.REACT_APP_AWS_FOLDER_NAME}/${folder}/${newName}${customExtension}`, // Object key, referenced whenever you want to access this file later.
    Body: file, // The object's contents. This variable is an object, not a string.
    ACL: 'public-read', // Defines ACL permissions, such as private or public.
    // Metadata: {
    //   // Defines metadata tags.
    //   'x-amz-meta-my-key': 'your-value',
    // },
  }

  try {
    await s3Client.send(new PutObjectCommand(params))

    const fileLink =
      process.env.REACT_APP_AWS_DIGITAL_OCEAN_SPACES_CDN_GATEWAY + params.Key

    return fileLink
  } catch (err) {
    console.log('Digital Ocean Spaces', err)

    return ''
  }
}

type TFolderTypes =
  | 'publications'
  | 'videos/preview'
  | 'comments'
  | 'faq'
  | 'avatars'
  | 'socialPosts'
  | 'other'

export { addFileToDigitalOceanSpaces }
export type { TFolderTypes as TFolderTypesCDN }
