import React, { FC, useCallback, useRef, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import { ModalPWA } from '../ModalPWA'
import { Header } from './Header'
import { PostType } from 'store/createPost'
import { AddFileButtons } from 'components/AddFileButtons'
import { BaseEmoji } from 'emoji-mart'
import { EmojiMobile } from 'components/EmojiMobile'
import { PriceUpdate } from 'components/PriceUpdate'
import { SwitchCurrency } from 'components/SwitchCurrency'
import { AdultCheckbox } from 'components/AdultCheckbox'
import { CustomEditor } from 'components/CustomEditor'
import { EditorState } from 'draft-js'
import CustomMultipleSwitch from 'components/CustomMultipleSwitch'
import useMediaQuery from '@mui/material/useMediaQuery'
import { SuperpostPublicText } from 'components/CreatePostModalNew/component/SuperpostPublicText'
import { ViewMediaOnCreateBlock } from 'components/ViewMediaOnCreateBlock'

import { createPostFileType, UserRole, ViewTypeEditor } from 'utils'
import { IDraft, IIpfsFiles, ISendPublication } from 'models'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  post: ISendPublication
  postType: PostType
  activeBlock: string
  profileUserRole: UserRole | string
  draftToEdit: IDraft | null
  quantitySymbols: number
  isAdultContent: boolean
  transactionBlockKey: string
  superPostPublicText: EditorState

  onClose: () => void
  onCreatePost: (postType?: PostType) => void
  onNextBlogpost: () => void
  onChangePostType: (value: number) => void

  onAddFile: (id: string, fileList: IIpfsFiles[]) => void
  onAddEmoji: (value: BaseEmoji) => void
  onGetPrice: () => Promise<{ price: string } | undefined>
  onSaveStreamInDraft: () => void
  onChangeAdultContent: (isAdult: boolean, id: string) => void

  onChangeText: (
    id: string,
    editorData: EditorState,
    transactionBlockKey: string
  ) => void
  setActiveBlock: Function
  setActiveEditorSuperpostPublicText: (value: boolean) => void
  setTransactionActiveBlock: Function

  onAddVideoPoster: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  onAddVideoDuration?: (id: string, duration: number) => void
  onAddAudiWaveForm: (id: string, waveForm: string) => void
  onCheckFile: (id: string, fileId: string) => void
  onChangeTitleDescription: (
    id: string,
    text: EditorState,
    transactionBlockKey: string,
    type: 'title' | 'description',
    fileID: string
  ) => void
  onDeleteFile: (postId: string, key: string) => void
  onEditFile: (postId: string, key: string, newFile: IIpfsFiles) => void
  onChangeSuperpostPublicText: (value: EditorState) => void

  onRotate360Image: (
    postId: string,
    key: string,
    position: {
      yaw: number
      pitch: number
    }
  ) => void
}

export const CreatePostModalPWA: FC<IProps> = ({
  open,
  onClose,
  post,
  superPostPublicText,
  postType,
  activeBlock,
  onCreatePost,
  onNextBlogpost,
  onChangePostType,
  profileUserRole,
  draftToEdit,
  onAddFile,
  onAddEmoji,
  onGetPrice,
  onSaveStreamInDraft,
  quantitySymbols,
  isAdultContent,
  onChangeAdultContent,
  transactionBlockKey,
  onChangeText,
  setActiveBlock,
  setTransactionActiveBlock,
  setActiveEditorSuperpostPublicText,
  onEditFile,
  onDeleteFile,
  onAddVideoPoster,
  onAddVideoDuration,
  onAddAudiWaveForm,
  onChangeTitleDescription,
  onCheckFile,
  onChangeSuperpostPublicText,
  onRotate360Image,
}) => {
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false)

  const postModalWrapperNode = useRef<HTMLDivElement | null>(null)

  const media400 = useMediaQuery('(max-width:400px)')
  const draftSwitchStyle = draftToEdit
    ? { width: '100px', minWidth: '100px' }
    : {}

  const isEncrypted = postType === PostType.Encrypted
  const isSuperpost = postType === PostType.Superpost
  const isBlogpost = postType === PostType.Blogpost
  const isStreampost = postType === PostType.Streampost
  const isFreepost = postType === PostType.Freepost

  const handlerOpenEmoji = () => {
    const focusKey = post.EditorData.getSelection().getFocusKey()

    // Need for correct work
    setTimeout(() => {
      setOpenEmojiPicker(true)

      // Need for focus
      setTimeout(() => {
        const focusElement = document.querySelector(
          `[data-offset-key="${focusKey}-0-0"]`
        )
        focusElement?.scrollIntoView(false)
      }, 100)
    }, 100)
  }

  const handleChangeAdultContent = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    onChangeAdultContent(event.target.checked, post.Id)
  }

  const getOverflowNodeDOMRect = useCallback(() => {
    if (postModalWrapperNode.current) {
      return postModalWrapperNode.current.getBoundingClientRect()
    }
    return null
  }, [postModalWrapperNode.current])

  const handlerOnClickEditorBlock = () => {
    setTransactionActiveBlock(transactionBlockKey)
    setActiveBlock(post.Id)
    setActiveEditorSuperpostPublicText(false)

    if (openEmojiPicker) {
      setOpenEmojiPicker(false)
    }
  }

  return (
    <ModalPWA
      open={open}
      onClose={onClose}
      dialogMode
      fullScreen
      disableOutsideClick
      dialogActionsSX={openEmojiPicker ? { padding: '16px 0 0' } : {}}
      dialogContentTextSX={{ display: 'flex', flexDirection: 'column' }}
      dialogTitle={
        <Header
          postType={postType}
          onCreatePost={onCreatePost}
          onNextBlogpost={onNextBlogpost}
          onClose={onClose}
          onChangePostType={onChangePostType}
          profileUserRole={profileUserRole}
          draftToEdit={draftToEdit}
          onSaveStreamInDraft={onSaveStreamInDraft}
          quantitySymbols={quantitySymbols}
        />
      }
      dialogActions={
        <>
          <div
            className={styles.modalFooter}
            style={{ display: openEmojiPicker ? 'none' : 'flex' }}
          >
            {post.IpfsFiles.length > 0 &&
            !post.IpfsFiles.find(
              item => item.FileType === createPostFileType.file
            ) ? (
              <AdultCheckbox
                checked={isAdultContent}
                onChange={handleChangeAdultContent}
                formSX={{ margin: '0 0 5px' }}
              />
            ) : null}
            <div className={styles.buttonsBox}>
              <AddFileButtons
                ipfsFiles={post.IpfsFiles}
                onAddFile={onAddFile}
                postId={post.Id}
                onOpenEmojiPWA={handlerOpenEmoji}
                onlyEmoji={isEncrypted || isBlogpost || isStreampost}
              />
              {!isFreepost ? <SwitchCurrency modePWA /> : null}
            </div>
            <PriceUpdate
              onGetPrice={onGetPrice}
              activeBlock={activeBlock}
              blockId={post.Id}
            />
          </div>
          <EmojiMobile
            open={openEmojiPicker}
            onSelect={onAddEmoji}
            style={{ margin: 0 }}
          />
        </>
      }
    >
      <div
        className={cn({
          [styles.editorAreaWrapper]: true,
          [styles.editorAreaWrapperFitContent]: post.IpfsFiles.length > 0,
        })}
        ref={postModalWrapperNode}
      >
        <div className={styles.switchBox}>
          {profileUserRole === UserRole.User ? (
            <CustomMultipleSwitch
              key={0}
              initialPosition={0}
              onSwitchClick={onChangePostType}
              switchStyle={{
                width: '100px',
                minWidth: '100px',
                height: '32px',
                padding: '2px',
              }}
              buttonStyle={{ fontSize: media400 ? '0.6rem' : '0.75rem' }}
              buttons={[{ key: 0, title: 'Freepost' }]}
            />
          ) : (
            <CustomMultipleSwitch
              key={1}
              initialPosition={
                draftToEdit
                  ? 0
                  : profileUserRole === UserRole.User
                  ? 4
                  : (postType as any)
              }
              onSwitchClick={onChangePostType}
              switchStyle={{
                minWidth: '100%',
                width: '100%',
                height: '32px',
                padding: '2px',
                ...draftSwitchStyle,
              }}
              buttonStyle={{ fontSize: media400 ? '0.6rem' : '0.75rem' }}
              buttons={
                draftToEdit
                  ? [
                      {
                        key: 0,
                        title:
                          draftToEdit.Type === 0 ? 'Blogpost' : 'Streampost',
                      },
                    ]
                  : [
                      { key: 0, title: 'Blockchain' },
                      { key: 1, title: 'Encrypted' },
                      { key: 2, title: 'Superpost' },
                      { key: 3, title: 'Blogpost' },
                      { key: 4, title: 'Freepost' },
                    ]
              }
            />
          )}
        </div>
        <CustomEditor
          viewType={ViewTypeEditor.editor}
          modePWA
          postId={post.Id}
          transactionBlockKey={transactionBlockKey}
          editorState={post.EditorData}
          setPostEditorState={onChangeText}
          onClickEditorBlock={handlerOnClickEditorBlock}
          isEncrypted={isEncrypted}
          isBlogpostEditor={isBlogpost}
          fullSizeStyle={true}
          getOverflowNodeDOMRect={getOverflowNodeDOMRect}
        />
      </div>
      {/*Wait new design*/}
      {/*{isSuperpost ? <SuperpostPublicText text={superPostPublicText} onChange={onChangeSuperpostPublicText}*/}
      {/*                                    onClick={setActiveEditorSuperpostPublicText}/> : null}*/}

      {post.IpfsFiles.length > 0 && (
        <ViewMediaOnCreateBlock
          IpfsFiles={post.IpfsFiles}
          postId={post.Id}
          transactionBlockKey={transactionBlockKey}
          onDeleteFile={onDeleteFile}
          onEditFile={onEditFile}
          onAddVideoPoster={onAddVideoPoster}
          onAddVideoDuration={onAddVideoDuration}
          onAddAudiWaveForm={onAddAudiWaveForm}
          onChangeTitleDescription={onChangeTitleDescription}
          onCheckFile={onCheckFile}
          onRotate360Image={onRotate360Image}
          modePWA
        />
      )}
    </ModalPWA>
  )
}
