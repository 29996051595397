import React, { FC } from 'react';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { observer } from 'mobx-react'
import { useStore } from 'store'

import styles from './styles.module.scss';

interface IProps {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void
	handlerYesClick: () => void
}

//Resize
export const CloseModal: FC<IProps> = observer(({isOpen, setIsOpen, handlerYesClick}) =>  {
	const {mediaQuery} = useStore()

  return (
		<Modal
			size={'small'}
			onClose={() => setIsOpen(false)}
			open={isOpen}
			children={
				<div className={styles.closeModal}>
					<div className={styles.header}>Are you sure you want to close this window?</div>
					<div className={styles.buttons}>
						<Button
							style={{
								width: '48%',
								height: mediaQuery.minWidth_2200 ? '50px' : '40px',
								background:
									'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
							}}
							styleText={{color: '#ffffff'}}
							btnStyle="classic"
							text="Yes"
							onClick={handlerYesClick}
							blueHover
						/>
						<Button
							style={{
								width: '48%',
								height: mediaQuery.minWidth_2200 ? '50px' : '40px',
								background: 'var(--boxColor)',
								borderColor: '#1F45C3'
							}}
							styleText={{color: 'var(--modalButtonBorderTextColor)'}}
							btnStyle="bordered"
							text="No"
							onClick={() => setIsOpen(false)}
							blueHover
						/>
					</div>
				</div>
			}
		/>
  );
})
