import React, { FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import { useStore } from 'store'

import emptyPhoto from 'sources/images/emptyPhoto.png'

import styles from './styles.module.scss'

interface IProps {
  pwaMode?: boolean
  [x: string]: any
}

//Resize
export const UserImageForEditor: FC<IProps> = observer(
  ({ pwaMode, ...rest }) => {
    const { profile } = useStore()

    const userImage = profile.selectedUser.ImageLink

    return (
      <div
        className={cn(styles.imageBlock, {
          [styles.pwaMode]: pwaMode,
        })}
        {...rest}
      >
        <img
          className={cn({
            [styles.emptyImage]: !userImage,
          })}
          src={userImage ? userImage : emptyPhoto}
          alt="#"
        />
      </div>
    )
  }
)
