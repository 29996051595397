import React, { FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import { AuthCurrency, TRenderType } from 'utils'
import { useMediaQuery } from 'utils/hooks'

import { useStore } from 'store'

import { ReactComponent as Ethereum } from 'sources/images/ETH.svg'
import { ReactComponent as Matic } from 'sources/images/MATIC.svg'

import styles from './styles.module.scss'

interface IProps {
  modePWA?: boolean
  renderType?: TRenderType
}

//Resize
export const SwitchCurrency: FC<IProps> = observer(
  ({ modePWA, renderType = 'default' }) => {
    const { paymentCurrency, widget } = useStore()

    const handleChangeCurrency = () => {
      paymentCurrency.changeCurrency()
    }

    return (
      <div
        className={cn(styles.switchCurrencyBox, {
          [styles.modePWA]: modePWA,
          [styles.switchCurrencyBox_widget]: renderType === 'widget',
        })}
        style={widget.getWidgetBackground()}
      >
        <div className={styles.switchBoxInner}>
          <div
            className={cn({
              [styles.mask]: true,
              [styles.positionRight]:
                paymentCurrency.currency === AuthCurrency.ethereum,
            })}
          />
          <button
            className={cn({
              [styles.btn]: true,
              [styles.inactive]:
                paymentCurrency.currency !== AuthCurrency.polygon,
            })}
            onClick={handleChangeCurrency}
          >
            <Matic className={styles.maticIcon} />
          </button>
          <button
            className={cn({
              [styles.btn]: true,
              [styles.inactive]:
                paymentCurrency.currency !== AuthCurrency.ethereum,
            })}
            onClick={handleChangeCurrency}
          >
            <Ethereum className={styles.ethereumIcon} />
          </button>
        </div>
      </div>
    )
  }
)

SwitchCurrency.displayName = 'SwitchCurrency'
