import React, { useCallback, useState } from 'react'

import { ContentState, EditorState } from 'draft-js'

import { getPostTextFromEditorState, ViewTypeEditor } from 'utils'

import { CustomEditor } from 'components/CustomEditor'

import { ISocialPostInfo } from 'models'

import ButtonNew from '../../../ButtonNew'
import TwitterPostPreview from '../../../SocialPostPreview/TwitterPostPreview'

import styles from './styles.module.scss'

interface IProps {
  socialPostLink: EditorState
  onChange: (value: EditorState) => void
  socialPostInfo: ISocialPostInfo | null
  onReceiveSocialPostInfo: (info: ISocialPostInfo) => void
}

const SocialPostInfo: React.FC<IProps> = ({
  socialPostLink,
  onChange,
  socialPostInfo,
  onReceiveSocialPostInfo,
}) => {
  const [url, _setUrl] = useState('')

  const setUrl = useCallback(() => {
    _setUrl(getPostTextFromEditorState(socialPostLink))
  }, [socialPostLink])

  const handlePressEnter = () => {
    setUrl()

    return false
  }

  return (
    <div className={styles.socialPostInfo}>
      <div className={styles.link}>
        <div className={styles.title}>
          <div>X Post link</div>
          <span>*</span>
        </div>
        <div className={styles.editorWrapper}>
          <CustomEditor
            viewType={ViewTypeEditor.editorWithoutAvatar}
            editorState={socialPostLink}
            setEditorState={onChange}
            editorAutoHeight
            styleContainer={{ margin: 0 }}
            placeholder={'Add X Post link'}
            withoutToolbar={true}
            onClickEnter={handlePressEnter}
          />
        </div>

        <div className={styles.buttons}>
          <ButtonNew
            btnStyleType={'style_6'}
            btnStyle={{}}
            btnText={'Generate preview'}
            onClick={setUrl}
            className={styles.button}
          />
        </div>
      </div>

      {url && (
        <TwitterPostPreview
          url={url}
          socialPostInfo={socialPostInfo}
          onReceiveSocialPostInfo={onReceiveSocialPostInfo}
          isCreateMode={true}
        />
      )}
    </div>
  )
}

SocialPostInfo.displayName = 'SocialPostInfo'

export default SocialPostInfo
