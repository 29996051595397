import { CompositeDecorator, ContentBlock } from 'draft-js'
import React, { FC } from 'react'
import { BadWords } from 'utils/const'

const Decorated: FC = ({ children }: any) => {
  return <span style={{ color: 'red' }}>{children}</span>
}

function findWithRegex(words: string[], contentBlock: ContentBlock, callback: (start: number, end: number) => void) {
  const text: any = contentBlock.getText()

  words.forEach(word => {
    const matches = [...text.matchAll(new RegExp('\\b(?:' + word + ')\\b', 'gi'))]
    matches.forEach(match =>
      callback(match.index, match.index + match[0].length)
    )
  })
}

function handleStrategy(contentBlock: ContentBlock, callback: (start: number, end: number) => void) {
  findWithRegex(BadWords, contentBlock, callback)
}

export const badWordsHighlightDecoratorPlugin = {
  decorators: [
    {
      strategy: handleStrategy,
      component: Decorated
    }
  ]
}

