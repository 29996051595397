import React, { useRef } from 'react'
import Draft, { AtomicBlockUtils, ContentBlock, ContentState, EditorState, Entity, genKey } from 'draft-js'
import cn from 'classnames'
import { toast } from 'App'

import { addNewBlock } from '../../../util'
import { Block, NOT_HANDLED } from '../../../util/constants'
import { ImageEditorPlugin } from '@draft-js-plugins/image'
import { getImagesSrcWithDimensions } from 'utils'

import { ReactComponent as ImageSvg } from 'sources/images/createPostModal/getImage.svg'
import styles from './styles.module.scss'

interface ImageButtonProps {
  setEditorState: (editorState: EditorState) => void;
  getEditorState: () => EditorState;
  close: () => void;
  imagePlugin: ImageEditorPlugin
}

export const ImageButton: React.FC<ImageButtonProps> = ({ setEditorState, getEditorState, close, imagePlugin }) => {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const onClick = (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
    e.stopPropagation()
    if (inputRef.current) {
      inputRef.current.value = ''
      inputRef.current.click()
    }
  }

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const fileList = Array.from(e.target.files)
      const filteredFileList = fileList.filter(file => (file.type === 'image/png' || file.type === 'image/jpeg') && file.size <= 52428800)

      if (filteredFileList.length > 0) {
        setEditorState(
          addNewBlock(getEditorState(), Block.IMAGE, {
            srcArray: await getImagesSrcWithDimensions(filteredFileList)
            // srcArray: filteredFileList.map(file => URL.createObjectURL(file))
          }, true)
        )

      } else {
        toast({
          type: 'warning',
          message: 'You can only use JPG, JPEG, PNG formats for images'
        })
        return false
      }

      // if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      //   if (file.size > 52428800) {
      //     return toast({
      //       type: 'warning',
      //       message: 'Maximum image size limit is 50MB'
      //     })
      //   }
      //
      //   const objectURL = URL.createObjectURL(file)
      //   setEditorState(
      //     addNewBlock(getEditorState(), Block.IMAGE, {
      //       src: objectURL,
      //     })
      //   );
      //   // setEditorState(imagePlugin.addImage(getEditorState(), objectURL, {}))
      // } else {
      //   toast({
      //     type: 'warning',
      //     message: 'You can only use JPG, JPEG, PNG formats for images'
      //   })
      //   return false
      // }
    }
    close()
  }

  return (
    <button
      className={cn({
        [styles.sideButton]: true
      })}
      type="button"
      onClick={onClick}
      // title="Add an Image"
    >
      <ImageSvg/>

      <input
        multiple
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={inputRef}
        onChange={onChange}
        style={{ display: 'none' }}
      />
    </button>
  )
}

// const newAtomicBlock = contentState.getBlockMap().find(b=>b.getEntityAt(0)===entityKey).getKey();
// const newBlockMap = contentState.getBlockMap().delete(contentState.getBlockBefore(newAtomicBlock)).delete(contentState.getBlockAfter(newAtomicBlock));
// const newContentState = contentState.set('blockMap', newBlockMap);
// const newEditorState = EditorState.push(editorState, newContentState, 'delete-empty-lines');

// const addImage = () => {
//   const virtualElement = document.createElement('input')
//   virtualElement.setAttribute('type', 'file')
//   virtualElement.setAttribute('accept', ".jpg, .jpeg, .png")
//   virtualElement.click()
//
//   virtualElement.addEventListener('change', (evt: any) => {
//     if (evt.target) {
//       const file = evt.target.files[0]
//
//       if (file.type === 'image/png' || file.type === 'image/jpeg') {
//         const objectURL = URL.createObjectURL(file)
//         onChange(imagePlugin.addImage(editorState, objectURL, {}))
//       } else {
//         toast({
//           type: 'warning',
//           message: 'You can only use JPG, JPEG, PNG formats for images'
//         })
//         return false
//       }
//     }
//   })
// }

// const insertImage = () => {
//   setTimeout(() => {
//     const { editorState } = this.state;
//     const contentState = editorState.getCurrentContent();
//     const data = {
//       image: {
//         url: 'http://www.maniakucing.com/wp-content/uploads/2017/04/kucing-garong.png',
//         title: 'kucing garong'
//       }
//     };
//
//     const entityKey = Entity.create('image', 'IMMUTABLE', data);
//     const withAtomic = AtomicBlockUtils.insertAtomicBlock(
//       editorState,
//       entityKey,
//       ' '
//     );
//     const nextContentState = withAtomic.getCurrentContent();
//     const blockMap = nextContentState.getBlockMap();
//     const currentAtomicBlock = blockMap.find((block: any) => {
//       if (block.getEntityAt(0) === entityKey) {
//         return block;
//       }
//     })
//     const atomicBlockKey = currentAtomicBlock.getKey();
//     const blockBeforeText = nextContentState.getBlockBefore(atomicBlockKey)?.getText();
//     const blockNextText = nextContentState.getBlockAfter(atomicBlockKey)?.getText();
//     const blockAfterKey = nextContentState.getBlockAfter(atomicBlockKey)?.getKey();
//     const currentBlockKey = editorState.getSelection().getStartKey();
//     const currentBlockIndex = editorState.getCurrentContent().getBlockMap()
//       .keySeq().findIndex(k => k === currentBlockKey);
//     const currentCursorIndex = editorState.getSelection().getFocusOffset();
//     const currentBlockText = editorState.getCurrentContent().getBlockForKey(currentBlockKey).getText();
//
//     let blockAfterText = '';
//     if (nextContentState.getBlockAfter(blockAfterKey)) {
//       blockAfterText = nextContentState.getBlockAfter(blockAfterKey).getText();
//     }
//     console.log('blockNextText', blockNextText);
//     console.log('blockAfterText', blockAfterText);
//     if (blockBeforeText.length > 0) {
//       if(blockNextText.length > 0) {
//         this.onChange(EditorState.moveFocusToEnd(withAtomic));
//       } else {
//         if (blockAfterText.length > 0) {
//           const blockAfter = nextContentState.getBlockAfter(atomicBlockKey).getKey();
//           const newBlockMap = blockMap.filter(block => {
//             if (block.getKey() !== blockAfter) {
//               return block;
//             }
//           });
//           const newContentState = contentState.set('blockMap', newBlockMap);
//           const newEditorState = EditorState.createWithContent(newContentState);
//           this.onChange(EditorState.moveFocusToEnd(
//             newEditorState
//           ));
//         } else {
//           this.onChange(EditorState.moveFocusToEnd(withAtomic));
//         }
//       }
//     } else {
//       const blockBefore = nextContentState.getBlockBefore(atomicBlockKey).getKey();
//       let newBlockMap = blockMap.filter(block => {
//         if (block.getKey() !== blockBefore) {
//           return block;
//         }
//       });
//       const newContentState = contentState.set('blockMap', newBlockMap);
//       const newEditorState = EditorState.createWithContent(newContentState);
//       this.onChange(EditorState.moveFocusToEnd(
//         newEditorState
//       ));
//     }
//   }, 1000);
// }
