import React, { CSSProperties, FC } from 'react'

import cn from 'classnames'

import { useGetElementSize } from 'utils/hooks'

import { ReactComponent as Image3DIcon } from 'sources/images/superpostTypes/image_3D.svg'
import { ReactComponent as Spatial_VideoIcon } from 'sources/images/superpostTypes/spatial_video_1.svg'

import styles from './styles.module.scss'

interface IProps {
  type: 'spatialVideo' | 'spatialImage' | '3dImageUsdz'
  style?: CSSProperties
  children?: React.ReactElement
}

export const MediaBlur: FC<IProps> = ({ type, style, children }) => {
  let icon
  let text = ''

  switch (type) {
    case 'spatialVideo':
      icon = Spatial_VideoIcon
      text = 'Use Vision Pro or iOS Application to view Spatial Video'
      break
    case 'spatialImage':
      icon = Spatial_VideoIcon
      text = 'Use Vision Pro or iOS Application to view Spatial Image'
      break
    case '3dImageUsdz':
      icon = Image3DIcon
      text = 'Use Vision Pro Application to view this 3D image'
      break
    default:
      icon = <></>
  }

  const { ref, width: divWidth } = useGetElementSize()

  return (
    <div
      className={cn({
        [styles.mediaBlur]: true,
        [styles.mediaBlur_multiple]: divWidth > 0 && divWidth < 275,
      })}
      style={style}
      ref={ref}
    >
      <div
        className={cn({
          [styles.iconsBlock]: true,
        })}
      >
        {React.createElement(icon as any, {
          style: { zIndex: 1 },
        })}
      </div>

      <div className={styles.mediaBlurPost}>
        <div className={styles.item}>{text}</div>
      </div>

      {children}
    </div>
  )
}

MediaBlur.displayName = 'MediaBlur'
