import React, { FC } from 'react'

import { isTelegramAndroid } from '../../utils'
import { useIsInIframe } from '../../utils/hooks'

import { useStore } from 'store'

import { ReactComponent as Apple } from 'sources/images/apple.svg'

import { BottomBanner } from '../BottomBanner'

const TESTFLIGHT_LINK = 'https://testflight.apple.com/join/w1P3Tn76'

interface IProps {
  showBanner: boolean
}

export const TestIOSAppBanner: FC<IProps> = ({ showBanner }) => {
  const { widget } = useStore()

  const handleClickButton = () => {
    window.open(TESTFLIGHT_LINK, '_blank')
  }

  const isInIframe = useIsInIframe()
  const isTelegramAndroidBrowser = isTelegramAndroid()

  if (!showBanner || widget.apiKey || isInIframe || isTelegramAndroidBrowser) {
    return null
  }

  return (
    <BottomBanner
      bannerKey={'test_iOS_app'}
      text={
        'We’re excited to invite you to test our new iOS beta app. By joining our beta testing program, you’ll get early access to the latest features and help us improve the app with your valuable feedback.'
      }
      closeButton={{
        text: 'Close',
      }}
      actionButton={{
        text: (
          <>
            <Apple
              style={{
                // filter: 'invert(1)',
                width: 18,
                height: 18,
              }}
            />
            <span>Try now</span>
          </>
        ),
        onClick: handleClickButton,
      }}
      delay={750}
    />
  )
}

TestIOSAppBanner.displayName = 'TestIOSAppBanner'
