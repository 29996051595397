import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'

import { createPostFileType, getNormalizedFile } from 'utils'

import { AudioWaveForm } from 'components/AudioWaveForm'
import { CropImageModal } from 'components/CropImageModal'
import VideoPlayer from 'components/VideoPlayer'

import { IIpfsFiles } from 'models'

import { ReactComponent as CloseIcon } from 'sources/images/close2.svg'

import { FileViewer } from '../FileViewer'
import { FileWarningOverlay } from '../FileWarningOverlay'
import { ImageBlock } from './ImageBlock'
import { VideoAudioTitleDescription } from './VideoAudioTitleDescription'
import { VideoAudioTitleDescriptionPWA } from './VideoAudioTitleDescriptionPWA'

import styles from './styles.module.scss'

interface IProps {
  IpfsFiles: IIpfsFiles[]
  postId: string
  transactionBlockKey?: string
  onDeleteFile: (postId: string, key: string) => void
  onEditFile: (postId: string, key: string, newFile: IIpfsFiles) => void
  onRotate360Image?: (
    postId: string,
    key: string,
    position: {
      yaw: number
      pitch: number
    }
  ) => void
  onAddVideoPoster?: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  onAddVideoDuration?: (id: string, duration: number) => void
  onAddAudiWaveForm?: (id: string, waveForm: string) => void
  onChangeTitleDescription?: (
    id: string,
    text: EditorState,
    transactionBlockKey: string,
    type: 'title' | 'description',
    fileID: string
  ) => void
  onCheckFile?: (id: string, fileId: string) => void
  modePWA?: boolean
  postOnX?: boolean
}

//Resize
export const ViewMediaOnCreateBlock: FC<IProps> = observer(
  ({
    IpfsFiles,
    postId,
    transactionBlockKey,
    onDeleteFile,
    onEditFile,
    onAddVideoPoster,
    onAddVideoDuration,
    onAddAudiWaveForm,
    onChangeTitleDescription,
    onCheckFile,
    modePWA,
    onRotate360Image,
    postOnX,
  }) => {
    const images = IpfsFiles.filter(
      item => item.FileType === createPostFileType.image
    )
    const mediaBlockItemNewRef = useRef<HTMLDivElement | null>(null)
    const [blockHeight, setBlockHeight] = useState<number>()
    const [isZoomed, setIsZoomed] = useState(false)
    const [editImage, setEditImage] = useState<string>()
    const [editImageFile, setEditImageFile] = useState<File>()
    const [isOpenEditImage, setIsOpenEditImage] = useState<boolean>(false)
    // const [isOpenEditAudioData, setOpenEditAudioData] = useState(false)
    // const [isOpenEditVideoData, setOpenEditVideoData] = useState(false)

    useEffect(() => {
      if (mediaBlockItemNewRef.current) {
        const width =
          mediaBlockItemNewRef.current?.getBoundingClientRect().width
        width && setBlockHeight((width / 100) * 56)
        window.addEventListener('resize', getWidth)
        window.addEventListener('scroll', handleScroll)
        document.addEventListener('keydown', handleClickEscape)
      }

      return () => {
        window.removeEventListener('resize', getWidth)
        window.removeEventListener('scroll', handleScroll)
        document.removeEventListener('keydown', handleClickEscape)
      }
    }, [mediaBlockItemNewRef.current])

    const getWidth = () => {
      const width = mediaBlockItemNewRef.current?.getBoundingClientRect().width
      width && setBlockHeight((width / 100) * 56)
    }

    const handleScroll = () => {
      setIsZoomed(false)
    }

    const handleClickEscape = (e: KeyboardEvent) => {
      if (e.key == 'Escape') {
        setIsZoomed(false)
      }
    }

    const handleClickOnZoom = (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if ((e.target as HTMLDivElement).tagName === 'BUTTON') {
        setIsZoomed(prev => !prev)
      }
    }

    const handleEditImage = async (fileLink: string) => {
      setEditImageFile(fileLink as unknown as File)
      setEditImage(URL.createObjectURL(fileLink as unknown as File))
      // const imageDataUrl = await readFile(fileLink)
      // setEditImage(imageDataUrl as string)
      setIsOpenEditImage(true)
    }

    const handlerOnRotate360Image = (
      key: string,
      position: {
        yaw: number
        pitch: number
      }
    ) => {
      onRotate360Image?.(postId, key, position)
    }

    const readFile = useCallback((file: File) => {
      return new Promise((resolve, reject) => {
        try {
          const reader = new FileReader()
          reader.onload = () => resolve(reader.result)
          getNormalizedFile(file)
            .then((normalizedFile: any) => reader.readAsDataURL(normalizedFile))
            .catch(error => reject(error))
        } catch (error) {
          reject(error)
        }
      })
    }, [])

    // const handleOpenEditAudioData = () => {
    //   setOpenEditAudioData(prev => !prev)
    // }

    // const handleOpenEditVideoData = () => {
    //   setOpenEditVideoData(prev => !prev)
    // }

    return (
      <div
        className={styles.mediaBlock}
        style={{ marginTop: modePWA ? '12px' : 0 }}
      >
        <CropImageModal
          imageAvatar={editImage}
          imageFile={editImageFile}
          isOpenCropImage={isOpenEditImage}
          setIsOpenCropImage={setIsOpenEditImage}
          onEditImage={onEditFile}
          postId={postId}
        />
        {images.length > 0 ? (
          <div
            ref={mediaBlockItemNewRef}
            style={{ height: blockHeight }}
            className={cn({
              [styles.mediaBlockItemNew]: true,
              [styles.mediaBlockItemNewMany]: true,
            })}
          >
            <div
              className={cn({
                [styles.firstBlock]: true,
                [styles.firstBlockMarginRight]: images.length > 1,
              })}
            >
              <ImageBlock
                images={images}
                imageIndex={0}
                postId={postId}
                isZoomed={isZoomed}
                handleClickOnZoom={handleClickOnZoom}
                handleEditImage={handleEditImage}
                onDeleteFile={onDeleteFile}
                imageBlockMarginBottom={images.length === 4}
                onAdd3DPreview={onAddVideoPoster}
                onRotate360Image={handlerOnRotate360Image}
                postOnX={postOnX}
              />

              {images.length === 4 ? (
                <ImageBlock
                  images={images}
                  imageIndex={2}
                  postId={postId}
                  isZoomed={isZoomed}
                  handleClickOnZoom={handleClickOnZoom}
                  handleEditImage={handleEditImage}
                  onDeleteFile={onDeleteFile}
                  onAdd3DPreview={onAddVideoPoster}
                  onRotate360Image={handlerOnRotate360Image}
                  postOnX={postOnX}
                />
              ) : null}
            </div>

            {images.length > 1 ? (
              <div
                className={cn({
                  [styles.secondBlock]: true,
                })}
              >
                <ImageBlock
                  images={images}
                  imageIndex={1}
                  postId={postId}
                  isZoomed={isZoomed}
                  handleClickOnZoom={handleClickOnZoom}
                  handleEditImage={handleEditImage}
                  onDeleteFile={onDeleteFile}
                  imageBlockMarginBottom={images.length > 2}
                  onAdd3DPreview={onAddVideoPoster}
                  onRotate360Image={handlerOnRotate360Image}
                  postOnX={postOnX}
                />

                {images.length > 2 ? (
                  <ImageBlock
                    images={images}
                    imageIndex={images.length === 3 ? 2 : 3}
                    postId={postId}
                    isZoomed={isZoomed}
                    handleClickOnZoom={handleClickOnZoom}
                    handleEditImage={handleEditImage}
                    onDeleteFile={onDeleteFile}
                    onAdd3DPreview={onAddVideoPoster}
                    onRotate360Image={handlerOnRotate360Image}
                    postOnX={postOnX}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}

        {IpfsFiles.map((file: IIpfsFiles, idx: number) => {
          if (file.FileType === createPostFileType.gif) {
            return (
              <div
                key={`${postId}_${idx}`}
                className={cn({
                  [styles.mediaBlockItem]: true,
                })}
              >
                <img
                  className={styles.image}
                  src={URL.createObjectURL(file.FileLink as unknown as File)}
                  alt=""
                />
                <button
                  className={styles.deleteButton}
                  onClick={() => onDeleteFile(postId, file.FileLink as string)}
                >
                  <CloseIcon />
                </button>
              </div>
            )
          } else if (
            file.FileType === createPostFileType.video ||
            file.FileType === createPostFileType.stream
          ) {
            const isStreampost = file.FileType === createPostFileType.stream
            return (
              <div
                className={styles.videoContainer}
                style={{ width: '100%' }}
                key={`${postId}_${idx}`}
              >
                <FileWarningOverlay
                  files={IpfsFiles}
                  fileIndex={idx}
                  postOnX={!!postOnX}
                  text={
                    "This attachment exceeds X (Twitter's) size or format limits. It will be posted only on Authencity"
                  }
                />

                {file.FileLink ? (
                  <div id={`${postId}_video`} className={styles.player}>
                    {!isStreampost ? (
                      <button
                        className={styles.deleteButton}
                        onClick={() =>
                          onDeleteFile(postId, file.FileLink as string)
                        }
                      >
                        <CloseIcon />
                      </button>
                    ) : null}
                    {/*<div className={styles.editButtonBlockVideo}>*/}
                    {/*  <EditButton onClick={handleOpenEditVideoData} showButton={isOpenEditVideoData} type={'revert'}/>*/}
                    {/*</div>*/}
                    <VideoPlayer
                      videoBlob={!isStreampost ? file.FileLink : ''}
                      url={isStreampost ? file.FileLink : ''}
                      smallButton
                      poster={file.VideoPreview}
                      postId={postId}
                      fileId={file.Id}
                      onAddVideoPoster={onAddVideoPoster}
                      onAddVideoDuration={
                        isStreampost ? undefined : onAddVideoDuration
                      }
                      createPoster
                      disableDefaultCreatePoster={
                        isStreampost || Boolean(file.DefaultVideoPreview)
                      }
                    />
                  </div>
                ) : null}

                {file.VideoAudioTitle &&
                file.VideoAudioDescription &&
                !modePWA ? (
                  <VideoAudioTitleDescription
                    editorStateTitle={file.VideoAudioTitle}
                    editorStateDescription={file.VideoAudioDescription}
                    onChange={onChangeTitleDescription}
                    postId={postId}
                    transactionBlockKey={transactionBlockKey}
                    type="video"
                    styleBlock={{ marginTop: file.FileLink ? '15px' : 0 }}
                    fileID={file.Id}
                  />
                ) : null}

                {modePWA ? (
                  <VideoAudioTitleDescriptionPWA
                    editorStateTitle={file.VideoAudioTitle}
                    editorStateDescription={file.VideoAudioDescription}
                    onChange={onChangeTitleDescription}
                    postId={postId}
                    transactionBlockKey={transactionBlockKey}
                    type="video"
                    fileID={file.Id}
                  />
                ) : null}
              </div>
            )
          } else if (file.FileType === createPostFileType.audio) {
            return (
              <div
                className={cn({
                  [styles.audioPlayerBox]: true,
                })}
                key={`${postId}_${idx}`}
              >
                <FileWarningOverlay
                  files={IpfsFiles}
                  fileIndex={idx}
                  postOnX={!!postOnX}
                  text={
                    "This attachment exceeds X (Twitter's) size or format limits. It will be posted only on Authencity"
                  }
                />

                <div
                  id={`${postId}_audio`}
                  className={styles.audioPlayer}
                  key={`${postId}_${idx}`}
                >
                  <button
                    className={cn({
                      [styles.deleteButton]: true,
                      [styles.deleteButtonAudio]: true,
                    })}
                    onClick={() =>
                      onDeleteFile(postId, file.FileLink as string)
                    }
                  >
                    <CloseIcon />
                  </button>
                  {/*<div className={styles.editButtonBlockAudio}>*/}
                  {/*  <EditButton onClick={handleOpenEditAudioData} showButton={isOpenEditAudioData}/>*/}
                  {/*</div>*/}
                  <AudioWaveForm
                    audioData={file.FileLink}
                    onAddAudiWaveForm={onAddAudiWaveForm}
                    postId={postId}
                  />
                </div>
                {file.VideoAudioTitle &&
                file.VideoAudioDescription &&
                !modePWA ? (
                  <VideoAudioTitleDescription
                    editorStateTitle={file.VideoAudioTitle}
                    editorStateDescription={file.VideoAudioDescription}
                    postId={postId}
                    transactionBlockKey={transactionBlockKey}
                    onChange={onChangeTitleDescription}
                    type="audio"
                    fileID={file.Id}
                  />
                ) : null}

                {modePWA ? (
                  <VideoAudioTitleDescriptionPWA
                    editorStateTitle={file.VideoAudioTitle}
                    editorStateDescription={file.VideoAudioDescription}
                    onChange={onChangeTitleDescription}
                    postId={postId}
                    transactionBlockKey={transactionBlockKey}
                    type="audio"
                    fileID={file.Id}
                  />
                ) : null}
              </div>
            )
          } else if (file.FileType === createPostFileType.file) {
            return (
              <FileViewer
                postId={postId}
                key={`${postId}_${idx}`}
                file={file}
                onDeleteFile={onDeleteFile}
                onCheckFile={onCheckFile}
                createMode
                postOnX={postOnX}
              />
            )
          }
        })}
      </div>
    )
  }
)

ViewMediaOnCreateBlock.displayName = 'ViewMediaOnCreateBlock'
