import React, { FC, useEffect, useRef, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import URLUtils from 'utils/URLUtils'
import { AtomicBlockUtils, EditorState } from 'draft-js'
import { toast } from 'App'

import styles from './styles.module.scss'

interface IProps {
  setEditorState: (editorState: EditorState) => void;
  getEditorState: () => EditorState;
  close: () => void;
}

export const EmbedLinkInput: FC<IProps> = ({ setEditorState, getEditorState, close }) => {
  const [embedUrl, setEmbedUrl] = useState('')
  const inputRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [inputRef.current])

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      e.stopPropagation()
      if (embedUrl.length === 0) return

      addEmbedURL(embedUrl)
    }
  }

  const addEmbedURL = (url: string) => {
    let newUrl = url
    if (!URLUtils.isMail(URLUtils.normaliseMail(newUrl))) {
      newUrl = URLUtils.normalizeUrl(newUrl)
      if (!URLUtils.isUrl(newUrl)) {
        return toast({
          type: 'warning',
          message: 'Invalid URL format'
        })
      }
    } else {
      newUrl = URLUtils.normaliseMail(newUrl)
    }

    if (newUrl !== '') {
      let editorState = getEditorState()
      const content = editorState.getCurrentContent()
      const contentWithEntity = content.createEntity('embed', 'IMMUTABLE', { url })
      const entityKey = contentWithEntity.getLastCreatedEntityKey()

      editorState = EditorState.push(editorState, contentWithEntity, 'apply-entity')
      setEditorState(
        AtomicBlockUtils.insertAtomicBlock(
          editorState,
          entityKey,
          ' '
        )
      )

      close()
    }
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmbedUrl(e.target.value)
  }

  return (
    <div className={styles.urlEmbedInputBox}
         onClick={event => {
           event.preventDefault()
           event.stopPropagation()
         }}>
      <input className={styles.urlInput}
             ref={inputRef}
             value={embedUrl}
             onChange={onChange}
             onKeyDown={onKeyDown}
             type="text"
             placeholder="Paste a link to embed content from another site (e.g. Twitter) and press Enter"/>
    </div>
  )
}
