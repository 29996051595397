import React, { FC, useEffect, useState } from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

interface IProps {
  mentions: string[]
  modePWA?: boolean
}

export const EncryptedToast: FC<IProps> = ({ mentions, modePWA }) => {
  // const [open, setOpen] = useState(false)
  //
  // useEffect(() => {
  //   mention && setOpen(true)
  //   const timer = setTimeout(() => {
  //     setOpen(false)
  //   }, 3000)
  //
  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [mention])

  // delete splice, now it is for tagging only one user
  const filteredMentions = mentions?.filter((e: string) => !!e)?.splice(0, 1)

  if ((!filteredMentions || filteredMentions?.length === 0) && !modePWA)
    return null

  return (
    <div
      className={cn(styles.container, {
        [styles.modePWA]: modePWA,
      })}
    >
      {filteredMentions?.length > 0
        ? `This message can only be unencrypted and read by: ${filteredMentions
            ?.map((m: string) => `@${m}`)
            ?.join(', ')}`
        : 'Tag someone who can read this message using "@"'}
    </div>
  )
}
