import { addFileToDigitalOceanSpaces, TFolderTypesCDN } from '../digitalOcean'
import { ipfs } from '../ipfs'

export const uploadFileByLink = async (
  fileUrl: string,
  folder: TFolderTypesCDN = 'other'
) => {
  try {
    const response = await fetch(fileUrl)
    if (!response.ok) {
      throw new Error('Failed to fetch file')
    }

    const fileBlob = await response.blob()

    const fileName = fileUrl.split('/').pop() || 'file'
    const fileType = fileBlob.type || 'application/octet-stream'
    const fileObj = new File([fileBlob], fileName, { type: fileType })

    const [ipfsFile, cdnFileLink] = await Promise.all([
      await ipfs.add(fileObj),
      addFileToDigitalOceanSpaces(fileObj, folder),
    ])

    return {
      FileType: fileType?.split('/')?.[0],
      FileLink: `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}`,
      CdnFileLink: cdnFileLink,
    }
  } catch (error) {
    console.error('Error uploading image to IPFS:', error)
  }
}
