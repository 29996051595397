import React, { FC, useEffect } from 'react'
import { Blur } from './Blur'

interface IProps {
  isAdultContent: boolean | undefined
  onlyView?: boolean
}

export const AdultBlur: FC<IProps> = React.memo(({ isAdultContent, onlyView }) => {
  return (
    <>
      {isAdultContent !== undefined
        ? <Blur isAdultContent={isAdultContent} onlyView={onlyView}/>
        : null}
    </>
  )
})