import React, { FC, useCallback, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { useCreatePost, useHandleScroll, useIsInIframe } from 'utils/hooks'

import { useStore } from 'store'

import { ReactComponent as IconNewPost } from 'sources/images/iconNewPost.svg'

import ButtonNew from '../ButtonNew'
import CreatePostModalNew from '../CreatePostModalNew'

import styles from './styles.module.scss'

const BUTTON_BOTTOM_POSITION = 16
const BUTTON_BOTTOM_POSITION_MODIFIED = 64

export const CreatePublicationButton: FC = () => {
  const location = useLocation()

  const { profile } = useStore()
  const {
    draftToEdit,
    isOpenCreatePostModal,
    getPublication,
    getDrafts,
    handleOpenCreatePostModal,
    setDefaultPostsSettings,
    setIsOpenCreatePostModal,
  } = useCreatePost({})

  const [buttonBottomPosition, setButtonBottomPosition] = useState(
    BUTTON_BOTTOM_POSITION
  )

  const onReachTop = () => {
    setButtonBottomPosition(BUTTON_BOTTOM_POSITION)
  }

  const onReachBottom = () => {
    setButtonBottomPosition(BUTTON_BOTTOM_POSITION_MODIFIED)
  }

  const onScroll = () => {
    if (buttonBottomPosition !== BUTTON_BOTTOM_POSITION_MODIFIED) {
      setButtonBottomPosition(BUTTON_BOTTOM_POSITION_MODIFIED)
    }
  }

  useHandleScroll({
    onReachTop: onReachTop,
    onReachInitialHeight: onReachTop,
    onReachBottom: onReachBottom,
    onScroll: onScroll,
  })

  useEffect(() => {
    setButtonBottomPosition(BUTTON_BOTTOM_POSITION)
  }, [location.pathname])

  const handleClickButton = useCallback(() => {
    setIsOpenCreatePostModal(true)
  }, [])

  const isInIframe = useIsInIframe()

  if (!profile?.selectedUser?.Id || isInIframe) {
    return null
  }

  return (
    <>
      <ButtonNew
        btnStyleType={'style_6'}
        btnStyle={{
          borderRadius: '50%',
          bottom: buttonBottomPosition,
        }}
        className={styles.createPublicationButton}
        btnText={<IconNewPost className={styles.iconNewPost} />}
        onClick={handleClickButton}
      />

      {isOpenCreatePostModal ? (
        <CreatePostModalNew
          getPublication={getPublication}
          getDrafts={getDrafts}
          setDefaultPostsSettings={setDefaultPostsSettings}
          userImage={profile.selectedUser.ImageLink}
          handleOpenCreatePostModal={handleOpenCreatePostModal}
          draftToEdit={draftToEdit}
          redirectToNewPublication={true}
        />
      ) : null}
    </>
  )
}

CreatePublicationButton.displayName = 'CreatePublicationButton'
