import React, { CSSProperties, FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'

import { getPostLengthFromEditorState, ViewTypeEditor } from 'utils'

import ButtonIcon from 'components/ButtonIcon'
import { CustomEditor } from 'components/CustomEditor'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {
  editorStateTitle: EditorState
  editorStateDescription: EditorState
  onChange?: (
    id: string,
    text: EditorState,
    transactionBlockKey: string,
    type: 'title' | 'description',
    fileID: string
  ) => void
  type: 'audio' | 'video'
  postId: string
  transactionBlockKey?: string
  styleBlock?: CSSProperties
  fileID?: string
}

//Resize
//Not resize ButtonIcon
export const VideoAudioTitleDescription: FC<IProps> = observer(
  ({
    editorStateTitle,
    editorStateDescription,
    onChange,
    type,
    postId,
    transactionBlockKey,
    styleBlock,
    fileID,
  }) => {
    const { mediaQuery } = useStore()

    const titleType = type === 'video' ? 'Video' : 'Audio'

    const handlerChangeTitle = (
      id: string,
      editorData: EditorState,
      transactionBlockKey: string
    ) => {
      onChange?.(id, editorData, transactionBlockKey, 'title', fileID as string)
    }

    const handlerChangeDescription = (
      id: string,
      editorData: EditorState,
      transactionBlockKey: string
    ) => {
      onChange?.(
        id,
        editorData,
        transactionBlockKey,
        'description',
        fileID as string
      )
    }

    const titleCharactersQuantity =
      getPostLengthFromEditorState(editorStateTitle)
    const descriptionCharactersQuantity = getPostLengthFromEditorState(
      editorStateDescription
    )

    return (
      <div className={styles.container}>
        <div className={styles.block} style={styleBlock}>
          <div className={styles.title}>
            <div>{titleType} title</div>
            <span>*</span>
            <div
              className={cn({
                [styles.charactersQuantity]: true,
                [styles.charactersQuantityError]: titleCharactersQuantity > 200,
              })}
            >
              {titleCharactersQuantity}/200
            </div>
          </div>
          <div className={styles.editorWrapper}>
            <CustomEditor
              viewType={ViewTypeEditor.editorWithoutAvatar}
              editorState={editorStateTitle}
              postId={postId}
              transactionBlockKey={transactionBlockKey}
              setPostEditorState={handlerChangeTitle}
              editorAutoHeight
              styleContainer={{ marginBottom: 0, marginTop: '2px' }}
            />
          </div>
        </div>

        <div className={styles.block}>
          <div className={styles.title}>
            <div>{titleType} description</div>
            <span>*</span>
            <ButtonIcon
              style={{
                minWidth: mediaQuery.minWidth_2200 ? '18px' : '18px',
                width: mediaQuery.minWidth_2200 ? '18px' : '18px',
                height: mediaQuery.minWidth_2200 ? '18px' : '18px',
                marginLeft: '5px',
              }}
              styleIcon={{
                width: mediaQuery.minWidth_2200 ? '10px' : '8px',
                height: mediaQuery.minWidth_2200 ? '10px' : '8px',
              }}
              icon={'info'}
              tooltip="The description must contain at least 1 hashtag"
              tooltipPosition="top-start"
              bordered
            />
            <div
              className={cn({
                [styles.charactersQuantity]: true,
                [styles.charactersQuantityError]:
                  descriptionCharactersQuantity > 500,
              })}
            >
              {descriptionCharactersQuantity}/500
            </div>
          </div>
          <div className={styles.editorWrapper}>
            <CustomEditor
              viewType={ViewTypeEditor.editorWithoutAvatar}
              editorState={editorStateDescription}
              postId={postId}
              transactionBlockKey={transactionBlockKey}
              setPostEditorState={handlerChangeDescription}
              styleContainer={{ marginBottom: 0, marginTop: '2px' }}
            />
          </div>
        </div>
      </div>
    )
  }
)
