import React, { useRef } from 'react'
import Draft, { convertToRaw, EditorState } from 'draft-js'
import cn from 'classnames'
import { toast } from 'App'
import { VideoPlugin } from '@draft-js-plugins/video/lib/createVideoPlugin'
import { ImageEditorPlugin } from '@draft-js-plugins/image'
import { observer } from 'mobx-react'
import { useStore } from 'store'
import { fileSize } from 'utils'

import { ReactComponent as VideoSvg } from 'sources/images/createPostModal/getVideo.svg'

import styles from './styles.module.scss'

interface ImageButtonProps {
  setEditorState: (editorState: EditorState) => void
  getEditorState: () => EditorState
  close: () => void
  videoPlugin: VideoPlugin
}

export const VideoButton: React.FC<ImageButtonProps> = observer(
  ({ setEditorState, getEditorState, close, videoPlugin }) => {
    const { profile } = useStore()
    const isActivated = profile.selectedUser.Activated
    const inputRef = useRef<HTMLInputElement | null>(null)

    const onClick = (e: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
      e.stopPropagation()
      if (inputRef.current) {
        inputRef.current.value = ''
        inputRef.current.click()
      }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]

      if (
        file &&
        (file.type === 'video/mp4' ||
          file.type === 'video/webm' ||
          file.type === 'video/quicktime')
      ) {
        if (file.size > (isActivated ? fileSize['400MB'] : fileSize['200MB'])) {
          return toast({
            type: 'warning',
            message: isActivated
              ? `Maximum video size limit is 400 MB`
              : `Maximum video size limit for a not-activated user is 200 MB`,
          })
        }

        const objectURL = URL.createObjectURL(file)
        setEditorState(
          videoPlugin.addVideo(getEditorState(), { src: objectURL })
        )
      } else {
        toast({
          type: 'warning',
          message: 'You can only use MP4, WebM, MOV formats for videos',
        })
        return false
      }
      close()
    }

    return (
      <button
        className={cn({
          [styles.sideButton]: true,
        })}
        type="button"
        onClick={onClick}
        // title="Add an Video"
      >
        <VideoSvg />

        <input
          type="file"
          accept="video/mp4,video/webm,video/quicktime"
          ref={inputRef}
          onChange={onChange}
          style={{ display: 'none' }}
        />
      </button>
    )
  }
)

// const addVideo = () => {
//   const virtualElement = document.createElement('input')
//   virtualElement.setAttribute('type', 'file')
//   virtualElement.setAttribute('accept', "video/mp4,video/webm")
//   virtualElement.click()
//
//   virtualElement.addEventListener('change', (evt: any) => {
//     if (evt.target) {
//       const file = evt.target.files[0]
//
//       if (file.type === 'video/mp4' || file.type === 'video/webm') {
//         const objectURL = URL.createObjectURL(file)
//         onChange(videoPlugin.addVideo(editorState, {src: objectURL}))
//       } else {
//         toast({
//           type: 'warning',
//           message: 'You can only use MP4, WebM formats for videos'
//         })
//         return false
//       }
//       console.log('state', toJS(convertToRaw(editorState.getCurrentContent())))
//       evt.target.value = ''
//     }
//   })
// }
