import React, { FC, useCallback, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'

import canvasSize from 'canvas-size'
import { v4 } from 'uuid'

import Slider from '@material-ui/core/Slider'

import { createPostFileType } from 'utils'

import { toast } from 'App'
import Button from 'components/Button'
import Modal from 'components/Modal'

import { IIpfsFiles } from 'models'

import { useStore } from 'store'

import { ReactComponent as Close } from 'sources/images/close.svg'

import styles from './styles.module.scss'

const createImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', error => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })
}

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180
}

const getCroppedImg = async (
  imageSrc: string,
  pixelCrop: Area,
  rotation = 0
): Promise<Blob> => {
  const image: HTMLImageElement = await createImage(imageSrc)
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  const maxSize = Math.max(image.width, image.height)
  let safeArea = 2 * ((maxSize / 2) * Math.sqrt(2))

  const canvasLimitation = await canvasSize.maxArea({
    usePromise: true,
    useWorker: true,
  })

  if (safeArea > canvasLimitation.height) {
    safeArea *= canvasLimitation.height / safeArea
  }

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea
  canvas.height = safeArea

  if (ctx) {
    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2)
    ctx.rotate(getRadianAngle(rotation))
    ctx.translate(-safeArea / 2, -safeArea / 2)

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    )
    const data = ctx.getImageData(0, 0, safeArea, safeArea)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
      Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
    )
  }

  return new Promise(resolve => {
    canvas.toBlob(file => {
      if (!file) {
        console.error('Canvas is empty')
        return
      }
      resolve(file)
    }, 'image/jpeg')
  })
}

interface IProps {
  isOpenCropImage: boolean
  imageAvatar: string | undefined
  imageFile: File | undefined
  setIsOpenCropImage: (value: boolean) => void
  onEditImage: (postId: string, key: string, newFile: IIpfsFiles) => void
  postId: string
}

//Resize
export const CropImageModal: FC<IProps> = observer(
  ({
    imageAvatar,
    imageFile,
    isOpenCropImage,
    setIsOpenCropImage,
    onEditImage,
    postId,
  }) => {
    const { mediaQuery } = useStore()

    const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
    const [zoom, setZoom] = useState(1)
    const [width, setWidth] = useState(1)
    const [height, setHeight] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()

    useEffect(() => {
      setCrop({ x: 0, y: 0 })
      setZoom(1)
    }, [imageAvatar])

    const onCropComplete = useCallback(
      (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
      },
      []
    )

    const clearCropSettings = () => {
      setZoom(1)
      setWidth(1)
      setHeight(1)
    }

    const saveImage = async () => {
      if (imageAvatar && croppedAreaPixels) {
        setIsOpenCropImage(false)
        // loading.setLoading(true)
        const blobFile = await getCroppedImg(imageAvatar, croppedAreaPixels)
        const newImage = new File(
          [blobFile],
          imageFile?.name ? imageFile?.name : 'image',
          { type: blobFile.type }
        )
        onEditImage(postId, imageFile as unknown as string, {
          Id: v4(),
          FileLink: newImage as unknown as string,
          FileType: createPostFileType.image,
          FileExtension: '',
        })
        clearCropSettings()
        // const bodySolution = new FormData()
        // bodySolution.append('file', newImage)
        // onEditImage && onEditImage(bodySolution, nftLink)
      } else {
        toast({
          type: 'error',
          message: 'Something went wrong. Please try again.',
        })
      }
    }

    const closeModal = () => {
      setIsOpenCropImage(false)
      clearCropSettings()
    }

    return (
      <Modal
        open={isOpenCropImage}
        children={
          <div className={styles.cropBlock}>
            <Close className={styles.closeModal} onClick={closeModal} />
            <div className={styles.cropField}>
              <Cropper
                image={imageAvatar}
                crop={crop}
                zoom={zoom}
                aspect={height / width}
                cropShape="rect"
                // showGrid={false}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                classes={{ containerClassName: styles.cropper }}
              />
            </div>
            <div className={styles.controls}>
              <div className={styles.sliderBox}>
                <div className={styles.sliderTitle}>Width</div>
                <Slider
                  value={width}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, width) => setWidth(Number(width))}
                  classes={{ root: styles.slider }}
                />
              </div>
              <div className={styles.sliderBox}>
                <div className={styles.sliderTitle}>Height</div>
                <Slider
                  value={height}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, height) => setHeight(Number(height))}
                  classes={{ root: styles.slider }}
                />
              </div>
              <div className={styles.sliderBox}>
                <div className={styles.sliderTitle}>Zoom</div>
                <Slider
                  value={zoom}
                  min={1}
                  max={3}
                  step={0.1}
                  aria-labelledby="Zoom"
                  onChange={(e, zoom) => setZoom(Number(zoom))}
                  classes={{ root: styles.slider }}
                />
              </div>
              <div className={styles.buttons}>
                <Button
                  style={{
                    background: 'linear-gradient(#2E2B63, #2E2B63)',
                    boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.0563538)',
                    borderRadius: 7,
                    width: mediaQuery.minWidth_2200 ? '115px' : '90px',
                    height: mediaQuery.minWidth_2200 ? '50px' : '40px',
                  }}
                  styleText={{ color: '#ffffff' }}
                  btnStyle="classic"
                  text="Cancel"
                  onClick={closeModal}
                />
                <Button
                  style={{
                    background: 'linear-gradient(#2E2B63, #2E2B63)',
                    boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.0563538)',
                    borderRadius: 7,
                    width: mediaQuery.minWidth_2200 ? '115px' : '90px',
                    height: mediaQuery.minWidth_2200 ? '50px' : '40px',
                  }}
                  styleText={{ color: '#ffffff' }}
                  btnStyle="classic"
                  text="Save"
                  onClick={saveImage}
                />
              </div>
            </div>
          </div>
        }
      />
    )
  }
)
