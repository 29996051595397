import React, { useEffect, useRef, useState } from 'react'

import cn from 'classnames'
import { ContentState, EditorState } from 'draft-js'

import { createPostFileType, formatTwitterTimestamp } from 'utils'

import { toast } from '../../../App'

import { ISocialPostInfo, IXPostResponse, TweetDetails } from 'models'

import { useStore } from 'store'

import { ReactComponent as LoaderInner } from 'sources/images/loader/loaderInner.svg'
import { ReactComponent as LoaderOuter } from 'sources/images/loader/loaderOuter.svg'
import { ReactComponent as TwitterIcon } from 'sources/images/twitter-x-plain.svg'

import { CustomEditor, ViewTypeEditor } from '../../CustomEditor'

import styles from './styles.module.scss'

interface IProps {
  url?: string
  onReceiveSocialPostInfo?: (info: ISocialPostInfo) => void
  socialPostInfo?: ISocialPostInfo | null
  className?: string
  darkStyle?: boolean
  isCreateMode?: boolean
}

const TwitterPostPreview: React.FC<IProps> = ({
  url = '',
  onReceiveSocialPostInfo,
  socialPostInfo,
  className,
  darkStyle,
  isCreateMode,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const { twitter } = useStore()

  const isXLink = Boolean(
    url?.match(/http(?:s)?:\/\/(?:www\.)?(?:twitter|x)\.com\/([a-zA-Z0-9_]+)/)
  )

  useEffect(() => {
    const getMetaData = async (url: string) => {
      setIsLoading(true)

      const match = url.match(/status\/(\d+)/)

      const postId = match?.[1]

      if (postId) {
        const postInfo: IXPostResponse = await twitter.getXPostInfo(postId)

        const postInfoModified: Partial<ISocialPostInfo> = {
          ...postInfo,
          XPostText: prepareText(postInfo),
          XPostMediaAttachments:
            postInfo?.XMediaInfos?.map(e => ({
              FileLink: e?.Url,
              CdnFileLink: e?.Url,
              FileType: getMediaType(e?.Type),
            })) || [],
        }

        onReceiveSocialPostInfo?.({
          ...postInfoModified,
          XPostLink: `https://x.com/${postInfo?.XAuthorUsername}/status/${postId}`,
        } as any)
      } else {
        toast({
          type: 'warning',
          message: 'Enter correct X Post link',
        })
      }

      setIsLoading(false)
    }

    if (url?.length > 0) {
      if (!isXLink) {
        toast({
          type: 'warning',
          message: 'Wrong X (Twitter) link',
        })
      } else {
        getMetaData(url)
      }
    }
  }, [url, isXLink])

  const prepareText = (socialPostInfo: IXPostResponse) => {
    if (!socialPostInfo?.UrlInfos || !socialPostInfo?.XPostText) return ''

    let text = socialPostInfo?.XPostText

    socialPostInfo?.UrlInfos.forEach((urlObj: any) => {
      if (text.includes(urlObj.Url)) {
        if (urlObj?.DisplayUrl?.startsWith('pic.x.com')) {
          text = text.replace(urlObj.Url, '')
        } else if (urlObj.DisplayUrl.endsWith('…') && urlObj.ExpandedUrl) {
          text = text.replace(urlObj.Url, urlObj.ExpandedUrl)
        } else {
          text = text.replace(urlObj.Url, urlObj.DisplayUrl)
        }
      }
    })

    return text
  }

  const getMediaType = (fileType: string) => {
    switch (fileType) {
      case 'photo':
        return createPostFileType.image
      case 'video':
        return createPostFileType.video
      case 'animated_gif':
        return createPostFileType.animated_gif
      default:
        return ''
    }
  }

  const postText = socialPostInfo?.XPostText
  const datetime = formatTwitterTimestamp(socialPostInfo?.XPostCreatedAt)
  const mediaFiles = socialPostInfo?.XPostMediaAttachments || []

  const actionButtons = (
    <div className={styles.actions}>
      <div className={styles.actionsItem}>
        <svg
          width="17.16"
          height="17.16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.75098 10C1.75098 5.58 5.33498 2 9.75598 2H14.122C18.612 2 22.251 5.64 22.251 10.13C22.251 13.09 20.644 15.81 18.055 17.24L10.001 21.7V18.01H9.93398C5.44398 18.11 1.75098 14.5 1.75098 10ZM9.75598 4C6.43898 4 3.75098 6.69 3.75098 10C3.75098 13.37 6.52098 16.08 9.88898 16.01L10.24 16H12.001V18.3L17.088 15.49C19.039 14.41 20.251 12.36 20.251 10.13C20.251 6.74 17.507 4 14.122 4H9.75598Z"
            fill="currentColor"
          ></path>
        </svg>

        {/* {(socialPostInfo?.XActionsStats?.Replies || 0) > 0 && (
          <p className={styles.actionsItemText}>
            {socialPostInfo?.XActionsStats?.Replies}
          </p>
        )}*/}
      </div>

      <div className={styles.actionsItem}>
        <svg
          width="17.16"
          height="17.16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.49987 3.87988L8.93187 8.01988L7.56787 9.47988L5.49987 7.54988V15.9999C5.49987 17.0999 6.39587 17.9999 7.49987 17.9999H12.9999V19.9999H7.49987C5.29087 19.9999 3.49987 18.2099 3.49987 15.9999V7.54988L1.43187 9.47988L0.0678711 8.01988L4.49987 3.87988ZM16.4999 5.99988H10.9999V3.99988H16.4999C18.7089 3.99988 20.4999 5.78988 20.4999 7.99988V16.4499L22.5679 14.5199L23.9319 15.9799L19.4999 20.1199L15.0679 15.9799L16.4319 14.5199L18.4999 16.4499V7.99988C18.4999 6.89988 17.6039 5.99988 16.4999 5.99988Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>

      <div className={styles.actionsItem}>
        <svg
          width="17.16"
          height="17.16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.6968 5.50015C15.4748 5.44015 14.0178 6.01015 12.8068 7.66015L12.0018 8.75015L11.1958 7.66015C9.98382 6.01015 8.52582 5.44015 7.30382 5.50015C6.06082 5.57015 4.95482 6.28015 4.39382 7.41015C3.84182 8.53015 3.76082 10.1901 4.87282 12.2301C5.94682 14.2001 8.12982 16.5001 12.0018 18.8401C15.8718 16.5001 18.0538 14.2001 19.1278 12.2301C20.2388 10.1901 20.1578 8.53015 19.6048 7.41015C19.0438 6.28015 17.9388 5.57015 16.6968 5.50015ZM20.8838 13.1901C19.5328 15.6701 16.8828 18.3101 12.5048 20.8601L12.0018 21.1601L11.4978 20.8601C7.11882 18.3101 4.46882 15.6701 3.11582 13.1901C1.75582 10.6901 1.70582 8.33015 2.60182 6.52015C3.48882 4.73015 5.24882 3.61015 7.20282 3.51015C8.85382 3.42015 10.5708 4.07015 12.0008 5.52015C13.4298 4.07015 15.1468 3.42015 16.7968 3.51015C18.7508 3.61015 20.5108 4.73015 21.3978 6.52015C22.2938 8.33015 22.2438 10.6901 20.8838 13.1901Z"
            fill="currentColor"
          ></path>
        </svg>

        {/*{(socialPostInfo?.XActionsStats?.Likes || 0) > 0 && (
          <p className={styles.actionsItemText}>
            {socialPostInfo?.XActionsStats?.Likes}
          </p>
        )}*/}
      </div>

      <div className={styles.actionsItem}>
        <svg
          width="17.16"
          height="17.16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4 4.5C4 3.12 5.119 2 6.5 2H17.5C18.881 2 20 3.12 20 4.5V22.94L12 17.23L4 22.94V4.5ZM6.5 4C6.224 4 6 4.22 6 4.5V19.06L12 14.77L18 19.06V4.5C18 4.22 17.776 4 17.5 4H6.5Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>

      <div className={styles.actionsItem}>
        <svg
          width="17.16"
          height="17.16"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 2.58984L17.7 8.28984L16.29 9.70984L13 6.40984V15.9998H11V6.40984L7.7 9.70984L6.29 8.28984L12 2.58984ZM21 14.9998L20.98 18.5098C20.98 19.8898 19.86 20.9998 18.48 20.9998H5.5C4.11 20.9998 3 19.8798 3 18.4998V14.9998H5V18.4998C5 18.7798 5.22 18.9998 5.5 18.9998H18.48C18.76 18.9998 18.98 18.7798 18.98 18.4998L19 14.9998H21Z"
            fill="currentColor"
          ></path>
        </svg>
      </div>
    </div>
  )

  const postFrame = !isLoading ? (
    <a
      href={url || socialPostInfo?.XPostLink}
      target={'_blank'}
      className={cn(styles.card, {
        [styles.card_darkStyle]: darkStyle,
      })}
    >
      <div className={styles.header}>
        <div className={styles.profile}>
          <img
            src={socialPostInfo?.XProfileImageUrl}
            alt="Avatar"
            className={styles.avatar}
          />
          <div>
            <div className={styles.name}>
              {socialPostInfo?.XName}

              {socialPostInfo?.IsXVerified && (
                <svg
                  viewBox="0 0 22 22"
                  aria-label="Verified account"
                  role="img"
                  className="r-1cvl2hr r-4qtqp9 r-yyyyoo r-1xvli5t r-9cviqr r-f9ja8p r-og9te1 r-bnwqim r-1plcrui r-lrvibr"
                  data-testid="icon-verified"
                >
                  <g>
                    <path d="M20.396 11c-.018-.646-.215-1.275-.57-1.816-.354-.54-.852-.972-1.438-1.246.223-.607.27-1.264.14-1.897-.131-.634-.437-1.218-.882-1.687-.47-.445-1.053-.75-1.687-.882-.633-.13-1.29-.083-1.897.14-.273-.587-.704-1.086-1.245-1.44S11.647 1.62 11 1.604c-.646.017-1.273.213-1.813.568s-.969.854-1.24 1.44c-.608-.223-1.267-.272-1.902-.14-.635.13-1.22.436-1.69.882-.445.47-.749 1.055-.878 1.688-.13.633-.08 1.29.144 1.896-.587.274-1.087.705-1.443 1.245-.356.54-.555 1.17-.574 1.817.02.647.218 1.276.574 1.817.356.54.856.972 1.443 1.245-.224.606-.274 1.263-.144 1.896.13.634.433 1.218.877 1.688.47.443 1.054.747 1.687.878.633.132 1.29.084 1.897-.136.274.586.705 1.084 1.246 1.439.54.354 1.17.551 1.816.569.647-.016 1.276-.213 1.817-.567s.972-.854 1.245-1.44c.604.239 1.266.296 1.903.164.636-.132 1.22-.447 1.68-.907.46-.46.776-1.044.908-1.681s.075-1.299-.165-1.903c.586-.274 1.084-.705 1.439-1.246.354-.54.551-1.17.569-1.816zM9.662 14.85l-3.429-3.428 1.293-1.302 2.072 2.072 4.4-4.794 1.347 1.246z"></path>
                  </g>
                </svg>
              )}
            </div>
            <div className={styles.handle}>
              @{socialPostInfo?.XAuthorUsername} ·{' '}
              <span className={styles.follow}>Follow</span>
            </div>
          </div>
        </div>
        <div className={styles.logo}>
          <TwitterIcon />
        </div>
      </div>

      {/* {replyingTo?.length > 0 && (
        <div className={styles.replying}>
          Replying to {replyingTo?.map(reply => `@${reply}`).join(' ')}
        </div>
      )}*/}

      <div className={styles.postText}>
        {postText && (
          <CustomEditor
            viewType={ViewTypeEditor.text}
            readOnly={true}
            editorState={EditorState.createWithContent(
              ContentState.createFromText(postText)
            )}
          />
        )}
      </div>

      {mediaFiles?.length > 0 && (
        <div className={styles.mediaGrid}>
          {mediaFiles.map((file, index) => {
            if (
              [
                createPostFileType.image,
                createPostFileType.animated_gif,
              ].includes(file?.FileType as createPostFileType)
            ) {
              return (
                <img
                  key={file?.FileLink + index}
                  src={file?.CdnFileLink || file?.FileLink}
                  alt={'img' + index}
                  className={cn(styles.media, {
                    [styles.media_wide]:
                      mediaFiles.length % 2 !== 0 &&
                      index === mediaFiles.length - 1,
                  })}
                />
              )
            } else if (file?.FileType === createPostFileType.video) {
              return (
                <div
                  key={file?.FileLink + index}
                  className={cn(styles.media, styles.videoWrapper, {
                    [styles.media_wide]:
                      mediaFiles.length % 2 !== 0 &&
                      index === mediaFiles.length - 1,
                  })}
                >
                  {/*<video
                    src={file?.CdnFileLink || file?.FileLink}
                    className={styles.video}
                    controls={false}
                  />*/}

                  <img
                    key={file?.FileLink + index}
                    src={file?.CdnFileLink || file?.FileLink}
                    alt={'img' + index}
                    className={styles.video}
                  />

                  <div className={styles.videoButton}>
                    <svg
                      viewBox="0 0 24 24"
                      aria-hidden="true"
                      className="r-jwli3a r-4qtqp9 r-yyyyoo r-1sa8knb r-dnmrzs r-1dsia8u r-bnwqim r-1plcrui r-lrvibr r-gcko2u"
                    >
                      <g>
                        <path d="M21 12L4 2v20l17-10z"></path>
                      </g>
                    </svg>
                  </div>
                </div>
              )
            }
          })}
        </div>
      )}

      {/* {embedLink && (
        <div className={styles.embedLink}>
          <AtomicEmbedComponent data={{ url: embedLink }} />
        </div>
      )}*/}

      <div className={styles.timestamp}>{datetime}</div>

      {/*{actionButtons}*/}

      <button className={styles.readMore}>Read more on X</button>
    </a>
  ) : (
    <div className={styles.loaderBox}>
      <LoaderOuter className={styles.loaderOuter} />
      <LoaderInner className={styles.loaderInner} />
    </div>
  )

  return (
    <div className={cn(styles.socialPostPreview, className)}>
      {(!isCreateMode || (isCreateMode && isXLink)) && postFrame}
    </div>
  )
}

TwitterPostPreview.displayName = 'TwitterPostPreview'

export default TwitterPostPreview
