import React, { FC, ReactElement } from 'react'
import emptyPhoto from 'sources/images/emptyPhoto.png'
import cn from 'classnames'
import LogoIcon from 'sources/images/companyIconGradient.svg'
import { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention'

export interface EntryComponentProps {
  className?: string;

  onMouseDown(event: MouseEvent): void;

  onMouseUp(event: MouseEvent): void;

  onMouseEnter(event: MouseEvent): void;

  role: string;
  id: string;
  'aria-selected'?: boolean | 'false' | 'true';
  theme?: MentionPluginTheme;
  mention: MentionData;
  isFocused: boolean;
  searchValue?: string;
}

export const MentionEntryComponent = (props: EntryComponentProps): ReactElement => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...parentProps
  } = props

  return (
    //@ts-ignore
    <div {...parentProps}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          <img
            alt="avatar"
            src={mention?.ImageLink ? mention?.ImageLink : emptyPhoto}
            className={cn({
              [`${theme?.mentionSuggestionsEntryAvatar}`]: true,
              [`${theme?.mentionSuggestionsEntryAvatarEmpty}`]: !mention?.ImageLink
            })}
            role="presentation"
          />
        </div>

        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryName}>
            {`${
              mention?.FirstName ? mention?.FirstName : ''
            } ${mention?.LastName ? mention?.LastName : ''}`}
          </div>

          <div className={theme?.mentionSuggestionsEntryNickName}>
            @{mention?.NickName ? mention?.NickName : ''}
          </div>
        </div>
        <div className={theme?.mentionSuggestionsEntryContainerBacked}>
          <div>
            <img src={LogoIcon} alt=""/>{mention?.BackedCount || 0}
          </div>
        </div>
      </div>
    </div>
  )
}
