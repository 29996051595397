import React, { FC } from 'react'

import classNames from 'classnames'

import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { SxProps } from '@mui/system'

import { AnimateHeight } from '../AnimateHeight/AnimateHeight'
import ButtonIcon from '../ButtonIcon'

import styles from './styles.module.scss'

interface IProps {
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  formSX?: SxProps
  disabled?: boolean
  hasFiles: boolean
}

export const PostOnTwitterCheckbox: FC<IProps> = ({
  checked,
  onChange,
  formSX,
  disabled,
  hasFiles,
}) => {
  return (
    <div className={styles.postOnTwitterCheckboxContainer}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            sx={{
              color: disabled ? '#999999' : 'var(--textColor)',
            }}
            className={classNames({
              [styles['postOnTwitterCheckbox-checkbox']]: true,
              [styles['postOnTwitterCheckbox-checkbox_disabled']]: disabled,
            })}
          />
        }
        label={
          <>
            <span>Post on X (Twitter)</span>
            <ButtonIcon
              style={{
                minWidth: '13px',
                width: '13px',
                height: '13px',
                margin: '0 0 1px 3px',
              }}
              styleIcon={{
                width: '7px',
                height: '7px',
              }}
              icon={'info'}
              tooltip={
                !disabled
                  ? "By selecting this checkbox, you agree to have your post immediately published on X (Twitter) for public viewing. Ensure your content complies with Twitter's guidelines before proceeding."
                  : 'Please connect X (Twitter) to your account to post on X (Twitter)'
              }
              tooltipPosition="top"
              tooltipDisablePortal={false}
              bordered
            />
          </>
        }
        className={styles.postOnTwitterCheckbox}
        sx={formSX}
      />

      <AnimateHeight isOpen={checked && hasFiles} className={styles.warning}>
        <p className={styles['warning-title']}>
          Please ensure your attachments meet X's (Twitter's) size requirements:
        </p>

        <ul>
          <li>Images (PNG, JPEG, WEBP): up to 5MB</li>
          <li>GIFs: up to 10MB</li>
          <li>Videos (MP4): up to 15MB</li>
        </ul>
      </AnimateHeight>
    </div>
  )
}
