const createClient = require('ipfs-http-client')
export const IPFS_HOST = 'ipfs.infura.io'
export const IPFS_URL = `https://${IPFS_HOST}/ipfs/`
const auth = "Basic " + btoa('2DO3oeg34S8gqQzj3SxYGyGgDxQ' + ":" + '5cf3602cb54861470846e7857d991281')
// const auth = 'Basic ' + Buffer.from('2DO3oeg34S8gqQzj3SxYGyGgDxQ' + ':' + '5cf3602cb54861470846e7857d991281').toString('base64');
export const ipfs = createClient({
  host: IPFS_HOST,
  port: 5001,
  protocol: 'https',
  headers: {
    authorization: auth
  }
})
