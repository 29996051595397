import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import cn from 'classnames'
import { observer } from 'mobx-react'
import { toast } from 'App'

import { createPostFileType, fileSize } from 'utils'
import { useStore } from 'store'
// @ts-ignore
import captureVideoFrame from 'capture-video-frame'
import { AdultBlur } from '../AdultBlur'

import { ReactComponent as Close } from 'sources/images/close.svg'
import { ReactComponent as PosterSvg } from 'sources/images/createPostModal/getImage.svg'
import playBtn from 'sources/images/playBtn.svg'

import styles from './styles.module.scss'

interface IProps {
  url?: string
  videoBlob?: any
  transparent?: boolean
  playProps?: boolean
  smallButton?: boolean
  activeVideo?: boolean
  sendActiveVideo?: (id: string) => void
  publicationId?: number
  fileType?: string
  countViews?: boolean
  uniqueVideoId?: string
  poster?: string
  onAddVideoPoster?: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  onAddVideoDuration?: (id: string, duration: number) => void
  postId?: string
  fileId?: string
  createPoster?: boolean
  disableDefaultCreatePoster?: boolean
  isAdultContent?: boolean
}

//Not resize
const VideoPlayer: React.FC<IProps> = observer(
  ({
    playProps,
    activeVideo,
    sendActiveVideo,
    smallButton,
    isAdultContent,
    url,
    publicationId,
    fileType,
    videoBlob,
    countViews,
    uniqueVideoId,
    poster,
    onAddVideoPoster,
    onAddVideoDuration,
    postId,
    fileId,
    createPoster,
    disableDefaultCreatePoster,
  }) => {
    const [play, setPlay] = useState<boolean>(false)
    const [isClickPlay, setIsClickPlay] = useState(false)
    const { tweets, publication, profile } = useStore()
    const [urlVideo, setUrlVideo] = useState<string>()
    const [addViewTime, setAddViewTime] = useState<number>(2)
    const [posterUrl, setPosterUrl] = useState<string>('')

    const PosterPlayerRef = useRef<ReactPlayer | null>(null)
    const totalPlayingTimeRef = useRef<number>(0)
    const isAddViewRef = useRef<boolean>(false)
    let addViewTimer!: NodeJS.Timeout

    // const [minHeightPlayer, setMinHeightPlayer] = useState<number>(0)
    // const playerContainerRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
      if (poster) {
        setPlay(false)
        setIsClickPlay(false)
      }

      setPosterUrl(
        poster
          ? `url(${
              createPoster && (poster as any) instanceof File
                ? URL.createObjectURL(poster as unknown as File)
                : poster
            })`
          : ''
      )
    }, [poster])

    useEffect(() => {
      if (playProps && !play) {
        setPlay(true)
      }
    }, [playProps, play])

    useEffect(() => {
      if (!url && videoBlob) {
        setUrlVideo(URL.createObjectURL(videoBlob))
      } else if (!url && !videoBlob) {
        getTweetUrlVideo()
      }
    }, [])

    useEffect(() => {
      totalPlayingTimeRef.current = 0
      isAddViewRef.current = false
    }, [urlVideo])

    const getTweetUrlVideo = async () => {
      const response = await tweets.getTweetVideo(String(publicationId))
      if (fileType === createPostFileType.animated_gif) {
        setUrlVideo(
          response.extended_entities.media[0].video_info.variants[0].url
        )
      } else {
        setUrlVideo(
          response.extended_entities.media[0].video_info.variants[2].url
        )
      }
    }

    const playHandler = () => {
      if (!isClickPlay) {
        setIsClickPlay(true)
      }

      setPlay(true)

      const activeVideoId = uniqueVideoId
        ? uniqueVideoId
        : String(publicationId)
      sendActiveVideo && sendActiveVideo(activeVideoId)
    }

    const startAccountPlayingTime = () => {
      countViews && startPlaying()
    }

    const startPlaying = () => {
      if (!profile.isAuthorizedProfile()) return
      if (totalPlayingTimeRef.current >= addViewTime) return
      addViewTimer = setInterval(() => {
        totalPlayingTimeRef.current += 1
        if (totalPlayingTimeRef.current >= addViewTime) {
          addView()
          if (addViewTimer) clearInterval(addViewTimer)
        }
      }, 1000)
    }

    const pausePlaying = () => {
      if (addViewTimer) clearInterval(addViewTimer)
    }

    const addView = async () => {
      if (!isAddViewRef.current) {
        try {
          isAddViewRef.current = true
          await publication.addViewPublication(Number(publicationId))
        } catch (error) {
          console.log(error)
        }
      }
    }

    const handlerOnDuration = (duration: number) => {
      if (onAddVideoDuration && postId) {
        onAddVideoDuration(postId, duration)
        console.log('duration', duration)
      }

      if (countViews) {
        const timeHalf = Math.floor((duration / 100) * 50)
        // setAddViewTime(timeHalf > 10 ? 10 : timeHalf)
        setAddViewTime(timeHalf > 2 ? 2 : timeHalf)
      }
    }

    //↓↓↓↓↓↓
    //Poster
    const posterInputRef = useRef<HTMLInputElement | null>(null)

    const handleFileChange = async (e: any) => {
      // const containerPlayer = playerContainerRef.current
      // if (createPoster && containerPlayer) {
      //   const height = containerPlayer.getBoundingClientRect().height
      //   setMinHeightPlayer(height)
      // }

      setPlay(false)

      const poster = e.target.files && e.target.files[0]
      if (!poster) {
        return
      }

      e.target.value = null

      if (poster.type !== 'image/png' && poster.type !== 'image/jpeg') {
        return toast({
          type: 'warning',
          message: 'You can only use JPG, JPEG, PNG formats for images',
        })
      }

      if (poster.size > fileSize['50MB']) {
        return toast({
          type: 'warning',
          message: 'Maximum image size limit is 50MB',
        })
      }

      if (onAddVideoPoster && postId && fileId) {
        await onAddVideoPoster(postId, poster, false, fileId)
        return toast({
          type: 'success',
          message: 'The poster was successfully added',
        })
      }
    }

    const onAddPoster = async () => {
      let input = document.getElementById(`poster_${postId}`)
      if (input) {
        input.click()
      }
    }

    const onDeletePoster = async () => {
      if (onAddVideoPoster && postId && fileId) {
        await onAddVideoPoster(postId, '', false, fileId)
        toast({
          type: 'success',
          message: 'The poster was successfully deleted',
        })
      }
    }

    const handlerOnDurationPosterPlayer = (duration: number) => {
      if (disableDefaultCreatePoster) return

      if (createPoster && urlVideo && onAddVideoPoster && postId && fileId) {
        const internalPlayer = PosterPlayerRef.current?.getInternalPlayer()
        if (internalPlayer) {
          internalPlayer.currentTime = duration > 5 ? 5 : duration / 2
          internalPlayer.onseeked = (event: any) => {
            const videoElement = event.target as HTMLVideoElement
            const frame = captureVideoFrame(videoElement)
            const posterFile: any = new File([frame.blob], 'poster')
            onAddVideoPoster(postId, posterFile, true, fileId)
          }
        }
      }
    }
    //↑↑↑↑↑↑↑
    return (
      <div
        className={styles.container}
        // ref={playerContainerRef}
        // style={{
        //   minHeight: minHeightPlayer ? `${minHeightPlayer}px` : undefined,
        //   maxHeight: minHeightPlayer ? `${minHeightPlayer}px` : undefined
        // }}
      >
        <AdultBlur isAdultContent={isAdultContent} />

        <div
          className={cn({
            [styles.poster]: poster,
            [styles.posterHidden]: !poster || isClickPlay,
          })}
          style={{ backgroundImage: posterUrl }}
        />
        <ReactPlayer
          onPlay={() => {
            playHandler()
            startAccountPlayingTime()
          }}
          onPause={pausePlaying}
          onEnded={pausePlaying}
          playing={activeVideo == undefined ? play : activeVideo}
          controls={play}
          url={url ? url : urlVideo}
          className={styles.reactPlayer}
          width="100%"
          height="100%"
          muted={true}
          onDuration={handlerOnDuration}
          config={{
            file: {
              attributes: {
                // crossorigin: 'anonymous',
                crossOrigin: 'anonymous',
                preload: 'metadata',
              },
            },
          }}
        />
        {createPoster ? (
          <ReactPlayer
            ref={PosterPlayerRef}
            controls
            url={url ? url : urlVideo}
            width="100%"
            height="100%"
            style={{ display: 'none' }}
            muted={true}
            onDuration={handlerOnDurationPosterPlayer}
            config={{
              file: {
                attributes: {
                  // crossorigin: 'anonymous',
                  crossOrigin: 'anonymous',
                  preload: 'metadata',
                },
              },
            }}
          />
        ) : null}
        {!play && (
          <div
            className={cn({
              [styles.btn]: true,
              [styles.btnIconSmall]: smallButton,
            })}
            onClick={playHandler}
          >
            <div
              className={cn({
                [styles.btnIcon]: true,
              })}
              style={{
                marginLeft: '4px',
                WebkitMaskImage: `url(${playBtn})`,
                maskImage: `url(${playBtn})`,
                height: '12px',
                width: '12px',
                background: 'rgba(255,255,255,1)',
                WebkitMaskRepeat: 'no-repeat',
                WebkitMaskSize: 'contain',
              }}
            />
          </div>
        )}

        {createPoster ? (
          <>
            <div
              className={styles.posterControls}
              onClick={e => e.stopPropagation()}
            >
              {poster ? (
                <Close onClick={onDeletePoster} className={styles.deleteIcon} />
              ) : (
                <PosterSvg onClick={onAddPoster} />
              )}
            </div>
            <input
              ref={posterInputRef}
              disabled={Boolean(poster)}
              accept=".jpg, .jpeg, .png"
              id={`poster_${postId}`}
              style={{ display: 'none' }}
              type="file"
              onChange={handleFileChange}
            />
          </>
        ) : null}
      </div>
    )
  }
)

export default VideoPlayer
