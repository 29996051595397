import React from 'react'

import { observer } from 'mobx-react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

import { useStore } from 'store'

interface IProps {
  children: React.ReactElement
  wrapStyle?: React.CSSProperties
  wrapStyleOpacity?: boolean
  dontUse?: boolean
}

export const CustomViewZoom: React.FC<IProps> = observer(
  ({ children, wrapStyle, wrapStyleOpacity, dontUse }) => {
    const { theme } = useStore()

    const getWrapStyle = (): React.CSSProperties | undefined => {
      if (wrapStyleOpacity) {
        return { position: 'absolute', width: '100%', opacity: 0 }
      }
    }

    if (dontUse) {
      return (
        <div style={wrapStyle ? wrapStyle : getWrapStyle()}>{children}</div>
      )
    }

    return (
      <Zoom
        zoomMargin={window.innerWidth <= 576 ? 20 : 50}
        wrapStyle={wrapStyle ? wrapStyle : getWrapStyle()}
        overlayBgColorEnd={
          theme.theme === 'light'
            ? 'rgba(255, 255, 255, 0.95)'
            : 'rgba(22, 20, 50, 0.95)'
        }
        overlayBgColorStart={
          theme.theme === 'light'
            ? 'rgba(255, 255, 255, 0)'
            : 'rgba(22, 20, 50, 0)'
        }
      >
        {children}
      </Zoom>
    )
  }
)
