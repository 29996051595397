import React, { CSSProperties, FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { ICurrencyInfoByTag } from 'models'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {
  currencyData: ICurrencyInfoByTag
  style?: CSSProperties
}

export const CurrencyBoxForTag: FC<IProps> = observer(
  ({ currencyData, style }) => {
    const { coins } = useStore()

    const [data, setData] = useState<ICurrencyInfoByTag>({
      id: '',
      name: '',
      symbol: '',
      image: '',
      current_price: 0,
    })

    useEffect(() => {
      getData()
    }, [])

    const getData = async () => {
      try {
        const coinCachedData = coins.coins.find(
          item => item.id === currencyData?.id
        )
        if (coinCachedData) {
          setData(coinCachedData)
        } else {
          setData({ ...currencyData, current_price: 0 })
        }
      } catch (e) {
        console.log('get coin info', e)
      }
    }

    return (
      <div className={styles.container} style={style}>
        <LazyLoadImage
          className={styles.logo}
          alt="logo"
          effect="blur"
          src={data?.image}
        />

        <div className={styles.currencyInfo}>
          <div className={styles.nameBox}>
            <div className={styles.name}>{data?.name}</div>
            <div className={styles.code}>{data?.symbol?.toUpperCase()}</div>
          </div>

          <div className={styles.rate}>
            Exchange rate: ${data?.current_price || '--'}
          </div>
        </div>
      </div>
    )
  }
)

CurrencyBoxForTag.displayName = 'CurrencyBoxForTag'
