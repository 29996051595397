import React, { useState } from 'react'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {
  onClick: () => void
  text: string
}

const WidgetPopoverButton: React.FC<IProps> = ({ onClick, text }) => {
  const { widget } = useStore()

  const [isHovered, setIsHovered] = useState(false)

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  return (
    <button
      onClick={onClick}
      className={styles.menuItem}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        background: isHovered
          ? widget?.theme?.themeButtonBG
            ? widget?.theme?.themeButtonBG
            : 'var(--linkHoverBurgerItem)'
          : '',
      }}
    >
      <div
        className={styles.menuItemInner}
        style={{
          color: widget?.theme?.themeButtonColor,
        }}
      >
        {text}
      </div>
    </button>
  )
}

WidgetPopoverButton.displayName = 'WidgetPopoverButton'

export default WidgetPopoverButton
