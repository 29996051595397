import React, { FC, useEffect, useRef, useState } from 'react'

import { v4 as uuidv4 } from 'uuid'

import '@google/model-viewer'

import { base64ToFile } from '../../utils'
import { Image3dFileExtensions } from '../../utils/const'
import {
  getFileExtension,
  getFileExtensionFromName,
} from '../../utils/functions/common'

import InnerLoading from '../InnerLoading'
import { MediaBlur } from '../MediaBlur'

import styles from './styles.module.scss'

//requires a glTF or GLB
const Models = [
  'https://modelviewer.dev/shared-assets/models/Astronaut.glb',
  'https://modelviewer.dev/shared-assets/models/shishkebab.glb',
  'https://modelviewer.dev/shared-assets/models/RobotExpressive.glb',
  'https://modelviewer.dev/shared-assets/models/glTF-Sample-Models/2.0/DamagedHelmet/glTF/DamagedHelmet.gltf',
  'https://modelviewer.dev/shared-assets/models/reflective-sphere.gltf',
  'https://modelviewer.dev/shared-assets/models/glTF-Sample-Models/2.0/2CylinderEngine/glTF-Draco/2CylinderEngine.gltf',
  'https://modelviewer.dev/shared-assets/models/BarramundiFish.mixed.glb',
]

interface IProps {
  imageUrl?: string
  imageBlob?: string
  disableZoom?: boolean
  ar?: boolean
  withLoading?: boolean
  postId?: string
  fileId?: string
  onAdd3DPreview?: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  fileExtension?: string
}

//Resize
const ViewerModel3D: FC<IProps> = ({
  imageUrl,
  imageBlob,
  disableZoom,
  ar,
  withLoading,
  onAdd3DPreview,
  postId,
  fileId,
  fileExtension,
}) => {
  const [viewerId] = useState(`model-viewer-${uuidv4()}`)
  const [progress, setProgress] = useState(0)
  const [load, setLoad] = useState(false)

  const modelRef = useRef<any>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)

  useEffect(() => {
    const onLoad = async (e: any) => {
      setLoad(true)
      if (onAdd3DPreview && postId && fileId) {
        // Need for correct work
        await modelEl.toBlob()
        // const previewFile: any = new File([previewBlob], 'preview', { type: previewBlob.type })
        const base64 = modelEl.toDataURL()
        const previewFile: any = base64ToFile(base64, 'preview')
        onAdd3DPreview(postId, previewFile, false, fileId)
      }
    }

    const modelEl = modelRef.current
    if (modelEl) {
      modelEl.addEventListener('load', onLoad)
    }

    // document.querySelector(`#${viewerId}`)?.addEventListener('progress', (e: any) => {
    //   console.log('%', (e.detail.totalProgress * 100).toFixed())
    //   setProgress(e.detail.totalProgress)
    // })

    return () => {
      if (modelEl) {
        modelEl.removeEventListener('load', onLoad)
      }
    }
  }, [])

  const extension =
    fileExtension || getFileExtensionFromName(imageBlob as unknown as File)

  if (extension === Image3dFileExtensions.usdz) {
    return <MediaBlur type={'3dImageUsdz'} />
  }

  return (
    <div className={styles.box}>
      {/*@ts-ignore*/}
      <model-viewer
        ref={modelRef}
        disable-zoom={disableZoom}
        id={viewerId}
        src={
          imageUrl
            ? imageUrl
            : URL.createObjectURL(imageBlob as unknown as File)
        }
        alt="3D model"
        ar-modes="webxr scene-viewer quick-look"
        environment-image="neutral"
        camera-controls
        class="model-view"
        // shadow-intensity="1"
        // auto-rotate
        // id="lazy-load"
        touch-action="pan-y"
        autoplay
        ar={ar}
      />
      <InnerLoading loading={!load && Boolean(withLoading)} />
    </div>
  )
}

export default React.memo(ViewerModel3D)
