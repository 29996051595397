import React, { FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js'
import cloneDeep from 'lodash/cloneDeep'
import { v4 as uuidv4 } from 'uuid'

import { fileSize } from '../../../../utils'
import {
  getImageQueryDimensionsFromUrl,
  isAvailableBlockchainProviderToast,
} from 'utils'
import { addFileToDigitalOceanSpaces } from 'utils/digitalOcean'
import { ipfs } from 'utils/ipfs'

import { toast } from 'App'
import { ConfirmModal as SaveDraftConfirmModal } from 'components/ConfirmModal'
import { Block as EditorBlockType } from 'components/CustomEditor/util/constants'
import { ConfirmModalPWA as SaveDraftConfirmModalPWA } from 'componentsPWA/ConfirmModalPWA'

import { IBlogpostPreviewData, IDraft, ISendPublication } from 'models'

import { PostType } from '../../../../store/createPost'
import { useStore } from 'store'

import { BlogpostPreviewMintModal } from '../BlogpostPreviewMintModal'

import styles from './styles.module.scss'

interface IProps {
  draftToEdit: IDraft | null
  getDrafts: () => Promise<void>
  blogpostData: ISendPublication[]
  blogpostPreviewData: IBlogpostPreviewData | null
  setBlogpostPreviewData: (value: IBlogpostPreviewData) => void
  isOpenSaveDraftBlogpostConfirmModal: {
    open: boolean
    isNeedCloseCreateModal?: boolean
  }
  closeSaveDraftBlogpostConfirmModal: () => void
  isOpenPreviewMintModal: boolean
  closePreviewMintModal: () => void
  clearPostData: () => void
  closeModal: (clearMintData?: boolean) => void
  isFullSize: boolean
  getIpfsFileSrc: (
    fileSrc: string
  ) => Promise<{ FileLink: string; CdnFileLink: string }>
  getIpfsVideoData: (
    fileSrc: string
  ) => Promise<{ src: string; posterSrc: string; cdnSrc: string }>
  onCreateBlockchainPost: (list: ISendPublication[]) => Promise<void>
  onApplyPostTypeAfterBlogpost: () => void
}

export const BlogpostModals: FC<IProps> = observer(
  ({
    draftToEdit,
    getDrafts,
    blogpostData,
    blogpostPreviewData,
    setBlogpostPreviewData,
    isOpenSaveDraftBlogpostConfirmModal,
    closeSaveDraftBlogpostConfirmModal,
    isOpenPreviewMintModal,
    closePreviewMintModal,
    clearPostData,
    closeModal,
    isFullSize,
    getIpfsFileSrc,
    getIpfsVideoData,
    onCreateBlockchainPost,
    onApplyPostTypeAfterBlogpost,
  }) => {
    const { loading, publication: publicationStore, main } = useStore()

    const handlerSaveBlogpostInDraft = async (
      newPreviewData?: IBlogpostPreviewData
    ) => {
      try {
        loading.setLoading(true)
        let newList = cloneDeep(blogpostData)

        // Send files to serves
        const formData = new FormData()
        const entitiesKeys: Array<{ fileSrc: string; key: string }> = []
        const pushToEntitiesKeys = async (fileSrc: string) => {
          if (fileSrc.includes('blob:')) {
            const uuid = uuidv4()
            const response = await fetch(fileSrc.split('?')[0])
            const blob = await response.blob()
            const file = new File([blob], uuid)
            formData.append('files', file)
            entitiesKeys.push({ fileSrc, key: uuid })
          }
        }

        await Promise.all(
          newList.map(async (post: ISendPublication, idx) => {
            const rawData = convertToRaw(post.EditorData.getCurrentContent())
            const entityMap = rawData.entityMap

            for (const key of Object.keys(entityMap)) {
              const entity = entityMap[key]
              if (
                entity.type === 'draft-js-video-plugin-video' ||
                entity.type === 'IMAGE'
              ) {
                const fileSrc = entity.data.src
                await pushToEntitiesKeys(fileSrc)
              }
            }

            for (const block of rawData.blocks) {
              if (block.type === EditorBlockType.IMAGE && block.data) {
                const fileSrcList: string[] = block.data.srcArray

                if (fileSrcList) {
                  const fileSrcArray: string[] = [...fileSrcList]
                  await Promise.all(
                    fileSrcArray.map(async fileSrc => {
                      await pushToEntitiesKeys(fileSrc)
                    })
                  )
                }
              }
            }
          })
        )

        console.log('entitiesKeys', entitiesKeys)

        if (entitiesKeys.length > 0) {
          const arrayServerFileSrc: string[] =
            await publicationStore.uploadImagesForBlogpost(formData)

          newList.map(async (post: ISendPublication, idx) => {
            const rawData = convertToRaw(post.EditorData.getCurrentContent())
            const entityMap = rawData.entityMap

            for (const block of rawData.blocks) {
              if (block.type === EditorBlockType.IMAGE && block.data) {
                const fileSrcArray: string[] = block.data.srcArray
                const fileServerSrcArray = fileSrcArray.map(fileSrc => {
                  if (fileSrc.includes('blob:')) {
                    const keyFileServer = entitiesKeys.find(
                      item => item.fileSrc === fileSrc
                    )
                    if (keyFileServer) {
                      const srcServer = arrayServerFileSrc.find(src =>
                        src.includes(keyFileServer.key)
                      )
                      const {
                        src: imageSrc,
                        dimensionsQuery,
                        width,
                        height,
                      } = getImageQueryDimensionsFromUrl(keyFileServer.fileSrc)

                      // Add dimensions to srcServer if src have query dimensions
                      if (width && height) {
                        return `${srcServer}?${dimensionsQuery}`
                      } else {
                        return srcServer
                      }
                    }
                  } else {
                    return fileSrc
                  }
                })

                if (fileServerSrcArray) {
                  block.data = {
                    ...block.data,
                    srcArray: fileServerSrcArray,
                  }
                }
              }
            }

            for (const key of Object.keys(entityMap)) {
              const entity = entityMap[key]

              if (
                entity.type === 'draft-js-video-plugin-video' ||
                entity.type === 'IMAGE'
              ) {
                const fileSrc = entity.data.src

                const keyFileServer = entitiesKeys.find(
                  item => item.fileSrc === fileSrc
                )
                if (keyFileServer) {
                  const fileServerSrc = arrayServerFileSrc.find(src =>
                    src.includes(keyFileServer.key)
                  )
                  if (fileServerSrc) {
                    entityMap[key].data = {
                      ...entityMap[key].data,
                      src: fileServerSrc,
                    }
                  }
                }
              }
            }
            newList[idx] = {
              ...post,
              EditorData: EditorState.createWithContent(
                convertFromRaw(rawData)
              ),
            }
          })
        }

        const previewData: IBlogpostPreviewData | null = newPreviewData
          ? newPreviewData
          : blogpostPreviewData
        console.log('previewData', previewData)
        if (previewData) {
          if (previewData.preview.includes('blob:')) {
            const response = await fetch(previewData.preview)
            const blob = await response.blob()
            const file = new File([blob], uuidv4())
            const formData = new FormData()
            formData.append('files', file)

            const previewFileSrcArrayServer: string[] =
              await publicationStore.uploadImagesForBlogpost(formData)
            if (previewFileSrcArrayServer.length > 0) {
              previewData.preview = previewFileSrcArrayServer[0]
            }
            // const ipfsFile = await ipfs.add(file)
            // previewLink = `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}`
          }
        }
        console.log('previewData', previewData)

        if (draftToEdit) {
          const updateDraft = await publicationStore.updateDraftPost(
            draftToEdit.Id,
            newList[0].EditorData,
            previewData,
            []
          )
          toast({
            type: 'success',
            message: 'The blogpost was successfully updated',
          })
        } else {
          const createDraft = await publicationStore.saveBlogpostAsDraft(
            newList[0].EditorData,
            previewData
          )
          toast({
            type: 'success',
            message: 'The blogpost was successfully saved',
          })
          console.log('saveBlogpostAsDraft', createDraft)
        }
        clearPostData()

        if (
          newPreviewData ||
          isOpenSaveDraftBlogpostConfirmModal.isNeedCloseCreateModal
        ) {
          // Save blogpost and close createPostModal
          await closeModal()
        } else {
          // Change tab after confirm/refuse
          onApplyPostTypeAfterBlogpost()
        }

        closeSaveDraftBlogpostConfirmModal()
        await getDrafts()
        loading.setLoading(false)
      } catch (e) {
        loading.setLoading(false)
        toast({
          type: 'error',
          message: 'Save blogpost failed!',
        })
        console.log('e', e)
      }
    }

    const handlerRefuseSaveDraftBlogpost = async () => {
      onApplyPostTypeAfterBlogpost()
      clearPostData()
      if (isOpenSaveDraftBlogpostConfirmModal.isNeedCloseCreateModal) {
        await closeModal()
      } else {
        closeSaveDraftBlogpostConfirmModal()
      }
    }

    const onCreateBlogpost = async (previewData: IBlogpostPreviewData) => {
      try {
        if (!isAvailableBlockchainProviderToast()) {
          loading.setLoading(false)
          return
        }
        loading.setLoading(true)
        let newList = cloneDeep(blogpostData)

        // Get preview image from blob/server
        const response = await fetch(previewData.preview)
        const blob = await response.blob()
        const file = new File([blob], 'preview')

        const [ipfsFile, ipfsFileCdn] = await Promise.all([
          ipfs.add(file),
          addFileToDigitalOceanSpaces(file, 'publications'),
        ])

        previewData.preview = `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}`
        previewData.previewCdn = ipfsFileCdn

        console.log('previewData', previewData)

        newList = newList.map((blockData, idx) =>
          idx === 0
            ? {
                ...blockData,
                IsBlogpost: true,
                BlogpostTitle: previewData.title,
                BlogpostDescription: previewData.description,
                BlogpostPreviewLink: previewData.preview,
                BlogpostPreviewLinkCdn: previewData.previewCdn,
              }
            : blockData
        )

        // Get media files from blob/server
        await Promise.all(
          newList.map(async (post: ISendPublication, idx) => {
            const rawData = convertToRaw(post.EditorData.getCurrentContent())
            const entityMap = rawData.entityMap

            for (const block of rawData.blocks) {
              if (block.type === EditorBlockType.IMAGE && block.data) {
                const fileSrcList: string[] = block.data.srcArray

                if (fileSrcList) {
                  const fileSrcArray: string[] = [...fileSrcList]
                  const ipfsFileSrcArray: string[] = []
                  const cdnFileSrcArray: string[] = []
                  await Promise.all(
                    fileSrcArray.map(async fileSrc => {
                      const ipfsFileSrc = await getIpfsFileSrc(fileSrc)
                      ipfsFileSrcArray.push(ipfsFileSrc.FileLink)
                      cdnFileSrcArray.push(ipfsFileSrc.CdnFileLink)
                    })
                  )

                  if (ipfsFileSrcArray) {
                    block.data = {
                      ...block.data,
                      srcArray: ipfsFileSrcArray,
                      cdnSrcArray: cdnFileSrcArray,
                    }
                  }
                }
              }
            }

            for (const key of Object.keys(entityMap)) {
              const entity = entityMap[key]

              if (entity.type === 'IMAGE') {
                console.log('entity.data.src', entity.data.src)

                const fileSrc = await getIpfsFileSrc(entity.data.src)
                entityMap[key].data = {
                  ...entityMap[key].data,
                  src: fileSrc.FileLink,
                  cdnSrc: fileSrc.CdnFileLink,
                }
              }

              if (entity.type === 'draft-js-video-plugin-video') {
                console.log('entity.data.src', entity.data.src)

                const videoData = await getIpfsVideoData(entity.data.src)
                entityMap[key].data = {
                  ...entityMap[key].data,
                  src: videoData.src,
                  posterSrc: videoData.posterSrc,
                  cdnSrc: videoData.cdnSrc,
                }
              }
            }

            newList[idx] = {
              ...post,
              EditorData: EditorState.createWithContent(
                convertFromRaw(rawData)
              ),
            }
          })
        )

        console.log(
          'newList',
          convertToRaw(newList[0].EditorData.getCurrentContent())
        )

        await onCreateBlockchainPost(newList)
      } catch (e) {
        loading.setLoading(false)
        toast({
          type: 'error',
          message: 'Blogpopst not created',
        })
        console.log('e', e)
      }
    }

    return (
      <>
        {/*Blogpost save modal*/}
        {main.isPWA ? (
          <SaveDraftConfirmModalPWA
            isOpen={isOpenSaveDraftBlogpostConfirmModal.open}
            setIsOpen={closeSaveDraftBlogpostConfirmModal}
            onClickYes={handlerSaveBlogpostInDraft}
            onClickNo={handlerRefuseSaveDraftBlogpost}
            text="Do you want to save your blogpost in draft?"
          />
        ) : (
          <SaveDraftConfirmModal
            isOpen={isOpenSaveDraftBlogpostConfirmModal.open}
            setIsOpen={closeSaveDraftBlogpostConfirmModal}
            onClickYes={handlerSaveBlogpostInDraft}
            onClickNo={handlerRefuseSaveDraftBlogpost}
            blockOutsideClose
            text="Do you want to save your blogpost in draft?"
          />
        )}

        {blogpostPreviewData ? (
          <BlogpostPreviewMintModal
            isOpen={isOpenPreviewMintModal}
            onClose={closePreviewMintModal}
            isFullSize={isFullSize}
            blogpostPreviewData={blogpostPreviewData}
            setBlogpostPreviewData={setBlogpostPreviewData}
            onSaveBlogpostInDraft={handlerSaveBlogpostInDraft}
            onCreateBlogpost={onCreateBlogpost}
          />
        ) : null}

        {/*/!*Blogpost save modal*!/*/}
        {/*<SaveDraftConfirmModal isOpen={isOpenSaveDraftBlogpostConfirmModal.open}*/}
        {/*                       setIsOpen={closeSaveDraftBlogpostConfirmModal}*/}
        {/*                       onClickYes={handleSaveBlogpostInDraft}*/}
        {/*                       onClickNo={refuseSaveDraftBlogpost}*/}
        {/*                       blockOutsideClose*/}
        {/*                       text="Do you want to save your blogpost in draft?"/>*/}
        {/*{isBlogpostPreviewModalOpen && blogpostPreviewData*/}
        {/*  ? <BlogpostPreviewMintModal isOpen={isBlogpostPreviewModalOpen}*/}
        {/*                          onClose={closeBlogpostPreviewModal}*/}
        {/*                          isFullSize={isFullSize}*/}
        {/*                          blogpostPreviewData={blogpostPreviewData}*/}
        {/*                          setBlogpostPreviewData={setNewBlogpostPreviewData}*/}
        {/*                          onSaveBlogpostInDraft={handleSaveBlogpostInDraft}*/}
        {/*                          onCreateBlogpost={onCreateBlogpost}/>*/}

        {/*  : null}*/}
      </>
    )
  }
)
