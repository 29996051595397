import React, { CSSProperties, FC, useMemo } from 'react'

import {
  ContentState,
  convertFromRaw,
  convertToRaw,
  EditorState,
  Modifier,
} from 'draft-js'
import { OrderedSet } from 'immutable'

import { getPostLengthFromEditorState, ViewTypeEditor } from 'utils'

import { CustomEditor } from '../CustomEditor'

import { ReactComponent as MediaIcon } from 'sources/images/mixed_media.svg'
import { Inline } from '../CustomEditor/util/constants'

import styles from './styles.module.scss'

interface IProps {
  viewType: ViewTypeEditor
  text: string
  editorText?: EditorState
  editorKey?: string
  insertText?: {
    text: string
    styleRule: string
  }
  isEncrypted?: boolean
  canDecrypt?: string
  styleContainer?: CSSProperties
  handleEmptyText?: () => void
  emptyTextComponent?: React.ReactNode | JSX.Element
  withDefaultEmptyTextComponent?: boolean
  isSuperPost?: boolean
  isBlogpost?: boolean
  isSuperPostPublicText?: boolean
  isSuperPostOpened?: boolean
  whiteStyle?: boolean
}

//Resize
export const EditorTextView: FC<IProps> = ({
  text,
  editorText,
  viewType,
  insertText,
  editorKey,
  isEncrypted,
  canDecrypt,
  styleContainer,
  handleEmptyText,
  emptyTextComponent,
  withDefaultEmptyTextComponent,
  isSuperPost,
  isSuperPostOpened,
  isSuperPostPublicText,
  isBlogpost,
  whiteStyle,
}) => {
  const canDecryptReceiver = canDecrypt ? canDecrypt.split(',')[0].trim() : ''

  const editorData = useMemo(() => {
    if (editorText) {
      if (insertText) {
        const editorState = EditorState.createWithContent(
          convertFromRaw(convertToRaw(editorText.getCurrentContent()))
        )
        const selection = editorState.getSelection()
        const contentState = editorState.getCurrentContent()
        const newContentState = Modifier.insertText(
          contentState,
          selection,
          insertText.text,
          OrderedSet([insertText.styleRule])
        )
        return EditorState.push(editorState, newContentState, 'insert-fragment')
      } else {
        return editorText
      }
    }

    if (!text) return EditorState.createEmpty()

    let parsedData: any
    try {
      parsedData = JSON.parse(text)
    } catch (e) {
      parsedData = text
    }

    if (typeof parsedData === 'number') {
      parsedData = String(parsedData)
    }
    if (typeof parsedData === 'string' && parsedData.length === 0) {
      return EditorState.createEmpty()
    }
    if (typeof parsedData === 'string' && parsedData.length !== 0) {
      if (isEncrypted && canDecryptReceiver) {
        const editorState = EditorState.createEmpty()
        // const editorState = EditorState.createWithContent(ContentState.createFromText(parsedData))
        const selection = editorState.getSelection()
        const contentState = editorState.getCurrentContent()
        const ContentStateCanDecrypt = Modifier.insertText(
          contentState,
          selection,
          `@${canDecryptReceiver}`,
          OrderedSet([Inline.CAN_DECRYPT])
        )
        const newEditorState = EditorState.push(
          editorState,
          ContentStateCanDecrypt,
          'insert-fragment'
        )

        const newSelection = newEditorState.getSelection()
        const newContentState = newEditorState.getCurrentContent()
        const ContentStateEncrypted = Modifier.insertText(
          newContentState,
          newSelection,
          parsedData.slice(0, 100),
          OrderedSet([Inline.ENCRYPTED])
        )

        return EditorState.push(
          newEditorState,
          ContentStateEncrypted,
          'insert-fragment'
        )
      }
      return EditorState.createWithContent(
        ContentState.createFromText(parsedData)
      )
    }

    if (typeof parsedData === 'object' && parsedData !== null) {
      if (insertText) {
        const editorState = EditorState.createWithContent(
          convertFromRaw(parsedData)
        )
        const selection = editorState.getSelection()
        const contentState = editorState.getCurrentContent()
        // const newContentState = Modifier.insertText(contentState, selection, "TEST: ", OrderedSet.of('BOLD'));
        const newContentState = Modifier.insertText(
          contentState,
          selection,
          insertText.text,
          OrderedSet([insertText.styleRule])
        )
        return EditorState.push(editorState, newContentState, 'insert-fragment')
      } else {
        try {
          return EditorState.createWithContent(convertFromRaw(parsedData))
        } catch (e) {
          console.log('editorState', e)
          return EditorState.createEmpty()
        }
      }

      //Second solution need check
      // import htmlToDraft from 'html-to-draftjs';
      // import { ContentState, EditorState, Modifier } from 'draft-js';
      //
      // const data = "<h3>Dear member!</h3><p>This is a <b>TEST</b></p>"
      //
      // let { contentBlocks, entityMap } = htmlToDraft(data);
      // let contentState = Modifier.replaceWithFragment(
      //   editorState.getCurrentContent(),
      //   editorState.getSelection(),
      //   ContentState.createFromBlockArray(contentBlocks, entityMap).getBlockMap()
      // )
      //
      // EditorState.push(editorState, contentState, 'insert-fragment')
    }

    return EditorState.createEmpty()
  }, [text])
  const textLength = useMemo(
    () => getPostLengthFromEditorState(editorData),
    [editorData]
  )

  if (!textLength) {
    handleEmptyText && handleEmptyText()

    if (emptyTextComponent) {
      return <>{emptyTextComponent}</>
    }

    if (withDefaultEmptyTextComponent) {
      return <MediaIcon className={styles.mediaIcon} />
    }

    return null
  }

  return (
    <CustomEditor
      editorState={editorData}
      viewType={viewType}
      readOnly={true}
      editorKey={editorKey}
      isEncrypted={isEncrypted}
      canDecrypt={canDecryptReceiver}
      styleContainer={styleContainer}
      isSuperPost={isSuperPost}
      isSuperPostOpened={isSuperPostOpened}
      isSuperPostPublicText={isSuperPostPublicText}
      isBlogpostView={isBlogpost}
      whiteStyle={whiteStyle}
    />
  )
}
