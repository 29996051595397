import React from 'react'

import { default as AnimateHeightReact } from 'react-animate-height'

import classNames from 'classnames'

interface IProps {
  isOpen: boolean
  children: React.ReactNode
  className?: string
}

export const AnimateHeight: React.FC<IProps> = props => {
  const { isOpen, children, className = '' } = props

  return (
    <AnimateHeightReact
      duration={250}
      height={isOpen ? 'auto' : 0}
      className={classNames({ [className || '']: !!className })}
      style={{
        width: '100%',
      }}
    >
      {children}
    </AnimateHeightReact>
  )
}
