import { action, makeAutoObservable, observable } from 'mobx'

import { EThemeType } from 'utils'

class Theme {
  theme: EThemeType = EThemeType.light
  xPostTheme: EThemeType = EThemeType.dark

  constructor() {
    makeAutoObservable(this)
  }

  setTheme(value: string) {
    this.theme = value as EThemeType
  }

  setXPostTheme(value: string) {
    this.xPostTheme = value as EThemeType
  }
}

export default new Theme()
