import React from 'react'
import PropTypes from 'prop-types'
// import './atomic.module.scss';

import { AtomicEmbedComponent } from './atomicEmbed'

const AtomicBlock = (props: any) => {
  const content = props.blockProps.getEditorState().getCurrentContent()
  const entity = content.getEntity(props.block.getEntityAt(0))
  const data = entity.getData()
  const type = entity.getType()

  if (type === 'image') {
    return (
      <div className="md-block-atomic-wrapper">
        <img role="presentation" src={data?.cdnSrc || data.src} />
        <div className="md-block-atomic-controls">
          <button>&times;</button>
        </div>
      </div>
    )
  }

  if (type === 'embed') {
    return <AtomicEmbedComponent data={data} />
  }
  return <p>No supported block for {type}</p>
}

AtomicBlock.propTypes = {
  block: PropTypes.object,
  getEditorState: PropTypes.func,
}

export default AtomicBlock
