import PropTypes from 'prop-types'
import React, {
  CSSProperties,
  FC,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { CustomViewZoom } from 'components/CustomViewZoom'

import {
  ContentBlock,
  EditorBlock,
  EditorState,
  SelectionState,
} from 'draft-js'
import cloneDeep from 'lodash/cloneDeep'
import cn from 'classnames'

import { getImageQueryDimensionsFromUrl } from 'utils'

import { getCurrentBlock } from '../util'

import styles from './image.module.scss'

interface IProps {
  block: ContentBlock
  blockProps: any
}

interface IImageDimensions {
  dimensions: { height: number; width: number }
  src: string
}

interface IImageWidthScale extends IImageDimensions {
  scaleWidth: number
}

interface IImageWidthPercent extends IImageWidthScale {
  widthPercent: number
}

const ImageBlock: FC<IProps> = props => {
  const { block, blockProps } = props
  const { readOnly, getEditorState } = blockProps

  const [srcArray] = useState<string[] | null>(() => {
    const data = block.getData()
    if (data) {
      const srcArrayList = data.get('srcArray')
      const cdnSrcArrayList = data.get('cdnSrcArray')
      return cdnSrcArrayList
        ? [...cdnSrcArrayList]
        : srcArrayList
        ? [...srcArrayList]
        : null
    }
    return null
  })

  const [arrayOfImagesSection, setArrayOfImagesSection] = useState<
    IImageWidthPercent[][]
  >([])

  // useLayoutEffect(() => {
  //   getArrayOfImagesSection()
  // }, [])
  useEffect(() => {
    getArrayOfImagesSection()
  }, [])

  const getImagesWithWidth = async (imagesArray: string[]) => {
    const imagesArrayDimensions: IImageDimensions[] = []

    await Promise.all(
      imagesArray.map(async (src: string) => {
        return new Promise((resolve, reject) => {
          const {
            src: imageSrc,
            dimensionsQuery,
            width,
            height,
          } = getImageQueryDimensionsFromUrl(src)
          if (imageSrc && width && height) {
            imagesArrayDimensions.push({
              src: imageSrc,
              dimensions: { height: Number(height), width: Number(width) },
            })
            resolve(true)
          } else {
            const newImg = new Image()
            newImg.onload = function () {
              const height = newImg.height
              const width = newImg.width
              imagesArrayDimensions.push({ src, dimensions: { height, width } })
              resolve(true)
            }

            newImg.onerror = function () {
              reject()
            }
            newImg.src = src
          }
        })
      })
    )

    const imagesArrayWidthScale: IImageWidthScale[] = imagesArrayDimensions.map(
      item => {
        const scale = 100 / item.dimensions.height
        const scaleWidth = item.dimensions.width * scale

        return { ...item, scaleWidth }
      }
    )

    const totalWidthImages = imagesArrayWidthScale.reduce(
      (accumulator: number, currentValue) =>
        accumulator + currentValue.scaleWidth,
      0
    )

    const imagesWithWidth: IImageWidthPercent[] = imagesArrayWidthScale.map(
      (item: any) => {
        return {
          ...item,
          widthPercent: (item.scaleWidth / totalWidthImages) * 100,
        }
      }
    )

    return imagesWithWidth
  }

  const getArrayOfImagesSection = async () => {
    try {
      if (srcArray && srcArray.length > 0) {
        const arrayOfArrays: string[][] = []
        const imagesSrcArray = cloneDeep(srcArray)

        while (imagesSrcArray.length > 0) {
          const splice = imagesSrcArray.splice(0, 3)

          if (imagesSrcArray.length === 1) {
            const last4images = [...splice, ...imagesSrcArray.splice(0, 1)]
            arrayOfArrays.push(
              last4images.splice(0, 2),
              last4images.splice(0, 2)
            )
          } else {
            arrayOfArrays.push(splice)
          }
        }

        const array = await Promise.all(
          arrayOfArrays.map(section => getImagesWithWidth(section))
        )
        setArrayOfImagesSection(array)
      }
    } catch (e) {
      console.log('getArrayOfImagesSection', e)
    }
  }

  const captionBoxRef = useRef<HTMLDivElement | null>(null)
  const captionBlockTextLengthRef = useRef<number>(block.getLength())
  captionBlockTextLengthRef.current = block.getLength()

  // const onKeyDown = (e: any) => {
  //   console.log('key', e.key, e.type)
  // }

  const onFocusCaptionBox = (e: any) => {
    if (captionBlockTextLengthRef.current > 0) return
    if (captionBoxRef.current) {
      if (Boolean(captionBoxRef.current.contains(e.target))) {
        // console.log('contain')
        captionBoxRef.current.classList.add(styles.captionEmpty)
      } else {
        // console.log('not contain')
        captionBoxRef.current.classList.remove(styles.captionEmpty)
      }
    }
  }

  useEffect(() => {
    if (readOnly) return

    document.addEventListener('mousedown', onFocusCaptionBox)
    // document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('mousedown', onFocusCaptionBox)
      // document.removeEventListener('keydown', onKeyDown)
    }
  }, [])

  const isActiveBlock = () => {
    if (readOnly) return false
    const anchorKey = getEditorState().getSelection().getAnchorKey()
    return anchorKey === block.getKey()
  }

  if (srcArray && srcArray.length > 0) {
    return (
      <div className={styles.figuresContainer}>
        {arrayOfImagesSection.map((imagesSection, idx) => (
          <div
            className={cn({
              [styles.figuresSection]: true,
              [styles.figuresSectionLast]:
                idx === arrayOfImagesSection.length - 1,
            })}
            key={idx}
          >
            {imagesSection.map(image => {
              let width = 0
              if (
                imagesSection.length === 1 &&
                image.dimensions.height > image.dimensions.width
              ) {
                width = (440 / image.dimensions.height) * image.dimensions.width
              }

              return (
                <figure
                  className={cn({
                    [styles.blockFigure]: true,
                    [styles.blockFigureMaxWidth]: imagesSection.length === 1,
                  })}
                  style={{
                    width: width ? `${width}px` : `${image.widthPercent}%`,
                  }}
                  key={image.src}
                >
                  <div
                    className={styles.imageContainer}
                    onClick={event => event.stopPropagation()}
                  >
                    <CustomViewZoom wrapStyle={{ width: '100%' }}>
                      <img
                        style={{ width: '100%' }}
                        role="presentation"
                        src={image.src}
                      />
                    </CustomViewZoom>
                  </div>
                </figure>
              )
            })}
          </div>
        ))}

        {captionBlockTextLengthRef.current !== 0 || !readOnly ? (
          <div
            data-placeholder="Type caption for image (optional)"
            ref={captionBoxRef}
            className={cn({
              [styles.caption]: true,
              [styles.captionEmpty]:
                captionBlockTextLengthRef.current !== 0 || isActiveBlock(),
            })}
          >
            <EditorBlock {...props} />
          </div>
        ) : null}
      </div>
    )
  }
  return <EditorBlock {...props} />
}

export default ImageBlock

// const focusBlock = () => {
//   const { getEditorState, setEditorState } = blockProps
//   const key = block.getKey()
//   const editorState = getEditorState()
//   const currentBlock = getCurrentBlock(editorState)
//
//   // Have bug currentBlock dont change
//   if (currentBlock.getKey() === key) {
//     return
//   }
//   const newSelection = new SelectionState({
//     anchorKey: key,
//     focusKey: key,
//     anchorOffset: 0,
//     focusOffset: 0
//   })
//   setEditorState(EditorState.forceSelection(editorState, newSelection))
// }
