import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import Button from 'components/Button'
import { PostType } from 'store/createPost'
import CustomMultipleSwitch from 'components/CustomMultipleSwitch'
import { CloseIconButtonPWA } from 'componentsPWA/CloseIconButtonPWA'

import useMediaQuery from '@mui/material/useMediaQuery'
import { UserRole } from 'utils'
import { IDraft } from 'models'

import styles from './styles.module.scss'

interface IProps {
  postType: PostType
  onCreatePost: (postType?: PostType) => void
  onNextBlogpost: () => void
  onClose: () => void
  onChangePostType: (value: number) => void
  profileUserRole: UserRole | string
  draftToEdit: IDraft | null,
  onSaveStreamInDraft: () => void
  quantitySymbols: number
}

export const Header: FC<IProps> = (
  {
    postType,
    onCreatePost,
    onNextBlogpost,
    onClose,
    onChangePostType,
    profileUserRole,
    draftToEdit,
    onSaveStreamInDraft,
    quantitySymbols
  }) => {
  // const media400 = useMediaQuery('(max-width:400px)')

  const isEncrypted = postType === PostType.Encrypted
  const isSuperpost = postType === PostType.Superpost
  const isBlogpost = postType === PostType.Blogpost
  const isStreampost = postType === PostType.Streampost
  const isFreepost = postType === PostType.Freepost

  const getMintButtonData = () => {
    let text
    let onClick

    if (isBlogpost) {
      text = 'Next'
      onClick = onNextBlogpost
    } else if (isFreepost) {
      text = 'Create'
      onClick = () => {
        onCreatePost(PostType.Freepost)
      }
    } else {
      text = 'Mint'
      onClick = onCreatePost
    }

    return { text, onClick }
  }

  const mintButton = getMintButtonData()
  // const draftSwitchStyle = draftToEdit ? { width: '100px', minWidth: '100px' } : {}

  return (
    <div className={styles.container}>
      <div className={styles.topBox}>
        <CloseIconButtonPWA onClose={onClose}/>

        <div className={cn(styles.quantitySymbols, {
          [styles.quantitySymbolsError]: quantitySymbols > 5000 && !isBlogpost
        })}>
          {isBlogpost ? quantitySymbols : `${quantitySymbols}/5000`}
        </div>

        <div className={styles.saveMintBox}>
          {isStreampost ?
            <button onClick={onSaveStreamInDraft} className={styles.saveInDraft}>Save in draft</button>
            : null}
          <Button
            type="button"
            style={{
              width: '74px',
              height: '32px',
              background: 'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
              boxShadow: 'none'
            }}
            styleText={{ color: '#ffffff', fontSize: '0.75rem', fontWeight: 700 }}
            btnStyle={'classic'}
            text={mintButton.text}
            onClick={mintButton.onClick}
            blueHover
          />
        </div>
      </div>
      {/*Maybe don't need here*/}
      {/*<div className={styles.switchBox}>*/}
      {/*  {profileUserRole === UserRole.User*/}
      {/*    ? <CustomMultipleSwitch*/}
      {/*      key={0}*/}
      {/*      initialPosition={0}*/}
      {/*      onSwitchClick={onChangePostType}*/}
      {/*      switchStyle={{ width: '100px', minWidth: '100px', height: '32px', padding: '2px' }}*/}
      {/*      buttonStyle={{ fontSize: media400 ? '0.6rem' : '0.75rem' }}*/}
      {/*      buttons={[{ key: 0, title: 'Freepost' }]}*/}
      {/*    />*/}
      {/*    : <CustomMultipleSwitch*/}
      {/*      key={1}*/}
      {/*      initialPosition={draftToEdit ? 0 : profileUserRole === UserRole.User ? 4 : postType as any}*/}
      {/*      onSwitchClick={onChangePostType}*/}
      {/*      switchStyle={{ minWidth: '100%', width: '100%', height: '32px', padding: '2px', ...draftSwitchStyle }}*/}
      {/*      buttonStyle={{ fontSize: media400 ? '0.6rem' : '0.75rem' }}*/}
      {/*      buttons={draftToEdit*/}
      {/*        ? [{ key: 0, title: draftToEdit.Type === 0 ? 'Blogpost' : 'Streampost' }]*/}
      {/*        : [*/}
      {/*          { key: 0, title: 'Blockchain' },*/}
      {/*          { key: 1, title: 'Encrypted' },*/}
      {/*          { key: 2, title: 'Superpost' },*/}
      {/*          { key: 3, title: 'Blogpost' },*/}
      {/*          { key: 4, title: 'Freepost' }*/}
      {/*        ]}*/}
      {/*    />*/}
      {/*  }*/}

      {/*</div>*/}

    </div>
  )
}
