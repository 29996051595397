import React, { CSSProperties, FC } from 'react'

import cn from 'classnames'
import { BaseEmoji, Picker } from 'emoji-mart'

import styles from './styles.module.scss'

interface IProps {
  open: boolean
  onSelect: (value: BaseEmoji) => void
  style?: CSSProperties
}
export const EmojiMobile: FC<IProps> = ({ open, onSelect, style }) => {
  return (
    <div
      onClick={event => event.stopPropagation()}
      className={cn({
        [styles.emoji]: true,
        [styles.emojiOpen]: open,
      })}
      style={style}
    >
      <Picker
        showSkinTones={false}
        showPreview={false}
        emojiSize={25}
        emoji="point_up"
        useButton={false}
        native={true}
        perLine={8}
        style={{
          height: '100%',
          width: '100vw',
        }}
        onSelect={onSelect}
      />
    </div>
  )
}

EmojiMobile.displayName = 'EmojiMobile'
