import React from 'react'

import Draft, { ContentBlock, ContentState } from 'draft-js'

import { OPEN_LINK_IN_SAME_TAB } from '../../../../utils/const'

import { Entity } from '../../util/constants'

export const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: (start: number, end: number) => void,
  contentState: ContentState
) => {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === Entity.LINK
    )
  }, callback)
}

interface ILink {
  children: React.ReactNode
  entityKey: string
  contentState: ContentState
}
export const Link: React.FC<ILink> = ({
  children,
  contentState,
  entityKey,
}) => {
  const { url } = contentState.getEntity(entityKey).getData()

  const _url = url?.replaceAll(OPEN_LINK_IN_SAME_TAB, '')

  return (
    <a
      onClick={e => e.stopPropagation()}
      style={{ color: 'rgb(118 45 186)', textDecoration: 'underline' }}
      href={_url}
      rel="noopener noreferrer"
      target={url?.includes(OPEN_LINK_IN_SAME_TAB) ? '_self' : '_blank'}
      aria-label={_url}
    >
      {children}
    </a>
  )
}

export const linkDecoratorPlugin = {
  decorators: [
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ],
}
