import React, { CSSProperties } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'

import Popover from '@mui/material/Popover'

import {
  createPostFileType,
  getPostLengthFromEditorState,
  ViewTypeEditor,
} from 'utils'
import { useAuth, useMediaQuery } from 'utils/hooks'

import { toast } from '../../../../App'
import { AddFileButtons } from 'components/AddFileButtons'
import { AdultCheckbox } from 'components/AdultCheckbox'
import Button from 'components/Button'
import { CustomEditor } from 'components/CustomEditor'
import { PriceUpdate } from 'components/PriceUpdate'
import { SwitchCurrency } from 'components/SwitchCurrency'
import { UserImageForEditor } from 'components/UserImageForEditor'
import { ViewMediaOnCreateBlock } from 'components/ViewMediaOnCreateBlock'
import PoweredByAuthencity from 'containers/Widgets/components/PoweredByAuthencity'
import WidgetPopoverButton from 'containers/Widgets/components/WidgetPopoverButton'

import { IIpfsFiles, ISendPublication } from 'models'

import { useStore } from 'store'
import { PostType } from 'store/createPost'

import styles from './styles.module.scss'

interface IProps {
  isAdultContent: boolean
  index: number
  post: ISendPublication
  postType: PostType
  activeBlock: string
  listLength: number
  onChangeText: (
    id: string,
    editorData: EditorState,
    transactionBlockKey: string
  ) => void
  onAddFile: (id: string, fileList: IIpfsFiles[]) => void
  onAddVideoPoster: (
    id: string,
    poster: string | undefined,
    defaultPoster: boolean,
    fileId: string
  ) => void
  onAddVideoDuration?: (id: string, duration: number) => void
  onAddAudiWaveForm: (id: string, waveForm: string) => void
  onCheckFile: (id: string, fileId: string) => void
  onChangeTitleDescription: (
    id: string,
    text: EditorState,
    transactionBlockKey: string,
    type: 'title' | 'description',
    fileID: string
  ) => void
  onDeleteFile: (postId: string, key: string) => void
  onEditFile: (postId: string, key: string, newFile: IIpfsFiles) => void
  onChangeAdultContent: (isAdult: boolean, id: string) => void
  onCreatePost: (postType?: PostType) => void
  onOpenEmojiPicker: (top: number, left: number, isEnoughSpace: boolean) => void
  onGetPrice: () => Promise<{ price: string } | undefined>
  quantitySymbols: number
  transactionBlockKey: string
  isFirstTransactionBlock: boolean
  blockStyle?: CSSProperties
  setActiveBlock: Function
  setTransactionActiveBlock: Function
  getOverflowNodeDOMRect: () => DOMRect | null
  onRotate360Image?: (
    postId: string,
    key: string,
    position: {
      yaw: number
      pitch: number
    }
  ) => void
}

//Resize
const BlockWidget: React.FC<IProps> = observer(
  ({
    isAdultContent,
    index,
    post,
    postType,
    activeBlock,
    listLength,
    setActiveBlock,
    onChangeText,
    onAddFile,
    onAddVideoPoster,
    onAddVideoDuration,
    onAddAudiWaveForm,
    onCheckFile,
    onChangeTitleDescription,
    onDeleteFile,
    onEditFile,
    onChangeAdultContent,
    onCreatePost,
    onOpenEmojiPicker,
    onGetPrice,
    quantitySymbols,
    setTransactionActiveBlock,
    transactionBlockKey,
    isFirstTransactionBlock,
    blockStyle,
    getOverflowNodeDOMRect,
    onRotate360Image,
  }) => {
    const { profile, widget } = useStore()
    const width_360 = useMediaQuery('(max-width: 360px)')
    const { logoutWithoutRedirect } = useAuth({})

    const isBlockchainPost = postType === PostType.Blockchain

    const handlerOnClickEditorBlock = () => {
      setTransactionActiveBlock(transactionBlockKey)
      setActiveBlock(post.Id)
    }

    const handleChangeAdultContent = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      onChangeAdultContent(event.target.checked, post.Id)
    }

    const postLength = getPostLengthFromEditorState(post.EditorData)

    const getMintButtonData = () => {
      let text
      let onClick

      if (isBlockchainPost) {
        text = 'Mint'
        onClick = () => {
          if (!widget.isWidgetDemo) {
            onCreatePost()
          } else {
            toast({
              type: 'warning',
              message: "This is demo widget. You can't post it",
            })
          }
        }
      } else {
        text = 'Create'
        onClick = () => {
          if (!widget.isWidgetDemo) {
            onCreatePost(PostType.Freepost)
          } else {
            toast({
              type: 'warning',
              message: "This is demo widget. You can't post it",
            })
          }
        }
      }

      return { text, onClick }
    }

    const mintButton = getMintButtonData()

    const redirectToProfile = () => {
      if (profile.selectedUser.NickName) {
        window.open(`/user/${profile.selectedUser.NickName}`, '_blank')
      }
    }

    const hasCheckbox =
      post.IpfsFiles.length > 0 &&
      !post.IpfsFiles.find(item => item.FileType === createPostFileType.file)

    const userAvatarPopover = (
      <PopupState variant="popover" popupId="logged-user-menu">
        {popupState => {
          return (
            <>
              <UserImageForEditor {...bindTrigger(popupState)} />

              <Popover
                {...bindPopover(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                sx={{
                  '.MuiPaper-root': {
                    backgroundColor: 'transparent',
                    margin: '5px 0',
                  },
                }}
              >
                <div
                  className={styles.menuBox}
                  style={widget.getWidgetBackground(20)}
                >
                  {!profile?.selectedUser?.DeletionMark && (
                    <WidgetPopoverButton
                      onClick={redirectToProfile}
                      text={'Go to Profile'}
                    />
                  )}

                  <WidgetPopoverButton
                    onClick={logoutWithoutRedirect}
                    text={'Log Out'}
                  />
                </div>
              </Popover>
            </>
          )
        }}
      </PopupState>
    )

    return (
      <div
        className={cn({
          [styles.createModalBlock]: true,
        })}
        key={post.Id}
        style={blockStyle}
      >
        {/*---▼▼▼--- SYMBOLS QUANTITY ---▼▼▼---*/}
        {activeBlock === post.Id ? (
          <div
            className={cn({
              [styles.symbolsQuantity]: true,
              [styles.symbolsQuantityMaxWidth]: width_360,
              [styles.symbolsQuantityError]: quantitySymbols > 5000,
            })}
          >
            <div
              className={cn({
                [styles.blockQuantityPlus]: true,
                [styles.blockQuantityPlusMaxWidth]: width_360,

                [styles.symbolsQuantityError]: quantitySymbols > 5000,
              })}
            >
              {`${quantitySymbols}/5000`}
            </div>
          </div>
        ) : null}

        {/*---▼▼▼--- TEXT INPUT BLOCK ---▼▼▼---*/}
        <div className={styles.editorAreaWrapper}>
          <CustomEditor
            viewType={ViewTypeEditor.editor}
            postId={post.Id}
            transactionBlockKey={transactionBlockKey}
            editorState={post.EditorData}
            setPostEditorState={onChangeText}
            onClickEditorBlock={handlerOnClickEditorBlock}
            isEncrypted={false}
            isBlogpostEditor={false}
            fullSizeStyle={false}
            getOverflowNodeDOMRect={getOverflowNodeDOMRect}
            onAddFile={onAddFile}
            styleContainer={widget.getWidgetBackground()}
            withoutToolbar={true}
          />

          {userAvatarPopover}
        </div>

        {/*---▼▼▼--- MEDIA BLOCK ---▼▼▼---*/}
        {post.IpfsFiles.length > 0 && (
          <ViewMediaOnCreateBlock
            IpfsFiles={post.IpfsFiles}
            postId={post.Id}
            transactionBlockKey={transactionBlockKey}
            onDeleteFile={onDeleteFile}
            onEditFile={onEditFile}
            onAddVideoPoster={onAddVideoPoster}
            onAddVideoDuration={onAddVideoDuration}
            onAddAudiWaveForm={onAddAudiWaveForm}
            onChangeTitleDescription={onChangeTitleDescription}
            onCheckFile={onCheckFile}
            onRotate360Image={onRotate360Image}
            postOnX={false}
          />
        )}
        {/*---▼▼▼--- CONTROL BLOCK ---▼▼▼---*/}
        <div
          className={cn({
            [styles.controlBlock]: true,
            [styles.activeControlBlock]: activeBlock === post.Id,
            [styles.activeControlBlockWithCheckbox]:
              activeBlock === post.Id &&
              (post.IpfsFiles.length > 0 || isAdultContent),
          })}
        >
          {/*---▼▼▼--- ADDITIONAL BUTTONS BLOCK ---▼▼▼---*/}
          <div
            className={cn(styles.additionalButtonsBlock, {
              [styles.additionalButtonsBlock_freepost]: !isBlockchainPost,
            })}
          >
            {/*---▼▼▼--- ADD NESTING BUTTONS ---▼▼▼---*/}
            <div className={styles.attachmentsBlock}>
              <AddFileButtons
                ipfsFiles={post.IpfsFiles}
                onAddFile={onAddFile}
                postId={post.Id}
                onOpenEmojiPicker={onOpenEmojiPicker}
                onlyEmoji={false}
                withSpatialVideo={true}
              />
            </div>

            <PoweredByAuthencity
              style={{
                flexGrow: 1,
                paddingLeft: '0.5rem',
              }}
              className={cn(styles.poweredBy, {
                [styles.poweredBy_blockchainpost]: isBlockchainPost,
              })}
            />

            {isBlockchainPost ? (
              <div className={styles.getPriceBlock}>
                <SwitchCurrency renderType={'widget'} />
                <PriceUpdate
                  onGetPrice={onGetPrice}
                  activeBlock={activeBlock}
                  blockId={post.Id}
                />
              </div>
            ) : null}

            {/*<div className={styles.buttonsBottom}>
              <Button
                type="button"
                style={{
                  width: '100%',
                  minWidth: '62px',
                  height: '32px',
                  background:
                    'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
                  boxShadow: 'none',
                }}
                styleText={{ color: '#ffffff' }}
                btnStyle={'classic'}
                text={mintButton.text}
                onClick={mintButton.onClick}
                blueHover
              />
            </div>*/}
          </div>
          {/*---▼▼▼--- CREATE BUTTON BLOCK ---▼▼▼---*/}
          {isFirstTransactionBlock && (
            <div
              className={cn(styles.createButtonBlock, {
                [styles.createButtonBlock_checkbox]: hasCheckbox,
              })}
            >
              <div className={styles.buttonsCheckboxes}>
                {hasCheckbox ? (
                  <AdultCheckbox
                    checked={isAdultContent}
                    onChange={handleChangeAdultContent}
                    formSX={{ margin: '0 0 5px -7px' }}
                  />
                ) : null}
              </div>
              <div className={styles.buttonsBottom}>
                <Button
                  type="button"
                  style={{
                    width: '100%',
                    minWidth: '62px',
                    // height: '32px',
                    height: '50px',
                    background:
                      widget?.theme?.themeButtonBG ||
                      'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
                    boxShadow: 'none',
                  }}
                  styleText={{
                    color: widget?.theme?.themeButtonColor || '#ffffff',
                  }}
                  btnStyle={'classic'}
                  text={mintButton.text}
                  onClick={mintButton.onClick}
                  blueHover={!widget?.theme?.themeButtonBG}
                />
              </div>
            </div>
          )}
        </div>
        {/*---▼▼▼--- CONNECTED LINES ---▼▼▼---*/}
        <div
          className={cn({
            [styles.connectedLines]: index !== listLength - 1,
          })}
        />
      </div>
    )
  }
)

const memoizedComponent = React.memo(BlockWidget)
memoizedComponent.displayName = 'BlockWidget'

export default memoizedComponent
