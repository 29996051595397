import React, { FC } from 'react'
import Button from 'components/Button'
import Modal from 'components/Modal'
import { observer } from 'mobx-react'
import { ModalPWA } from '../ModalPWA'

import { useStore } from 'store'
import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void
  handlerYesClick: () => void
}

export const CloseModalPWA: FC<IProps> = observer(({ isOpen, setIsOpen, handlerYesClick }) => {
  return (
    <ModalPWA open={isOpen}
              disableOutsideClick
              paperSX={{ justifyContent: 'flex-end', background: 'none', }}
              contentSX={{padding: {xs: '24px 16px 40px', sm: '24px 24px 40px'} , background: 'var(--boxColor)', borderRadius: '16px 16px 0px 0px'}}
    >
      <div className={styles.closeModal}>
        <div className={styles.header}>Are you sure you want to close this window?</div>
        <div className={styles.buttons}>
          <Button
            style={{
              width: '48%',
              height: '40px',
              background:
                'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)'
            }}
            styleText={{ color: '#ffffff' }}
            btnStyle="classic"
            text="Yes"
            onClick={handlerYesClick}
            blueHover
          />
          <Button
            style={{
              width: '48%',
              height: '40px',
              background: 'var(--boxColor)',
              borderColor: '#1F45C3'
            }}
            styleText={{ color: 'var(--modalButtonBorderTextColor)' }}
            btnStyle="bordered"
            text="No"
            onClick={() => setIsOpen(false)}
            blueHover
          />
        </div>
      </div>
    </ModalPWA>
  )
})
