import React from 'react';
import PropTypes from 'prop-types';
import { AtomicBlockUtils, EditorState, Modifier } from 'draft-js'
import cn from 'classnames'
import styles from '../styles.module.scss'

import { ReactComponent as EmbedSvg } from 'sources/images/embed.svg'

interface EmbedSideButtonProps {
  setEditorState: (editorState: EditorState) => void;
  getEditorState: () => EditorState;
  close: () => void;
  openEmbedLinkInput: () => void
}

export const EmbedButton: React.FC<EmbedSideButtonProps> = ({ openEmbedLinkInput }) => {
  const onClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    openEmbedLinkInput && openEmbedLinkInput()
  };

  return (
    <button
      // className="md-sb-button md-sb-img-button"
      className={cn({
        [styles.sideButton]: true,
      })}
      type="button"
      title="Add an Embed"
      onClick={onClick}
    >
      <EmbedSvg className={styles.embedIcon}/>
    </button>
  );
};
