import React, {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'

import ReactDOM from 'react-dom'

import cn from 'classnames'
import PropTypes from 'prop-types'

import { OPEN_LINK_IN_SAME_TAB } from '../../../../utils/const'

import { ReactComponent as CheckIcon } from 'sources/images/check.svg'
import { ReactComponent as CloseIcon } from 'sources/images/close.svg'

import { CustomCheckbox } from '../../../CustomCheckbox'
import { getCurrentBlock } from '../../util'
import { Entity, HYPERLINK } from '../../util/constants'
import { getSelection, getSelectionRect } from '../../util/selection'
import { BlockToolbar } from './BlockToolbar'
import InlineToolbar from './InlineToolbar'

import styles from './styles.module.scss'

interface IProps {
  // wait to remove next prop, maube need
  editorEnabled?: boolean
  editorState: any
  toggleBlockType: (type: string) => void
  toggleInlineStyle: (style: string) => void
  createLinkAtSelection: (url: string) => void
  focus: () => void
  getEditorNodeDOMRect: () => DOMRect | null
  getOverflowNodeDOMRect?: () => DOMRect | null
  positionType?: 'window' | 'editor' | 'element'
  isBlogpostEditor?: boolean
}

type TToolbarHandles = {
  handleLinkInput(e: React.MouseEvent<HTMLElement>, direct: boolean): void
} | null

const Toolbar = React.forwardRef<TToolbarHandles, IProps>(
  (
    {
      editorEnabled = true,
      editorState,
      toggleBlockType,
      toggleInlineStyle,
      createLinkAtSelection,
      focus,
      getEditorNodeDOMRect,
      getOverflowNodeDOMRect,
      positionType = 'element',
      isBlogpostEditor,
    },
    ref
  ) => {
    const [showURLInput, setShowURLInput] = useState(false)
    const [urlInputValue, setUrlInputValue] = useState('')
    const toolbarRef = useRef<HTMLDivElement | null>(null)
    const urlInputRef = useRef<HTMLInputElement>(null)

    const [openLinkInSameTab, setOpenLinkInSameTab] = useState(false)

    useEffect(() => {
      if (!editorEnabled) {
        return
      }
      const selectionState = editorState.getSelection()
      if (selectionState.isCollapsed()) {
        if (showURLInput) {
          setShowURLInput(false)
          setUrlInputValue('')
        }
        return
      }
    }, [editorEnabled, editorState, showURLInput])

    useEffect(() => {
      if (!editorEnabled || showURLInput) {
        return
      }
      const selectionState = editorState.getSelection()
      if (selectionState.isCollapsed()) {
        return
      }
      const nativeSelection = getSelection(window)
      if (!nativeSelection.rangeCount) {
        return
      }
      const selectionBoundary = getSelectionRect(nativeSelection)
      const toolbarNode = toolbarRef.current
      const editorNodeDOMRect = getEditorNodeDOMRect()
      const overflowNodeDOMRect =
        getOverflowNodeDOMRect && getOverflowNodeDOMRect()
      if (toolbarNode && editorNodeDOMRect) {
        const toolbarBoundary = toolbarNode.getBoundingClientRect()

        toolbarNode.style.top = `${
          selectionBoundary.top -
          editorNodeDOMRect.top -
          toolbarBoundary.height -
          5
        }px`
        toolbarNode.style.width = `${toolbarBoundary.width}px`

        const selectionCenter =
          selectionBoundary.left +
          selectionBoundary.width / 2 -
          editorNodeDOMRect.left
        let left = selectionCenter - toolbarBoundary.width / 2

        // Window position rule
        // const screenLeft = editorNodeDOMRect.left + left
        // if (screenLeft < 0) {
        //   left = -editorNodeDOMRect.left
        // }
        // Need for screen right

        // Editor position rule
        if (
          positionType === 'editor' ||
          (!overflowNodeDOMRect && positionType === 'element')
        ) {
          if (left < 0) {
            left = 0
          }

          const maxLeft = editorNodeDOMRect.width - toolbarBoundary.width
          if (left > maxLeft) {
            left = maxLeft
          }
        }

        // Overflow parent position rule
        if (overflowNodeDOMRect && positionType === 'element') {
          const diffLeft = overflowNodeDOMRect.left - editorNodeDOMRect.left
          if (left < diffLeft) {
            left = diffLeft
          }

          const diffRight = overflowNodeDOMRect.right - editorNodeDOMRect.right
          const maxLeft = editorNodeDOMRect.width - toolbarBoundary.width
          if (left > maxLeft) {
            left = maxLeft + diffRight
          }
        }

        if (isBlogpostEditor) {
          // padding left
          left += 3
        }

        toolbarNode.style.left = `${left}px`
      }
    }, [editorEnabled, editorState, showURLInput])

    useImperativeHandle(ref, () => ({
      handleLinkInput,
    }))

    const handleSaveLinkSettings = (e: any) => {
      console.log(openLinkInSameTab)

      e.preventDefault()
      e.stopPropagation()

      if (openLinkInSameTab) {
        createLinkAtSelection(
          urlInputValue?.replaceAll(OPEN_LINK_IN_SAME_TAB, '') +
            OPEN_LINK_IN_SAME_TAB
        )
      } else {
        createLinkAtSelection(
          urlInputValue?.replaceAll(OPEN_LINK_IN_SAME_TAB, '')
        )
      }

      hideLinkInput()
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        handleSaveLinkSettings(e)
      } else if (e.key === 'Escape') {
        hideLinkInput(true)
      }
    }

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setUrlInputValue(e.target.value?.replaceAll(OPEN_LINK_IN_SAME_TAB, ''))
    }

    const handleLinkInput = (
      e: React.MouseEvent<HTMLElement>,
      direct = false
    ) => {
      if (!direct) {
        e.preventDefault()
        e.stopPropagation()
      }
      const selection = editorState.getSelection()
      if (selection.isCollapsed()) {
        focus()
        return
      }
      const currentBlock = getCurrentBlock(editorState)
      let selectedEntity = ''
      let linkFound = false
      currentBlock.findEntityRanges(
        (character: any) => {
          const entityKey = character.getEntity()
          selectedEntity = entityKey
          return (
            entityKey !== null &&
            editorState.getCurrentContent().getEntity(entityKey).getType() ===
              Entity.LINK
          )
        },
        (start: number, end: number) => {
          let selStart = selection.getAnchorOffset()
          let selEnd = selection.getFocusOffset()
          if (selection.getIsBackward()) {
            selStart = selection.getFocusOffset()
            selEnd = selection.getAnchorOffset()
          }
          if (start === selStart && end === selEnd) {
            linkFound = true
            const { url } = editorState
              .getCurrentContent()
              .getEntity(selectedEntity)
              .getData()
            setShowURLInput(true)
            setUrlInputValue(url)
            setOpenLinkInSameTab(String(url).includes(OPEN_LINK_IN_SAME_TAB))
            setTimeout(() => {
              if (urlInputRef.current) {
                urlInputRef.current.focus()
                urlInputRef.current.select()
              }
            }, 0)
          }
        }
      )
      if (!linkFound) {
        setShowURLInput(true)
        setTimeout(() => {
          if (urlInputRef.current) {
            urlInputRef.current.focus()
          }
        }, 0)
      }
    }

    const closeLinkInput = (e: React.MouseEvent<HTMLOrSVGElement>) => {
      if (e !== null) {
        e.preventDefault()
        e.stopPropagation()
      }

      hideLinkInput(true)
    }

    const hideLinkInput = (focusEditor?: boolean) => {
      setShowURLInput(false)
      setUrlInputValue('')

      // Never focus after set state is will broken plugin
      focusEditor && focus()
    }

    const handleChangeOpenInSameTabCheckbox = (e: any) => {
      setOpenLinkInSameTab(e.target.checked)
    }

    let isOpen = true
    if (!editorEnabled || editorState.getSelection().isCollapsed()) {
      isOpen = false
    }

    if (showURLInput) {
      return (
        <div
          className={cn({
            [styles.toolbar]: true,
            [styles.toolbarLinkInput]: true,
            [styles.toolbarOpen]: isOpen,
          })}
        >
          <div
            className={cn(styles.controls, styles.showLinkInput)}
            onClick={event => {
              event.stopPropagation()
              event.preventDefault()
            }}
          >
            <CloseIcon
              className={styles.urlInputClose}
              onClick={closeLinkInput}
            />
            <input
              ref={urlInputRef}
              type="text"
              className={styles.urlInput}
              onKeyDown={onKeyDown}
              onChange={onChange}
              placeholder="Press ENTER or ESC"
              value={urlInputValue?.replaceAll(OPEN_LINK_IN_SAME_TAB, '')}
            />
          </div>

          <div className={cn(styles.toolbarLinkCheckButton)}>
            <CustomCheckbox
              checked={openLinkInSameTab}
              onChange={handleChangeOpenInSameTabCheckbox}
              text={'Open in same tab'}
              color={'#fff'}
              className={styles.toolbarLinkInputCheckbox}
            />

            <CheckIcon
              className={cn(
                styles.urlInputClose,
                styles.toolbarLinkCheckButton_button
              )}
              onClick={handleSaveLinkSettings}
            />
          </div>
        </div>
      )
    }

    return (
      <div
        ref={toolbarRef}
        className={cn({
          [styles.toolbar]: true,
          [styles.toolbarOpen]: isOpen,
        })}
      >
        <InlineToolbar editorState={editorState} onToggle={toggleInlineStyle} />

        <BlockToolbar
          editorState={editorState}
          onToggle={toggleBlockType}
          handleLinkInput={handleLinkInput}
        />
      </div>
    )
  }
)

export default Toolbar

// const MyNewComponent = React.memo(
//   (props) => {
//     <MyComponent {...props} />;
//   },
//   (oldProps, newProps) => {
//     if (
//       oldProps.options !== newProps.options &&
//       oldProps.options.multiSelected !== newProps.multiSelected &&
//       oldProps.options.showOuterCheckBoxOnSelected !==
//       newProps.showOuterCheckBoxOnSelected
//     ) {
//       return true;
//     }
//     return false;
//   }
// );
