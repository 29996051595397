import React, { FC, useState } from 'react'

import { saveAs } from 'file-saver'

import { toast } from '../../../App'

import { ReactComponent as Download } from 'sources/images/downloadIcon.svg'

import Button from '../../Button'
import { ConfirmModal } from '../../ConfirmModal'
import Modal from '../../Modal'

import styles from './styles.module.scss'

interface IProps {
  imageUrl: string
  fileType?: string
  showWarning?: boolean
}

//Resize
export const DownloadButton: FC<IProps> = ({
  imageUrl,
  fileType,
  showWarning,
}) => {
  const [showModal, setShowModal] = useState(false)

  const checkModal = () => {
    setShowModal(true)
  }

  const saveImage = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    url: string
  ) => {
    const fileName = fileType
      ? `${fileType}_${imageUrl?.split('/')?.at(-1)}`
      : 'image'

    saveAs(imageUrl + '?', fileName)
  }

  const handleClick = (e: any, imageUrl: string) => {
    e.stopPropagation()

    if (showWarning) {
      checkModal()
    } else {
      saveImage(e, imageUrl)
    }
  }

  const handleConfirmModal = (e: any): void => {
    setShowModal(false)
    saveImage(e, imageUrl)
  }

  return (
    <>
      <button onClick={e => handleClick(e, imageUrl)} className={styles.button}>
        <Download />
      </button>
      <ConfirmModal
        isOpen={showModal}
        setIsOpen={setShowModal}
        onClickYes={handleConfirmModal as any}
        text={'Are you sure you want to download this file?'}
      />
    </>
  )
}
