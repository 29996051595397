import { Inline } from './constants';
import { CSSProperties, useMemo } from 'react'

/*
Custom style map for custom entities
*/

const query = window.matchMedia('(min-width: 2201px)')

const customStyleMap = {
  [Inline.VIDEO_AUDIO_TD]: {
    fontWeight: 600,
    opacity: 0.6
  },
  [Inline.ENCRYPTED]: {
    filter: 'blur(3px)',
    WebkitFilter: 'blur(3px)'
  },
  [Inline.CAN_DECRYPT]: {
    minHeight: query.matches ? '24px' : '19px',
    color: 'var(--colorCanDecrypt)',
    background: 'var(--backgroundEncryptedMention)',
    padding: query.matches ? '0 4px 3px' : '0 4px 1px',
    fontWeight: 'bold',
    fontSize: query.matches ? '0.95rem' : '0.8rem',
    // fontFamily: 'Lato',
    // fontStyle: 'normal',
    borderRadius: '4px',
    marginRight: '10px'
  },
  // [Inline.HIGHLIGHT]: {
  //   backgroundColor: 'yellow',
  // },
  // [Inline.CODE]: {
  //   fontFamily: 'Consolas, "Liberation Mono", Menlo, Courier, monospace',
  //   margin: '4px 0',
  //   fontSize: '0.9em',
  //   padding: '1px 3px',
  //   color: '#555',
  //   backgroundColor: '#fcfcfc',
  //   border: '1px solid #ccc',
  //   borderBottomColor: '#bbb',
  //   borderRadius: 3,
  //   boxShadow: 'inset 0 -1px 0 #bbb',
  // },
};

export default customStyleMap;

// const EncryptedStyle: CSSProperties = {
//   filter: 'blur(3px)',
//   WebkitFilter: 'blur(3px)'
// }
//
// const CanDecryptStyle = useMemo((): CSSProperties => ({
//   minHeight: minWidth_2200 ? '24px' : '19px',
//   color: 'var(--colorCanDecrypt)',
//   background: 'var(--backgroundEncryptedMention)',
//   padding: minWidth_2200 ? '0 4px 3px' : '0 4px 1px',
//   fontWeight: 'bold',
//   fontSize: minWidth_2200 ? '0.95rem' : '0.8rem',
//   // fontFamily: 'Lato',
//   // fontStyle: 'normal',
//   borderRadius: '4px',
//   marginRight: '10px'
// }), [minWidth_2200])
