import React, { useEffect } from 'react'

import cn from 'classnames'

import { useGetElementSize } from 'utils/hooks'

import { IIpfsFiles } from 'models'

import styles from './styles.module.scss'

interface IProps {
  files?: IIpfsFiles[]
  fileIndex?: number
  file?: IIpfsFiles
  postOnX: boolean
  text: string
}

export const FileWarningOverlay: React.FC<IProps> = ({
  files = [],
  fileIndex = 0,
  file,
  postOnX,
  text,
}) => {
  const { ref, height: imageBlockHeight, resize } = useGetElementSize()

  useEffect(() => {
    resize()
  }, [files?.length, resize])

  return (
    <div
      className={cn({
        [styles['fileWarningOverlay']]: true,
        [styles['fileWarningOverlay_show']]:
          (files?.[fileIndex] || file)?.ExceedsTwitterLimits && postOnX,
        [styles['fileWarningOverlay_small']]:
          files?.length > 2 && imageBlockHeight < 130,
      })}
      ref={ref}
    >
      <p className={styles['text']}>{text}</p>
    </div>
  )
}
