import React, { useState, useEffect, useRef, CSSProperties } from 'react'
import { TransitionGroup, CSSTransition, Transition } from 'react-transition-group'
import cn from 'classnames'
import { getSelectedBlockNode } from '../../util/selection'
import { ImageButton } from './ImageButton'
import { VideoButton } from './VideoButton'
import { EmbedButton } from './EmbedButton'
import Draft, { EditorState } from 'draft-js'
import { ImageEditorPlugin } from '@draft-js-plugins/image'
import { VideoPlugin } from '@draft-js-plugins/video/lib/createVideoPlugin'
import { Block } from '../../util/constants'

import { ReactComponent as PlusIcon } from 'sources/images/plus.svg'

import styles from './styles.module.scss'
import { EmbedLinkInput } from './EmbedLinkInput'

interface IAddButtonProps {
  editorState: EditorState,
  focus: () => void;
  getEditorState: () => EditorState;
  setEditorState: (editorState: EditorState) => void;
  imagePlugin: ImageEditorPlugin
  videoPlugin: VideoPlugin
  fullSizeStyle?: boolean
  placeholderH1?: boolean
}


const defaultTransitionStyle = (isOpen: boolean) => ({
  transition: `opacity ${isOpen ? 500 : 0}ms ease-in-out`,
  opacity: 0,
  display: 'inline-block'
})

const transitionStyles = {
  entering: { opacity: 0, visibility: 'hidden' },
  entered: { opacity: 1 },
  exiting: { opacity: 1 },
  exited: { opacity: 0, visibility: 'hidden' }
}

const sideButtons = [
  {
    title: 'Image',
    component: ImageButton
  }, {
    title: 'Video',
    component: VideoButton
  },
  {
    title: 'Embed',
    component: EmbedButton
  }
  //   {
  //   title: 'Separator',
  //   component: SeparatorSideButton,
  // }
]

export const SideAddButton: React.FC<IAddButtonProps> = (
  {
    editorState,
    focus,
    getEditorState,
    setEditorState,
    imagePlugin,
    videoPlugin,
    fullSizeStyle,
    placeholderH1
  }) => {
  const [style, setStyle] = useState<CSSProperties>({})
  const [visible, setVisible] = useState(false)
  const [visibleEmbedLinkInput, setVisibleEmbedLinkInput] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const nodeRef = useRef<HTMLDivElement | null>(null)
  const blockKeyRef = useRef<string>('')
  const blockTypeRef = useRef<string>('')

  const makeCenter = placeholderH1 ? -3 : 7
  const makeCenterRef = useRef<number>(makeCenter)
  makeCenterRef.current = makeCenter
  const makeCenterAfterDeleteMedia = placeholderH1 ? -4 : 6


  useEffect(() => {
    const contentState = editorState.getCurrentContent()
    const selectionState = editorState.getSelection()

    if (
      !selectionState.isCollapsed() ||
      selectionState.getAnchorKey() !== selectionState.getFocusKey() ||
      contentState.getBlockForKey(selectionState.getAnchorKey()).getType().indexOf('atomic') >= 0
    ) {
      hideBlock()
      return
    }

    const block = contentState.getBlockForKey(selectionState.getAnchorKey())
    const blockKey = block.getKey()

    if (block.getLength() > 0) {
      hideBlock()
      return
    }

    if (block.getType() === Block.BLOCKQUOTE) {
      hideBlock()
      return
    }

    if (block.getType() !== blockTypeRef.current) {
      blockTypeRef.current = block.getType()
      if (block.getLength() === 0) {
        setTimeout(findNode, 50)
      }
      blockKeyRef.current = blockKey
      return
    }

    if (blockKeyRef.current === blockKey) {
      if (block.getLength() > 0) {
        hideBlock()
      } else {
        setVisible(true)

        // Need when delete media
        if (nodeRef.current) {
          const newTop = nodeRef.current.offsetTop - makeCenterAfterDeleteMedia
          if (newTop === style.top) return
          setStyle({
            top: nodeRef.current.offsetTop - makeCenter
          })
        }
      }
      return
    }

    blockKeyRef.current = blockKey

    if (block.getLength() > 0) {
      hideBlock()
      return
    }

    setTimeout(findNode, 50)
  }, [editorState])

  const isFirstRender = useRef<boolean>(true)
  // keep watch
  useEffect(() => {
    if (isFirstRender.current) {
      setTimeout(() => {
        findNodeAnyCase()
      }, 0)
      isFirstRender.current = false
    } else {
      findNodeAnyCase()
    }
  }, [fullSizeStyle, placeholderH1])

  const findNodeAnyCase = () => {
    focus()
    setTimeout(() => {
      if (visible && nodeRef.current) {
        setStyle({
          top: nodeRef.current.offsetTop -  makeCenterRef.current
        })
      }
    }, 100)
  }

  const findNode = () => {
    const node = getSelectedBlockNode(window)
    if (node === nodeRef.current) {
      return
    }
    if (!node) {
      hideBlock()
      return
    }
    nodeRef.current = node
    if (isOpen) {
      setIsOpen(false)
      setVisibleEmbedLinkInput(false)
    }
    setStyle({
      top: node.offsetTop -  makeCenterRef.current
    })
    setVisible(true)
  }

  const hideBlock = () => {
    if (visible) {
      setVisible(false)
      setIsOpen(false)
      setVisibleEmbedLinkInput(false)
    }
  }

  const openToolbar = (e?: React.MouseEvent<HTMLButtonElement | MouseEvent>) => {
    if (e) {
      e.stopPropagation()
    }

    if (isOpen) {
      setVisibleEmbedLinkInput(false)
    }

    setIsOpen(prevIsOpen => !prevIsOpen)
  }

  const openEmbedLinkInput = () => {
    setVisibleEmbedLinkInput(true)
  }

  if (!visible) {
    return null
  }

  return (
    <div className={cn({
      [styles.sideToolbar]: true,
      [styles.sideToolbarOpenEmbedInput]: isOpen && visibleEmbedLinkInput
    })} style={style}>
      <button
        onClick={openToolbar}
        className={cn({
          [styles.sideButton]: true,
          [styles.sideButtonOpen]: isOpen
        })}
        type="button"
      >
        <PlusIcon/>
      </button>
      {visibleEmbedLinkInput && isOpen
        ? <EmbedLinkInput getEditorState={getEditorState}
                          setEditorState={setEditorState}
                          close={openToolbar}/>
        : <>
          {sideButtons.map((button, idx) => {
            const Button = button.component
            return (
              <Transition in={isOpen}
                          key={button.title}
                          unmountOnExit
                          timeout={{
                            enter: 50 * idx,
                            exit: 100,
                            appear: 500
                          }}>
                {(state) => (
                  <div
                    style={{
                      ...defaultTransitionStyle(isOpen),
                      // @ts-ignore
                      ...transitionStyles[state]
                    }}
                  >
                    <Button
                      imagePlugin={imagePlugin}
                      videoPlugin={videoPlugin}
                      getEditorState={getEditorState}
                      setEditorState={setEditorState}
                      close={openToolbar}
                      openEmbedLinkInput={openEmbedLinkInput}
                    />

                  </div>
                )}
              </Transition>
            )
          })}
        </>
      }
    </div>
  )
}
