import React, { memo, useEffect, useRef, useState } from 'react'
import { Viewer } from '@photo-sphere-viewer/core'
import { ReactPhotoSphereViewer } from 'react-photo-sphere-viewer'
import { GyroscopePlugin } from '@photo-sphere-viewer/gyroscope-plugin'
import classNames from 'classnames'

import { IconButton } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { isMobile } from '../../utils/functions/mobile'
import { IIpfsFiles } from '../../models/user'
import { Fullscreen } from '@mui/icons-material'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'

import styles from './styles.module.scss'

interface IProps {
  url: string
  iconButtonStyle: React.CSSProperties
  closeModal: () => void
  openMode: 'modal' | 'block'
  imagePosition?: IIpfsFiles['Image360Position'] | null
  onRotateOnCreate?: (position: IIpfsFiles['Image360Position']) => void
}

const View360New: React.FC<IProps> = memo(
  ({
    url,
    iconButtonStyle,
    closeModal,
    openMode,
    imagePosition,
    onRotateOnCreate,
  }) => {
    const photoSphereRef = React.createRef<any>()
    const viewerRef = useRef<any>(null)

    const initialReadyRef = useRef<boolean>(false)

    useEffect(() => {
      return () => {
        initialReadyRef.current = false
      }
    })

    const isOnMobile = isMobile()

    const toggleFullscreen = () => {
      photoSphereRef?.current?.toggleFullscreen?.()
    }

    const zoomIn = () => {
      photoSphereRef?.current?.zoom(
        photoSphereRef?.current?.getZoomLevel() + 25
      )
    }

    const zoomOut = () => {
      photoSphereRef?.current?.zoom(
        photoSphereRef?.current?.getZoomLevel() - 25
      )
    }

    const onReady = () => {
      initialReadyRef.current = true

      if (imagePosition) {
        const yaw = imagePosition?.yaw || (imagePosition as any)?.Yaw || 0
        const pitch = imagePosition?.pitch || (imagePosition as any)?.Pitch || 0

        photoSphereRef?.current?.rotate({ yaw, pitch })
      }
    }

    const onRotate = (pitch: number, yaw: number) => {
      if (initialReadyRef?.current) {
        onRotateOnCreate?.({ yaw, pitch })
      }
    }

    const [supportsGyroscope, setSupportsGyroscope] = useState(false)

    useEffect(() => {
      const checkGyroscopeSupport = async () => {
        if (window?.DeviceOrientationEvent) {
          if (
            typeof (window?.DeviceOrientationEvent as any)
              ?.requestPermission === 'function'
          ) {
            try {
              const response = await (
                window?.DeviceOrientationEvent as any
              )?.requestPermission?.()
              if (response === 'granted') {
                setSupportsGyroscope(true)
              }
            } catch (error) {
              console.error(
                'Error requesting DeviceOrientationEvent permission:',
                error
              )
            }
          } else {
            setSupportsGyroscope(true)
          }
        }
      }

      if (isOnMobile) {
        checkGyroscopeSupport()
      }
    }, [isOnMobile])

    useEffect(() => {
      if (isOnMobile) {
        const yaw = imagePosition?.yaw || (imagePosition as any)?.Yaw || 0
        const pitch = imagePosition?.pitch || (imagePosition as any)?.Pitch || 0

        const viewer = new Viewer({
          container: viewerRef.current,
          panorama: url,
          plugins: supportsGyroscope
            ? [
                [
                  GyroscopePlugin,
                  {
                    moveMode: 'smooth',
                  },
                ],
              ]
            : [],
          defaultZoomLvl: 50,
          defaultYaw: yaw,
          defaultPitch: pitch,
        })

        if (onRotateOnCreate) {
          viewer.addEventListener(
            'ready',
            () => {
              initialReadyRef.current = true
            },
            { once: true }
          )

          viewer.addEventListener('position-updated', ({ position }: any) => {
            onRotate(position?.pitch, position?.yaw)
          })
        }

        return () => {
          viewer.destroy()
        }
      }
    }, [supportsGyroscope, isOnMobile, onRotateOnCreate])

    return (
      <div
        className={classNames({
          [styles['reactPhotoSphereViewer']]: true,
          [styles['reactPhotoSphereViewer_block']]: openMode === 'block',
        })}
      >
        {!isOnMobile ? (
          <ReactPhotoSphereViewer
            ref={photoSphereRef}
            src={url}
            height={openMode === 'modal' ? '90vh' : '70vh'}
            width={'100%'}
            onReady={onReady}
            {...(!isOnMobile ? { navbar: false } : {})}
            {...(onRotateOnCreate ? { onPositionChange: onRotate } : {})}
          />
        ) : (
          <div ref={viewerRef} style={{ width: '100%', height: '70vh' }} />
        )}

        <div>
          {openMode === 'modal' && (
            <div className={styles.closeIconBox}>
              <IconButton
                disableRipple
                onClick={closeModal}
                sx={{ ...iconButtonStyle, marginTop: 0 }}
              >
                <ClearIcon style={{ color: '#2e2b63', fontSize: '20px' }} />
              </IconButton>
            </div>
          )}
          {!isOnMobile && (
            <div className={styles.controls}>
              {/* <IconButton
                disableRipple
                onClick={toggleCompass}
                sx={iconButtonStyle}
              >
                {isCompassActive ? (
                    <AdjustIcon style={{ color: '#2e2b63', fontSize: '22px' }} />
                ) : (
                    <ExploreOutlined style={{ color: '#2e2b63', fontSize: '22px' }} />
                )}
              </IconButton>*/}
              <IconButton
                disableRipple
                onClick={toggleFullscreen}
                sx={iconButtonStyle}
              >
                <Fullscreen style={{ color: '#2e2b63', fontSize: '24px' }} />
              </IconButton>
              <IconButton disableRipple onClick={zoomOut} sx={iconButtonStyle}>
                <RemoveIcon style={{ color: '#2e2b63', fontSize: '20px' }} />
              </IconButton>
              <IconButton onClick={zoomIn} sx={iconButtonStyle}>
                <AddIcon style={{ color: '#2e2b63', fontSize: '20px' }} />
              </IconButton>
            </div>
          )}
        </div>
      </div>
    )
  }
)

View360New.displayName = 'View360New'

export default View360New
