import { EditorState } from 'draft-js'
import { action, makeAutoObservable, observable } from 'mobx'

import { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag'

import {
  api,
  checkBadWords,
  checkNumberOfLineBreaks,
  getPostLengthFromEditorState,
  getPostTextFromEditorState,
} from '../utils'

import { toast } from '../App'

import { IActiveRoomData } from '../models'

import LoadingStore from 'store/loading'

class VideoChat {
  chatRooms: string[] = []
  activeRoom: string = ''
  activeRoomData: IActiveRoomData | null = null

  constructor() {
    makeAutoObservable(this)
  }

  setChatRooms(data: string[]) {
    this.chatRooms = data
  }

  setActiveRoom(room: string) {
    this.activeRoom = room
  }

  setActiveRoomData(roomData: IActiveRoomData | null) {
    this.activeRoomData = roomData
  }

  async checkRoom(preferences: string) {
    const response = await api.get(`/api/Room/checkRoom`, {
      params: {
        preferences,
      },
    })

    // if (mainPost.status !== 200 && mainPost.status !== 400) {
    if (response.status !== 200 && response.status !== 404)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )

    return response.data
  }

  async createRoom(roomId: string, preferences: string) {
    const response = await api.post(`/api/Room/createRoom`, null, {
      params: {
        roomId,
        preferences,
      },
    })
    if (response.status !== 201)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async updateRoom(roomId: string) {
    const response = await api.put(`/api/Room/updateRoom`, null, {
      params: {
        roomId,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async leaveRoom(roomId: string) {
    const response = await api.get(`/api/Room/leaveRoom`, {
      params: {
        roomId,
      },
    })
    // if (response.status !== 200)
    //   throw new Error(
    //     response.data && response.data.Description
    //       ? response.data.Description
    //       : 'Some error'
    //   )
    return response.data
  }

  async addCheckUserAnswer(roomId: string, answer: boolean | null) {
    const response = await api.post(`api/Room/addUserAnswer`, null, {
      params: { roomId, answer },
    })

    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async getAllCategories() {
    const response = await api.get(`api/Room/getAllCategories`)
    if (response.status !== 200)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  async addCategory(name: string) {
    const response = await api.post(`/api/Room/addCategory`, null, {
      params: { name },
    })

    if (response.status !== 201)
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    return response.data
  }

  checkVideoTitleDescription = (
    editorState: EditorState,
    type: 'title' | 'description'
  ) => {
    const textLength = getPostLengthFromEditorState(editorState)
    const text = getPostTextFromEditorState(editorState)

    if (textLength < 1) {
      toast({
        type: 'warning',
        message: `There is no ${
          type === 'title' ? 'title' : 'description'
        } for the video`,
      })
      return true
    }

    if (type === 'title' && textLength > 200) {
      toast({
        type: 'warning',
        message:
          'The number of characters in the title exceed the limit of 200',
      })
      return true
    }

    if (type === 'description' && textLength > 500) {
      toast({
        type: 'warning',
        message:
          'The number of characters in the description exceed the limit of 500',
      })
      return true
    }

    if (textLength <= (text.match(/ /g) || []).length) {
      toast({
        type: 'warning',
        message: `Invalid ${type === 'title' ? 'title' : 'description'}!`,
      })
      return true
    }

    if (checkBadWords(text)) {
      toast({
        type: 'warning',
        message: 'You are not allowed to use prohibited words',
      })
      return true
    }

    if (
      type === 'description' &&
      new Set(extractHashtagsWithIndices(text).map(item => item.hashtag)).size <
        1
    ) {
      toast({
        type: 'warning',
        message: 'The description must contain at least 1 hashtag',
      })
      return true
    }

    if (checkNumberOfLineBreaks(text)) {
      toast({
        type: 'warning',
        message: 'You are not allowed to use more than 2 line breaks together',
      })
      return true
    }

    return false
  }
}

export default new VideoChat()
