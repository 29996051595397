import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import { HYPERLINK } from '../../../util/constants'

import styles from '../styles.module.scss'

interface StyleButtonProps {
  onToggle: (style: string) => void;
  style: string;
  active: boolean;
  icon?: string;
  label: string | JSX.Element | object;
  description: string;
  onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export const StyleButton: FC<StyleButtonProps> = (
  { onToggle, onClick, style, icon, label, description, active }) => {

  const onToggleHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (onClick) {
      onClick(e)
    }

    if (style) {
      e.preventDefault()
      onToggle(style)
    }
  }

  if (style === HYPERLINK) {
    return null
  }

  return (
    <span
      className={cn({
        [styles.hinTop]: true,
        [styles.styleButton]: true,
        [styles.styleButtonActive]: active,
        [styles.styleButtonBold]: style.toLowerCase() === 'bold',
        [styles.styleButtonItalic]: style.toLowerCase() === 'italic',
        [styles.styleButtonUnderline]: style.toLowerCase() === 'underline',
        [styles.styleButtonStrikethrough]: style.toLowerCase() === 'strikethrough'
      })}
      onMouseDown={onToggleHandler}
      aria-label={description}
    >
        {<>{label}</>}
      </span>
  )
}
