import React, { ChangeEvent, FC } from 'react'

import cn from 'classnames'

import { ReactComponent as CompanyIcon } from 'sources/images/companyIcon.svg'

import styles from './styles.module.scss'

interface IProps {
  rewardPrice: string
  onChangeRewardPrice: (value: string) => void
  disabled?: boolean
}

export const SocialPostRewardPrice: FC<IProps> = ({
  rewardPrice,
  onChangeRewardPrice,
  disabled,
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target
    onChangeRewardPrice(value)
  }

  if (disabled) {
    return null
  }

  return (
    <div
      className={cn(styles.priceContainer, {
        [styles.priceContainer_disabled]: disabled,
      })}
    >
      <div className={styles.title}>Reward price:</div>
      <div className={styles.inputBlock}>
        <input
          step="0.1"
          type="number"
          value={rewardPrice}
          onChange={onChange}
          className={styles.input}
          disabled={disabled}
        />
        <CompanyIcon />
      </div>
    </div>
  )
}

SocialPostRewardPrice.displayName = 'SocialPostRewardPrice'
