import { EditorState } from 'draft-js'

import { createPostFileType } from 'utils/const'

import { VideoPostType } from 'store/createPost'

import { IComment } from './comments'
import {
  ISocialPostInfo,
  ITopPublication,
  IWidgetPostInfo,
  PostSource,
} from './publication'

export interface ISignUp {
  nickname: string
  invitationCode?: string
  firstName?: string
  lastName?: string
  dateOfBirth: Date | null
  email?: string
  saveTwitterInfoId?: string
}

export interface IUserProfile {
  FirstName: string
  Id: string
  ImageLink: string
  IsAuthenticatorConnected: boolean
  IsPayedForTwoFactor: boolean
  IsSMSEnabled: boolean
  LastName: string
  NickName: string
  OpenSeaLink: string
  PhoneNumber: string
  TwoFactorEnabled?: boolean
  Wallet: string
}

export interface ILoginResponse {
  Item1: IUser
  Item2: string //Token
}

export enum UserRole {
  SuperAdmin,
  Admin,
  Moderator,
  User,
}

export interface IUser {
  AbleToChangeNickname: boolean
  Activated?: boolean
  ApprovedAmount: number
  AuthencityBalance: number
  BackedCount: number
  DateOfBirth: string
  DateTimeOfRegistration?: string
  DiscordLink: string
  Email: string
  EyeColor: string
  FacebookLink: string
  FirstName: string
  Follow: boolean
  GitLink: string
  Hometown: string
  Id: string
  ImageLink: string | null
  IsAppleConnected: boolean
  DeletionMark?: boolean
  InstagramLink: string
  IsAuthenticatorConnected: boolean
  IsGoogleConnected: boolean
  IsPayedForTwoFactor: boolean
  IsSMSEnabled: boolean
  IsStreaming: boolean
  IsTwitterConnected: boolean
  LastName: string
  LinkedInLink: string
  NickName: string
  OpenSeaLink: string
  PhoneNumber: string
  PublicEmail: string
  RedditLink: string
  RegistrationHash: string
  RewardPercent: number
  Role: UserRole
  TelegramLink: string
  TwitterId: string
  TwitterLink: string
  TwitterName: string
  TwoFactorEnabled?: boolean
  UserBIO: string
  UserGender: number | null
  Wallet: string
  Website: string
}

export interface I2FAResponseUser {
  Code: string
  FirstName: string
  Id: string
  ImageLink: string
  IsAuthenticatorConnected: boolean
  IsSMSEnabled: boolean
  LastName: string
  NickName: string
  PhoneNumberForTwoFactor: string
}

export interface ISendPublication {
  BlockchainId?: number
  EditorData: EditorState
  EncryptedData?: string
  Id: string
  IsEncrypted?: boolean
  IsAdultContent?: boolean
  IsThread?: boolean
  IpfsFiles: IIpfsFiles[]
  PublicationId?: number
  TaggedNickname?: string
  Text?: string
  ThreadMainMessage?: number
  ThreadNumber?: number
  UserId?: string
  IsSuperpost?: boolean
  PublicText?: EditorState
  PriceToOpenPost?: number
  IsBlogpost?: boolean
  BlogpostTitle?: EditorState
  BlogpostDescription?: EditorState
  BlogpostPreviewLink?: string
  BlogpostPreviewLinkCdn?: string
  IsStreampost?: boolean
  PostOnX?: boolean
  PostSource?: PostSource
  IsXPost?: boolean
  XPostInfo?: ISocialPostInfo | null
}

export interface ISendVideoPublication extends ISendPublication {
  IsDefault: boolean // For localstorage functionality
  PostType: VideoPostType // For localstorage functionality
}

// export interface ISendPublication {
//   Text: string
//   Id: string
//   IpfsFiles: IIpfsFiles[]
//   UserId?: string
//   BlockchainId?: number
//   ThreadMainMessage?: number
//   ThreadNumber?: number
//   IsThread?: boolean
// }

export interface IPublication {
  Activated: boolean
  BackedCount: number
  Bid: IBid | null
  BlockchainId: number
  BlogpostInfo: IBlogpostInfo
  CanDecrypt: string
  Comment: IComment | null
  CommentCount: number
  CreatorId: string
  CreatorWallet: string
  DateCreation: string
  Id: number
  ImageLink: string | null
  IpfsFiles: IIpfsFiles[]
  IsAdultContent: boolean
  IsBlogpost: boolean
  IsEncrypted: boolean
  IsLiveStream: boolean // live stream view
  IsSuperpost: boolean
  IsStreampost: boolean // post from stream
  IsStreaming: boolean // user streaming now
  IsThread: boolean
  Favorite: boolean
  FirstName?: string
  Follow: boolean
  LastName?: string
  LikeCount: number
  Liked: boolean
  NetworkType: number
  NickName: string
  OpenSeaLink: string
  OwnerActivated: boolean
  OwnerFirstName: string | null
  OwnerImageLink: string | null
  OwnerLastName: string | null
  OwnerNickName: string | null
  OwnerOpenSeaLink: string | null
  OwnerWallet: string | null
  PublicationKey: string | null
  Pinned: boolean
  PriceToOpenPost: number | null
  Retweet: boolean
  RetweetCount: number
  RetweetData: IRetweet
  SoldCount: number
  SoldPrice: number | null
  SuperpostInfo: ISuperPostInfo
  StreamInfo: any
  TagDTOs: ITagDTOs[]
  Text: string
  ThreadMainMessage: number | null
  ThreadNumber: number
  TweetLink?: string
  UserId: string
  ViewsCount: number
  VideoId: number
  Comments?: IComment[] // this is in response with publications
  DeletionMark?: boolean
  IsWidgetPost: boolean
  WidgetPostInfo: IWidgetPostInfo
  IsOpenedByRecipient: boolean // if encrypted message is opened
  PostSource?: PostSource
  IsXPost?: boolean
  XPostInfo?: ISocialPostInfo
}

export interface IRetweet {
  Activated: boolean
  BackedCount: number
  BlogpostInfo: IBlogpostInfo
  CanDecrypt: string
  CreatorId: string
  DateCreation: string
  FirstName: string
  Id: number
  ImageLink: string
  IpfsFiles: IIpfsFiles[]
  IsEncrypted: boolean
  IsLiveStream: boolean // live stream view
  IsStreampost: boolean // post from stream
  IsAdultContent: boolean
  IsBlogpost: boolean
  IsSuperpost: boolean
  IsStreaming: boolean // user streaming now
  LastName: string
  NickName: string
  OpenSeaLink: string
  OwnerActivated: boolean
  OwnerFirstName: string
  OwnerImageLink: string
  OwnerLastName: string
  OwnerNickName: string
  OwnerOpenSeaLink: string
  OwnerWallet: string | null
  PriceToOpenPost: number | null
  SoldPrice: number | null
  SoldCount: number
  SuperpostInfo: ISuperPostInfo
  StreamInfo: any
  TagDTOs: ITagDTOs[]
  Text: string
  UserId: string
  ViewsCount: number
  IsWidgetPost: boolean
  WidgetPostInfo: IWidgetPostInfo
  NetworkType: number
}

export interface IBid {
  BidCreatorId: string
  CreatedAt: string
  Id: number
  IsCompleted: boolean
  Price: number
  PublicationId: number
  UserInfo: IBidUserInfo
}

export interface IBidUserInfo {
  FirstName: string
  LastName: string
  ImageLink: string
  NickName: string
  OpenSeaLink: null | string
  Wallet: string
}

export interface IUserInfo {
  Activated: boolean
  DateTimeOfRegistration: string
  FirstName: string
  Id: string
  ImageLink: null | string
  IsStreaming: boolean
  LastName: string
  NickName: string
  OpenSeaLink: null | string
  Wallet: string
  DeletionMark: boolean
}

export interface IUserFeed {
  Activated: boolean
  DateTimeOfRegistration: string
  FirstName: string
  BackedCount: number
  LastName: string
  ImageLink: string
  NickName: string
  OpenSeaLink: null | string
  Wallet: string
  IsStreaming: boolean
}

export interface IUserShort {
  Id: string
  Activated: boolean
  FirstName: string
  LastName: string
  NickName: string
  ImageLink: null | string
  OpenSeaLink: null | string
}

export interface IBackingFromInfo extends IUserShort {}

export interface IBackingToInfo {
  Activated: boolean
  FirstName: string
  LastName: string
  NickName: string
  ImageLink: string
  OpenSeaLink: null | string
  BackedToPublicationInfo: null | IBackingToPublicationInfo
  IsStreaming: boolean
}

export interface IBackingToPublicationInfo {
  PublicationId: number
  PublicationText: string
}

export interface IBackingFeed {
  CreatedAt: string
  Amount: number
  BackedFromInfo: IBackingFromInfo
  BackedToInfo: IBackingToInfo
  FullAmount: number
}

export interface IIpfsFiles {
  Id: string
  FileLink: string
  FileType: createPostFileType
  FileExtension: string
  CdnFileLink?: string | null
  VideoPreview?: string
  DefaultVideoPreview?: string
  Duration?: number
  Size?: number
  VideoAudioTitle?: EditorState
  VideoAudioDescription?: EditorState
  Is360Image?: boolean
  Is3DImage?: boolean
  IsSpatialVideo?: boolean
  IsFileChecked?: boolean
  WaveFormInfo?: string | null
  Image360Position?: { yaw: number; pitch: number } | null
  ExceedsTwitterLimits?: boolean
}

export interface ITagDTOs {
  Id: number
  PublicationTag: string
  Count: number
}

export interface ILiveFeed {
  Activated: boolean
  CanDecrypt: string
  DateCreation: string
  FirstName: string
  Id: number
  ImageLink: string | null
  IsEncrypted: boolean
  LastName: string
  NetworkType: number
  NickName: string
  PublicationKey: string
  Text: string
  UserId: string
  OpenSeaLink: string | null
  Follow: boolean
}

export interface IUserStatistic {
  AdditionalStatistic: {
    TotalBackedPostsByProfileAuthCount: number
    TotalBackedPostsByProfileCount: number
    TotalBackedProfilesByProfileAuthCount: number
    TotalBackedProfilesByProfileCount: number
  }
  ForGraph: {
    PostBackedAuthTotal: number[]
    PostCreatedCount: number[]
    ProfileBackedAuthTotal: number[]
    Labels: number[]
  }
  PostStatistic: {
    FromProfile: {
      PostCreatedPerDay: number
      PostCreatedPerMonth: number
      PostCreatedPerWeek: number
      PostCreatedTotal: number
    }
    ToProfile: {
      PostBackAuthPerDay: number
      PostBackAuthPerMonth: number
      PostBackAuthPerWeek: number
      PostBackAuthTotal: number
      PostBackersCount: number
    }
  }
  ProfileStatistic: {
    FromProfile: {
      ProfileCommentsCount: number
      ProfileFollowsCount: number
      ProfileLikesCount: number
    }
    ToProfile: {
      ProfileBackAuthPerDay: number
      ProfileBackAuthPerMonth: number
      ProfileBackAuthPerWeek: number
      ProfileBackAuthTotal: number
      ProfileBackersCount: number
      ProfileCommentedCount: number
      ProfileFollowedCount: number
      ProfileLikedCount: number
    }
  }
  TopBackedPosts: ITopPublication[]
  TopCommentedPosts: ITopPublication[]
  TopLikedPosts: ITopPublication[]
}

export interface IUserStatisticShort {
  BackedBy: number
  BackersCount: number
  FollowingCount: number
  Id: string
  PostPerDay: number
  PostPerMonth: number
  PostPerWeek: number
}

export interface IUserInfoByTag {
  BackProfileCount?: number
  FirstName: string
  FollowersCount?: number
  ImageLink: string | null
  LastName: string
  NickName: string
  OpenSeaLink: string | null
}

export interface IUserOpenedSuperpost extends IUserShort {
  Id: string
}

export interface ISuperPostInfo {
  IsOpened: boolean
  PublicText: string
  PriceToOpenPost: number
  OpenedBy: IUserOpenedSuperpost[]
  Has360Image: number
  Has3DFile: number
  HasImages: number
  HasVideos: number
  HasAudio: number
  HasGif: number
  HasFiles: number
}

export interface IBlogpostInfo {
  BlogpostDescription: string
  BlogpostPreviewLink: string
  BlogpostPreviewLinkCdn: string
  BlogpostTitle: string
}

export interface IUserOnline {
  socketID: string
  userID: string
  nickname: string
  isOnline: boolean
}

export interface IWelcomeInfo {
  Publication: IPublication
  XAuthorFullName: string
  XAuthorId: string
  XAuthorUsername: string
  XProfileImage: string
  XRewardPrice: number
}
