import React, { FC, useState } from 'react'

import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'

import { ICurrencyInfoByTag } from '../../models'

import { CurrencyBoxForTag } from './CurrencyBoxForTag'

import editorStyles from '../CustomEditor/editorStyles.module.scss'
import styles from './styles.module.scss'

interface IProps {
  children?: React.ReactElement
  currency: ICurrencyInfoByTag
}

export const CurrencyTooltip: FC<IProps> = ({ children, currency }) => {
  return (
    <Tooltip
      title={
        <CurrencyBoxForTag
          currencyData={currency}
          style={{ color: 'white', padding: '5px 0 7px' }}
        />
      }
      placement={'top'}
      arrow
      interactive
      classes={{
        popper: editorStyles.popper,
        popperArrow: editorStyles.popperArrow,
      }}
      enterTouchDelay={0}
      TransitionComponent={Zoom}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
          },
        },
      }}
    >
      {children ? (
        children
      ) : (
        <span className={styles.taggedNickname}>${currency?.name}</span>
      )}
    </Tooltip>
  )
}

CurrencyTooltip.displayName = 'CurrencyTooltip'
