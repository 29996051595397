import React, { FC, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'

import { extractHashtagsWithIndices } from '@draft-js-plugins/hashtag'

import { ViewTypeEditor } from 'utils'
import { getPostLengthFromEditorState, getPostTextFromEditorState } from 'utils'

import { toast } from 'App'
import Button from 'components/Button'
import ButtonIcon from 'components/ButtonIcon'
import { CustomEditor } from 'components/CustomEditor'
import Modal from 'components/Modal'
import { CloseIconButtonPWA } from 'componentsPWA/CloseIconButtonPWA'
import { ModalPWA } from 'componentsPWA/ModalPWA'

import { IBlogpostPreviewData } from 'models'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {
  isOpen: boolean
  onClose: () => void
  isFullSize: boolean
  onSaveBlogpostInDraft: (value: IBlogpostPreviewData) => void
  blogpostPreviewData: IBlogpostPreviewData
  setBlogpostPreviewData: (value: IBlogpostPreviewData) => void
  onCreateBlogpost: (value: IBlogpostPreviewData) => void
}

export const BlogpostPreviewMintModal: FC<IProps> = observer(
  ({
    isOpen,
    onClose,
    isFullSize,
    blogpostPreviewData,
    onCreateBlogpost,
    setBlogpostPreviewData,
    onSaveBlogpostInDraft,
  }) => {
    const { mediaQuery, main } = useStore()
    const [titleState, setTitleState] = useState(blogpostPreviewData.title)
    const [descriptionState, setDescriptionState] = useState(
      blogpostPreviewData.description
    )
    const [previewImageSrc, setPreviewImageSrc] = useState(
      blogpostPreviewData.preview
    )
    const inputImageRef = useRef<HTMLInputElement | null>(null)

    const getHashtags = (editorState: EditorState) => {
      return [
        ...new Set(
          extractHashtagsWithIndices(
            getPostTextFromEditorState(editorState)
          ).map(item => item.hashtag)
        ),
      ]
    }

    const handleChangeTitle = (editorState: EditorState) => {
      setTitleState(editorState)
    }

    const handleChangeDescription = (editorState: EditorState) => {
      setDescriptionState(editorState)
    }

    const uploadImage = () => {
      inputImageRef.current?.click()
    }

    const addImage = (e: any) => {
      if (e.target) {
        const file = e.target.files[0]

        if (file.type === 'image/png' || file.type === 'image/jpeg') {
          if (file.size > 52428800) {
            return toast({
              type: 'warning',
              message: 'Maximum image size limit is 50MB',
            })
          }

          const objectURL = URL.createObjectURL(file)
          setPreviewImageSrc(objectURL)
        } else {
          toast({
            type: 'warning',
            message: 'You can only use JPG, JPEG, PNG formats for images',
          })
        }
        e.target.value = ''
      }
    }

    const onMintBlogpost = async () => {
      if (!previewImageSrc) {
        return toast({
          type: 'warning',
          message: 'Add preview image',
        })
      }

      const blogpostTextLength = getPostLengthFromEditorState(titleState)

      if (blogpostTextLength === 0) {
        return toast({
          type: 'warning',
          message: 'Preview title is required',
        })
      }

      if (
        blogpostTextLength <=
        (getPostTextFromEditorState(titleState).match(/ /g) || []).length
      ) {
        return toast({
          type: 'warning',
          message: 'Invalid public text!',
        })
      }

      if (blogpostTextLength > 200) {
        return toast({
          type: 'warning',
          message:
            'The number of characters in the preview title exceed the limit of 200',
        })
      }

      if (getHashtags(descriptionState).length < 1) {
        return toast({
          type: 'warning',
          message: 'The description must contain at least 1 hashtag',
        })
      }
      const blogpostDescriptionLength =
        getPostLengthFromEditorState(descriptionState)

      if (blogpostDescriptionLength > 500) {
        return toast({
          type: 'warning',
          message:
            'The number of characters in the preview description exceed the limit of 500',
        })
      }

      const previewData = {
        preview: previewImageSrc,
        previewCdn: previewImageSrc,
        title: titleState,
        description: descriptionState,
      }
      onCreateBlogpost(previewData)
    }

    const handleClosePreviewModal = async () => {
      setBlogpostPreviewData({
        preview: previewImageSrc,
        previewCdn: previewImageSrc,
        title: titleState,
        description: descriptionState,
      })
      onClose()
    }

    const handleSaveBlogpost = async () => {
      const previewData = {
        preview: previewImageSrc,
        previewCdn: previewImageSrc,
        title: titleState,
        description: descriptionState,
      }
      setBlogpostPreviewData(previewData)
      onSaveBlogpostInDraft(previewData)
      onClose()
    }

    const titleCharactersQuantity = getPostLengthFromEditorState(titleState)
    const descriptionCharactersQuantity =
      getPostLengthFromEditorState(descriptionState)

    const actionsButtons = (
      <div className={styles.actionsButtonsBox}>
        <Button
          type="submit"
          style={{
            width: '100%',
            height: '50px',
            background:
              'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
            boxShadow: 'none',
          }}
          styleText={{ color: '#ffffff' }}
          btnStyle="classic"
          text="Mint"
          onClick={onMintBlogpost}
          blueHover
        />

        <button onClick={handleSaveBlogpost} className={styles.saveInDraft}>
          Save in draft
        </button>
      </div>
    )

    const content = (
      <div
        className={cn({
          [styles.blogpostPreviewModalWrapper]: true,
          [styles.modePWA]: main.isPWA,
        })}
      >
        <div className={styles.blogpostPreviewContainer}>
          <div className={styles.previewBox}>
            <div className={styles.title}>
              Preview<span>*</span>
            </div>
            <div className={styles.imageBox}>
              {previewImageSrc ? (
                <img src={previewImageSrc} alt="preview" />
              ) : null}
              <button className={styles.addPreviewBtn} onClick={uploadImage}>
                {previewImageSrc ? 'Change preview image' : 'Add preview image'}
              </button>
            </div>
          </div>

          <div className={styles.titleHashtagsWrapper}>
            <div className={styles.titleBox}>
              <div className={styles.title}>
                Preview title
                <span>*</span>
                <div
                  className={cn({
                    [styles.charactersQuantity]: true,
                    [styles.charactersQuantityError]:
                      titleCharactersQuantity > 200,
                  })}
                >
                  {titleCharactersQuantity}/200
                </div>
              </div>
              <CustomEditor
                viewType={ViewTypeEditor.editorWithoutAvatar}
                editorAutoHeight
                withoutToolbar
                placeholder={'Write a preview title'}
                editorState={titleState}
                setEditorState={handleChangeTitle}
                styleContainer={{ margin: 0 }}
              />
            </div>
            <div className={styles.hashtagsBox}>
              <div className={styles.title}>
                Preview description
                <span>*</span>
                <ButtonIcon
                  style={{
                    minWidth: mediaQuery.minWidth_2200 ? '18px' : '18px',
                    width: mediaQuery.minWidth_2200 ? '18px' : '18px',
                    height: mediaQuery.minWidth_2200 ? '18px' : '18px',
                    marginLeft: '5px',
                  }}
                  styleIcon={{
                    width: mediaQuery.minWidth_2200 ? '10px' : '8px',
                    height: mediaQuery.minWidth_2200 ? '10px' : '8px',
                  }}
                  icon={'info'}
                  tooltip="The description must contain at least 1 hashtag"
                  tooltipPosition="top-start"
                  bordered
                />
                <div
                  className={cn({
                    [styles.charactersQuantity]: true,
                    [styles.charactersQuantityError]:
                      descriptionCharactersQuantity > 500,
                  })}
                >
                  {descriptionCharactersQuantity}/500
                </div>
              </div>
              <CustomEditor
                viewType={ViewTypeEditor.editorWithoutAvatar}
                withoutToolbar
                placeholder={'Write a preview description'}
                editorState={descriptionState}
                setEditorState={handleChangeDescription}
                styleContainer={{ margin: 0 }}
              />
            </div>
          </div>
          {main.isPWA ? null : actionsButtons}
        </div>
        <input
          ref={inputImageRef}
          accept=".jpg, .jpeg, .png"
          style={{ display: 'none' }}
          type="file"
          onChange={addImage}
        />
      </div>
    )

    return (
      <>
        {main.isPWA ? (
          <ModalPWA
            open={isOpen}
            onClose={onClose}
            dialogMode
            fullScreen
            disableOutsideClick
            dialogTitle={<CloseIconButtonPWA onClose={onClose} />}
            dialogTitleSX={{ textAlign: 'start' }}
            dialogActions={actionsButtons}
          >
            {content}
          </ModalPWA>
        ) : (
          <Modal
            open={isOpen}
            contentStyle={{
              padding: '10px',
            }}
            blockOutsideClose
            closeButton
            onClose={handleClosePreviewModal}
          >
            {content}
          </Modal>
        )}
      </>
    )
  }
)
