import { useEffect } from 'react'

import { useDebouncedCallback } from 'use-debounce'

interface IProps {
  onReachBottom?: () => void
  onReachTop?: () => void
  onReachInitialHeight?: () => void
  onScroll?: () => void
}

export const useHandleScroll = (props: IProps) => {
  const initialWindowHeight = window.innerHeight

  const handleScroll = () => {
    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight
    const documentHeight = document.documentElement.scrollHeight

    const isAtBottom = Math.ceil(scrollTop + windowHeight) >= documentHeight
    const isAtTop = scrollTop === 0
    const isAtInitialHeight = scrollTop <= initialWindowHeight

    if (isAtBottom) {
      props?.onReachBottom?.()
    } else if (isAtTop) {
      props?.onReachTop?.()
    } else if (isAtInitialHeight) {
      props?.onReachInitialHeight?.()
    } else {
      props?.onScroll?.()
    }
  }

  const handleDebouncedScroll = useDebouncedCallback(handleScroll, 25)

  useEffect(() => {
    window.addEventListener('scroll', handleDebouncedScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleDebouncedScroll)
    }
  }, [])
}
