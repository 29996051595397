import React, { FC, ReactElement } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'

import cn from 'classnames'

import { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention'

import styles from './styles.module.scss'

export interface EntryComponentProps {
  className?: string

  onMouseDown(event: MouseEvent): void

  onMouseUp(event: MouseEvent): void

  onMouseEnter(event: MouseEvent): void

  role: string
  id: string
  'aria-selected'?: boolean | 'false' | 'true'
  theme?: MentionPluginTheme
  mention: MentionData
  isFocused: boolean
  searchValue?: string
}

export const CurrencyEntryComponent = (
  props: EntryComponentProps
): ReactElement => {
  const {
    mention,
    theme,
    searchValue, // eslint-disable-line @typescript-eslint/no-unused-vars
    isFocused, // eslint-disable-line @typescript-eslint/no-unused-vars
    className,
    ...parentProps
  } = props

  return (
    //@ts-ignore
    <div {...parentProps} className={cn(styles.currencyEntry, className)}>
      <div className={theme?.mentionSuggestionsEntryContainer}>
        <div className={theme?.mentionSuggestionsEntryContainerLeft}>
          <img
            alt="avatar"
            src={mention?.image}
            className={cn({
              [`${theme?.mentionSuggestionsEntryAvatar}`]: true,
              [`${theme?.mentionSuggestionsEntryAvatarEmpty}`]:
                !mention?.ImageLink,
            })}
            role="presentation"
          />
        </div>

        <div className={theme?.mentionSuggestionsEntryContainerRight}>
          <div className={theme?.mentionSuggestionsEntryName}>
            {mention?.name}
          </div>

          <div className={theme?.mentionSuggestionsEntryNickName}>
            {mention?.symbol.toUpperCase()}
          </div>
        </div>
        <div className={cn(styles.rate)}>${mention?.current_price || 0} </div>
      </div>
    </div>
  )
}
