import axios from 'axios'
import { action, makeAutoObservable, observable } from 'mobx'

import { api } from '../utils'

class Twitter {
  constructor() {
    makeAutoObservable(this)
  }

  async saveTwitterId(oauth_token: string, oauth_verifier: string) {
    const response = await api.get(
      `api/Twitter/saveTwitterId?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return response.data
  }

  async getListTwitterUserBackingInfo(nickname: string) {
    const response = await api.get(
      `api/Twitter/getListTwitterUserBackingInfo`,
      {
        params: {
          nickname,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async prepareTwitterInfo(twitternickname: string) {
    const response = await api.get(`api/Twitter/prepareTwitterInfo`, {
      params: {
        user: twitternickname,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }

    return response.data
  }

  async getTwitterUserBackingAmount(nickname: string) {
    const response = await api.get(
      `api/Twitter/getTwitterBackingAmount?nickname=${nickname}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return response.data
  }

  async subscribeToAutomaticMessages(
    oauth_token: string,
    oauth_verifier: string
  ) {
    const response = await api.get(`api/Twitter/unfollowTwitter`, {
      params: { oauth_token, oauth_verifier },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return response.data
  }

  async getXPostInfo(id: string) {
    const response = await api.get(`api/Twitter/getXPostInfo`, {
      params: { id },
    })

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return response.data
  }

  async getXPostInfoBrandBird(id: string) {
    // const response = await api.get(
    //   `https://api.brandbird.app/twitter/public/tweets/${id}`
    // )

    // if (response.status !== 200)
    //   throw new Error(
    //     response.data &&
    //       (response.data.Description || response.data.Title || 'Some error')
    //   )

    // return response.data

    return {
      success: true,
      tweet: {
        all: {
          __typename: 'Tweet',
          lang: 'en',
          favorite_count: 0,
          possibly_sensitive: false,
          created_at: '2025-02-20T15:55:49.000Z',
          display_text_range: [0, 36],
          entities: {
            hashtags: [],
            urls: [
              {
                display_url: 'authencity.io/post/18268',
                expanded_url: 'https://authencity.io/post/18268',
                indices: [13, 36],
                url: 'https://t.co/swfjZndTAf',
              },
            ],
            user_mentions: [],
            symbols: [],
            media: [
              {
                display_url: 'pic.x.com/v9dkZxohuo',
                expanded_url:
                  'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
                indices: [37, 60],
                url: 'https://t.co/v9dkZxohuo',
              },
            ],
          },
          id_str: '1892604076145189335',
          text: '⛰️ \n\nSource: https://t.co/swfjZndTAf https://t.co/v9dkZxohuo',
          user: {
            id_str: '1783786194297356289',
            name: 'Aндрій Андрій',
            profile_image_url_https:
              'https://pbs.twimg.com/profile_images/1783786232075427841/E7G_PONn_normal.png',
            screen_name: 'andryspam2020',
            verified: false,
            is_blue_verified: false,
            profile_image_shape: 'Circle',
          },
          edit_control: {
            edit_tweet_ids: ['1892604076145189335'],
            editable_until_msecs: '1740070549000',
            is_edit_eligible: true,
            edits_remaining: '5',
          },
          mediaDetails: [
            {
              display_url: 'pic.x.com/v9dkZxohuo',
              expanded_url:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              ext_media_availability: {
                status: 'Available',
              },
              indices: [37, 60],
              media_url_https:
                'https://pbs.twimg.com/media/GkPhUvfXgAA6ww2.jpg',
              original_info: {
                height: 3611,
                width: 5417,
                focus_rects: [
                  {
                    x: 0,
                    y: 577,
                    w: 5417,
                    h: 3034,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 3611,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 3168,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 1806,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 5417,
                    h: 3611,
                  },
                ],
              },
              sizes: {
                large: {
                  h: 1365,
                  resize: 'fit',
                  w: 2048,
                },
                medium: {
                  h: 800,
                  resize: 'fit',
                  w: 1200,
                },
                small: {
                  h: 453,
                  resize: 'fit',
                  w: 680,
                },
                thumb: {
                  h: 150,
                  resize: 'crop',
                  w: 150,
                },
              },
              type: 'photo',
              url: 'https://t.co/v9dkZxohuo',
            },
            {
              display_url: 'pic.x.com/v9dkZxohuo',
              expanded_url:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              ext_media_availability: {
                status: 'Available',
              },
              indices: [37, 60],
              media_url_https:
                'https://pbs.twimg.com/media/GkPhVfNWMAAOuKE.jpg',
              original_info: {
                height: 3611,
                width: 5417,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    w: 5417,
                    h: 3034,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 3611,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 3168,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 1806,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 5417,
                    h: 3611,
                  },
                ],
              },
              sizes: {
                large: {
                  h: 1365,
                  resize: 'fit',
                  w: 2048,
                },
                medium: {
                  h: 800,
                  resize: 'fit',
                  w: 1200,
                },
                small: {
                  h: 453,
                  resize: 'fit',
                  w: 680,
                },
                thumb: {
                  h: 150,
                  resize: 'crop',
                  w: 150,
                },
              },
              type: 'photo',
              url: 'https://t.co/v9dkZxohuo',
            },
            {
              display_url: 'pic.x.com/v9dkZxohuo',
              expanded_url:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              ext_media_availability: {
                status: 'Available',
              },
              indices: [37, 60],
              media_url_https:
                'https://pbs.twimg.com/media/GkPhWijWQAAnSh4.jpg',
              original_info: {
                height: 3611,
                width: 5417,
                focus_rects: [
                  {
                    x: 0,
                    y: 577,
                    w: 5417,
                    h: 3034,
                  },
                  {
                    x: 1038,
                    y: 0,
                    w: 3611,
                    h: 3611,
                  },
                  {
                    x: 1259,
                    y: 0,
                    w: 3168,
                    h: 3611,
                  },
                  {
                    x: 1940,
                    y: 0,
                    w: 1806,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 5417,
                    h: 3611,
                  },
                ],
              },
              sizes: {
                large: {
                  h: 1365,
                  resize: 'fit',
                  w: 2048,
                },
                medium: {
                  h: 800,
                  resize: 'fit',
                  w: 1200,
                },
                small: {
                  h: 453,
                  resize: 'fit',
                  w: 680,
                },
                thumb: {
                  h: 150,
                  resize: 'crop',
                  w: 150,
                },
              },
              type: 'photo',
              url: 'https://t.co/v9dkZxohuo',
            },
            {
              display_url: 'pic.x.com/v9dkZxohuo',
              expanded_url:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              ext_media_availability: {
                status: 'Available',
              },
              indices: [37, 60],
              media_url_https:
                'https://pbs.twimg.com/media/GkPhXSyWEAAh2wb.jpg',
              original_info: {
                height: 3611,
                width: 5417,
                focus_rects: [
                  {
                    x: 0,
                    y: 0,
                    w: 5417,
                    h: 3034,
                  },
                  {
                    x: 1580,
                    y: 0,
                    w: 3611,
                    h: 3611,
                  },
                  {
                    x: 1801,
                    y: 0,
                    w: 3168,
                    h: 3611,
                  },
                  {
                    x: 2482,
                    y: 0,
                    w: 1806,
                    h: 3611,
                  },
                  {
                    x: 0,
                    y: 0,
                    w: 5417,
                    h: 3611,
                  },
                ],
              },
              sizes: {
                large: {
                  h: 1365,
                  resize: 'fit',
                  w: 2048,
                },
                medium: {
                  h: 800,
                  resize: 'fit',
                  w: 1200,
                },
                small: {
                  h: 453,
                  resize: 'fit',
                  w: 680,
                },
                thumb: {
                  h: 150,
                  resize: 'crop',
                  w: 150,
                },
              },
              type: 'photo',
              url: 'https://t.co/v9dkZxohuo',
            },
          ],
          photos: [
            {
              backgroundColor: {
                red: 204,
                green: 214,
                blue: 221,
              },
              cropCandidates: [
                {
                  x: 0,
                  y: 577,
                  w: 5417,
                  h: 3034,
                },
                {
                  x: 0,
                  y: 0,
                  w: 3611,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 3168,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 1806,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 5417,
                  h: 3611,
                },
              ],
              expandedUrl:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              url: 'https://pbs.twimg.com/media/GkPhUvfXgAA6ww2.jpg',
              width: 5417,
              height: 3611,
            },
            {
              backgroundColor: {
                red: 204,
                green: 214,
                blue: 221,
              },
              cropCandidates: [
                {
                  x: 0,
                  y: 0,
                  w: 5417,
                  h: 3034,
                },
                {
                  x: 0,
                  y: 0,
                  w: 3611,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 3168,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 1806,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 5417,
                  h: 3611,
                },
              ],
              expandedUrl:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              url: 'https://pbs.twimg.com/media/GkPhVfNWMAAOuKE.jpg',
              width: 5417,
              height: 3611,
            },
            {
              backgroundColor: {
                red: 204,
                green: 214,
                blue: 221,
              },
              cropCandidates: [
                {
                  x: 0,
                  y: 577,
                  w: 5417,
                  h: 3034,
                },
                {
                  x: 1038,
                  y: 0,
                  w: 3611,
                  h: 3611,
                },
                {
                  x: 1259,
                  y: 0,
                  w: 3168,
                  h: 3611,
                },
                {
                  x: 1940,
                  y: 0,
                  w: 1806,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 5417,
                  h: 3611,
                },
              ],
              expandedUrl:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              url: 'https://pbs.twimg.com/media/GkPhWijWQAAnSh4.jpg',
              width: 5417,
              height: 3611,
            },
            {
              backgroundColor: {
                red: 204,
                green: 214,
                blue: 221,
              },
              cropCandidates: [
                {
                  x: 0,
                  y: 0,
                  w: 5417,
                  h: 3034,
                },
                {
                  x: 1580,
                  y: 0,
                  w: 3611,
                  h: 3611,
                },
                {
                  x: 1801,
                  y: 0,
                  w: 3168,
                  h: 3611,
                },
                {
                  x: 2482,
                  y: 0,
                  w: 1806,
                  h: 3611,
                },
                {
                  x: 0,
                  y: 0,
                  w: 5417,
                  h: 3611,
                },
              ],
              expandedUrl:
                'https://x.com/andryspam2020/status/1892604076145189335/photo/1',
              url: 'https://pbs.twimg.com/media/GkPhXSyWEAAh2wb.jpg',
              width: 5417,
              height: 3611,
            },
          ],
          conversation_count: 0,
          news_action_type: 'conversation',
          isEdited: false,
          isStaleEdit: false,
        },
        text: '⛰️ <br/><br/>Source: <span class=\'tweet_link\' style="color: #2DA0F1">authencity.io/post/18268</span>',
        likes: 1,
        replies: 0,
        createdAt: '3:55 PM · 20 Feb 2025',
        avatar:
          'https://pbs.twimg.com/profile_images/1783786232075427841/E7G_PONn_400x400.png',
        name: 'Aндрій Андрій',
        username: 'andryspam2020',
        verified: false,
        isBlueVerified: false,
        images: [
          'https://pbs.twimg.com/media/GkPhUvfXgAA6ww2.jpg',
          'https://pbs.twimg.com/media/GkPhVfNWMAAOuKE.jpg',
          'https://pbs.twimg.com/media/GkPhWijWQAAnSh4.jpg',
          'https://pbs.twimg.com/media/GkPhXSyWEAAh2wb.jpg',
        ],
        cardPreview: null,
      },
    }
  }
}

export default new Twitter()
