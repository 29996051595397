import { makeAutoObservable } from 'mobx'
import { number, string } from 'yup'

import { api } from '../utils'

import { ICurrencyInfoByTag } from '../models'

import { makePersistable } from 'mobx-persist-store'

import AuthTokenStore from './authToken'

class Coins {
  coins: ICurrencyInfoByTag[] = []

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'coins',
      properties: ['coins'],
      storage: window.localStorage,
      expireIn: 86400000,
    })
  }

  setCoinsExchangeRate(coins: ICurrencyInfoByTag[]) {
    this.coins = coins
    this.coins = coins.concat({
      id: 'AUTH-token',
      symbol: 'AUTH',
      name: 'Authencity',
      image: 'https://authencity.io/files/favicon.png',
      current_price: AuthTokenStore.AUTHPrice.USDPriceForAUTH,
    })
  }

  async getCoinsExchangeRate() {
    try {
      const response = await api.get(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd`
      )

      if (response.status !== 200) {
        throw new Error(
          response.data &&
            (response.data.Description || response.data.Title || '')
        )
      }

      return response.data
    } catch (error) {
      console.log('get coin exchange rate', error)
    }
  }

  async searchCurrencies(name: string): Promise<Array<ICurrencyInfoByTag>> {
    const startWithCoinName = this.coins?.filter(
      coin =>
        coin?.name?.toLowerCase()?.startsWith(name?.toLowerCase()) ||
        coin?.symbol?.toLowerCase()?.startsWith(name?.toLowerCase())
    )

    return startWithCoinName
  }
}

export default new Coins()
