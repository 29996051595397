import { EditorState } from 'draft-js'

import { createPostFileType } from '../utils'

import { IBlogpostInfo } from 'models'

import { IIpfsFiles, ISuperPostInfo } from './user'

export interface ISortParameters {
  sortBy: number
  orderByDescending: boolean
}

export interface ITopPublication {
  Count: number
  DateCreation: string
  Id: number
  Text: string
  IsEncrypted: boolean
  IsBlogpost: boolean
  IsSuperpost: boolean
  CanDecrypt: string
  UserInfo: IUserInfo
  SuperpostInfo: ISuperPostInfo
  BlogpostInfo: IBlogpostInfo
  IsWidgetPost: boolean
  WidgetPostInfo: IWidgetPostInfo
  IsOpenedByRecipient: boolean
  PostSource?: PostSource
  IsXPost: boolean
}

interface IUserInfo {
  Activated: boolean
  FirstName: string
  ImageLink: string
  LastName: string
  NickName: string
  OpenSeaLink: string
  IsStreaming: boolean
}

export interface ITopHashtag {
  Id: number
  Tag: string
  Count: number
}

export interface ITopHashtagFull {
  Id: number
  PublicationTag: string
  Count: number
}

export interface IBlogpostPreviewData {
  preview: string
  previewCdn: string
  title: EditorState
  description: EditorState
}

export enum DraftStatus {
  preparing,
  readyToMint,
}

export enum DraftType {
  blogpost,
  streampost,
}

export interface IDraft {
  CreatedAt: string
  Description: string
  Id: string
  IpfsFiles: IIpfsFiles[]
  PreviewLink: string
  PreviewLinkCdn: string
  Text: string
  TimeToDelete: number
  Title: string
  Type: DraftType
  Status: DraftStatus
}

export interface IWidgetPostInfo {
  WidgetSite: string
  WidgetUrl: string
}

export enum PostSource {
  Web = 0,
  Ios = 1,
  Android = 2,
  VisionOs = 3,
  MacOs = 4,
  Ipad = 5,
}

export interface IXPostResponse {
  XPostAuthorId: string
  XAuthorUsername: string
  XName: string
  IsXVerified: boolean
  XProfileImageUrl: string
  XPostText: string
  XPostCreatedAt: string
  XPublicationId: number
  XMediaInfos: Array<{
    Url: string
    Type: string
  }>
  UrlInfos: Array<{
    ExpandedUrl: string
    DisplayUrl: string
    Url: string
  }>
}

export interface IXPostMediaAttachments {
  FileLink: string
  CdnFileLink: string
  FileType: string
}

// export interface IXActionsStats {
//   Likes: number
//   Replies: number
// }

export interface ISocialPostInfo {
  XPostText: string
  XPostAuthorId: string
  XAuthorUsername: string
  XName: string
  IsXVerified: boolean
  XProfileImageUrl: string
  XPostCreatedAt: string
  XRewardPrice: string
  XPostLink: string
  XPostMediaAttachments: IXPostMediaAttachments[]
  // XActionsStats: IXActionsStats
}
