import React, { ChangeEvent, FC, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'

import { ReactComponent as CompanyIcon } from 'sources/images/companyIcon.svg'

import styles from './styles.module.scss'

interface IProps {
  superpostPrice: string
  onChangeSuperpostPrice: (value: string) => void
}
export const SuperpostPrice: FC<IProps> = ({superpostPrice, onChangeSuperpostPrice}) =>  {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target
    onChangeSuperpostPrice(value)
  }

  return (
   <div className={styles.priceContainer}>
     <div className={styles.title}>Price for unlocking this post:</div>
     <div className={styles.inputBlock}>
       <input
         step="0.1"
         type="number"
         value={superpostPrice}
         onChange={onChange}
         className={styles.input}
       />
       <CompanyIcon />
     </div>
   </div>
  );
}