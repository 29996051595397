import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState, getVisibleSelectionRect } from 'draft-js'

import { OPEN_LINK_IN_SAME_TAB } from '../../../../../utils/const'

import { useStore } from 'store'

import { getSelection, getSelectionRect } from '../../../util/selection'

import styles from '../styles.module.scss'

export const getRelativeParent = (
  element: HTMLElement | null
): HTMLElement | null => {
  if (!element) {
    return null
  }

  const position = window.getComputedStyle(element).getPropertyValue('position')
  if (position !== 'static') {
    return element
  }

  return getRelativeParent(element.parentElement)
}

interface IProps {
  editorState: EditorState
  url: string
  blockKey: string
  entityKey: string
  removeLink: (blockKey: string, entityKey: string) => void
  editLink: (blockKey: string, entityKey: string | null) => void

  getEditorNodeDOMRect: () => DOMRect | null
  getOverflowNodeDOMRect?: () => DOMRect | null
  positionType?: 'window' | 'editor' | 'element'
  isBlogpostEditor?: boolean
}

export const LinkEditComponent: React.FC<IProps> = ({
  editorState,
  editLink,
  url,
  removeLink,
  blockKey,
  entityKey,
  getEditorNodeDOMRect,
  getOverflowNodeDOMRect,
  positionType = 'element',
  isBlogpostEditor,
}) => {
  const [position, setPosition] = useState<Record<string, number | string>>({})
  const toolbarRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setTimeout(calculatePosition, 0)
  }, [entityKey])

  const calculatePosition = (): void => {
    if (!toolbarRef.current) {
      return
    }

    const nativeSelection = getSelection(window)
    if (!nativeSelection.rangeCount) {
      return
    }
    const selectionBoundary = getSelectionRect(nativeSelection)
    const toolbarNode = toolbarRef.current
    const editorNodeDOMRect = getEditorNodeDOMRect()
    const overflowNodeDOMRect =
      getOverflowNodeDOMRect && getOverflowNodeDOMRect()

    if (toolbarNode && editorNodeDOMRect) {
      const toolbarBoundary = toolbarNode.getBoundingClientRect()
      const selectionCenter =
        selectionBoundary.left +
        selectionBoundary.width / 2 -
        editorNodeDOMRect.left
      let left = selectionCenter - toolbarBoundary.width / 2

      // Window position rule
      // const screenLeft = editorNodeDOMRect.left + left
      // if (screenLeft < 0) {
      //   left = -editorNodeDOMRect.left
      // }
      // Need for screen right

      // Editor position rule
      if (
        positionType === 'editor' ||
        (!overflowNodeDOMRect && positionType === 'element')
      ) {
        if (left < 0) {
          left = 0
        }

        const maxLeft = editorNodeDOMRect.width - toolbarBoundary.width
        if (left > maxLeft) {
          left = maxLeft
        }
      }

      // Overflow parent position rule
      if (overflowNodeDOMRect && positionType === 'element') {
        const diffLeft = overflowNodeDOMRect.left - editorNodeDOMRect.left
        if (left < diffLeft) {
          left = diffLeft
        }

        const diffRight = overflowNodeDOMRect.right - editorNodeDOMRect.right
        const maxLeft = editorNodeDOMRect.width - toolbarBoundary.width
        if (left > maxLeft) {
          left = maxLeft + diffRight
        }
      }

      if (isBlogpostEditor) {
        // padding left
        left += 3
      }

      const newPosition = {
        top: `${selectionBoundary.top - editorNodeDOMRect.top + 35}px`,
        width: `${toolbarBoundary.width}px`,
        left: `${left}px`,
      }
      setPosition(newPosition)
    }
  }

  // const calculatePosition = (): void => {
  //   if (!toolbarRef.current) {
  //     return;
  //   }
  //
  //   const relativeParent = getRelativeParent(toolbarRef.current.parentElement);
  //   const relativeRect = relativeParent ? relativeParent.getBoundingClientRect() : window.document.body.getBoundingClientRect();
  //   const selectionRect = getVisibleSelectionRect(window);
  //   if (!selectionRect) {
  //     return;
  //   }
  //   const newPosition = {
  //     top: (selectionRect.top - relativeRect.top) + 35,
  //     left: (selectionRect.left - relativeRect.left) + (selectionRect.width / 2),
  //     transform: 'translate(-50%) scale(1)',
  //   };
  //   setPosition(newPosition);
  // };

  const handleRemoveLink = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault()
    e.stopPropagation()
    removeLink(blockKey, entityKey)
  }

  const handleEditLink = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    e.preventDefault()
    e.stopPropagation()
    editLink(blockKey, entityKey)
  }

  return (
    <div
      className={cn(styles.toolbar, styles.toolbarOpen, styles.editLink)}
      style={position}
      ref={toolbarRef}
    >
      <a
        href={url}
        title={url?.replaceAll(OPEN_LINK_IN_SAME_TAB, '')}
        target={url?.includes(OPEN_LINK_IN_SAME_TAB) ? '_self' : '_blank'}
        rel="noopener noreferrer"
      >
        {url?.replaceAll(OPEN_LINK_IN_SAME_TAB, '')}
      </a>
      <div className={styles.buttonsBox}>
        <button className={styles.editButton} onClick={handleEditLink}>
          Edit
        </button>
        <button onClick={handleRemoveLink}>Unlink</button>
      </div>
    </div>
  )
}
