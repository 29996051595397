import { jwtDecode } from 'jwt-decode'
import { makeAutoObservable } from 'mobx'

import { addFileToDigitalOceanSpaces } from '../utils/digitalOcean'
import {
  api,
  defaultUser,
  downloadTableExcel,
  LoginProvider,
  TwitterEventType,
  UserRole,
} from 'utils'

import { toast } from 'App'

import type { IUser, IUserInfoByTag } from 'models'

import { makePersistable } from 'mobx-persist-store'
import LoadingStore from 'store/loading'

interface JwtPayload {
  nameid: string
  unique_name: string
  wallet: string
  provider: string
  role: string
  nbf: number
  exp: number
  iat: number
}

//Check this old sheet ↓↓↓

class ProfileStore {
  // sessionStorage
  selectedUser: IUser = defaultUser
  activeProfileId = ''
  selectedProfileIdSession = '' //Need think about this
  tokenSession = '' //Need think about this
  walletSession = '' //Need think about this
  userRoleSession: UserRole | string = '' //Need think about this
  loginProviderSession: LoginProvider | string = '' //Need think about this

  // localStorage
  selectedProfileId = ''
  token = ''
  wallet = ''
  userRole: UserRole | string = ''
  loginProvider: LoginProvider | string = ''
  trust: { [userID: string]: number } = {}

  // mobx
  viewedUser: IUser = defaultUser
  usersInfoByTag: IUserInfoByTag[] = []

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'profile',
      properties: [
        'selectedUser',
        'activeProfileId',
        'selectedProfileIdSession',
        'tokenSession',
        'walletSession',
        'userRoleSession',
        'loginProviderSession',
      ],
      storage: window.sessionStorage,
    })

    makePersistable(this, {
      name: 'profile',
      properties: [
        'selectedProfileId',
        'token',
        'wallet',
        'userRole',
        'loginProvider',
      ],
      storage: window.localStorage,
    })

    makePersistable(this, {
      name: 'trust2FA',
      properties: ['trust'],
      storage: window.localStorage,
    })
  }

  async refreshSessionTokenData() {
    const tempToken = this.token || this.tokenSession
    const decoded: JwtPayload = jwtDecode(tempToken)

    if (decoded.exp * 1000 < Date.now()) {
      const response = await api.get(`api/Account/refreshAccessToken`, {
        params: {
          token: tempToken.replace('Bearer ', ''),
        },
      })

      if (response.status !== 200) {
        throw new Error(
          response.data &&
            (response.data.Description || response.data.Title || 'Some error')
        )
      }

      // else if (response.data) {
      const responseReLogin = await api.post(
        `api/Account/reLogin`,
        {},
        {
          params: { userId: decoded.nameid, provider: decoded.provider },
        }
      )

      if (response.status !== 200) {
        throw new Error(
          responseReLogin.data &&
            (responseReLogin.data.Description ||
              responseReLogin.data.Title ||
              'Some error')
        )
      }

      const newToken = responseReLogin?.data?.Token
      api.defaults.headers['Authorization'] = newToken
      this.setToken(newToken)
      // }
    }
  }

  setSessionTokenData(token: string, trust?: number) {
    api.defaults.headers['Authorization'] = token
    const decoded: JwtPayload = jwtDecode(token)
    this.setToken(token)
    this.userRole = decoded.role
    this.loginProvider = decoded.provider
    this.trust = trust
      ? { ...this.trust, [decoded?.nameid]: trust }
      : this.trust
  }

  setOnlySessionTokenData(token: string) {
    api.defaults.headers['Authorization'] = token
    const decoded: JwtPayload = jwtDecode(token)
    this.tokenSession = token
    this.userRoleSession = decoded.role
    this.loginProviderSession = decoded.provider
  }

  clearSessionTokenData() {
    api.defaults.headers['Authorization'] = ''
    this.setToken('')
    this.userRole = ''
    this.loginProvider = ''

    this.tokenSession = ''
    this.userRoleSession = ''
    this.loginProviderSession = ''
  }

  setSessionProfileData(user: IUser, isNotActive?: boolean) {
    this.selectedProfileId = user.Id
    this.wallet = user.Wallet
    !isNotActive && this.setActiveProfileId(user.Id)
    this.setSelectedUser(user)
  }

  setOnlySessionProfileData(user: IUser, isNotActive?: boolean) {
    this.selectedProfileIdSession = user.Id
    this.walletSession = user.Wallet
    !isNotActive && this.setActiveProfileId(user.Id)
    this.setSelectedUser(user)
  }

  clearSessionProfileData() {
    this.selectedProfileId = ''
    this.wallet = ''
    this.setActiveProfileId('')
    // Problem here ↓
    this.setSelectedUser(defaultUser)

    this.selectedProfileIdSession = ''
    this.walletSession = ''
  }

  setTrust(value: number | null) {
    if (value) {
      this.trust = { ...this.trust, [this.selectedProfileId]: value }
    }
  }

  removeTrust(userID: string) {
    if (this.trust?.[userID]) {
      delete this.trust[userID]
    }
  }

  getUserRole() {
    return this.userRole || this.userRoleSession
  }

  getLoginProvider() {
    return this.loginProvider || this.loginProviderSession
  }

  setSelectedUser(user: IUser) {
    this.selectedUser = user
  }

  setViewedUser(user: IUser) {
    this.viewedUser = user
  }

  setSelectedProfileId(id: string) {
    this.selectedProfileId = id
  }

  getSelectedProfileId() {
    return this.selectedProfileId || this.selectedProfileIdSession
  }

  setActiveProfileId(id: string) {
    this.activeProfileId = id
  }

  getActiveProfileId() {
    return this.activeProfileId
  }

  setToken(token: string) {
    this.token = token
  }

  getToken() {
    return this.token || this.tokenSession
  }

  setWallet(wallet: string) {
    this.wallet = wallet
  }

  getWallet() {
    return this.wallet || this.walletSession
  }

  setUsersInfoByTag(value: IUserInfoByTag[]) {
    this.usersInfoByTag = value
  }

  isAuthorizedProfileToast() {
    if (this.isAuthorizedProfile()) {
      return true
    } else {
      return toast({
        type: 'warning',
        message: `You don't have access to this functionality. Please log in or sign up.`,
      })
    }
  }

  isBlockchainUserRoleToast() {
    if (this.getUserRole() === UserRole.BlockchainUser) {
      return true
    } else {
      return toast({
        type: 'warning',
        message: `You don't have access to this functionality. Please log in or sign up with MetaMask or WalletConnect.`,
      })
    }
  }

  isAuthorizedProfile() {
    return !!(this.getSelectedProfileId() && this.getToken())
  }

  async onChangeUserInfo(data: Record<string, string>) {
    try {
      LoadingStore.setLoading(true)
      const newUserData = {
        ...this.selectedUser,
        ...data,
        UserGender: Number(data.UserGender),
      }
      const updatedUser = await this.modifyProfile(newUserData)
      this.setViewedUser(updatedUser)
      this.setSessionProfileData(updatedUser)
    } catch (e: any) {
      console.log(e.message)
    } finally {
      LoadingStore.setLoading(false)
    }
  }

  async changeUserImage(data: File, openSeaLink?: string) {
    try {
      LoadingStore.setLoading(true)
      // const user = await this.updateImage(data, openSeaLink)
      const user = await this.updateImageCDN(data, openSeaLink)
      this.setViewedUser(user)
      this.setSessionProfileData(user)
      LoadingStore.setLoading(false)
    } catch (e: any) {
      toast({
        type: 'error',
        message: e.message,
      })
      LoadingStore.setLoading(false)
    }
  }

  async changeNickname(newNickname: string) {
    const response = await api.put('api/Account/changeNickname', null, {
      params: {
        newNickname,
      },
    })
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return response.data
  }

  async updateImage(file: File, openSeaLink?: string) {
    let response

    const url = openSeaLink
      ? `api/Avatar/upload?openSeaLink=${openSeaLink}`
      : `api/Avatar/upload`

    // if (openSeaLink) {
    const bodySolution = new FormData()
    bodySolution.append('file', file)
    response = await api.post(url, bodySolution)
    // } else {
    //   response = await api.post(url, file)
    // }

    if (response.status !== 201) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    }

    return response.data
  }

  async updateImageCDN(file: File, openSeaLink?: string) {
    const avatarURL = await addFileToDigitalOceanSpaces(file, 'avatars')

    const response = await api.post('api/Avatar/uploadAvatarLink', null, {
      params: {
        link: openSeaLink || avatarURL,
        isNFT: !!openSeaLink,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    }

    return { ...this.selectedUser, ImageLink: avatarURL }
  }

  async getProfileByToken() {
    const response = await api.get(`api/Account/FindUserByIdAsync`)

    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }

  async getProfileByNickName(userNickName: string) {
    const response = await api.get(
      `api/Account/GetUserByNickName?nickName=${userNickName}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }

  async modifyProfile(user: IUser) {
    const response = await api.patch(`api/Account`, user)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }

  async unlinkTwitter() {
    const response = await api.put(`api/Account/RemoveTwitterId`)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }

  async requestToken(eventType: TwitterEventType) {
    const response = await api.get(
      `api/Account/Request_token?oAuthCallback=${process.env.REACT_APP_CALLBACK_TWITTER_URL}&eventType=${eventType}`
    )
    console.log('requestToken response', response)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )

    return response.data
  }

  async twitterCallback(url: string) {
    const response = await api.post(`api/Account/twitter_callback?url=` + url)
    console.log('twitterCallback response', response)
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }

  async setTwitterId(oauth_token: string, oauth_verifier: string) {
    const response = await api.get(
      `api/Account/SetTwitterId?oauth_token=${oauth_token}&oauth_verifier=${oauth_verifier}`
    )
    if (response.status !== 200)
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || 'Some error')
      )
    return response.data
  }

  async checkNFT(tokenId: string, contract: string) {
    const response = await api.post(
      `api/Account/UploadNFTAsAvatar?tokenId=${tokenId}&contract=${contract}`
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async getUserStatistic(userId: string) {
    const response = await api.get(`api/Account/getStatistic?userId=${userId}`)

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async generateStatistic(userId: string) {
    const response = await api.get(
      `api/Account/generateStatistic?userId=${userId}`
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async downloadStatistic(userId: string, fileName?: string) {
    const response = await api.get(
      `api/Account/downloadStatistic?userId=${userId}`,
      {
        responseType: 'blob',
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    downloadTableExcel(response.data, fileName)
    return response.data
  }

  async searchByNameAndNickName(searchText: string) {
    const response = await api.get(
      `api/Search/searchByNameAndNickName?searchText=${searchText}`
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async getInfoForTagInPost(nickname: string) {
    const response = await api.get(
      `api/Search/getInfoForTagInPost?nickName=${nickname}`
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async getAUTHTokenIsAdded() {
    const response = await api.get(`api/Account/getAUTHTokenIsAdded`)

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async setCustomPersentForIdentityPoolShare(value: number) {
    const response = await api.put(
      `api/Donate/setCustomPersentForIdentityPoolShare`,
      null,
      {
        params: {
          customPersentForIdentityPoolShare: value,
        },
      }
    )

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async getInfoForActivation(userId: string) {
    const response = await api.get(`api/Account/getInfoForActivation`, {
      params: {
        userId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data &&
          (response.data.Description || response.data.Title || '')
      )
    }
    return response.data
  }

  async deleteAccount() {
    const response = await api.delete(`api/Account/deleteAccount`)

    if (response.status !== 204) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async restoreAccount() {
    const response = await api.get(`api/Account/restoreAccount`)

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }

  async getWelcomeInfo(xAuthorUsername: string, publicationId: number) {
    const response = await api.get(`api/Account/getWelcomeInfo`, {
      params: {
        xAuthorUsername,
        publicationId,
      },
    })

    if (response.status !== 200) {
      throw new Error(
        response.data && response.data.Description
          ? response.data.Description
          : 'Some error'
      )
    }

    return response.data
  }
}

export default new ProfileStore()
