import React, { FC, useMemo, useRef, useState } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import Slider from '@mui/material/Slider'
import { styled } from '@mui/material/styles'

import { ReactComponent as VolumeIcon } from 'sources/images/volume.svg'

import styles from './styles.module.scss'
import { useMediaQuery } from 'utils/hooks'

interface IProps {
  volume: number
  onClickVolumeButton: () => void
  onChangeVolume: (e: Event, value: number | number[]) => void
  type?: 'horizontal' | 'vertical'
  changeTypeWidth?: number
  whiteStyle?: boolean
}

export const Volume: FC<IProps> = (
  {
    volume,
    onClickVolumeButton,
    onChangeVolume,
    type = 'horizontal',
    changeTypeWidth,
    whiteStyle
  }) => {
  const [isVisibleSlider, setIsVisibleSlider] = useState(false)
  const changeWidth = useMediaQuery(`(max-width: ${String(changeTypeWidth)}px)`)

  // let timerVisibleRef.current!: NodeJS.Timeout
  const timerVisibleRef = useRef<NodeJS.Timeout | null>(null)

  const VolumeSlider = useMemo(() => (
    styled(Slider)({
      color: whiteStyle ? 'white' : 'var(--mainColor)',
      // height: 4,
      '& .MuiSlider-track': {
        border: 'none'
      },
      '& .MuiSlider-thumb::after': {
        width: '20px',
        height: '20px'
      },
      '& .MuiSlider-thumb': {
        height: 10,
        width: 10,
        backgroundColor: whiteStyle ? 'white' : 'var(--mainColor)',
        border: 'none',
        // border: '2px solid currentColor',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
          boxShadow: 'inherit'
        },
        '&:before': {
          display: 'none'
        }
      },
      '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        color: whiteStyle ? '#2E2B63' : 'var(--mainColorRevert)',
        borderRadius: '50% 50% 50% 0',
        backgroundColor: whiteStyle ? 'white' : 'var(--mainColor)',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
          transform: 'translate(50%, -100%) rotate(-45deg) scale(1)'
        },
        '& > *': {
          transform: 'rotate(45deg)'
        }
      }
    })
  ), [])

  const orientation = useMemo(() => {
    if (changeTypeWidth && changeWidth) {
      return 'vertical'
    }

    return type === 'horizontal' ? 'horizontal' : 'vertical'
  }, [changeTypeWidth, changeWidth])

  const showHideSlider = () => {
    clearTimeout(timerVisibleRef.current as NodeJS.Timeout)
    setIsVisibleSlider(true)
    timerVisibleRef.current = setTimeout(() => {
      setIsVisibleSlider(false)
    }, 3000)
  }

  const handleChangeVolume = (e: Event, value: number | number[]) => {
    onChangeVolume(e, value)

    if (orientation === 'vertical') {
      showHideSlider()
    }
  }

  const handleClickVolumeButton = () => {
    if (orientation === 'vertical') {
      if (isVisibleSlider) {
        setIsVisibleSlider(false)
        clearTimeout(timerVisibleRef.current as NodeJS.Timeout)
      } else {
        showHideSlider()
      }

    } else {
      onClickVolumeButton()
    }
  }

  return (
    <div className={cn({
      [styles.volumeContainer]: true,
      [styles.volumeContainerVertical]: orientation === 'vertical'
    })}>
      <button style={{ opacity: volume === 0 ? 0.6 : 1 }}
              className={cn({
                [styles.volumeButton]: true,
                [styles.volumeButtonWhiteStyle]: whiteStyle
              })}
              onClick={handleClickVolumeButton}
      >
        <VolumeIcon/>
      </button>

      <div className={cn({
        [styles.sliderContainer]: true,
        [styles.sliderContainerVisible]: isVisibleSlider
      })}>
        {orientation === 'vertical'
          ? <div className={cn({
            [styles.backgroundMask]: true,
            [styles.backgroundMaskWhiteStyle]: whiteStyle,
          })}/>
          : null}
        <VolumeSlider
          orientation={orientation}
          size="small"
          value={volume}
          onChange={handleChangeVolume}
          sx={{
            width: orientation === 'vertical' ? '4px' : '40px',
            height: orientation === 'vertical' ? '40px' : '4px'
          }}
          valueLabelDisplay="off"
          aria-label="volume"
        />
      </div>

    </div>
  )
}