import React, { CSSProperties, FC } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import { main_link } from 'utils'

import { useStore } from 'store'

import authLogo from 'sources/images/auth.svg'
import authLogoDark from 'sources/images/authDark.svg'

import styles from './styles.module.scss'

interface IProps {
  width100?: boolean
  style?: CSSProperties
  className?: string
}

const PoweredByAuthencity: FC<IProps> = observer(
  ({ width100, style, className }) => {
    const { theme } = useStore()

    const redirectToMainPage = () => {
      window.open('https://authencity.io', '_blank')
    }

    return (
      <div
        className={cn(styles.poweredByAuthencity, className, {
          [styles.poweredByAuthencity_w100]: width100,
        })}
        style={style}
      >
        <p>powered by </p>

        <img
          onClick={redirectToMainPage}
          src={theme.theme === 'light' ? authLogo : authLogoDark}
          alt="logo"
        />
      </div>
    )
  }
)

PoweredByAuthencity.displayName = 'PoweredByAuthencity'

export default PoweredByAuthencity
