import React from 'react';
import { RichUtils } from 'draft-js';

import { StyleButton } from '../StyleButton';

import {ReactComponent as QuotesIcon} from 'sources/images/editorToolbar/quotes.svg'
import { ReactComponent as LinkIcon } from 'sources/images/editorToolbar/link.svg'

import styles from '../styles.module.scss'
import cn from 'classnames'

export const BLOCK_BUTTONS = [
  {
    label: 'H1',
    style: 'header-one',
    description: 'Heading 1'
  },
  {
    label: 'H2',
    style: 'header-two',
    description: 'Heading 1'
  },
  {
    label: 'H3',
    style: 'header-three',
    description: 'Heading 1'
  },
  {
    label: (
      <QuotesIcon className={styles.quotesIcon}/>
    ),
    style: 'blockquote',
    description: 'Blockquote'
  },
  // {
  //   label: 'UL',
  //   style: 'unordered-list-item',
  //   description: 'Unordered List'
  // },
  // {
  //   label: 'OL',
  //   style: 'ordered-list-item',
  //   description: 'Ordered List'
  // },
  // {
  //   label: '✓',
  //   style: 'todo',
  //   description: 'Todo List'
  // }
]

interface BlockToolbarProps {
  editorState: any;
  onToggle: (style: string) => void;
  handleLinkInput(e: React.MouseEvent<HTMLElement>): void
}

export const BlockToolbar: React.FC<BlockToolbarProps> = ({editorState, onToggle, handleLinkInput}) => {

  const blockType = RichUtils.getCurrentBlockType(editorState);
  return (
    // <div className="md-RichEditor-controls md-RichEditor-controls-block">
    <div className={cn(styles.controls, styles.controlsBlock)}>
      {BLOCK_BUTTONS.map((type) => {
        return (
          <StyleButton
            key={type.style}
            label={type.label}
            active={type.style === blockType}
            onToggle={onToggle}
            style={type.style}
            description={type.description}
            // onClick={type.onClick}
          />
        );
      })}
      <span
        className={cn(styles.styleButton, styles.linkButton, styles.hinTop)}
        onClick={handleLinkInput}
        aria-label={'Add a link'}>
            <LinkIcon className={styles.linkIcon}/>
          </span>
    </div>
  );
};
