import React, { FC, useState } from 'react'

//@ts-ignore
import ReactPannellum, { getConfig } from 'react-pannellum'

import { ReactComponent as Cube3dIcon } from 'sources/images/cube3d.svg'

import { ModalView } from '../ModalView'
import ViewerModel3D from '../ViewerModel3D'

import styles from './styles.module.scss'

interface IProps {
  url: string
  fileExtension?: string
}

//Resize
export const View3D: FC<IProps> = ({ url, fileExtension }) => {
  const [open, setOpen] = useState(false)

  const closeModal = () => {
    setOpen(false)
  }

  const openModal = () => {
    setOpen(true)
  }

  if (!open) {
    return (
      <div onClick={openModal} className={styles.button}>
        <Cube3dIcon className={styles.btn3D} />
      </div>
    )
  }

  return (
    <ModalView
      blockOutsideClose
      onClose={closeModal}
      open={open}
      closeButton3D
      closeButtonStyle={{ stroke: 'white' }}
      style={{
        width: '95vw',
        height: '90vh',
        maxWidth: '95vw',
        maxHeight: '90vh',
        backgroundColor: 'transparent',
        // backgroundColor: 'rgba(22, 20, 50, 0.1)'
      }}
      children={
        <ViewerModel3D imageUrl={url} ar fileExtension={fileExtension} />
      }
    />
  )
}
