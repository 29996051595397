import React, { FC } from 'react'
import cn from 'classnames'
import { useStore } from 'store'
import { observer } from 'mobx-react'
import IconButton from '@mui/material/IconButton'

import { createPostFileType } from 'utils'

import { ReactComponent as Image } from 'sources/imagesPWA/mediaAddButtons/image.svg'
import { ReactComponent as Video } from 'sources/imagesPWA/mediaAddButtons/video.svg'
import { ReactComponent as Emoji } from 'sources/imagesPWA/mediaAddButtons/emoji.svg'
import { ReactComponent as GIF } from 'sources/imagesPWA/mediaAddButtons/gif.svg'
import { ReactComponent as View360 } from 'sources/imagesPWA/mediaAddButtons/image360.svg'
import { ReactComponent as Audio } from 'sources/imagesPWA/mediaAddButtons/audio.svg'
import { ReactComponent as View3D } from 'sources/imagesPWA/mediaAddButtons/image3D.svg'

import styles from './styles.module.scss'

const Buttons = [
  {
    type: createPostFileType.image,
    disabledType: createPostFileType.image,
    icon: <Image className={styles.image}/>
  },
  {
    type: createPostFileType.video,
    disabledType: createPostFileType.video,
    icon: <Video className={styles.video}/>
  },
  {
    type: 'emoji',
    disabledType: 'emoji',
    icon: <Emoji className={styles.emoji}/>
  },
  {
    type: createPostFileType.gif,
    disabledType: createPostFileType.gif,
    icon: <GIF className={styles.gif}/>
  },
  {
    type: createPostFileType.image360,
    disabledType: createPostFileType.image,
    icon: <View360 className={styles.image360}/>
  },
  {
    type: createPostFileType.audio,
    disabledType: createPostFileType.audio,
    icon: <Audio className={styles.audio}/>
  },
  {
    type: createPostFileType.image3D,
    disabledType: createPostFileType.image,
    icon: <View3D className={styles.image3D}/>
  }
]

interface IProps {
  getStatusBlocked: (type: createPostFileType) => boolean
  uploadImage: (type: createPostFileType) => void
  onOpenEmojiPWA: () => void
  onlyEmoji?: boolean
}

export const AddFileButtonsPWA: FC<IProps> = ({ getStatusBlocked, uploadImage, onOpenEmojiPWA, onlyEmoji }) => {

  return (
    <div className={styles.addFileButtonsBox}>
      {Buttons.map(button => {
        const handlerClick = () => {
          button.type === 'emoji' ? onOpenEmojiPWA() : uploadImage(button.type as createPostFileType)
        }

        if (onlyEmoji && button.type !== 'emoji') return null

        const disabled = button.type === 'emoji' ? false : getStatusBlocked(button.disabledType as createPostFileType)

        return (
          <IconButton
            key={button.type}
            disabled={disabled}
            color="inherit"
            aria-label={button.type}
            onClick={handlerClick}
            sx={{opacity: disabled ? 0.4 : 1}}
          >
            {button.icon}
          </IconButton>
        )
      })}
    </div>
  )
}
