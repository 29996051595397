import React, { CSSProperties } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'

import { TooltipProps } from '@material-ui/core/Tooltip/Tooltip'

import { useStore } from 'store'

import save from 'sources/images/check.svg'
import edit from 'sources/images/edit.svg'
import facebook from 'sources/images/facebook.svg'
import git from 'sources/images/git.svg'
import google from 'sources/images/google.svg'
import linkedIn from 'sources/images/in.svg'
import info from 'sources/images/info_icon.svg'
import instagram from 'sources/images/instagram.svg'
import lock from 'sources/images/lockIcon.svg'
import more from 'sources/images/moreIcon.svg'
import plus from 'sources/images/plus.svg'
import reddit from 'sources/images/reddit.svg'
import telegram from 'sources/images/telegram.svg'
import twitter from 'sources/images/twitter-x-plain.svg'

import { CustomTooltip } from '../CustomTooltip'

import styles from './styles.module.scss'

const getIcon = (icon: string | undefined) => {
  switch (icon) {
    case 'edit':
      return edit
    case 'facebook':
      return facebook
    case 'twitter':
      return twitter
    case 'telegram':
      return telegram
    case 'google':
      return google
    case 'git':
      return git
    case 'linkedIn':
      return linkedIn
    case 'reddit':
      return reddit
    case 'instagram':
      return instagram
    case 'save':
      return save
    case 'info':
      return info
    case 'more':
      return more
    case 'lock':
      return lock
    case 'plus':
      return plus
  }
}

interface IProps {
  icon?:
    | 'edit'
    | 'facebook'
    | 'twitter'
    | 'telegram'
    | 'google'
    | 'git'
    | 'linkedIn'
    | 'reddit'
    | 'instagram'
    | 'save'
    | 'info'
    | 'more'
    | 'lock'
    | 'plus'
  iconSource?: string
  style?: CSSProperties
  styleIcon?: CSSProperties
  dark?: boolean
  onClick?: () => void
  type?: 'button' | 'reset' | 'submit'
  tooltip?: string
  tooltipDisablePortal?: boolean
  tooltipPosition?: TooltipProps['placement']
  bordered?: boolean
  disabledHoverStyle?: boolean
  children?: React.ReactNode
}

//Resize
const ButtonIcon: React.FC<IProps> = observer(
  ({
    icon,
    iconSource,
    style,
    styleIcon,
    dark,
    onClick,
    type,
    tooltip,
    tooltipDisablePortal = true,
    tooltipPosition,
    bordered,
    disabledHoverStyle,
    children,
  }) => {
    const { theme } = useStore()

    return (
      <CustomTooltip
        title={tooltip ? tooltip : ''}
        placement={tooltipPosition ? tooltipPosition : 'top'}
        disablePortal={tooltipDisablePortal}
      >
        <button
          onClick={() => onClick && onClick()}
          style={style}
          className={cn({
            [styles.body]: true,
            [styles.darkBody]: dark ?? theme.theme === 'dark',
            [styles.bodyBorder]: bordered,
            [styles.activeHover]: !disabledHoverStyle,
          })}
          type={type ? type : 'button'}
        >
          {children ? (
            children
          ) : (
            <div
              className={styles.btnIcon}
              style={{
                ...styleIcon,
                WebkitMaskImage: `url(${
                  iconSource ? iconSource : getIcon(icon)
                })`,
                maskImage: `url(${iconSource ? iconSource : getIcon(icon)})`,
              }}
            />
          )}
        </button>
      </CustomTooltip>
    )
  }
)

export default ButtonIcon
