import React, { FC, useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { AuthCurrency } from 'utils'

import ButtonIcon from 'components/ButtonIcon'

import { useStore } from 'store'

import { ReactComponent as Dollar } from 'sources/images/dollarIcon.svg'
import { ReactComponent as Ethereum } from 'sources/images/ethereumIcon.svg'
import { ReactComponent as Matic } from 'sources/images/polygonIcon.svg'

import styles from './styles.module.scss'

interface IProps {
  onGetPrice: () => Promise<{ price: string } | undefined>
  activeBlock?: string
  blockId?: string
}

//Resize
export const PriceUpdate: FC<IProps> = observer(
  ({ onGetPrice, activeBlock = '0', blockId = '0' }) => {
    const { paymentCurrency, mediaQuery, main } = useStore()
    const [price, setPrice] = useState<{ usd: string; crypto: string }>({
      usd: '0',
      crypto: '0',
    })

    useEffect(() => {
      if (activeBlock !== blockId) return

      let timer = setInterval(() => {
        getPostPrice()
      }, 5000)
      return () => {
        clearInterval(timer)
      }
    }, [activeBlock, blockId])

    useEffect(() => {
      getPostPrice()
    }, [paymentCurrency.currency])

    const getPostPrice = async () => {
      const crypto = await onGetPrice()

      if (crypto?.price) {
        if (+crypto?.price === 0) {
          setPrice({
            usd: '0',
            crypto: crypto.price,
          })
        } else {
          let data: any
          if (paymentCurrency.currency === AuthCurrency.ethereum) {
            data = await paymentCurrency.getExchangeRateETHUSD()
          } else if (paymentCurrency.currency === AuthCurrency.polygon) {
            data = await paymentCurrency.getExchangeRateMATICUSD()
          }

          setPrice({
            usd: data?.last
              ? String((+data?.last * +crypto.price).toFixed(6))
              : '0',
            crypto: crypto.price,
          })
        }
      }
    }

    const isEthereumCurrency =
      paymentCurrency.currency === AuthCurrency.ethereum

    if (main.isPWA) {
      return (
        <div className={styles.priceBoxPWA}>
          <span>Price:</span>
          <span>
            {price.crypto} {isEthereumCurrency ? 'ETH' : 'MATIC'}
          </span>
          <span>($ {price.usd})</span>
        </div>
      )
    }

    return (
      <div className={styles.priceWrapper}>
        <div className={styles.title}>Price:</div>
        <div className={styles.priceContainer}>
          <div className={styles.priceBlock}>
            <div className={styles.priceUSD}>
              {+price.usd}
              <div className={styles.iconBox}>
                <Dollar className={styles.dollar} />
              </div>
            </div>
            <div className={styles.priceCrypto}>
              {+price.crypto}
              <div className={styles.iconBox}>
                {isEthereumCurrency ? (
                  <Ethereum className={styles.eth} />
                ) : (
                  <Matic className={styles.matic} />
                )}
              </div>
            </div>
          </div>
          <ButtonIcon
            style={{
              minWidth: mediaQuery.minWidth_2200 ? '22px' : '18px',
              width: mediaQuery.minWidth_2200 ? '22px' : '18px',
              height: mediaQuery.minWidth_2200 ? '22px' : '18px',
            }}
            styleIcon={{
              width: mediaQuery.minWidth_2200 ? '12px' : '10px',
              height: mediaQuery.minWidth_2200 ? '12px' : '10px',
            }}
            icon={'info'}
            tooltip="This approximate price is updated every 5 seconds and the final price may slightly vary. Length of your message impacts the price directly."
            tooltipPosition="top"
          />
        </div>
      </div>
    )
  }
)

PriceUpdate.displayName = 'PriceUpdate'
