import React, { CSSProperties, FC, useState } from 'react'

import { observer } from 'mobx-react'

import cn from 'classnames'
import { EditorState } from 'draft-js'

import { getPostLengthFromEditorState, ViewTypeEditor } from 'utils'

import Button from 'components/Button'
import ButtonIcon from 'components/ButtonIcon'
import { CustomEditor } from 'components/CustomEditor'
import { Inline } from 'components/CustomEditor/util/constants'
import { EditorTextView } from 'components/EditorTextView'
import { ModalPWA } from 'componentsPWA/ModalPWA'

import { useStore } from 'store'

import styles from './styles.module.scss'

interface IProps {
  editorStateTitle?: EditorState
  editorStateDescription?: EditorState
  onChange?: (
    id: string,
    text: EditorState,
    transactionBlockKey: string,
    type: 'title' | 'description',
    fileID: string
  ) => void
  type: 'audio' | 'video'
  postId: string
  transactionBlockKey?: string
  styleBlock?: CSSProperties
  fileID?: string
}

export const VideoAudioTitleDescriptionPWA: FC<IProps> = observer(
  ({
    editorStateTitle,
    editorStateDescription,
    onChange,
    type,
    postId,
    transactionBlockKey,
    styleBlock,
    fileID,
  }) => {
    const [openModal, setOpenModal] = useState(false)

    const titleType = type === 'video' ? 'Video' : 'Audio'

    const handlerChangeTitle = (
      id: string,
      editorData: EditorState,
      transactionBlockKey: string
    ) => {
      onChange &&
        onChange(id, editorData, transactionBlockKey, 'title', fileID as string)
    }

    const handlerChangeDescription = (
      id: string,
      editorData: EditorState,
      transactionBlockKey: string
    ) => {
      onChange &&
        onChange(
          id,
          editorData,
          transactionBlockKey,
          'description',
          fileID as string
        )
    }

    const handlerOpenModal = () => {
      setOpenModal(true)
    }

    const handlerCloseModal = () => {
      setOpenModal(false)
    }

    const addButton = (
      <button onClick={handlerOpenModal} className={styles.addButton}>
        Add {type} title & description<b>*</b>
      </button>
    )

    if (!(editorStateTitle && editorStateDescription)) {
      return addButton
    }

    const titleCharactersQuantity =
      getPostLengthFromEditorState(editorStateTitle)
    const descriptionCharactersQuantity = getPostLengthFromEditorState(
      editorStateDescription
    )

    return (
      <>
        {titleCharactersQuantity === 0 && descriptionCharactersQuantity === 0
          ? addButton
          : null}

        {titleCharactersQuantity > 0 || descriptionCharactersQuantity > 0 ? (
          <div>
            <button onClick={handlerOpenModal} className={styles.editButton}>
              Edit {type} title & description
            </button>
            {titleCharactersQuantity > 0 ? (
              <div className={styles.audioVideoTitle}>
                <EditorTextView
                  editorText={editorStateTitle}
                  key={Number(openModal)}
                  text=""
                  insertText={{
                    text: `${titleType} title: `,
                    styleRule: Inline.VIDEO_AUDIO_TD,
                  }}
                  viewType={ViewTypeEditor.text}
                />
              </div>
            ) : null}

            {descriptionCharactersQuantity > 0 ? (
              <div className={styles.audioVideoTitle}>
                <EditorTextView
                  editorText={editorStateDescription}
                  key={Number(openModal)}
                  text=""
                  insertText={{
                    text: `${titleType} description: `,
                    styleRule: Inline.VIDEO_AUDIO_TD,
                  }}
                  viewType={ViewTypeEditor.text}
                />
              </div>
            ) : null}
          </div>
        ) : null}

        <ModalPWA
          open={openModal}
          disableOutsideClick
          paperSX={{ justifyContent: 'flex-end', background: 'none' }}
          contentSX={{
            padding: { xs: '24px 16px 40px', sm: '24px 24px 40px' },
            background: 'var(--boxColor)',
            borderRadius: '16px 16px 0px 0px',
          }}
        >
          <div className={styles.container}>
            <div className={styles.block} style={styleBlock}>
              <div className={styles.title}>
                <div>{titleType} title</div>
                <span>*</span>
                <div
                  className={cn({
                    [styles.charactersQuantity]: true,
                    [styles.charactersQuantityError]:
                      titleCharactersQuantity > 200,
                  })}
                >
                  {titleCharactersQuantity}/200
                </div>
              </div>
              <div className={styles.editorWrapper}>
                <CustomEditor
                  viewType={ViewTypeEditor.editorWithoutAvatar}
                  editorState={editorStateTitle}
                  postId={postId}
                  transactionBlockKey={transactionBlockKey}
                  setPostEditorState={handlerChangeTitle}
                  editorAutoHeight
                  styleContainer={{ marginBottom: 0, marginTop: '2px' }}
                />
              </div>
            </div>

            <div className={styles.block}>
              <div className={styles.title}>
                <div>{titleType} description</div>
                <span>*</span>
                <ButtonIcon
                  style={{
                    minWidth: '18px',
                    width: '18px',
                    height: '18px',
                    marginLeft: '5px',
                  }}
                  styleIcon={{
                    width: '8px',
                    height: '8px',
                  }}
                  icon={'info'}
                  tooltip="The description must contain at least 1 hashtag"
                  tooltipPosition="top-start"
                  bordered
                />
                <div
                  className={cn({
                    [styles.charactersQuantity]: true,
                    [styles.charactersQuantityError]:
                      descriptionCharactersQuantity > 500,
                  })}
                >
                  {descriptionCharactersQuantity}/500
                </div>
              </div>
              <div className={styles.editorWrapper}>
                <CustomEditor
                  viewType={ViewTypeEditor.editorWithoutAvatar}
                  editorState={editorStateDescription}
                  postId={postId}
                  transactionBlockKey={transactionBlockKey}
                  setPostEditorState={handlerChangeDescription}
                  styleContainer={{ marginBottom: 0, marginTop: '2px' }}
                />
              </div>
            </div>

            <Button
              type="button"
              style={{
                marginTop: '16px',
                width: '100%',
                height: '40px',
                background:
                  'linear-gradient(131.69deg, #1f45c3 6.46%, #0e219b 100%)',
                boxShadow: 'none',
              }}
              styleText={{
                color: '#ffffff',
                fontSize: '0.75rem',
                fontWeight: 700,
              }}
              btnStyle={'classic'}
              text="Save"
              onClick={handlerCloseModal}
              blueHover
            />
          </div>
        </ModalPWA>
      </>
    )
  }
)
