import React, { CSSProperties, FC, useCallback, useEffect, useRef, useState } from 'react'
import WaveformData, { JsonWaveformData } from 'waveform-data'

import styles from './styles.module.scss'
import WaveFormView from './WaveFormView'
import InnerLoading from '../InnerLoading'

interface IProps {
  audioData: string | File
  publicationId?: number
  activeAudio?: boolean
  setActiveAudio?: (id: string) => void
  uniqueAudioId?: string
  styleContainer?: CSSProperties
  postId?: string
  onAddAudiWaveForm?: (id: string, waveForm: string) => void
  waveFormJSON?: string | null
  whiteStyle?: boolean
}

export const AudioWaveForm: FC<IProps> = (
  {
    audioData,
    styleContainer,
    publicationId,
    activeAudio,
    setActiveAudio,
    uniqueAudioId,
    postId,
    onAddAudiWaveForm,
    waveFormJSON,
    whiteStyle
  }) => {
  const [waveformJson, setWaveformJson] = useState<JsonWaveformData>()
  const [waveformArrayBuffer, setWaveformArrayBuffer] = useState<ArrayBuffer>()
  const [audioUrl, setAudioUrl] = useState<string>('')
  const [waveFormResize, setWaveFormResize] = useState<number>(0)
  const audioElementRef = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    !activeAudio && audioElementRef.current?.pause()
  }, [activeAudio])

  const playHandler = useCallback(() => {
    const activeVideoId = uniqueAudioId ? uniqueAudioId : String(publicationId)
    setActiveAudio && setActiveAudio(activeVideoId)
  }, [uniqueAudioId, publicationId])


  useEffect(() => {
    let prevWidth: number = document.body.offsetWidth
    const resizeObserver = new ResizeObserver(changes => {
      for(const change of changes){
        if(change.contentRect.width === prevWidth) return
        if (change.contentRect.width < prevWidth) {
          setWaveFormResize(prev => prev + 1)
        }
        prevWidth = change.contentRect.width
      }
    })

    resizeObserver.observe(document.body)

    return () => {
      resizeObserver.unobserve(document.body)
    }
  }, [])

  useEffect(() => {
    let audioUrlData = ''
    if (typeof audioData === 'string') {
      audioUrlData = audioData
    } else {
      audioUrlData = URL.createObjectURL(audioData)
    }
    setAudioUrl(audioUrlData)

    if (waveFormJSON) {
      setWaveformJson(JSON.parse(waveFormJSON))
    } else {
      const audioContext = new AudioContext()

      fetch(audioUrlData)
        .then(response => {
          return response.arrayBuffer()
        })
        .then(buffer => {
          const bufferByteLength = buffer.byteLength
          const aroundScale = bufferByteLength/ 10000
          // console.log('bufferByteLength', bufferByteLength)
          // console.log('aroundScale', aroundScale)
          let scale: number
          if (aroundScale > 2048) {
            scale = 2048
          } else if( aroundScale < 2048 && aroundScale > 1024) {
            scale = 2048
          } else if( aroundScale < 1024 && aroundScale > 512) {
            scale = 1024
          } else if( aroundScale < 512 && aroundScale > 256) {
            scale = 512
          } else if( aroundScale < 256 && aroundScale > 128) {
            scale = 256
          } else if( aroundScale < 128 && aroundScale > 64) {
            scale = 128
          } else if( aroundScale < 64 && aroundScale > 32) {
            scale = 64
          } else if( aroundScale < 32 && aroundScale > 16) {
            scale = 32
          } else if( aroundScale < 16 && aroundScale > 8) {
            scale = 16
          } else if( aroundScale < 8 && aroundScale > 4) {
            scale = 8
          } else if( aroundScale < 4 && aroundScale > 2) {
            scale = 4
          } else {
            scale = 2
          }

          // console.log('scale', scale)
          const options = {
            audio_context: audioContext,
            array_buffer: buffer,
            scale: scale,
          }

          return new Promise((resolve, reject) => {
            WaveformData.createFromAudio(options, (err, waveform) => {
              if (err) {
                reject(err)
              } else {
                resolve(waveform)
              }
            })
          })
        })
        .then((waveform: any) => {
          const waveformData = waveform as WaveformData
          // setWaveformArrayBuffer(waveformData.toArrayBuffer())
          const waveFormJSON = waveformData.toJSON()
          setWaveformJson(waveFormJSON)
          // console.log('waveFormJSON', waveFormJSON)
          console.log('size bytes', Buffer.byteLength(JSON.stringify(waveFormJSON), 'utf8'))

          if (onAddAudiWaveForm && postId) {
            onAddAudiWaveForm(postId, JSON.stringify(waveFormJSON))
          }
          // console.log(JSON.stringify(waveFormJSON))

        })
        .catch(e => {
          console.log('e', e)
        })
    }
  }, [audioData, waveFormResize])

  return (
    <div className={styles.container} style={styleContainer}>
      <WaveFormView audioUrl={audioUrl}
                    audioContentType={'audio/mpeg'}
                    waveformArrayBuffer={waveformArrayBuffer}
                    waveformJson={waveformJson}
                    ref={audioElementRef}
                    onPlay={playHandler}
                    whiteStyle={whiteStyle}
      />
      <InnerLoading loading={!(waveformJson || waveformArrayBuffer)}
                    style={{ background: 'transparent' }}
                    styleLoading={{ width: '26px', height: '26px' }}/>

    </div>
  )
}

// export default React.memo(AudioWaveForm)