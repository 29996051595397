import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'

import { observer } from 'mobx-react'
import { useNavigate } from 'react-router-dom'

// @ts-ignore
import captureVideoFrame from 'capture-video-frame'
import cn from 'classnames'
import { is } from 'date-fns/locale'
import { convertFromRaw, convertToRaw, EditorState, Modifier } from 'draft-js'
import { BaseEmoji } from 'emoji-mart'
import cloneDeep from 'lodash/cloneDeep'
import uniqueId from 'lodash/uniqueId'
import mime from 'mime'
import { useDebouncedCallback } from 'use-debounce'
import { v4 as uuidv4, v4 } from 'uuid'

import {
  checkBadWords,
  checkNumberOfLineBreaks,
  createPostFileType,
  defaultLoadPage,
  fileSize,
  getAuthencityObjectERC721AndUserWallet,
  getCanDecryptNicknames,
  getChainIdFromPaymentCurrency,
  getEditorStateAsString,
  getGasPrice,
  getImageQueryDimensionsFromUrl,
  getNetworkTypeFromPaymentCurrency,
  getPostAsString,
  getPostLengthFromEditorState,
  isAvailableBlockchainProviderToast,
  isMobile,
  isSelectedCorrectCurrency,
  TRenderType,
  uploadFileByLink,
  UserRole,
} from 'utils'
import { addFileToDigitalOceanSpaces } from 'utils/digitalOcean'
import { getPostTextFromEditorState } from 'utils/functions'
import { useBodyDisableScroll } from 'utils/hooks'
import { ipfs } from 'utils/ipfs'

import { toast } from 'App'
import { CloseModal } from 'components/CloseModal'
import {
  ConfirmModal as ChangePostTypeModal,
  ConfirmModal as SaveDraftConfirmModal,
} from 'components/ConfirmModal'
import { Block as EditorBlockType } from 'components/CustomEditor/util/constants'
import CustomMultipleSwitch from 'components/CustomMultipleSwitch'
import { EmojiMobile } from 'components/EmojiMobile'
import { EmojiModal } from 'components/EmojiModal'
import Modal from 'components/Modal'
import { CloseModalPWA } from 'componentsPWA/CloseModalPWA'
import { CreatePostModalPWA } from 'componentsPWA/CreatePostModalPWA'

import {
  DraftStatus,
  IBlogpostPreviewData,
  IDraft,
  IIpfsFiles,
  ILoadPage,
  ISendPublication,
  ISocialPostInfo,
  IXPostResponse,
  PostSource,
} from 'models'

import { useStore } from 'store'
import {
  defaultTransactionCreateBlock,
  getBlockchainPostType,
  IMintData,
  ITransactionCreateBlock,
  PostType,
} from 'store/createPost'

import { ReactComponent as Close } from 'sources/images/close.svg'
import { ReactComponent as PlusIcon } from 'sources/images/plus.svg'

import { resetBlockWithType } from '../CustomEditor/util'
import Block from './component/Block'
import BlockWidget from './component/BlockWidget'
import { BlogpostModals } from './component/BlogpostModals'
import { SocialPostRewardPrice } from './component/SocialPostRewardPrice'
import { SuperpostPrice } from './component/SuperpostPrice'

import styles from './styles.module.scss'

interface IProps {
  userImage?: string | null
  getPublication?: (
    isNewProfile: boolean,
    isRefresh: boolean,
    paramPage: ILoadPage,
    total?: number
  ) => void
  setDefaultPostsSettings?: () => void
  handleOpenCreatePostModal: (value: boolean) => void
  draftToEdit: IDraft | null
  getDrafts: () => Promise<void>
  renderType?: TRenderType
  redirectToNewPublication?: boolean
}

//Resize
//Not resize EmojiModal and EmojiMobile
const CreatePostModal: React.FC<IProps> = observer(
  ({
    userImage,
    getPublication,
    setDefaultPostsSettings,
    handleOpenCreatePostModal,
    draftToEdit,
    getDrafts,
    renderType = 'default',
    redirectToNewPublication,
  }) => {
    const {
      loading,
      createPost,
      paymentCurrency,
      signalR,
      publication: publicationStore,
      transaction,
      profile,
      main,
      walletConnect,
      widget,
      theme,
    } = useStore() //-◄-Store
    const [isOpen, setIsOpen] = useState(false) //-◄-Open "Create Post Modal" State
    const [isCloseModalOpen, setIsCloseModalOpen] = useState(false) //-◄-Open "Close modal => Modal" State
    const [activeBlock, setActiveBlock] = useState('0') //-◄-Active create post block in "Create Post Modal"
    const [firstTransactionBlock, setFirstTransactionBlock] = useState('0')
    const [postModalStyle, setPostModalStyle] = useState<CSSProperties>({})
    const [emojiClickPosition, setEmojiClickPosition] = useState<
      { top: number; left: number; isEnoughSpace: boolean } | undefined
    >()
    const [quantitySymbols, setQuantitySymbols] = useState<number>(0) //-◄-Set quantity of textarea symbols
    const [windowHeight, setWindowHeight] = useState<number>(0)
    const [isMobileDevice] = useState<boolean>(() => isMobile())
    const [openMobilePicker, setOpenMobilePicker] = useState<boolean>(false)
    const [heightModal, setHeightModal] = useState<number | undefined>()
    const [blockchainIdTransactionBlocks, setBlockchainIdTransactionBlocks] =
      useState<{ [key: string]: string }>({})
    const [postType, setPostType] = useState<PostType>(PostType.Blockchain)
    const [postTypeAfterBlogpost, setPostTypeAfterBlogpost] =
      useState<PostType>(PostType.Blockchain)
    const [superpostPrice, setSuperpostPrice] = useState('')
    const [superPostPublicText, setSuperPostPublicText] = useState(
      EditorState.createEmpty()
    )
    const [
      isActiveEditorSuperpostPublicText,
      setIsActiveEditorSuperpostPublicText,
    ] = useState<boolean>(false)
    const [isFullSize, setIsFullSize] = useState(false)
    const modalRef = useRef<HTMLDivElement>(null)
    const postModalWrapperNode = useRef<HTMLDivElement | null>(null)
    const [isBlogpostPreviewModalOpen, setIsBlogpostPreviewModalOpen] =
      useState(false)
    const [blogpostPreviewData, setBlogpostPreviewData] =
      useState<IBlogpostPreviewData | null>(null)
    const [
      isOpenSaveDraftBlogpostConfirmModal,
      setIsOpenSaveDraftBlogpostConfirmModal,
    ] = useState<{ open: boolean; isNeedCloseCreateModal?: boolean }>({
      open: false,
      isNeedCloseCreateModal: true,
    })
    // const [isStreampost] = useState(Boolean(draftToEdit && draftToEdit.Type === 1))
    const [
      isOpenSaveDraftStreamConfirmModal,
      setIsOpenSaveDraftStreamConfirmModal,
    ] = useState(false)
    const [prepareDraftStatus, setPrepareDraftStatus] = useState<DraftStatus>(
      DraftStatus.preparing
    )
    const [
      isOpenChangePostTypeConfirmModal,
      setIsOpenChangePostTypeConfirmModal,
    ] = useState(false)
    const [preferredPostType, setPreferredPostType] = useState(0)

    const [rewardPrice, setRewardPrice] = useState('')
    const [socialPostLink, setSocialPostLink] = useState(
      EditorState.createEmpty()
    )
    const [socialPostInfo, setSocialPostInfo] =
      useState<ISocialPostInfo | null>(null)

    const transactionActiveBlockRef = useRef<string>('0')
    const lastTransactionBlockRef = useRef<string>('0')
    const transactionCreateBlock = useRef<ITransactionCreateBlock>(
      createPost.transactionCreateBlocks.filter(
        block => block.blockKey === transactionActiveBlockRef.current
      )[0]
    )
    transactionCreateBlock.current = createPost.transactionCreateBlocks.filter(
      block => block.blockKey === transactionActiveBlockRef.current
    )[0]
    const listCreateBlock = useRef<ISendPublication[]>(
      transactionCreateBlock.current
        ? transactionCreateBlock.current.blockData
        : []
    )
    listCreateBlock.current = transactionCreateBlock.current
      ? transactionCreateBlock.current.blockData
      : []

    useEffect(() => {
      setIsOpenModalWrite()
    }, [])

    // Need for make first block H1 style
    useEffect(() => {
      if (postType === PostType.Blogpost && !draftToEdit) {
        setFirstBlockH1()
      }
    }, [postType])

    const navigate = useNavigate()

    const setFirstBlockH1 = () => {
      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === '0') {
            const newBlockData = transactionBlock.blockData.map(postBlock => {
              return postBlock.Id === '0'
                ? {
                    ...postBlock,
                    EditorData: resetBlockWithType(
                      postBlock.EditorData,
                      EditorBlockType.H1
                    ),
                  }
                : postBlock
            })
            return { ...transactionBlock, blockData: newBlockData }
          } else {
            return transactionBlock
          }
        })
      )
    }

    useEffect(() => {
      const textLength = countSymbols()
      setQuantitySymbols(textLength)
    }, [transactionActiveBlockRef.current, listCreateBlock.current.length])

    useEffect(() => {
      if (createPost.transactionCreateBlocks.length !== 0) {
        setFirstTransactionBlock(createPost.transactionCreateBlocks[0].blockKey)
      }
    }, [createPost.transactionCreateBlocks.length])

    useBodyDisableScroll(renderType === 'default', main.isPWA)

    const countSymbols = () => {
      return listCreateBlock.current.reduce(
        (acc, current) =>
          getPostLengthFromEditorState(current.EditorData) + acc,
        0
      )
    }

    // const convertFileToBase64 = (file: File) => {
    //   const reader = new FileReader()
    //   reader.onload = function(base64) {
    //     localStorage['file'] = base64
    //     // localStorage['file'] = reader.result
    //   }
    //   reader.readAsDataURL(file)
    // }
    //
    // const convertBase64ToFile = () => {
    //   // Don't work
    //   const base64 = localStorage["file"];
    //   const base64Parts = base64.split(',')
    //   const fileFormat = base64Parts[0].split(';')[1]
    //   const fileContent = base64Parts[1]
    //   const file = new File([fileContent], 'file', { type: fileFormat })
    //   console.log('file--', file)
    //   return file
    // }

    //---▼▼▼---Close "Create Post Modal"---▼▼▼---\\

    const closeModal = (clearMintData?: boolean) => {
      const getMintData = (localStorage?: boolean): IMintData => {
        return {
          data: createPost.transactionCreateBlocks.map(
            (item: ITransactionCreateBlock) => ({
              blockKey: item.blockKey,
              blockData: item.blockData.map(block => {
                return {
                  ...block,
                  EditorData: localStorage
                    ? (convertToRaw(
                        block.EditorData.getCurrentContent()
                      ) as any)
                    : block.EditorData,
                  IpfsFiles: localStorage
                    ? []
                    : block.IpfsFiles.map(file =>
                        file.IsFileChecked
                          ? { ...file, IsFileChecked: false }
                          : file
                      ),
                }
              }),
              isAdultContent: localStorage ? false : item.isAdultContent,
              postOnX: localStorage ? false : item.postOnX,
            })
          ),
          superPostPublicText: localStorage
            ? (convertToRaw(superPostPublicText.getCurrentContent()) as any)
            : superPostPublicText,
          postType: postType,
        }
      }
      const mintDataType = 'mintData'

      if (postType === PostType.Streampost) {
        localStorage.removeItem(mintDataType)
        createPost.setMintData({
          data: null,
          superPostPublicText: null,
          postType: PostType.Blockchain,
        })
      } else if (postType === PostType.Blogpost) {
        localStorage.removeItem(mintDataType)
        createPost.setMintData({
          data: null,
          superPostPublicText: null,
          postType: postType,
        })
      } else if (clearMintData) {
        localStorage.removeItem(mintDataType)
        createPost.setMintData(null)
      } else {
        main.setLocalStorage(mintDataType, JSON.stringify(getMintData(true)))
        createPost.setMintData(getMintData())
      }

      setIsOpen(false) // close modal with slide effect
      setIsCloseModalOpen(false)
      if (renderType === 'default') {
        setPostType(PostType.Blockchain)
      }
      clearPostData()

      // remove modal from dom
      setTimeout(() => {
        handleOpenCreatePostModal(false)
      }, 300)
    }

    const setIsOpenModalWrite = async () => {
      clearPostData()

      if (profile.getUserRole() === UserRole.User) {
        const mintDataType = 'mintData'
        const mintDataLocal = JSON.parse(
          main.getLocalStorage(mintDataType) as string
        )
        if (createPost.mintData) {
          if (createPost.mintData.postType === PostType.Freepost) {
            createPost.mintData.data &&
              createPost.setTransactionCreateBlocks(createPost.mintData.data)
          }
        } else if (
          mintDataLocal &&
          mintDataLocal.postType === PostType.Freepost
        ) {
          mintDataLocal.data &&
            createPost.setTransactionCreateBlocks(
              mintDataLocal.data.map((item: ITransactionCreateBlock) => ({
                ...item,
                blockData: item.blockData.map(block => {
                  return {
                    ...block,
                    EditorData: EditorState.createWithContent(
                      convertFromRaw(block.EditorData as any)
                    ),
                  }
                }),
              }))
            )
        }

        setPostType(PostType.Freepost)
      } else if (draftToEdit) {
        if (draftToEdit.Type === 0) {
          createPost.setTransactionCreateBlocks([
            {
              blockKey: '0',
              blockData: [
                {
                  EditorData: draftToEdit.Text
                    ? EditorState.createWithContent(
                        convertFromRaw(JSON.parse(draftToEdit.Text))
                      )
                    : EditorState.createEmpty(),
                  Id: '0',
                  IpfsFiles: [],
                  IsEncrypted: false,
                },
              ],
              isAdultContent: false,
              postOnX: false,
            },
          ])

          if (
            draftToEdit.PreviewLink ||
            draftToEdit.PreviewLinkCdn ||
            draftToEdit.Title ||
            draftToEdit.Description
          ) {
            setBlogpostPreviewData({
              previewCdn: draftToEdit.PreviewLinkCdn
                ? draftToEdit.PreviewLinkCdn
                : '',
              preview: draftToEdit.PreviewLink ? draftToEdit.PreviewLink : '',
              title: draftToEdit.Title
                ? EditorState.createWithContent(
                    convertFromRaw(JSON.parse(draftToEdit.Title))
                  )
                : EditorState.createEmpty(),
              description: draftToEdit.Description
                ? EditorState.createWithContent(
                    convertFromRaw(JSON.parse(draftToEdit.Description))
                  )
                : EditorState.createEmpty(),
            })
          }

          setPostType(PostType.Blogpost)
        }

        if (draftToEdit.Type === 1) {
          const streamFile = draftToEdit.IpfsFiles[0]

          createPost.setTransactionCreateBlocks([
            {
              blockKey: '0',
              blockData: [
                {
                  EditorData: draftToEdit.Text
                    ? EditorState.createWithContent(
                        convertFromRaw(JSON.parse(draftToEdit.Text))
                      )
                    : EditorState.createEmpty(),
                  Id: '0',
                  IpfsFiles: streamFile
                    ? [
                        {
                          ...streamFile,
                          Id: '0',
                          VideoAudioTitle: draftToEdit.Title
                            ? EditorState.createWithContent(
                                convertFromRaw(JSON.parse(draftToEdit.Title))
                              )
                            : EditorState.createEmpty(),
                          VideoAudioDescription: draftToEdit.Description
                            ? EditorState.createWithContent(
                                convertFromRaw(
                                  JSON.parse(draftToEdit.Description)
                                )
                              )
                            : EditorState.createEmpty(),
                        },
                      ]
                    : [],
                  IsEncrypted: false,
                },
              ],
              isAdultContent: false,
              postOnX: false,
            },
          ])

          setPrepareDraftStatus(draftToEdit.Status)
          setPostType(PostType.Streampost)
        }
      } else {
        const mintDataType = 'mintData'
        const mintDataLocal = JSON.parse(
          main.getLocalStorage(mintDataType) as string
        )
        if (createPost.mintData) {
          createPost.mintData.data &&
            createPost.setTransactionCreateBlocks(createPost.mintData.data)
          createPost.mintData.superPostPublicText &&
            setSuperPostPublicText(createPost.mintData.superPostPublicText)
          setPostType(createPost.mintData.postType)
        } else if (mintDataLocal) {
          mintDataLocal.data &&
            createPost.setTransactionCreateBlocks(
              mintDataLocal.data.map((item: ITransactionCreateBlock) => ({
                ...item,
                blockData: item.blockData.map(block => {
                  return {
                    ...block,
                    EditorData: EditorState.createWithContent(
                      convertFromRaw(block.EditorData as any)
                    ),
                  }
                }),
              }))
            )
          mintDataLocal.superpostPublicText &&
            setSuperPostPublicText(
              EditorState.createWithContent(
                convertFromRaw(mintDataLocal.superpostPublicText)
              )
            )
          setPostType(mintDataLocal.postType)
        }
      }

      setWindowHeight(window.innerHeight)
      setBlockchainIdTransactionBlocks({ '0': 'blockchainId' })
      setIsOpen(true) //maybe need
      //Next lines in test
      setTimeout(() => {
        const editorBlock = document.getElementById(`editorBlock_0`)
        if (editorBlock) {
          editorBlock.click()
          setTimeout(() => {
            setHeightModal(modalRef.current?.getBoundingClientRect().height)
          }, 1000)
        }
      }, 100)
      //Before lines in test
    }

    const clearPostData = () => {
      setActiveBlock('0')
      transactionActiveBlockRef.current = '0'
      lastTransactionBlockRef.current = '0'
      transactionCreateBlock.current = createPost.transactionCreateBlocks[0]
      listCreateBlock.current = []
      setFirstTransactionBlock('0')
      setQuantitySymbols(0)
      Object.keys(blockchainIdTransactionBlocks).length &&
        setBlockchainIdTransactionBlocks({})
      createPost.setTransactionCreateBlocksDefault()
      createPost.setThreadPublicationId()
      setSuperpostPrice('')
      setSuperPostPublicText(EditorState.createEmpty())
      setSocialPostLink(EditorState.createEmpty())
      setRewardPrice('')
      setSocialPostInfo(null)
      setBlogpostPreviewData(null)
    }

    const onCreateNewPostBlock = (index: number) => {
      let uid = uniqueId() //-◄-Create unique ID from new block

      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            const newBlockData = transactionBlock.blockData.concat([
              {
                EditorData: EditorState.createEmpty(),
                Id: uid,
                IpfsFiles: [],
              },
            ])
            return { ...transactionBlock, blockData: newBlockData }
          } else {
            return transactionBlock
          }
        })
      )

      setActiveBlock(uid) //-◄-Set active created block
      //-▼-Add a timer for render new block
      setTimeout(() => {
        const editorBlock = document.getElementById(`editorBlock_${uid}`)
        if (editorBlock) {
          editorBlock.click()
        }
      }, 100)
    }

    const onChangeText = (
      id: string,
      editorData: EditorState,
      transactionBlockKey: string
    ) => {
      const transactionBlock: ITransactionCreateBlock =
        createPost.transactionCreateBlocks.filter(
          block => block.blockKey === transactionBlockKey
        )[0]
      const activeBlock: ISendPublication[] = transactionBlock
        ? transactionBlock.blockData
        : []

      const textLengthTransactionBlock = activeBlock.reduce(
        (acc, current) =>
          current.Id === id
            ? acc
            : getPostLengthFromEditorState(current.EditorData) + acc,
        0
      )

      const editorDataTextLength = getPostLengthFromEditorState(editorData)
      const newTextLengthTransactionBlock =
        textLengthTransactionBlock + editorDataTextLength

      //For now is not enable
      // // Check only if is a last transaction block
      // if (newTextLengthTransactionBlock > 5000 && lastTransactionBlockRef.current === transactionBlockKey) {
      //   let postText = ''
      //   const textOldBlocks: { blocks: DraftModel.Encoding.RawDraftContentBlock[] }[] = []
      //   const convertedRawData = convertToRaw(editorData.getCurrentContent())
      //
      //   convertedRawData.blocks.every((item, idx) => {
      //     postText += idx === 0 ? item.text : '\n' + item.text
      //
      //     if (postText.length > 5000 && textOldBlocks.length === 0) {
      //       const editorDataRaw = convertToRaw(editorData.getCurrentContent())
      //       const blocks = editorDataRaw.blocks.slice(0, idx + 1)
      //       const blocksTextLength = getPostLengthFromEditorStateRawBlocks(blocks)
      //       const overCount = blocksTextLength - 5000
      //       blocks[blocks.length - 1].text = blocks[blocks.length - 1].text.slice(0, -overCount)
      //       if (blocks[blocks.length - 1].text.length === 0 && getPostLengthFromEditorStateRawBlocks(blocks) > 5000) {
      //         blocks.splice(-1)
      //       }
      //       textOldBlocks.push({ blocks })
      //       return false
      //     }
      //     return true
      //   })
      //
      //   const extraTextLength = newTextLengthTransactionBlock - 5000
      //   // const textOldBlock = value.slice(0, value.length - extraTextLength)
      //   // const textNewBlock = value.slice(-extraTextLength)
      //   const textNewBlock = getPostTextFromEditorState(editorData).slice(-extraTextLength)
      //   convertedRawData.blocks = textOldBlocks[0].blocks
      //
      //   const textNewBlockArr = textNewBlock.split('')
      //   const arrTextChunks = []
      //   while (textNewBlockArr.length > 0) {
      //     const chunk = textNewBlockArr.splice(0, 5000).join('')
      //     arrTextChunks.push(chunk)
      //   }
      //
      //   // Old Block
      //   createPost.setTransactionCreateBlocks(createPost.transactionCreateBlocks.map(transactionBlock => {
      //     if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
      //       const newBlockData = transactionBlock.blockData.map(postBlock => {
      //         return postBlock.Id === id ? {
      //           ...postBlock,
      //           EditorData: EditorState.createWithContent(convertFromRaw(convertedRawData)),
      //         } : postBlock
      //       })
      //       return { blockKey: transactionBlock.blockKey, blockData: newBlockData }
      //     } else {
      //       return transactionBlock
      //     }
      //   }))
      //   // setQuantitySymbols(textLengthTransactionBlock + textOldBlock.length) ///????
      //   const textLengthOldBlock = getPostLengthFromEditorStateRawBlocks(convertedRawData.blocks)
      //   setQuantitySymbols(textLengthTransactionBlock + textLengthOldBlock) //?????
      //
      //   //Generate new blocks
      //   arrTextChunks.forEach(chunk => {
      //     const postUniqueId = uniqueId()
      //     const transactionBlockUniqueId = uniqueId()
      //     const newEditorState = EditorState.createEmpty()
      //     const convertedRawData = convertToRaw(newEditorState.getCurrentContent())
      //     convertedRawData.blocks[0].text = chunk
      //
      //     // ?????
      //     //const blockchainId = await blockchainId from server
      //     setBlockchainIdTransactionBlocks(prev => ({ ...prev, [postUniqueId]: 'blockchainId' }))
      //     createPost.setTransactionCreateBlocks(createPost.transactionCreateBlocks.concat([{
      //       blockKey: transactionBlockUniqueId,
      //       blockData: [{
      //         EditorData: EditorState.createWithContent(convertFromRaw(convertedRawData)),
      //         Id: postUniqueId,
      //         IpfsFiles: [],
      //       }],
      //     }]))
      //
      //     setActiveBlock(postUniqueId)
      //     transactionActiveBlockRef.current = transactionBlockUniqueId
      //     lastTransactionBlockRef.current = transactionBlockUniqueId
      //     setTimeout(() => {
      //       const editorBlock = document.getElementById(`editorBlock_${postUniqueId}`)
      //       if (editorBlock) {
      //         editorBlock.click()
      //       }
      //     }, 100)
      //   })
      // } else {
      setQuantitySymbols(newTextLengthTransactionBlock)
      // setQuantitySymbols(textLengthTransactionBlock + editorDataTextLength)
      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            const newBlockData = transactionBlock.blockData.map(postBlock => {
              return postBlock.Id === id
                ? { ...postBlock, EditorData: editorData }
                : postBlock
            })
            return { ...transactionBlock, blockData: newBlockData }
          } else {
            return transactionBlock
          }
        })
      )
      // }
    }

    const onChangeAdultContent = (isAdult: boolean, id: string) => {
      console.log('onChangeAdultContent', isAdult)
      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            return { ...transactionBlock, isAdultContent: isAdult }
          } else {
            return transactionBlock
          }
        })
      )
    }

    const onChangePostOnTwitter = (toPost: boolean, id: string) => {
      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            return { ...transactionBlock, postOnX: toPost }
          } else {
            return transactionBlock
          }
        })
      )
    }

    const onAddFile = async (id: string, fileList: IIpfsFiles[]) => {
      let blockId = listCreateBlock.current.findIndex(v => v.Id === id)
      let newList = cloneDeep(listCreateBlock.current)
      if (blockId !== -1) {
        let newIpfsFiles = cloneDeep(newList[blockId].IpfsFiles)
        newIpfsFiles = [...newIpfsFiles, ...fileList]

        // if (createPost.transactionCreateBlocks[blockId].postOnX) {
        // }

        // const imageFiles = newIpfsFiles.filter(
        //   item => item.FileType === createPostFileType.image
        // )
        // if (imageFiles.length > 4) {
        if (newIpfsFiles.length > 4) {
          return toast({
            type: 'warning',
            // message: 'You can only post 4 images',
            message: 'You can only post 4 files',
          })
        }

        newList[blockId].IpfsFiles = newIpfsFiles

        createPost.setTransactionCreateBlocks(
          createPost.transactionCreateBlocks.map(transactionBlock => {
            if (
              transactionBlock.blockKey === transactionActiveBlockRef.current
            ) {
              return { ...transactionBlock, blockData: newList }
            } else {
              return transactionBlock
            }
          })
        )
      }
    }

    const changeIPFSFiles = (
      id: string,
      callback: (IpfsFiles: IIpfsFiles) => IIpfsFiles
    ) => {
      let blockId = listCreateBlock.current.findIndex(v => v.Id === id)
      let newList = cloneDeep(listCreateBlock.current)
      if (blockId !== -1) {
        let newIpfsFiles = cloneDeep(newList[blockId].IpfsFiles)

        newList[blockId].IpfsFiles = newIpfsFiles.map(callback)

        createPost.setTransactionCreateBlocks(
          createPost.transactionCreateBlocks.map(transactionBlock => {
            if (
              transactionBlock.blockKey === transactionActiveBlockRef.current
            ) {
              return { ...transactionBlock, blockData: newList }
            } else {
              return transactionBlock
            }
          })
        )
      }
    }

    const onAddVideoPoster = useCallback(
      async (
        id: string,
        poster: string | undefined,
        defaultPoster: boolean,
        fileId: string
      ) => {
        const callback = (file: IIpfsFiles) => {
          return file.Id === fileId
            ? {
                ...file,
                VideoPreview: !defaultPoster ? poster : file.VideoPreview,
                DefaultVideoPreview: defaultPoster
                  ? poster
                  : file.DefaultVideoPreview,
              }
            : file
        }

        changeIPFSFiles(id, callback)
      },
      []
    )

    const onAddVideoDuration = async (id: string, duration: number) => {
      const callback = (file: IIpfsFiles) => {
        return file.FileType === createPostFileType.video ||
          file.FileType === createPostFileType.spatial_video
          ? {
              ...file,
              Duration: duration,
            }
          : file
      }

      changeIPFSFiles(id, callback)
    }

    const onAddAudiWaveForm = async (id: string, waveForm: string) => {
      const callback = (file: IIpfsFiles) => {
        return file.FileType === createPostFileType.audio
          ? {
              ...file,
              WaveFormInfo: waveForm,
            }
          : file
      }

      changeIPFSFiles(id, callback)
    }

    const onCheckFile = async (id: string, fileId: string) => {
      const callback = (file: IIpfsFiles) => {
        return file.Id === fileId
          ? {
              ...file,
              IsFileChecked: true,
            }
          : file
      }

      changeIPFSFiles(id, callback)
    }

    const onChangeTitleDescription = async (
      id: string,
      text: EditorState,
      transactionBlockKey: string,
      type: 'title' | 'description',
      fileID: string
    ) => {
      // const transactionBlock: ITransactionCreateBlock = createPost.transactionCreateBlocks.filter(block => block.blockKey === transactionBlockKey)[0]

      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            const newBlockData = transactionBlock.blockData.map(postBlock => {
              return postBlock.Id === id
                ? {
                    ...postBlock,
                    IpfsFiles: postBlock.IpfsFiles.map(item => {
                      if (
                        item.Id === fileID &&
                        (item.FileType === createPostFileType.video ||
                          item.FileType === createPostFileType.audio ||
                          item.FileType === createPostFileType.stream ||
                          item.FileType === createPostFileType.spatial_video)
                      ) {
                        return type === 'title'
                          ? { ...item, VideoAudioTitle: text }
                          : { ...item, VideoAudioDescription: text }
                      } else {
                        return item
                      }
                    }),
                  }
                : postBlock
            })
            return { ...transactionBlock, blockData: newBlockData }
          } else {
            return transactionBlock
          }
        })
      )
    }

    //---▼▼▼--- On delete some file in block on "Create Post Modal"---▼▼▼---\\
    const onDeleteFile = (postId: string, key: string) => {
      let newListCreateBlock = cloneDeep(listCreateBlock)

      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            const newList = newListCreateBlock.current.map(block => {
              if (block.Id === postId) {
                const newIpfsFiles = block.IpfsFiles.filter(
                  item => item.FileLink !== key
                )
                return { ...block, IpfsFiles: newIpfsFiles }
              } else {
                return block
              }
            })
            return { ...transactionBlock, blockData: newList }
          } else {
            return transactionBlock
          }
        })
      )
    }

    const onEditFile = (postId: string, key: string, newFile: IIpfsFiles) => {
      let newListCreateBlock = cloneDeep(listCreateBlock)

      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            const newList = newListCreateBlock.current.map(block => {
              if (block.Id === postId) {
                const newIpfsFiles = block.IpfsFiles.map(item => {
                  return item.FileLink === key ? newFile : item
                })
                return { ...block, IpfsFiles: newIpfsFiles }
              } else {
                return block
              }
            })
            return { ...transactionBlock, blockData: newList }
          } else {
            return transactionBlock
          }
        })
      )
    }

    //---▼▼▼--- On delete some block on "Create Post Modal"---▼▼▼---\\
    const onDeleteBlock = (id: string) => {
      let blockId = listCreateBlock.current.findIndex(v => v.Id === id) //-◄-Find block index
      //-▼-If block find
      if (blockId !== -1) {
        let newList = cloneDeep(listCreateBlock.current) //-◄-Clone block lists
        newList.splice(blockId, 1) //-◄-Delete block

        createPost.setTransactionCreateBlocks(
          createPost.transactionCreateBlocks.map(transactionBlock => {
            if (
              transactionBlock.blockKey === transactionActiveBlockRef.current
            ) {
              return { ...transactionBlock, blockData: newList }
            } else {
              return transactionBlock
            }
          })
        )

        setActiveBlock(listCreateBlock.current[blockId - 1].Id) //-◄-Set active previous block
        let previousEditorBlock = document.getElementById(
          `editorBlock_${listCreateBlock.current[blockId - 1].Id}`
        )
        if (previousEditorBlock) {
          previousEditorBlock.click()
        }
      }
    }

    const deleteTransactionCreateBlock = (blockKey: string) => {
      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.filter(transactionBlock => {
          return transactionBlock.blockKey !== blockKey
        })
      )

      const lastTransactionBlock =
        createPost.transactionCreateBlocks[
          createPost.transactionCreateBlocks.length - 1
        ]
      const lastPostBlock =
        lastTransactionBlock.blockData[
          lastTransactionBlock.blockData.length - 1
        ]
      setActiveBlock(lastPostBlock.Id)
      transactionActiveBlockRef.current = lastTransactionBlock.blockKey
      lastTransactionBlockRef.current = lastTransactionBlock.blockKey

      const activeEditorBlock = document.getElementById(
        `editorBlock_${lastPostBlock.Id}`
      )

      if (activeEditorBlock) {
        activeEditorBlock.click()
      }
    }

    const handleCreateBlockchainPost = async (list: ISendPublication[]) => {
      try {
        if (!isAvailableBlockchainProviderToast()) {
          loading.setLoading(false)
          return
        }
        const { AuthencityObjectERC721, userWallet, chainId } =
          await getAuthencityObjectERC721AndUserWallet()

        if (
          getChainIdFromPaymentCurrency(paymentCurrency.currency) !== chainId
        ) {
          loading.setLoading(false)
          return toast({
            type: 'warning',
            message: 'The selected network does not match the currency',
          })
        }

        if (!AuthencityObjectERC721) {
          loading.setLoading(false)
          return toast({
            type: 'warning',
            message: 'Choose the Ethereum or Polygon network',
          })
        }

        createPost.setThreadPublicationId(undefined)
        const networkType = getNetworkTypeFromPaymentCurrency(
          paymentCurrency.currency
        )
        const post = await createPost.createPost(
          list,
          0,
          null,
          createPost.threadPublicationId,
          networkType,
          true,
          renderType
        )
        createPost.setThreadPublicationId(post.Id)
        const tokenId = post.Id
        const tokenURI = `${process.env.REACT_APP_LINK_OBJECT_TOKEN_URI}${tokenId}`
        const tokenType = getBlockchainPostType(postType)

        if (list[0].IsEncrypted && list[0].TaggedNickname) {
          const encryptedData = await publicationStore.getEncryptedText(
            Number(tokenId),
            getEditorStateAsString(list[0].EditorData),
            list[0].TaggedNickname
          )
          // Set Text encrypted
          list[0].Text = encryptedData
        }

        let superpostIpfsFiles: IIpfsFiles[] = [] //need for backend
        if (list[0].IsSuperpost && list[0].EditorData) {
          superpostIpfsFiles = list[0].IpfsFiles
          const IpfsFiles = superpostIpfsFiles.map(item => ({
            CustomIpfsFileId: item.Id,
            FileLink: item.FileLink,
          }))
          // Need for blockchain
          const superpostEncryptedData =
            await publicationStore.getEncryptedTextForSuperpost(
              Number(tokenId),
              getEditorStateAsString(list[0].EditorData),
              IpfsFiles
            )
          list[0].Text = superpostEncryptedData.Item1
          list[0].IpfsFiles = superpostIpfsFiles.map(file => ({
            ...file,
            FileLink: superpostEncryptedData.Item2.find(
              (item: { CustomIpfsFileId: string; FileLink: string }) =>
                item.CustomIpfsFileId === file.Id
            ).FileLink,
          }))
        }

        const postAsStringWithID = await getPostAsString(list, { id: tokenId })

        //need for backend
        if (list[0].IsSuperpost) {
          list[0].IpfsFiles = superpostIpfsFiles
        }

        AuthencityObjectERC721.mintWithTokenObjectData(
          userWallet, //to - the address of the wallet
          tokenId, //tokenId
          tokenURI.toString(), //tokenURI
          tokenType, //tokenType
          postAsStringWithID, //tokenData
          '', //tokenKey
          // tokenId //databaseKey - it doesn't work
          '' //databaseKey - it works
        )
          .then(async (res: any) => {
            if (res) {
              // console.log('mintWithTokenObjectData', res)
              const post = await createPost.createPost(
                list,
                tokenId,
                res.hash,
                createPost.threadPublicationId,
                networkType,
                undefined,
                renderType
              )

              if (post) {
                if (list[0].IsBlogpost || list[0].IsStreampost) {
                  try {
                    draftToEdit?.Id &&
                      (await publicationStore.deleteDraft(draftToEdit.Id))
                  } catch (e) {
                    console.log('deleteDraft', e)
                  }
                }

                publicationStore.setLastCreatedPublicationId(Number(post.Id))

                if (signalR.connection) {
                  try {
                    await signalR.connection.invoke('SendMessage', post.Id)
                  } catch (e) {
                    console.log(e)
                  }
                }

                createPost.setTransactionCreateBlocks(
                  createPost.transactionCreateBlocks.filter(block => {
                    return block.blockKey !== firstTransactionBlock
                  })
                )

                setDefaultPostsSettings && (await setDefaultPostsSettings())
                getPublication &&
                  (await getPublication(false, true, defaultLoadPage, 0))

                if (createPost.transactionCreateBlocks.length === 0) {
                  await closeModal(true)
                } else {
                  const lastPostBlockInActiveBlock =
                    createPost.transactionCreateBlocks[0].blockData[
                      createPost.transactionCreateBlocks[0].blockData.length - 1
                    ]

                  transactionActiveBlockRef.current =
                    createPost.transactionCreateBlocks[0].blockKey
                  lastTransactionBlockRef.current =
                    createPost.transactionCreateBlocks[
                      createPost.transactionCreateBlocks.length - 1
                    ].blockKey
                  setActiveBlock(lastPostBlockInActiveBlock.Id)

                  let activeEditorBlock = document.getElementById(
                    `editorBlock_${lastPostBlockInActiveBlock.Id}`
                  )

                  if (activeEditorBlock) {
                    activeEditorBlock.focus()
                  }
                }

                if (renderType === 'widget') {
                  widget.onCreatePost()
                }

                if (redirectToNewPublication) {
                  navigate('/post/' + post.Id)
                }

                toast({
                  type: 'success',
                  message: 'Post has been created',
                })
              }
              loading.setLoading(false)
              transaction.setIsActive(false)
            } else {
              loading.setLoading(false)
              transaction.setIsActive(false)
              toast({
                type: 'warning',
                message: 'Failed to create publication',
              })
            }
          })
          .catch((e: any) => {
            loading.setLoading(false)
            console.log('e', e)
            const errorMessage = () => {
              if (e.code === 'ACTION_REJECTED' || e.code === 4001) {
                return `User denied transaction signature.`
              } else if (e.error) {
                return `Metamask error code: ${e.error?.code} ${e.error?.message}`
              } else {
                return e.message ? e.message : 'Some error'
              }
            }
            toast({
              type: 'warning',
              message: errorMessage(),
            })
          })

        // const accountNonce = '0x6' + (await signer.getTransactionCount() + 1).toString(16)
      } catch (e: any) {
        console.log(e.message)

        loading.setLoading(false)
        toast({
          type: 'warning',
          message: 'Failed to create publication',
        })
      }
    }

    // FREE POST
    const handleCreateFreePost = async (data: ISendPublication[]) => {
      try {
        const post = await createPost.createFreePost(data, renderType)

        if (post) {
          if (signalR.connection) {
            try {
              await signalR.connection.invoke('SendMessage', post.Id)
            } catch (e) {
              console.log(e)
            }
          }

          createPost.setTransactionCreateBlocks(
            createPost.transactionCreateBlocks.filter(block => {
              return block.blockKey !== firstTransactionBlock
            })
          )

          setDefaultPostsSettings && (await setDefaultPostsSettings())
          getPublication &&
            (await getPublication(false, true, defaultLoadPage, 0))
          await closeModal(true)

          if (renderType === 'widget') {
            widget.onCreatePost()
          }

          if (redirectToNewPublication) {
            navigate('/post/' + post.Id)
          }

          toast({
            type: 'success',
            message: 'Post has been created',
          })
        }
        loading.setLoading(false)
        transaction.setIsActive(false)
      } catch (e: any) {
        console.log(e.message)

        loading.setLoading(false)
        toast({
          type: 'warning',
          message: 'Failed to create publication',
        })
      }
    }

    const uploadFileToServer = async (file: File) => {
      try {
        return await createPost.uploadLargeFile(file)
      } catch (error: any) {
        console.error('Error uploading the file:', error)
        throw new Error(error.message)
      }
    }

    const onCreatePost = async (postType?: PostType) => {
      try {
        let newList = cloneDeep(listCreateBlock.current)

        const isAdultContent = transactionCreateBlock.current.isAdultContent
        const postOnX = transactionCreateBlock.current.postOnX

        newList = newList.map(blockData => ({
          ...blockData,
          IsAdultContent: isAdultContent,
          PostOnX: postOnX,
        }))

        // STREAMPOST rules ⬇︎⬇︎⬇︎
        if (postType === PostType.Streampost) {
          newList = newList.map(blockData => ({
            ...blockData,
            IsStreampost: true,
          }))
        }
        // STREAMPOST rules ⬆︎⬆︎⬆︎

        // ENCRYPTED rules ⬇︎⬇︎⬇︎
        if (postType === PostType.Encrypted) {
          newList = newList.map(blockData => ({
            ...blockData,
            IsEncrypted: true,
          }))

          const CanDecryptNickname = getCanDecryptNicknames(
            newList[0].EditorData
          )[0]
          if (!CanDecryptNickname) {
            return toast({
              type: 'warning',
              message: 'Specify a user who can read this message',
            })
          }

          // Set TaggedNickname
          newList[0].TaggedNickname = CanDecryptNickname
        }
        // ENCRYPTED rules ⬆︎⬆︎⬆︎

        // SUPERPOST rules ⬇︎⬇︎⬇︎
        if (postType === PostType.Superpost) {
          newList = newList.map((blockData, idx) =>
            idx === 0
              ? {
                  ...blockData,
                  IsSuperpost: true,
                  PublicText: superPostPublicText,
                  PriceToOpenPost: Number(superpostPrice),
                }
              : blockData
          )

          if (superpostPrice) {
            if (Number(superpostPrice) < 100) {
              return toast({
                type: 'warning',
                message: 'Price must be 100 AUTH or more',
              })
            }
          } else {
            return toast({
              type: 'warning',
              message: 'Enter the price for unlocking the post',
            })
          }

          const superPostPublicTextLength =
            getPostLengthFromEditorState(superPostPublicText)

          if (superPostPublicTextLength === 0) {
            return toast({
              type: 'warning',
              message: 'Public text is required',
            })
          }

          if (superPostPublicTextLength > 200) {
            return toast({
              type: 'warning',
              message:
                'The number of characters in the public text exceed the limit of 200',
            })
          }

          if (
            superPostPublicTextLength <=
            (getPostTextFromEditorState(superPostPublicText).match(/ /g) || [])
              .length
          ) {
            return toast({
              type: 'warning',
              message: 'Invalid public text!',
            })
          }

          if (
            checkNumberOfLineBreaks(
              getPostTextFromEditorState(superPostPublicText)
            )
          ) {
            return toast({
              type: 'warning',
              message:
                'You are not allowed to use more than 2 line breaks together in public text',
            })
          }

          if (checkBadWords(getPostTextFromEditorState(superPostPublicText))) {
            return toast({
              type: 'warning',
              message:
                'You are not allowed to use prohibited words in public text',
            })
          }
        }
        // SUPERPOST rules ⬆︎⬆︎⬆︎

        // SOCIAL POST rules ⬇︎⬇︎⬇︎
        if (postType === PostType.SocialPost) {
          const xPostMediaAttachments = await Promise.all(
            (socialPostInfo as any)?.XPostMediaAttachments?.map(
              async (e: any) =>
                await uploadFileByLink(e?.FileLink, 'socialPosts')
            )
          ).catch(() => [])

          newList = newList.map((blockData, idx) =>
            idx === 0
              ? {
                  ...blockData,
                  IsXPost: true,
                  XPostInfo: {
                    ...socialPostInfo,
                    XRewardPrice: rewardPrice,
                    XPostMediaAttachments: xPostMediaAttachments,
                  } as ISocialPostInfo,
                }
              : blockData
          )

          if (rewardPrice) {
            if (Number(rewardPrice) < 10) {
              return toast({
                type: 'warning',
                message: 'Price must be 10 AUTH or more',
              })
            }
          } else {
            return toast({
              type: 'warning',
              message: 'Enter the reward price',
            })
          }

          if (
            getPostLengthFromEditorState(socialPostLink) <=
            (getPostTextFromEditorState(socialPostLink).match(/ /g) || [])
              .length
          ) {
            return toast({
              type: 'warning',
              message: 'Enter X Post link',
            })
          }

          const socialPostLinkTextLength =
            socialPostInfo?.XPostLink?.length || 0

          if (socialPostLinkTextLength === 0) {
            return toast({
              type: 'warning',
              message: 'Generate X Post preview',
            })
          }
        }
        // SOCIAL POST rules ⬆︎⬆︎⬆︎

        // Is empty post
        if (
          postType !== PostType.SocialPost &&
          createPost.isPostEmptyListToast(newList)
        )
          return

        // Is right quantity
        if (createPost.isRightQuantitySymbolsToast(quantitySymbols)) return

        // Is two line breaks together
        if (createPost.isTwoLineBreaksTogetherListToast(newList)) return

        // Is contain bad words
        if (createPost.isContainBadWordsListToast(newList)) return

        if (
          newList.some(item =>
            item.IpfsFiles.some(
              file =>
                file.FileType === createPostFileType.file && !file.IsFileChecked
            )
          )
        ) {
          return toast({
            type: 'warning',
            message: 'You need to wait for the file to be checked',
          })
        }

        const isWrongAudioVideoAdditionalInfo = newList.some(item => {
          return createPost.isWrongAudioVideoAdditionalInfoToast(item.IpfsFiles)
        })

        if (isWrongAudioVideoAdditionalInfo) return

        if (postType === PostType.Blockchain) {
          if (!isAvailableBlockchainProviderToast()) return
          if (!(await isSelectedCorrectCurrency(paymentCurrency.currency)))
            return
        }

        // -▼-Else send all file ti IPFS
        loading.setLoading(true)
        transaction.setIsActive(true)
        await Promise.all(
          newList.map(async (post: ISendPublication) => {
            await Promise.all(
              post.IpfsFiles.map(async (file: IIpfsFiles, idx: number) => {
                if (postType !== PostType.Streampost) {
                  // // upload 3D image to server and receive converted file
                  // if (file.Is3DImage) {
                  //   const file3DImage = await createPost.upload3DImage(
                  //     file.FileLink as unknown as File
                  //   )
                  //
                  //   file.FileLink = file3DImage || file?.FileLink
                  // }

                  const [link, cdnLink] = await Promise.all([
                    postType === PostType.Freepost
                      ? ''
                      : (async () => {
                          const ipfsFile = await ipfs.add(file.FileLink)
                          if (file.Size && file.Size > fileSize['512MB']) {
                            console.log('add pin', file.Size)
                            const pinAdded = await ipfs.pin.add(ipfsFile.cid)
                          }
                          return `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}`
                        })(),

                    addFileToDigitalOceanSpaces(
                      file?.FileLink as any,
                      'publications'
                    ),
                  ])

                  if (postType === PostType.Freepost) {
                    file.FileLink = cdnLink
                    file.CdnFileLink = cdnLink
                  } else {
                    file.FileLink = link
                    file.CdnFileLink = cdnLink
                  }
                }

                if (file.VideoPreview) {
                  if ((file.VideoPreview as any) instanceof File) {
                    // if (postType === PostType.Freepost) {
                    const posterCdn = await addFileToDigitalOceanSpaces(
                      file.VideoPreview,
                      'videos/preview'
                    )

                    file.VideoPreview = posterCdn
                    // } else {
                    //   const poster = await ipfs.add(file.VideoPreview)
                    //   file.VideoPreview = `${process.env.REACT_APP_IPFS_GATEWAY}${poster.path}`
                    // }
                  } else {
                    file.VideoPreview = (
                      await getIpfsFileSrc(file.VideoPreview)
                    ).CdnFileLink
                  }
                  if ((file.DefaultVideoPreview as any) instanceof File) {
                    file.DefaultVideoPreview = ''
                  }
                } else if (file.DefaultVideoPreview) {
                  if ((file.DefaultVideoPreview as any) instanceof File) {
                    // if (postType === PostType.Freepost) {
                    const posterCdn = await addFileToDigitalOceanSpaces(
                      file.DefaultVideoPreview,
                      'videos/preview'
                    )

                    file.DefaultVideoPreview = posterCdn
                    // } else {
                    //   const poster = await ipfs.add(file.DefaultVideoPreview)
                    //   file.DefaultVideoPreview = `${process.env.REACT_APP_IPFS_GATEWAY}${poster.path}`
                    // }
                  } else {
                    file.DefaultVideoPreview = (
                      await getIpfsFileSrc(file.DefaultVideoPreview)
                    ).FileLink
                  }
                }
              })
            )
          })
        )
        if (postType === PostType.Freepost) {
          await handleCreateFreePost(newList)
        } else {
          await handleCreateBlockchainPost(newList)
        }
      } catch (e: any) {
        toast({
          type: 'error',
          message: e.message || 'Failed to create publication',
        })
        console.log(e.message)
        loading.setLoading(false)
        transaction.setIsActive(false)
      }
    }

    //---▼▼▼--- Get total price  ---▼▼▼---\\
    const onGetPrice = async (): Promise<any> => {
      const transactionCreateBlock: ITransactionCreateBlock =
        createPost.transactionCreateBlocks.filter(
          block => block.blockKey === transactionActiveBlockRef.current
        )[0]
      const listCreateBlock: ISendPublication[] = transactionCreateBlock
        ? transactionCreateBlock.blockData
        : []
      if (
        listCreateBlock.find(item => {
          if (item?.IsXPost) {
            return !item?.XPostInfo?.XPostLink
          }

          return (
            !getPostLengthFromEditorState(item.EditorData) &&
            item.IpfsFiles.length === 0
          )
        })
      )
        return { price: '0' }
      try {
        const postAsString = await getPostAsString(listCreateBlock, {
          getPrice: true,
        })
        return await getGasPrice(postAsString)
      } catch (e: any) {
        console.log(e.message)
      }
    }

    const onOpenEmoji = async (
      top: number,
      left: number,
      isEnoughSpace: boolean
    ) => {
      //Old
      // setEmojiClickPosition({top, left, isEnoughSpace})

      //New
      if (isMobileDevice) {
        if (openMobilePicker) {
          setTimeout(() => {
            setOpenMobilePicker(false)
          }, 300)
        } else {
          setOpenMobilePicker(true)
        }
      } else {
        setEmojiClickPosition({ top, left, isEnoughSpace })
      }
    }

    const onAddEmoji = (value: BaseEmoji) => {
      const getStateWithEmoji = (
        editorState: EditorState,
        emoji: BaseEmoji
      ) => {
        const currentContent = editorState.getCurrentContent()
        const currentSelection = editorState.getSelection()
        // const newContent = Modifier.replaceText(currentContent, currentSelection, emoji.native)
        // const newEditorState = EditorState.push(editorState, newContent, 'insert-characters')
        // EditorState.forceSelection(newEditorState, newContent.getSelectionAfter())

        const stateWithEntity = currentContent.createEntity(
          'emoji',
          'IMMUTABLE',
          {
            emojiUnicode: emoji.native,
          }
        )
        const entityKey = stateWithEntity.getLastCreatedEntityKey()
        const stateWithText = Modifier.insertText(
          stateWithEntity,
          currentSelection,
          emoji.native,
          undefined,
          entityKey
        )
        const newEditorState = EditorState.push(
          editorState,
          stateWithText,
          'insert-characters'
        )
        EditorState.forceSelection(
          newEditorState,
          stateWithText.getSelectionAfter()
        )
        return newEditorState
      }

      if (isActiveEditorSuperpostPublicText) {
        return setSuperPostPublicText(prev => getStateWithEmoji(prev, value))
      }

      const blockId = listCreateBlock.current.findIndex(
        v => v.Id === activeBlock
      )
      const blockDataNew = cloneDeep(listCreateBlock.current)
      if (blockId !== -1) {
        blockDataNew[blockId].EditorData = getStateWithEmoji(
          blockDataNew[blockId].EditorData,
          value
        )

        createPost.setTransactionCreateBlocks(
          createPost.transactionCreateBlocks.map(transactionBlock => {
            if (
              transactionBlock.blockKey === transactionActiveBlockRef.current
            ) {
              return { ...transactionBlock, blockData: blockDataNew }
            } else {
              return transactionBlock
            }
          })
        )

        setQuantitySymbols(
          getPostLengthFromEditorState(blockDataNew[blockId].EditorData)
        )
      }
    }

    const onRotate360Image = useDebouncedCallback(
      (
        postId: string,
        key: string,
        position: {
          yaw: number
          pitch: number
        }
      ) => {
        try {
          const newFile = {
            Id: v4(),
            Size: (key as unknown as File).size,
            FileLink: key,
            FileType: createPostFileType.image,
            FileExtension:
              mime.getExtension?.((key as unknown as File).type) || '',
            Is360Image: true,
            Is3DImage: false,
            IsSpatialVideo: false,
            Image360Position: position,
          }

          onEditFile(postId, key, newFile)
        } catch (e) {
          console.log('onRotate360Image', e)
        }
      },
      500
    )

    const clickOnModalContentBlock = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (openMobilePicker) {
        const activeEditorBlock = (event.target as HTMLElement).closest(
          'div[id*=editorBlock_]'
        )
        if (activeEditorBlock) {
          setTimeout(() => {
            setOpenMobilePicker(false)
          }, 300)
        } else {
          setTimeout(() => {
            setOpenMobilePicker(false)
          }, 500)
        }
      }
    }

    const setTransactionActiveBlock = (value: string) => {
      transactionActiveBlockRef.current = value
    }

    const handlerChangePostType = (value: number) => {
      if (
        postType === PostType.Blogpost &&
        isNotEmptyBlogpost(listCreateBlock.current[0].EditorData)
      ) {
        if (main.isPWA) {
          setTimeout(() => {
            setIsOpenSaveDraftBlogpostConfirmModal({ open: true })
            setPostTypeAfterBlogpost(value)
          }, 300)
        } else {
          setIsOpenSaveDraftBlogpostConfirmModal({ open: true })
          setPostTypeAfterBlogpost(value)
        }
      } else {
        setPostType(value)
        clearPostData()
      }
    }

    const handlerApplyPostTypeAfterBlogpost = () => {
      setPostType(postTypeAfterBlogpost)
    }

    const openChangePostTypeConfirmModal = (type: number) => {
      let newList = cloneDeep(listCreateBlock.current)

      const checkIsEmptyPostEditor = !newList.find(item => {
        const emptyText = !getPostLengthFromEditorState(item.EditorData)
        const emptyFiles = item.IpfsFiles.length === 0

        const postTypesToCheck = renderType === 'widget' ? [0, 1] : [0, 3, 4, 5]

        if (postTypesToCheck.includes(postType)) {
          return emptyText && emptyFiles
        } else if (postType === 1 && renderType !== 'widget') {
          return emptyText && !getCanDecryptNicknames(newList[0].EditorData)[0]
        } else if (postType === 2) {
          return (
            emptyText &&
            !superpostPrice &&
            !getPostLengthFromEditorState(superPostPublicText)
          )
        }
      })

      if (checkIsEmptyPostEditor) {
        // open modal
        setPreferredPostType(type)
        setIsOpenChangePostTypeConfirmModal(true)
      } else {
        // change post type witout comfirm modal
        handlerChangePostType(type)
      }
    }

    const closeChangePostTypeConfirmModal = () => {
      setIsOpenChangePostTypeConfirmModal(false)
    }

    const handleChangePostTypeConfirmModal = () => {
      handlerChangePostType(preferredPostType)
    }

    const handleChangeSuperpostPrice = (value: string) => {
      setSuperpostPrice(value)
    }

    const handlerChangeSuperpostPublicText = (value: EditorState) => {
      setSuperPostPublicText(value)
    }

    const handlerSetActiveEditorSuperpostPublicText = (value: boolean) => {
      setIsActiveEditorSuperpostPublicText(value)
    }

    const handleChangeRewardPrice = (value: string) => {
      setRewardPrice(value)
    }

    const handleChangeSocialPostLink = (value: EditorState) => {
      setSocialPostLink(value)
    }

    const handleChangeSocialPostInfo = (value: ISocialPostInfo) => {
      setSocialPostInfo(value)

      createPost.setTransactionCreateBlocks(
        createPost.transactionCreateBlocks.map(transactionBlock => {
          if (transactionBlock.blockKey === transactionActiveBlockRef.current) {
            return {
              ...transactionBlock,
              blockData: transactionBlock.blockData?.map(bd => ({
                ...bd,
                IsXPost: true,
                XPostInfo: value,
              })),
            }
          } else {
            return transactionBlock
          }
        })
      )
    }

    const changeFullSize = () => {
      setIsFullSize(prev => !prev)
    }

    // STREAMPOST-----------------------------------------------------------------------------------------------↓↓↓
    const closeSaveDraftStreamConfirmModal = () => {
      setIsOpenSaveDraftStreamConfirmModal(false)
    }

    const handleSaveStreamInDraft = async () => {
      try {
        loading.setLoading(true)
        let newList = cloneDeep(listCreateBlock.current)

        const uploadFileToServer = async (value: any) => {
          if (value instanceof File) {
            const fileName = uuidv4()
            const formData = new FormData()
            formData.append('file', value, fileName)

            const previewFileSrcServer: string =
              await publicationStore.uploadImageForStreamPreview(formData)
            return previewFileSrcServer ? previewFileSrcServer : ''
          } else {
            return value
          }
        }

        await Promise.all(
          newList.map(async (post: ISendPublication) => {
            await Promise.all(
              post.IpfsFiles.map(async (file: IIpfsFiles, idx: number) => {
                if (file.VideoPreview) {
                  file.VideoPreview = await uploadFileToServer(
                    file.VideoPreview
                  )
                }
              })
            )
          })
        )

        const defaultPreview = newList[0].IpfsFiles[0]?.DefaultVideoPreview
          ? newList[0].IpfsFiles[0]?.DefaultVideoPreview
          : ''

        const previewData = {
          preview: newList[0].IpfsFiles[0]?.VideoPreview
            ? newList[0].IpfsFiles[0]?.VideoPreview
            : defaultPreview,
          previewCdn: newList[0].IpfsFiles[0]?.VideoPreview
            ? newList[0].IpfsFiles[0]?.VideoPreview
            : defaultPreview,
          title: newList[0].IpfsFiles[0]?.VideoAudioTitle
            ? newList[0].IpfsFiles[0]?.VideoAudioTitle
            : EditorState.createEmpty(),
          description: newList[0].IpfsFiles[0]?.VideoAudioDescription
            ? newList[0].IpfsFiles[0]?.VideoAudioDescription
            : EditorState.createEmpty(),
        }

        // Don't work set on server VideoAudioTitle and VideoAudioDescription for ipfs file
        draftToEdit &&
          (await publicationStore.updateDraftPost(
            draftToEdit.Id,
            newList[0].EditorData,
            previewData,
            newList[0].IpfsFiles
          ))
        toast({
          type: 'success',
          message: 'The stream post was successfully updated',
        })

        clearPostData()
        await closeModal()
        setIsOpenSaveDraftStreamConfirmModal(false)

        await getDrafts()
        loading.setLoading(false)
      } catch (e) {
        loading.setLoading(false)
        toast({
          type: 'error',
          message: 'Save stream post failed!',
        })
        console.log('e', e)
      }
    }

    const refuseSaveDraftStream = async () => {
      clearPostData()
      await closeModal()
      setIsOpenSaveDraftStreamConfirmModal(false)
    }
    // STREAMPOST-----------------------------------------------------------------------------------------------↑↑↑

    // BLOGPOST-----------------------------------------------------------------------------------------------↓↓↓

    const onNextBlogpostPost = async () => {
      try {
        let newList = cloneDeep(listCreateBlock.current)

        if (
          newList.find(
            item =>
              !getPostLengthFromEditorState(item.EditorData) &&
              item.IpfsFiles.length === 0
          )
        ) {
          return toast({
            type: 'warning',
            message: 'Blogpost empty',
          })
        } else if (
          newList.find(
            item => getPostLengthFromEditorState(item.EditorData) < 500
          )
        ) {
          return toast({
            type: 'warning',
            message:
              "Your post is too short. It needs to be at least 500 characters long. If you'd like to share a shorter message, please create a regular post.",
          })
        }

        // if (quantitySymbols > 5000) {
        //   return toast({
        //     type: 'warning',
        //     message: 'The number of characters exceeds the limit of 5000'
        //   })
        // }

        // if (newList.some(item => checkNumberOfLineBreaks(getPostTextFromEditorState(item.EditorData)))) {
        //   return toast({
        //     type: 'warning',
        //     message: 'You are not allowed to use more than 2 line breaks together'
        //   })
        // }

        if (
          newList.some(item =>
            checkBadWords(getPostTextFromEditorState(item.EditorData))
          )
        ) {
          return toast({
            type: 'warning',
            message: 'You are not allowed to use prohibited words',
          })
        }

        // Create preview data from blogpost
        console.log('blogpostPreviewData', blogpostPreviewData)
        if (!blogpostPreviewData) {
          const blogPostEditorData = newList[0].EditorData
          const rawBlogPostEditorData = convertToRaw(
            blogPostEditorData.getCurrentContent()
          )
          const { blocks, entityMap } = rawBlogPostEditorData

          const firstBlock = blocks[0]
          const isAtomicBlock =
            firstBlock.type === EditorBlockType.ATOMIC ||
            firstBlock.type === EditorBlockType.IMAGE
          const blogpostTitle = firstBlock.text

          // Old (when add image like entity) Dont delete
          // const blogpostPreview = Object.keys(entityMap).filter(idx => entityMap[idx].type === 'IMAGE' || entityMap[idx].type === 'image:atomic').map(key => entityMap[key].data.src)
          const imagesArray = blocks
            .filter(block => block.type === EditorBlockType.IMAGE)
            .find(
              block =>
                block.data &&
                (block.data.srcArray.size > 0 ||
                  block.data?.srcArray.length > 0)
            )?.data?.srcArray
          const blogpostPreview =
            imagesArray && [...imagesArray][0] ? [...imagesArray][0] : ''
          console.log('blogpostPreview', blogpostPreview)

          const cdnImagesArray = blocks
            .filter(block => block.type === EditorBlockType.IMAGE)
            .find(
              block =>
                block.data &&
                (block.data?.cdnSrcArray?.size > 0 ||
                  block.data?.cdnSrcArray?.length > 0)
            )?.data?.cdnSrcArray
          const blogpostPreviewCdn =
            cdnImagesArray && [...cdnImagesArray][0]
              ? [...cdnImagesArray][0]
              : ''

          if (blogpostTitle.trim().length > 0 && !isAtomicBlock) {
            // Take the entities of the first block
            const blogpostTitleEntityMap = {
              ...Object.keys(entityMap)
                .filter(entityKey =>
                  firstBlock.entityRanges.some(
                    (item: any) => String(entityKey) === String(item.key)
                  )
                )
                .map(key => entityMap[key]),
            }

            setBlogpostPreviewData({
              title: resetBlockWithType(
                EditorState.createWithContent(
                  convertFromRaw({
                    blocks: [firstBlock],
                    entityMap: blogpostTitleEntityMap as any,
                  })
                )
              ),
              description: EditorState.createEmpty(),
              preview: blogpostPreview ? blogpostPreview.split('?')[0] : '', //set without dimensions
              previewCdn: blogpostPreviewCdn
                ? blogpostPreviewCdn.split('?')[0]
                : '',
            })
          } else {
            setBlogpostPreviewData({
              title: EditorState.createEmpty(),
              description: EditorState.createEmpty(),
              preview: blogpostPreview ? blogpostPreview.split('?')[0] : '', //set without dimensions
              previewCdn: blogpostPreviewCdn
                ? blogpostPreviewCdn.split('?')[0]
                : '',
            })
          }
        }
        setIsBlogpostPreviewModalOpen(true)
      } catch (e) {
        console.log('e', e)
      }
    }

    const closeBlogpostPreviewModal = () => {
      setIsBlogpostPreviewModalOpen(false)
    }

    const setNewBlogpostPreviewData = (value: IBlogpostPreviewData) => {
      setBlogpostPreviewData(value)
    }

    const closeSaveDraftBlogpostConfirmModal = () => {
      setIsOpenSaveDraftBlogpostConfirmModal({ open: false })
    }

    const getIpfsFileSrc = async (fileSrc: string) => {
      const {
        src: imageSrc,
        dimensionsQuery,
        width,
        height,
      } = getImageQueryDimensionsFromUrl(fileSrc)

      const response = await fetch(imageSrc)
      const blob = await response.blob()
      const file = new File([blob], uuidv4())

      const [ipfsFile, cdnFile] = await Promise.all([
        (async () => {
          const ipfsFileTemp = await ipfs.add(file)
          if (file.size && file.size > 536870912) {
            console.log('add pin', file.size)
            const pinAdded = await ipfs.pin.add(ipfsFile.cid)
          }

          return ipfsFileTemp
        })(),

        addFileToDigitalOceanSpaces(file, 'publications'),
      ])

      // Add dimensions to srcIpfs if src have query dimensions
      if (width && height) {
        return {
          FileLink: `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}?${dimensionsQuery}`,
          CdnFileLink: cdnFile,
        }
      } else {
        return {
          FileLink: `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}`,
          CdnFileLink: cdnFile,
        }
      }
    }

    const getIpfsVideoData = async (fileSrc: string) => {
      const response = await fetch(fileSrc)
      const blob = await response.blob()
      const file = new File([blob], uuidv4())

      const posterUrl = await getVideoPoster(URL.createObjectURL(file))

      const [ipfsFile, cdnFile] = await Promise.all([
        (async () => {
          const ipfsFileTemp = await ipfs.add(file)
          if (file.size && file.size > 536870912) {
            console.log('add pin', file.size)
            const pinAdded = await ipfs.pin.add(ipfsFile.cid)
          }

          return ipfsFileTemp
        })(),

        addFileToDigitalOceanSpaces(file, 'publications'),
      ])

      return {
        src: `${process.env.REACT_APP_IPFS_GATEWAY}${ipfsFile.path}`,
        posterSrc: posterUrl as string,
        cdnSrc: cdnFile,
      }
    }

    const getVideoPoster = async (videoSrc: string) => {
      const video = document.createElement('video')
      video.crossOrigin = 'anonymous'
      video.src = videoSrc
      video.volume = 0

      const setCurrentTime = (event: Event) => {
        const videoElement = event.target as HTMLVideoElement
        videoElement.currentTime =
          videoElement.duration > 5 ? 5 : videoElement.duration / 2
        videoElement.pause()
      }
      video.addEventListener('canplay', setCurrentTime)

      return new Promise((resolve, reject) => {
        try {
          video.onseeked = async (event: any) => {
            const videoElement = event.target as HTMLVideoElement
            const frame = captureVideoFrame(videoElement)
            const posterFile: any = new File([frame.blob], 'poster')
            videoElement.removeEventListener('canplay', setCurrentTime)
            videoElement.remove()

            // const poster = await ipfs.add(posterFile)
            const posterCdn = await addFileToDigitalOceanSpaces(
              posterFile,
              'videos/preview'
            )
            const posterIpfsUrl = posterCdn

            resolve(posterIpfsUrl)
          }
        } catch (error) {
          reject('')
        }
      })
    }
    // BLOGPOST-----------------------------------------------------------------------------------------------↑↑↑

    const getOverflowNodeDOMRect = useCallback(() => {
      if (postModalWrapperNode.current) {
        return postModalWrapperNode.current.getBoundingClientRect()
      }
      return null
    }, [postModalWrapperNode.current])

    const isNotEmptyBlogpost = (editorState: EditorState) => {
      const rawCurrentContent = convertToRaw(editorState.getCurrentContent())
      const textLength = editorState
        .getCurrentContent()
        .getPlainText()
        .trim().length
      const entityLength = Object.keys(rawCurrentContent.entityMap).length
      const atomicImageBlocks = rawCurrentContent.blocks.filter(
        block => block.type === EditorBlockType.IMAGE
      )
      return Boolean(textLength || entityLength || atomicImageBlocks.length > 0)
    }

    const onCloseCreatePostModal = () => {
      if (
        postType === PostType.Blogpost &&
        isNotEmptyBlogpost(listCreateBlock.current[0].EditorData)
      ) {
        if (main.isPWA) {
          setTimeout(() => {
            setIsOpenSaveDraftBlogpostConfirmModal({
              open: true,
              isNeedCloseCreateModal: true,
            })
          }, 300)
        } else {
          setIsOpenSaveDraftBlogpostConfirmModal({
            open: true,
            isNeedCloseCreateModal: true,
          })
        }
      } else if (postType === PostType.Streampost) {
        if (main.isPWA) {
          setTimeout(() => {
            setIsOpenSaveDraftStreamConfirmModal(true)
          }, 300)
        } else {
          setIsOpenSaveDraftStreamConfirmModal(true)
        }
      } else {
        if (main.isPWA) {
          setTimeout(() => {
            setIsCloseModalOpen(true)
          }, 300)
        } else {
          setIsCloseModalOpen(true)
        }
      }
    }

    // const fullSizeModalStyle = isFullSize ? { width: '95vw', height: '95vh', maxWidth: 'calc(min(1480px, 95vw))' } : {}
    const fullSizeModalStyle = isFullSize
      ? { width: '100vw', height: '100vh', maxWidth: '100vw', borderRadius: 0 }
      : {}

    const profileUserRole = profile.userRole || profile.userRoleSession

    const firstTransactionBlockPWA = createPost.transactionCreateBlocks[0]
      ? createPost.transactionCreateBlocks[0]
      : defaultTransactionCreateBlock
    const firstPostBlockPWA = firstTransactionBlockPWA.blockData[0]

    const blockQuantity = (
      <div
        className={cn({
          [styles.blockQuantityPlus]: true,
          [styles.symbolsQuantityError]: quantitySymbols > 5000,
        })}
      >
        <PlusIcon /> {`${quantitySymbols}/5000`}
      </div>
    )

    const modalContent = (
      <div
        ref={postModalWrapperNode}
        className={cn({
          [styles.createPostModalWrapper]: true,
          [styles.createPostModalWrapperFullSize]: isFullSize,
          [styles.createPostModalWidget]: renderType === 'widget',
        })}
      >
        <div
          className={cn(styles.postTypeSwitch, {
            [styles.postTypeSwitch_widget]: renderType === 'widget',
          })}
        >
          {/*{renderType === 'widget' && blockQuantity}*/}

          {profileUserRole === UserRole.User ? (
            <CustomMultipleSwitch
              key={0}
              initialPosition={0}
              onSwitchClick={openChangePostTypeConfirmModal}
              // preferredSwitchPositionFromModal={postType} // it is not used because there is only one post type, so used don't need to select it
              switchStyle={{
                width: '100px',
                minWidth: '100px',
                ...widget.getWidgetBackground(),
              }}
              buttons={[{ key: 0, title: 'Freepost' }]}
              renderType={renderType}
            />
          ) : (
            <CustomMultipleSwitch
              key={1}
              initialPosition={draftToEdit ? 0 : (postType as any)}
              onSwitchClick={openChangePostTypeConfirmModal}
              preferredSwitchPositionFromModal={postType}
              switchStyle={
                draftToEdit
                  ? {
                      width: '100px',
                      minWidth: '100px',
                      ...widget.getWidgetBackground(),
                    }
                  : {
                      ...widget.getWidgetBackground(),
                    }
              }
              buttons={
                draftToEdit
                  ? [
                      {
                        key: 0,
                        title:
                          draftToEdit.Type === 0 ? 'Blogpost' : 'Streampost',
                      },
                    ]
                  : renderType === 'widget'
                  ? [
                      { key: 0, title: 'Blockchain' },
                      { key: 1, title: 'Freepost' },
                    ]
                  : [
                      { key: 0, title: 'Blockchain' },
                      { key: 1, title: 'Encrypted' },
                      { key: 2, title: 'Superpost' },
                      { key: 3, title: 'Blogpost' },
                      { key: 4, title: 'X Post' },
                      { key: 5, title: 'Freepost' },
                    ]
              }
              renderType={renderType}
            />
          )}
        </div>
        {postType === PostType.Superpost ? (
          <SuperpostPrice
            superpostPrice={superpostPrice}
            onChangeSuperpostPrice={handleChangeSuperpostPrice}
          />
        ) : null}
        {postType === PostType.SocialPost ? (
          <SocialPostRewardPrice
            rewardPrice={rewardPrice}
            onChangeRewardPrice={handleChangeRewardPrice}
            disabled={!profile?.selectedUser?.IsTwitterConnected}
          />
        ) : null}
        {createPost.transactionCreateBlocks.map((block, idx) => (
          <div
            key={block.blockKey}
            className={cn({
              [styles.transactionCreateBlock]: true,
              [styles.transactionCreateBlockFullSize]: isFullSize,
              [styles.transactionCreateBlockOne]:
                createPost.transactionCreateBlocks.length === 1,
              [styles.transactionCreateBlockWidget]: renderType === 'widget',
            })}
            style={{
              marginBottom:
                idx === createPost.transactionCreateBlocks.length - 1
                  ? 0
                  : '16px',
            }}
          >
            <div className={styles.createPostTopBlock}>
              <div className={styles.createPostTitle}>Paid post</div>
              <div
                className={cn({
                  [styles.onCloseIcon]: true,
                  [styles.onCloseIconVisible]: idx !== 0,
                })}
                onClick={() => deleteTransactionCreateBlock(block.blockKey)}
              >
                <Close />
              </div>
            </div>

            {block.blockData.map(
              (postBlock: ISendPublication, index: number) => {
                if (renderType === 'widget') {
                  return (
                    <BlockWidget
                      isAdultContent={block.isAdultContent}
                      key={postBlock.Id}
                      index={index}
                      post={postBlock}
                      postType={postType}
                      listLength={listCreateBlock.current.length}
                      onChangeText={onChangeText}
                      onAddFile={onAddFile}
                      onCheckFile={onCheckFile}
                      onAddVideoPoster={onAddVideoPoster}
                      onAddVideoDuration={onAddVideoDuration}
                      onAddAudiWaveForm={onAddAudiWaveForm}
                      onChangeTitleDescription={onChangeTitleDescription}
                      onDeleteFile={onDeleteFile}
                      onEditFile={onEditFile}
                      onChangeAdultContent={onChangeAdultContent}
                      onCreatePost={onCreatePost}
                      onOpenEmojiPicker={onOpenEmoji}
                      onGetPrice={onGetPrice}
                      quantitySymbols={quantitySymbols}
                      isFirstTransactionBlock={
                        firstTransactionBlock === block.blockKey
                      }
                      blockStyle={{
                        marginBottom:
                          index === block.blockData.length - 1 ? 0 : '32px',
                      }}
                      onRotate360Image={onRotate360Image}
                      getOverflowNodeDOMRect={getOverflowNodeDOMRect}
                      activeBlock={activeBlock} //need remove after disconnect tree logic
                      transactionBlockKey={block.blockKey} //need remove after disconnect tree logic
                      setActiveBlock={setActiveBlock} //need remove after disconnect tree logic
                      setTransactionActiveBlock={setTransactionActiveBlock} //need remove after disconnect tree logic
                    />
                  )
                }

                return (
                  <Block
                    isAdultContent={block.isAdultContent}
                    key={postBlock.Id}
                    index={index}
                    post={postBlock}
                    postType={postType}
                    prepareDraftStatus={prepareDraftStatus}
                    listLength={listCreateBlock.current.length}
                    onCreateNewPostBlock={onCreateNewPostBlock}
                    onDeleteBlock={onDeleteBlock}
                    onChangeText={onChangeText}
                    onAddFile={onAddFile}
                    onCheckFile={onCheckFile}
                    onAddVideoPoster={onAddVideoPoster}
                    onAddVideoDuration={onAddVideoDuration}
                    onAddAudiWaveForm={onAddAudiWaveForm}
                    onChangeTitleDescription={onChangeTitleDescription}
                    onDeleteFile={onDeleteFile}
                    onEditFile={onEditFile}
                    onChangeAdultContent={onChangeAdultContent}
                    onCreatePost={onCreatePost}
                    onNextBlogpost={onNextBlogpostPost}
                    onSaveStreamInDraft={handleSaveStreamInDraft}
                    onOpenEmojiPicker={onOpenEmoji}
                    onGetPrice={onGetPrice}
                    quantitySymbols={quantitySymbols}
                    isFirstTransactionBlock={
                      firstTransactionBlock === block.blockKey
                    }
                    blockStyle={{
                      marginBottom:
                        index === block.blockData.length - 1 ? 0 : '32px',
                    }}
                    superPostPublicText={superPostPublicText}
                    onChangeSuperpostPublicText={
                      handlerChangeSuperpostPublicText
                    }
                    fullSizeStyle={isFullSize}
                    changeFullSize={changeFullSize}
                    onRotate360Image={onRotate360Image}
                    getOverflowNodeDOMRect={getOverflowNodeDOMRect}
                    activeBlock={activeBlock} //need remove after disconnect tree logic
                    transactionBlockKey={block.blockKey} //need remove after disconnect tree logic
                    setActiveBlock={setActiveBlock} //need remove after disconnect tree logic
                    setTransactionActiveBlock={setTransactionActiveBlock} //need remove after disconnect tree logic
                    setActiveEditorSuperpostPublicText={
                      handlerSetActiveEditorSuperpostPublicText
                    } //need remove after disconnect tree logic
                    postOnX={block.postOnX}
                    onChangePostOnTwitter={onChangePostOnTwitter}
                    socialPostLink={socialPostLink}
                    onChangeSocialPostLink={handleChangeSocialPostLink}
                    socialPostInfo={socialPostInfo}
                    onReceiveSocialPostInfo={handleChangeSocialPostInfo}
                  />
                )
              }
            )}
          </div>
        ))}
      </div>
    )

    return (
      <>
        {renderType === 'widget' ? (
          modalContent
        ) : !main.isPWA ? (
          <>
            <Modal
              open={isOpen}
              ref={modalRef}
              contentStyle={{
                display: isBlogpostPreviewModalOpen ? 'none' : 'flex',
                padding: '10px',
                marginTop: 'auto',
                marginBottom: 'auto',
                ...postModalStyle,
                //Next lines in test
                maxHeight: openMobilePicker ? heightModal : '100%',
                minHeight: openMobilePicker ? heightModal : 'none',
                //Before
                // maxHeight: openMobilePicker ? '50%' : 'none',
                // minHeight: windowHeight / 2,

                //full size
                ...fullSizeModalStyle,
              }}
              additionalNode={
                <EmojiMobile open={openMobilePicker} onSelect={onAddEmoji} />
              }
              additionalStyleMainBlock={openMobilePicker}
              onClickContentBlock={clickOnModalContentBlock}
              blockOutsideClose
              closeButton
              onClose={onCloseCreatePostModal}
              children={modalContent}
            />
            <CloseModal
              isOpen={isCloseModalOpen}
              setIsOpen={setIsCloseModalOpen}
              handlerYesClick={() => closeModal(true)}
            />
          </>
        ) : (
          <>
            <CreatePostModalPWA
              open={isOpen}
              post={firstPostBlockPWA}
              draftToEdit={draftToEdit}
              isAdultContent={firstTransactionBlockPWA.isAdultContent}
              quantitySymbols={quantitySymbols}
              profileUserRole={profileUserRole}
              postType={postType}
              superPostPublicText={superPostPublicText}
              onClose={onCloseCreatePostModal}
              onChangePostType={openChangePostTypeConfirmModal}
              onChangeText={onChangeText}
              onChangeSuperpostPublicText={handlerChangeSuperpostPublicText}
              onCreatePost={onCreatePost}
              onNextBlogpost={onNextBlogpostPost}
              onAddFile={onAddFile}
              onAddEmoji={onAddEmoji}
              onGetPrice={onGetPrice}
              onSaveStreamInDraft={handleSaveStreamInDraft}
              onChangeAdultContent={onChangeAdultContent}
              onEditFile={onEditFile}
              onDeleteFile={onDeleteFile}
              onAddVideoPoster={onAddVideoPoster}
              onAddVideoDuration={onAddVideoDuration}
              onAddAudiWaveForm={onAddAudiWaveForm}
              onChangeTitleDescription={onChangeTitleDescription}
              onCheckFile={onCheckFile}
              onRotate360Image={onRotate360Image}
              activeBlock={activeBlock} //need remove after disconnect tree logic
              transactionBlockKey={firstTransactionBlockPWA.blockKey} //need remove after disconnect tree logic
              setActiveBlock={setActiveBlock} //need remove after disconnect tree logic
              setTransactionActiveBlock={setTransactionActiveBlock} //need remove after disconnect tree logic
              setActiveEditorSuperpostPublicText={
                handlerSetActiveEditorSuperpostPublicText
              } //need remove after disconnect tree logic
            />
            <CloseModalPWA
              isOpen={isCloseModalOpen}
              setIsOpen={setIsCloseModalOpen}
              handlerYesClick={closeModal}
            />
          </>
        )}

        {/*Stream save modal*/}
        <SaveDraftConfirmModal
          isOpen={isOpenSaveDraftStreamConfirmModal}
          setIsOpen={closeSaveDraftStreamConfirmModal}
          onClickYes={handleSaveStreamInDraft}
          onClickNo={refuseSaveDraftStream}
          blockOutsideClose
          text="Do you want to save your changes in draft?"
        />
        <ChangePostTypeModal
          isOpen={isOpenChangePostTypeConfirmModal}
          setIsOpen={closeChangePostTypeConfirmModal}
          onClickYes={handleChangePostTypeConfirmModal}
          blockOutsideClose
          text="Do you want to change post type? The data will be cleared"
        />
        <EmojiModal
          onAddEmoji={onAddEmoji}
          textAreaId={`textArea_${activeBlock}`}
          emojiClickPosition={emojiClickPosition}
          mainModalStyle={postModalStyle}
          setMainModalStyle={setPostModalStyle}
          windowHeight={windowHeight}
        />

        <BlogpostModals
          draftToEdit={draftToEdit}
          getDrafts={getDrafts}
          blogpostData={listCreateBlock.current}
          blogpostPreviewData={blogpostPreviewData}
          setBlogpostPreviewData={setNewBlogpostPreviewData}
          isOpenSaveDraftBlogpostConfirmModal={
            isOpenSaveDraftBlogpostConfirmModal
          }
          closeSaveDraftBlogpostConfirmModal={
            closeSaveDraftBlogpostConfirmModal
          }
          isOpenPreviewMintModal={isBlogpostPreviewModalOpen}
          closePreviewMintModal={closeBlogpostPreviewModal}
          clearPostData={clearPostData}
          closeModal={closeModal}
          isFullSize={isFullSize}
          getIpfsFileSrc={getIpfsFileSrc}
          getIpfsVideoData={getIpfsVideoData}
          onCreateBlockchainPost={handleCreateBlockchainPost}
          onApplyPostTypeAfterBlogpost={handlerApplyPostTypeAfterBlogpost}
        />
      </>
    )
  }
)

CreatePostModal.displayName = 'CreatePostModal'

export default CreatePostModal

// const uploadFileToCloudinaryLarge = async (file: File, resolve: any, reject: any) => {
//   const uniqueUploadId = `uqid-${Date.now()}`
//   const chunkSize = fileSize['50MB']
//   const totalChunks = Math.ceil(file.size / chunkSize)
//   let currentChunk = 0
//
//   const uploadChunk = async (start: number, end: number) => {
//     const formData = new FormData()
//     formData.append('file', file.slice(start, end))
//     formData.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || '');
//     formData.append('upload_preset', process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || '')
//
//     formData.append('folder', 'Authencity. videos')
//
//     const contentRange = `bytes ${start}-${end - 1}/${file.size}`
//
//     try {
//       const response = await fetch(
//         `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/upload`,
//         {
//           method: 'POST',
//           body: formData,
//           headers: {
//             'X-Unique-Upload-Id': uniqueUploadId,
//             'Content-Range': contentRange
//           }
//         }
//       )
//
//       if (!response.ok) {
//         console.log('response failed', response)
//         throw new Error('Chunk upload failed.')
//       }
//
//       currentChunk++
//
//       if (currentChunk < totalChunks) {
//         const nextStart = currentChunk * chunkSize
//         const nextEnd = Math.min(nextStart + chunkSize, file.size)
//         uploadChunk(nextStart, nextEnd)
//       } else {
//         const fetchResponse = await response.json()
//         console.info('File upload complete.')
//         resolve(fetchResponse.secure_url)
//       }
//     } catch (error: any) {
//       console.error('Error uploading chunk:', error)
//       reject(new Error(error.message || 'Error uploading chunk'))
//     }
//   }
//
//   const start = 0
//   const end = Math.min(chunkSize, file.size)
//
//   uploadChunk(start, end)
// }

// const onPrepareToMint = async () => {
//   let newList = cloneDeep(listCreateBlock.current)
//
//   const isAdultContent = transactionCreateBlock.current.isAdultContent
//   newList = newList.map(blockData => ({ ...blockData, IsAdultContent: isAdultContent }))
//
//   // Is empty post
//   if (createPost.isPostEmptyListToast(newList)) return
//
//   // Is right quantity
//   if (createPost.isRightQuantitySymbolsToast(quantitySymbols)) return
//
//   // Is two line breaks together
//   if (createPost.isTwoLineBreaksTogetherListToast(newList)) return
//
//   // Is contain bad words
//   if (createPost.isContainBadWordsListToast(newList)) return
//
//   const isWrongAudioVideoAdditionalInfo = newList.some(item => {
//     return createPost.isWrongAudioVideoAdditionalInfoToast(item.IpfsFiles)
//   })
//
//   if (isWrongAudioVideoAdditionalInfo) return
//
//   // STREAMPOST rules ⬇︎⬇︎⬇︎
//   if (postType === PostType.Streampost) {
//     try {
//       loading.setLoading(true)
//       newList = newList.map(blockData => ({ ...blockData, IsStreampost: true }))
//
//       await Promise.all(
//         newList.map(async (post: ISendPublication) => {
//           await Promise.all(
//             post.IpfsFiles.map(async (file: IIpfsFiles, idx: number) => {
//               if (file.VideoPreview) {
//                 if (file.VideoPreview as any instanceof File) {
//                   const poster = await ipfs.add(file.VideoPreview)
//                   file.VideoPreview = `${process.env.REACT_APP_IPFS_GATEWAY}${poster.path}`
//                 }
//               }
//             })
//           )
//         })
//       )
//
//       const defaultPreview = newList[0].IpfsFiles[0]?.DefaultVideoPreview ? newList[0].IpfsFiles[0]?.DefaultVideoPreview : ''
//
//       const previewData = {
//         preview: newList[0].IpfsFiles[0]?.VideoPreview ? newList[0].IpfsFiles[0]?.VideoPreview : defaultPreview,
//         title: newList[0].IpfsFiles[0]?.VideoAudioTitle ? newList[0].IpfsFiles[0]?.VideoAudioTitle : EditorState.createEmpty(),
//         description: newList[0].IpfsFiles[0]?.VideoAudioDescription ? newList[0].IpfsFiles[0]?.VideoAudioDescription : EditorState.createEmpty(),
//       }
//
//       // Don't work set on server VideoAudioTitle and VideoAudioDescription for ipfs file
//       draftToEdit && await publicationStore.updateDraftPost(draftToEdit.Id, newList[0].EditorData, previewData, newList[0].IpfsFiles)
//       draftToEdit && await videoStream.prepareToMint(draftToEdit.Id)
//
//       clearPostData()
//       await closeModal()
//       await getDrafts()
//       loading.setLoading(false)
//       return
//     } catch (e) {
//       console.log('e', e)
//       loading.setLoading(false)
//       return toast({
//         type: 'error',
//         message: 'Something went wrong. Please try again'
//       })
//     }
//   }
//   // STREAMPOST rules ⬆︎⬆︎⬆︎
// }
