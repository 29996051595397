import React, { FC, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { ErrorBoundary } from 'react-error-boundary'

import cn from 'classnames'
import { EditorState } from 'draft-js'
// const mime = require('mime')
import mime from 'mime'
//@ts-ignore
import MP4Box from 'mp4box'
import { v4 } from 'uuid'

import {
  getFileExtension,
  getFileExtensionFromName,
} from '../../utils/functions/common'
import { useOutsideClick } from '../../utils/hooks/useOutsideClick'
import { createPostFileType, fileSize } from 'utils'

import { toast } from 'App'

import { IIpfsFiles } from 'models'

import { useStore } from 'store'

import { ReactComponent as AudioSvg } from 'sources/images/audio.svg'
import { ReactComponent as FileAddSvg } from 'sources/images/createPostModal/file_add2.svg'
import { ReactComponent as GifSvg } from 'sources/images/createPostModal/getGif.svg'
import { ReactComponent as ImageSvg } from 'sources/images/createPostModal/getImage.svg'
import { ReactComponent as SmileSvg } from 'sources/images/createPostModal/getSmile.svg'
import { ReactComponent as VideoSvg } from 'sources/images/createPostModal/getVideo.svg'
import { ReactComponent as SpatialVideoSvg } from 'sources/images/createPostModal/getVisionPro.svg'
import { ReactComponent as Cube3dSvg } from 'sources/images/cube3d.svg'
import { ReactComponent as View360Svg } from 'sources/images/view_360.svg'

import { CustomTooltip } from '../CustomTooltip'
import { AddFileButtonsPWA } from './AddFileButtonsPWA'

import styles from './styles.module.scss'

enum TwitterFormatSizes {
  'image/png' = 5,
  'image/jpeg' = 5,
  'image/webp' = 5,
  'image/gif' = 15,
  'video/mp4' = 15,
  'video/quicktime' = 15,
}

interface IProps {
  ipfsFiles: IIpfsFiles[]
  postId: string
  onOpenEmojiPicker?: (
    top: number,
    left: number,
    isEnoughSpace: boolean
  ) => void
  onOpenEmojiPWA?: () => void
  onAddFile: (id: string, fileList: IIpfsFiles[]) => void
  onlyEmoji?: boolean
  onlyVideo?: boolean
  withSpatialVideo?: boolean
}

//Resize
export const AddFileButtons: FC<IProps> = observer(
  ({
    ipfsFiles,
    postId,
    onOpenEmojiPicker,
    onOpenEmojiPWA,
    onAddFile,
    onlyEmoji,
    onlyVideo,
    withSpatialVideo,
  }) => {
    const { profile, main } = useStore()
    const isActivated = profile.selectedUser.Activated

    const pickerEmojiHeight = window.innerHeight < 650 ? 260 : 353
    const pickerEmojiWidth = window.innerWidth <= 540 ? '99vw' : '274px'

    const [is3dImageTooltipOpen, setIs3dImageTooltipOpen] = useState(false)
    const tooltip3dImageRef = useRef<any>(null)

    useOutsideClick(tooltip3dImageRef, () => setIs3dImageTooltipOpen(false))

    const addFile = async (e: any, type: createPostFileType) => {
      const filesArr = Array.from(e.target.files)

      // check video codec if it is spatial video
      if (
        filesArr?.every((f: any) => f.type === 'video/quicktime') &&
        type === createPostFileType.spatial_video
      ) {
        let videoCodecs: string[] = []

        await Promise.all(
          filesArr?.map(async f => {
            const mp4boxfile = MP4Box.createFile()
            mp4boxfile.onError = console.error
            mp4boxfile.onReady = (info: any) => {
              videoCodecs.push(
                info.tracks.map((track: any) => track.codec).join(',')
              )
            }

            await new Promise(resolve => {
              resolve(f)
            })
              .then((resp: any) => resp.arrayBuffer())
              .then((buf: any) => {
                buf.fileStart = 0
                mp4boxfile.appendBuffer(buf)
                mp4boxfile.flush()
              })
          })
        )

        if (!videoCodecs.every(vc => vc?.includes('hvc1'))) {
          return toast({
            type: 'warning',
            message: 'All videos must have Spatial Video type',
          })
        }
      }

      let fileList: IIpfsFiles[] = []
      //-▼-Check maybe user load many file
      for (let i = 0; i <= e.target.files.length - 1; i++) {
        if (e.target.files[i]) {
          let isSpatialImage =
            type === createPostFileType.spatial_video &&
            e.target.files[i].type === 'image/heic'
          let isSpatialVideo =
            type === createPostFileType.spatial_video &&
            e.target.files[i].type !== 'image/heic'

          //-▼-Check IMAGE and GIF  size
          if (
            type !== createPostFileType.video &&
            type !== createPostFileType.spatial_video &&
            type !== createPostFileType.audio &&
            type !== createPostFileType.file &&
            type !== createPostFileType.image3D &&
            type !== createPostFileType.image3D_usdz &&
            e.target.files[i].size > fileSize['10MB']
          ) {
            return toast({
              type: 'warning',
              message: 'Maximum image size limit is 10MB',
            })
          }
          //-▼-Check video and audio size
          if (
            (type === createPostFileType.video ||
              isSpatialVideo ||
              type === createPostFileType.audio) &&
            e.target.files[i].size >
              (isActivated ? fileSize['400MB'] : fileSize['200MB'])
          ) {
            return toast({
              type: 'warning',
              message: isActivated
                ? `Maximum ${type} size limit is 400 MB`
                : `Maximum ${type} size limit for a not-activated user is 200 MB`,
            })
          }
          //-▼-Check IMAGE type
          if (
            (type === createPostFileType.image ||
              type === createPostFileType.image360) &&
            e.target.files[i].type !== 'image/png' &&
            e.target.files[i].type !== 'image/jpeg'
          ) {
            return toast({
              type: 'warning',
              message: 'You can only use JPG, JPEG, PNG formats for images',
            })
          }

          //-▼-Check 3D images type
          if (
            (type === createPostFileType.image3D ||
              type === createPostFileType.image3D_usdz) &&
            e.target.files[i].size > fileSize['30MB']
          ) {
            return toast({
              type: 'warning',
              message: 'Maximum 3D image size limit is 30 MB',
            })
          }

          //-▼-Check VIDEO type
          if (
            type === createPostFileType.video &&
            e.target.files[i].type !== 'video/mp4' &&
            e.target.files[i].type !== 'video/webm' &&
            e.target.files[i].type !== 'video/quicktime'
          ) {
            return toast({
              type: 'warning',
              message: 'You can only use MP4, WebM, MOV formats for videos',
            })
          }

          //-▼-Check AUDIO type
          if (
            type === createPostFileType.audio &&
            e.target.files[i].type !== 'audio/mpeg' &&
            e.target.files[i].type !== 'audio/mp3' &&
            e.target.files[i].type !== 'audio/wav' &&
            e.target.files[i].type !== 'audio/ogg'
          ) {
            return toast({
              type: 'warning',
              message: 'You can only use MP3, WAV, OGG formats for audios',
            })
          }

          //-▼-Check GIF type
          if (
            type === createPostFileType.gif &&
            e.target.files[i].type !== 'image/gif'
          ) {
            return toast({
              type: 'warning',
              message: 'You can only use the GIF format for gifs',
            })
          }

          /*//-▼-Check Spatial Video type
          if (
            type === createPostFileType.spatial_video &&
            e.target.files[i].type !== 'video/quicktime'
          ) {
            return toast({
              type: 'warning',
              message: 'You can only use the MOV format for spatial videos',
            })
          }*/

          //-▼-Check spatial images size
          if (isSpatialImage && e.target.files[i].size > fileSize['50MB']) {
            return toast({
              type: 'warning',
              message: `Maximum Spatial Image size limit is 50 MB`,
            })
          }

          //-▼-Check File size and type(.doc, .docx, .pdf, .txt, .xls, .xlsx)
          if (type === createPostFileType.file) {
            const fileType = mime.getExtension(e.target.files[i].type)
            const fileSize = e.target.files[i].size

            if (
              fileType !== 'doc' &&
              fileType !== 'docx' &&
              fileType !== 'pdf' &&
              fileType !== 'txt' &&
              fileType !== 'xls' &&
              fileType !== 'xlsx' &&
              fileType !== 'html'
            ) {
              return toast({
                type: 'warning',
                message:
                  'You can only use .doc, .docx, .pdf, .txt, .xls, .xlsx, .html file types',
              })
            }

            if (fileSize > fileSize['50MB']) {
              return toast({
                type: 'warning',
                message: 'Maximum file size limit is 50MB',
              })
            }
          }

          const additionalVideoAudioInfo = () => {
            if (
              type === createPostFileType.video ||
              isSpatialVideo ||
              type === createPostFileType.audio
            ) {
              return {
                VideoAudioTitle: EditorState.createEmpty(),
                VideoAudioDescription: EditorState.createEmpty(),
              }
            }
            return {}
          }

          const fileLimit = TwitterFormatSizes[e.target.files[i].type] + 'MB'
          const exceedsTwitterLimits =
            !Object.keys(TwitterFormatSizes).includes(e.target.files[i].type) ||
            e.target.files[i].size > (fileSize as any)[fileLimit]

          //-▼-Add file in list
          fileList.push({
            Id: v4(),
            Size: e.target.files[i].size,
            FileLink: e.target.files[i],
            FileType:
              type === createPostFileType.image360 ||
              type === createPostFileType.image3D ||
              type === createPostFileType.image3D_usdz ||
              isSpatialImage
                ? createPostFileType.image
                : isSpatialVideo
                ? createPostFileType.video
                : type === createPostFileType.file
                ? createPostFileType?.file
                : type,
            FileExtension:
              mime.getExtension?.(e.target.files[i].type) ||
              getFileExtensionFromName(e.target.files[i]) ||
              '',
            Is360Image: type === createPostFileType.image360,
            Is3DImage:
              type === createPostFileType.image3D ||
              type === createPostFileType.image3D_usdz,
            IsSpatialVideo: type === createPostFileType.spatial_video,
            Image360Position:
              type === createPostFileType.image360
                ? { yaw: 0, pitch: 0 }
                : null,
            ExceedsTwitterLimits: exceedsTwitterLimits,
            ...additionalVideoAudioInfo(),
          })
        }
      }

      //-▼-Send new file list
      await onAddFile(postId, fileList)
      e.target.value = ''
    }

    //---▼▼▼--- CLICK IN ADD IMAGE ICON ---▼▼▼---\\
    const uploadImage = (type: createPostFileType) => {
      let input = document.getElementById(`${postId}_add_${type}`)
      if (input) {
        input.click()
        //@ts-ignore
        input.value = ''
      }
    }

    // Need refactored this sheet
    const onOpenEmoji = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const node = e.target as HTMLElement
      let rect = node.getBoundingClientRect()
      let x = window.innerWidth - rect.left - window.innerWidth / 2
      let y = window.innerHeight - rect.top - window.innerHeight / 2
      if (window.innerHeight - 40 < rect.top + pickerEmojiHeight) {
        const dif = window.innerHeight - (rect.top + pickerEmojiHeight + 17)
        onOpenEmojiPicker && onOpenEmojiPicker(-y + dif, -x, true)
      } else {
        onOpenEmojiPicker && onOpenEmojiPicker(-y + 30, -x, true)
      }
    }

    const handlerOpenEmojiPWA = () => {
      onOpenEmojiPWA && onOpenEmojiPWA()
    }

    //---▼▼▼--- GET TYPE ---▼▼▼---\\
    const getStatusBlocked = (type: createPostFileType) => {
      return ipfsFiles?.length >= 4

      /* if (
        ipfsFiles.length > 0 &&
        (type === createPostFileType.gif ||
          type === createPostFileType.video ||
          type === createPostFileType.spatial_video)
      ) {
        return true
      } else if (
        type === createPostFileType.audio &&
        ipfsFiles.find(
          item =>
            item.FileType === createPostFileType.audio ||
            item.FileType === createPostFileType.video ||
            item.FileType === createPostFileType.spatial_video ||
            item.FileType === createPostFileType.gif
        )
      ) {
        return true
      } else if (
        type === createPostFileType.image &&
        (ipfsFiles.filter(item => item.FileType === createPostFileType.image)
          .length >= 4 ||
          ipfsFiles.find(
            item =>
              item.FileType === createPostFileType.video ||
              item.FileType === createPostFileType.spatial_video ||
              item.FileType === createPostFileType.gif
          ))
      ) {
        return true
      } else
        return !!(
          type === createPostFileType.file &&
          ipfsFiles.find(item => item.FileType === createPostFileType.file)
        )*/
    }

    if (onlyVideo) {
      const isEmptyLink = ipfsFiles.some(item => Boolean(item.FileLink))

      return (
        <div className={styles.fileButtonsContainer}>
          <CustomTooltip
            title={'Add video'}
            placement="top"
            disablePortal
            children={
              <VideoSvg
                className={cn({
                  [styles.disabledIcon]: isEmptyLink,
                  [styles.videoIcon]: true,
                })}
                onClick={() =>
                  !isEmptyLink && uploadImage(createPostFileType.video)
                }
              />
            }
          />
          <input
            disabled={isEmptyLink}
            accept="video/mp4,video/webm,video/quicktime"
            style={{ display: 'none' }}
            id={`${postId}_add_video`}
            type="file"
            onChange={e => addFile(e, createPostFileType.video)}
          />
        </div>
      )
    }

    if (onlyEmoji) {
      return (
        <>
          {main.isPWA ? (
            <AddFileButtonsPWA
              onlyEmoji
              getStatusBlocked={getStatusBlocked}
              uploadImage={uploadImage}
              onOpenEmojiPWA={handlerOpenEmojiPWA}
            />
          ) : (
            <div className={styles.fileButtonsContainer}>
              <ErrorBoundary fallback={<div>Error</div>}>
                <CustomTooltip
                  title={'Add emoji'}
                  placement="top"
                  disablePortal
                  children={
                    <SmileSvg
                      className={cn({
                        [styles.disabledIcon]: false,
                        [styles.smileIcon]: true,
                      })}
                      onClick={(e: any) => onOpenEmoji(e)}
                    />
                  }
                />
              </ErrorBoundary>
            </div>
          )}
        </>
      )
    }

    return (
      <>
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.image)}
          accept=".jpg, .jpeg, .png"
          style={{ display: 'none' }}
          id={`${postId}_add_image`}
          type="file"
          onChange={e => addFile(e, createPostFileType.image)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.video)}
          accept="video/mp4,video/webm,video/quicktime"
          style={{ display: 'none' }}
          id={`${postId}_add_video`}
          type="file"
          onChange={e => addFile(e, createPostFileType.video)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.gif)}
          accept=".gif"
          style={{ display: 'none' }}
          id={`${postId}_add_gif`}
          type="file"
          onChange={e => addFile(e, createPostFileType.gif)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.image)}
          accept=".jpg, .jpeg, .png"
          style={{ display: 'none' }}
          id={`${postId}_add_image360`}
          type="file"
          onChange={e => addFile(e, createPostFileType.image360)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.audio)}
          accept="audio/mp3, audio/wav, audio/ogg"
          style={{ display: 'none' }}
          id={`${postId}_add_audio`}
          type="file"
          onChange={e => addFile(e, createPostFileType.audio)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.image)}
          accept=".gltf, .glb"
          style={{ display: 'none' }}
          id={`${postId}_add_image3D`}
          type="file"
          onChange={e => addFile(e, createPostFileType.image3D)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.image)}
          accept=".usdz"
          style={{ display: 'none' }}
          id={`${postId}_add_image3D_usdz`}
          type="file"
          onChange={e => addFile(e, createPostFileType.image3D_usdz)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.spatial_video)}
          accept="video/quicktime, .heic, .m4v"
          style={{ display: 'none' }}
          id={`${postId}_add_spatial_video`}
          type="file"
          onChange={e => addFile(e, createPostFileType.spatial_video)}
        />
        <input
          multiple
          // disabled={getStatusBlocked(createPostFileType.file)}
          // accept=".txt, .pdf, .doc, .dot, .docx, .dotx, .docm, .dotm, .xls, .xlt, .xla, .xlsx, .xltx, .xlsm, .xltm, .xlam, .xlsb"
          accept=".doc, .docx, .pdf, .txt, .xls, .xlsx, .html"
          style={{ display: 'none' }}
          id={`${postId}_add_file`}
          type="file"
          onChange={e => addFile(e, createPostFileType.file)}
        />
        {main.isPWA ? (
          <AddFileButtonsPWA
            getStatusBlocked={getStatusBlocked}
            uploadImage={uploadImage}
            onOpenEmojiPWA={handlerOpenEmojiPWA}
          />
        ) : (
          <div className={styles.fileButtonsContainer}>
            <CustomTooltip
              title={'Add image'}
              placement="top"
              disablePortal
              children={
                <ImageSvg
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.image
                    ),
                    [styles.imageIcon]: true,
                  })}
                  onClick={() =>
                    !getStatusBlocked(createPostFileType.image) &&
                    uploadImage(createPostFileType.image)
                  }
                />
              }
            />
            <CustomTooltip
              title={'Add video'}
              placement="top"
              disablePortal
              children={
                <VideoSvg
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.video
                    ),
                    [styles.videoIcon]: true,
                  })}
                  onClick={() =>
                    !getStatusBlocked(createPostFileType.video) &&
                    uploadImage(createPostFileType.video)
                  }
                />
              }
            />
            <CustomTooltip
              title={'Add emoji'}
              placement="top"
              disablePortal
              children={
                <SmileSvg
                  className={cn({
                    [styles.disabledIcon]: false,
                    [styles.smileIcon]: true,
                  })}
                  onClick={(e: any) => onOpenEmoji(e)}
                />
              }
            />
            <CustomTooltip
              title={'Add GIF'}
              placement="top"
              disablePortal
              children={
                <GifSvg
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.gif
                    ),
                    [styles.gifIcon]: true,
                  })}
                  onClick={() =>
                    !getStatusBlocked(createPostFileType.gif) &&
                    uploadImage(createPostFileType.gif)
                  }
                />
              }
            />
            <CustomTooltip
              title={'Add 360 image'}
              placement="top"
              disablePortal
              children={
                <View360Svg
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.image
                    ),
                    [styles.view360Icon]: true,
                  })}
                  onClick={() =>
                    !getStatusBlocked(createPostFileType.image) &&
                    uploadImage(createPostFileType.image360)
                  }
                />
              }
            />
            <CustomTooltip
              title={'Add audio'}
              placement="top"
              disablePortal
              children={
                <AudioSvg
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.audio
                    ),
                    [styles.audioIcon]: true,
                  })}
                  onClick={() =>
                    !getStatusBlocked(createPostFileType.audio) &&
                    uploadImage(createPostFileType.audio)
                  }
                />
              }
            />
            <CustomTooltip
              title={
                <div
                  className={cn({
                    [styles.tooltip3dImage]: true,
                  })}
                >
                  <span
                    onClick={() =>
                      !getStatusBlocked(createPostFileType.image) &&
                      uploadImage(createPostFileType.image3D)
                    }
                  >
                    GLB
                  </span>
                  <span
                    onClick={() =>
                      !getStatusBlocked(createPostFileType.image) &&
                      uploadImage(createPostFileType.image3D_usdz)
                    }
                  >
                    USDZ
                    <SpatialVideoSvg
                      style={{
                        filter: 'invert(1)',
                        transform: 'translateY(1px)',
                        marginLeft: '4px',
                      }}
                    />
                  </span>
                </div>
              }
              placement="top"
              disablePortal={false}
              children={
                <Cube3dSvg
                  ref={tooltip3dImageRef}
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.image
                    ),
                    [styles.cube3dIcon]: true,
                  })}
                  onClick={() => setIs3dImageTooltipOpen(e => !e)}
                />
              }
              isOpen={is3dImageTooltipOpen}
              isInteractive={true}
            />
            {withSpatialVideo && (
              <CustomTooltip
                title={'Add spatial media'}
                placement="top"
                disablePortal
                children={
                  <SpatialVideoSvg
                    className={cn({
                      [styles.disabledIcon]: getStatusBlocked(
                        createPostFileType.video
                      ),
                      [styles.spatialVideoIcon]: true,
                    })}
                    onClick={() => {
                      !getStatusBlocked(createPostFileType.video) &&
                        uploadImage(createPostFileType.spatial_video)
                    }}
                  />
                }
              />
            )}
            <CustomTooltip
              title={'Add file'}
              placement="top"
              disablePortal
              children={
                <FileAddSvg
                  className={cn({
                    [styles.disabledIcon]: getStatusBlocked(
                      createPostFileType.file
                    ),
                    [styles.fileAddIcon]: true,
                  })}
                  onClick={() =>
                    !getStatusBlocked(createPostFileType.file) &&
                    uploadImage(createPostFileType.file)
                  }
                />
              }
            />
          </div>
        )}
      </>
    )
  }
)

AddFileButtons.displayName = 'AddFileButtons'
