import React, {CSSProperties, FC, useEffect, useState} from 'react';
import {BaseEmoji, Picker} from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css'
import Modal from 'components/Modal';


interface IProps {
  windowHeight: number,
  onAddEmoji: (value: BaseEmoji) => void,
  textAreaId: string,
  mainModalStyle: CSSProperties,
  setMainModalStyle: (value: CSSProperties) => void,
  emojiClickPosition: {top: number, left: number, isEnoughSpace: boolean} | undefined
}

export const EmojiModal: FC<IProps> = ({onAddEmoji, textAreaId, mainModalStyle, setMainModalStyle, emojiClickPosition, windowHeight}) =>  {
  const [openPickerEmoji, setOpenPickerEmoji] = useState<boolean>(false)
  const [coordinatePickerEmoji, setCoordinatePickerEmoji] = useState({top: 0, left: 0})
  const [isEnoughSpaceForPickerEmoji, setIsEnoughSpaceForPickerEmoji] = useState<boolean>(true)

  const pickerEmojiHeight = windowHeight < 650 ? 260 : 353;
  const pickerEmojiWidth = window.innerWidth <= 540 ? '99vw' : '425px';

  useEffect(() => {
    emojiClickPosition && onOpenEmoji(emojiClickPosition.top, emojiClickPosition.left, emojiClickPosition.isEnoughSpace)
  }, [emojiClickPosition])

  useEffect(() => {
    const activeTextArea = document.getElementById(textAreaId)
    if (mainModalStyle.top) {
      activeTextArea?.scrollIntoView({block: 'center'})
    } else {
      activeTextArea?.focus()
    }
  }, [mainModalStyle])

  //---▼▼▼--- Open emoji modal  ---▼▼▼---\\
  const onOpenEmoji = async (top: number, left: number, isEnoughSpace: boolean) => {
    if (window.innerWidth <= 540) {
      //New
      if (openPickerEmoji) return onCloseEmojiPicker()

      //Old
      // const activeTextArea = document.getElementById(textAreaId)
      // activeTextArea && await hideKeyboard(activeTextArea as HTMLTextAreaElement);

      setMainModalStyle({
        position: 'fixed',
        top: '5px',
        height: windowHeight - pickerEmojiHeight - 15
      })
    } else {
      setCoordinatePickerEmoji({
        top: openPickerEmoji ? 0 : top,
        left: openPickerEmoji ? 0 : left + 15,
      })
      setIsEnoughSpaceForPickerEmoji(isEnoughSpace)
    }
    setOpenPickerEmoji(!openPickerEmoji)
  }

  const getPickerPositionLeft = () => {
    if (window.innerWidth <= 540) {
      return 'auto'
    } else {
      return coordinatePickerEmoji.left - 170;
    }
  }

  const getPickerPositionTop = () => {
    if (window.innerWidth <= 540) {
      // return window.innerHeight - 353 - 5
      return 'auto'
    } else {
      return coordinatePickerEmoji.top;
    }
  }

  const getPickerPositionBottom = () => {
    if (window.innerWidth <= 540) {
      // return window.innerHeight - 353 - 5
      return 0
    } else {
      return 'auto'
    }
  }

  const getPickerPositionType = () => {
    if (window.innerWidth <= 540) {
      return 'fixed'
    } else {
      return isEnoughSpaceForPickerEmoji ? 'absolute' : 'static'
    }
  }

  const onCloseEmojiPicker = () => {
    if (window.innerWidth <= 540) {
      setMainModalStyle({
        height: 'auto'
      })
    }
    setOpenPickerEmoji(false)
  }

  return (
    <Modal
      freeBlock
      withoutBlurBox
      contentStyle={{padding: 0}}
      zeroPadding
      onClose={onCloseEmojiPicker}
      open={openPickerEmoji}
      children={
        <Picker
          showSkinTones={false}
          showPreview={false}
          emojiSize={25}
          emoji="point_up"
          useButton={false}
          native={true}
          perLine={8}
          // set="twitter"
          // enableFrequentEmojiSort
          style={{
            position: getPickerPositionType(),
            top: getPickerPositionTop(),
            left: getPickerPositionLeft(),
            bottom: getPickerPositionBottom(),
            width: pickerEmojiWidth,
            height: pickerEmojiHeight,
            overflow: 'hidden'
          }}
          onSelect={onAddEmoji}
        />
      }
    />
  );
}
