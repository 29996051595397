import { useEffect, useState } from 'react'

import { getTimeAgo } from '../functions'

export const useUpdateTimeAgo = (timeStamp: string) => {
  const [timeAgo, setTimeAgo] = useState(() => getTimeAgo(timeStamp))

  useEffect(() => {
    const updateTimeAgo = () => {
      setTimeAgo(getTimeAgo(timeStamp))
    }

    const interval = setInterval(updateTimeAgo, 30000)

    return () => clearInterval(interval)
  }, [timeStamp])

  return timeAgo
}
